import React, { useState } from 'react';
import { Menu, MenuItem } from "@mui/material";
import { AccountBox, Close, Reorder, Settings } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { AdminMenuUrls, AuthenticatedUrls, ConsultantAdminMenuUrls, CustomerAdminMenuUrls, MenuUrls, OnboardingUrls } from "Routes/Urls";
import { Roles } from "Util/Const";
import { setAppStateSetting, userSignOut } from "Features/AppState/AppStateSlice";
import { InfoText, TwKey } from "Components/StandardTextComponentsWithTranslation"

const Navbar = () => {
  const appState = useSelector((state) => state.settings.appState);
  const dispatch = useDispatch();

  const [stateOpen, setStateOpen] = useState(null);
  const [stateAnchorEl, setStateAnchorEl] = useState(null);

  const DropDowns = { account: "account", adminMenu: "adminMenu" };

  function handleOpen(menu, anchorEl) {
    setStateOpen(menu)
    setStateAnchorEl(anchorEl);
  }

  function handleClose(fun) {
    setStateOpen(null);
    setStateAnchorEl(null);
    
    if (fun && {}.toString.call(fun) === "[object Function]") {
      fun();
    }
  }

  const showMenu = appState.sideMenuOpen;
  const titleTextKey = appState.titleTextKey || "";
  const chapterInformationTextKey = appState.chapterInformationTextKey || "";
  const title = appState.title || "";

  let links = MenuUrls;

  if (appState.customer == null) {
    links = OnboardingUrls;
  } else if (appState.role === Roles.consultantAdmin.value) {
    links = [...links, ...ConsultantAdminMenuUrls];
  } else if (appState.role === Roles.admin.value) {
    links = [...links, ...AdminMenuUrls];
  } else if (appState.role === Roles.customerAdmin.value) {
    links = [...links, ...CustomerAdminMenuUrls];
  }

  return (
    <div className="navbar">
      <div style={{ display: "flex", flexDirection: "row", marginLeft: "20px", alignItems: "center" }}>
        <div style={{ display: "flex", flexDirection: "row", padding: "0px", margin: "0px", alignItems: "center" }}>
          {showMenu && (
            <div onClick={() => dispatch( setAppStateSetting( {  sideMenuOpen: false } )) }>
              <Close
                style={{
                  width: "35px",
                  height: "35px",
                  padding: "10px",
                  backgroundColor: "var(--color5)",
                  borderRadius: "27.5px",
                  cursor: "pointer",
                }}
              />
            </div>
          )}
          {!showMenu && (
            <div onClick={() => dispatch( setAppStateSetting( { sideMenuOpen: true } )) }>
              <Reorder
                style={{
                  width: "35px",
                  height: "35px",
                  padding: "10px",
                  backgroundColor: "var(--color5)",
                  borderRadius: "27.5px",
                  cursor: "pointer",
                }}
              />
            </div>
          )}
        </div>
        </div>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", textAlign: "center" }}>
          <h1 className="navbarH1">
            {
              titleTextKey !==  "" ? <TwKey textKey={ titleTextKey } /> : title 
            }
            &nbsp;
            {
              chapterInformationTextKey !==  "" ? <InfoText textKey={ chapterInformationTextKey } /> : "" 
            }
            </h1>
        </div>
        <div style={{ display: "flex", flexDirection: "row", marginRight: "30px", alignItems: "center" }}>
          {
            appState.role.localeCompare(Roles.customer.value) === 0 ?
              ""
            :
              <div
                style={{ padding: "10px", marginRight: "5px", backgroundColor: "var(--color5)", cursor: "pointer", display: "flex" }}
                onClick={(event) => handleOpen(DropDowns.adminMenu, event.currentTarget)}
              >
                <Settings
                  sx={{ fontSize: 50 }}
                />
              </div>
          }
          <div
            style={{ padding: "10px", backgroundColor: "var(--colorButtonBG)", color: "white", display: "flex", cursor: "pointer" }}
            onClick={(event) => handleOpen(DropDowns.account, event.currentTarget)}
          >
            <AccountBox
              sx={{ fontSize: 50 }}
            />
            {stateOpen === DropDowns.account}
          </div>
        <div>
          {
            appState.role.localeCompare(Roles.customer.value) === 0 ?
              ""
            :
              <Menu
                elevation={0}
                anchorEl={stateAnchorEl}
                keepMounted
                open={stateOpen === DropDowns.adminMenu}
                onClose={ handleClose }
                style={{ top: "0px", width: "300px" }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                {links.map((url, idx) => (
                  <MenuItem
                    key={idx} onClick={ handleClose }
                    style={{ borderBottom: "1px solid #EEF2F6"}}
                  >
                    <Link
                      to={url.path}
                      style={{ textDecoration: "none", color: "black", width: "100%" }}
                    >
                      <p>{url.name}</p>
                    </Link>
                  </MenuItem>
                ))}
              </Menu>
            }

            <Menu
              elevation={0}
              anchorEl={stateAnchorEl}
              keepMounted open={stateOpen === DropDowns.account}
              onClose={handleClose}
              style={{ top: "0px", width: "300px" }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem
                onClick={() => handleClose()}
                style={{ borderBottom: "1px solid #EEF2F6"}}
              >
                <Link
                  to={AuthenticatedUrls.content.subPages.profile.path}
                  style={{ textDecoration: "none", color: "black", width: "100%" }}
                >
                  <p>Profil</p>
                </Link>
              </MenuItem>
              <MenuItem onClick={() => handleClose(() => dispatch( userSignOut()) )}>
                <p>Logga ut</p>
              </MenuItem>
            </Menu>
          </div>
        </div>
    </div>
  );
}

export default Navbar;