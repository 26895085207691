import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { API } from "aws-amplify";
import Loading from "Components/Loading";
import PropertyTable from "Components/PropertyTable";
import { listCustomers } from "graphql/queries";
import { AuthenticatedUrls } from "Routes/Urls";
import { CustomerModel } from "Models/Models";
import { FillModel } from "Models/FillModel";
import { Roles } from "Util/Const";

const Customer = () => {
  const navigate = useNavigate();
  const appState = useSelector((state) => state.settings.appState);
  
  const [stateIsLoading, setStateIsLoading] = useState(true);
  const [stateCustomers, setStateCustomers] = useState(null);

  useEffect(() => {
    async function asyncUseEffect(){
      //Check if we have a customerId.
      
      if(!appState?.customer){
        //The customer object is empty, do nothinng for now!
        //ToDo: Stop customer object from being loaded when it is empty.
      }else{
        let items = (await API.graphql({ query: listCustomers }))?.data?.listCustomers?.items
                      .sort((a, b) => a.name.localeCompare(b.name));
        
        //if we are a consultantAdmin we can only view our own customers and our own customer.
        if (appState.role === Roles.consultantAdmin.value) {
          items = items.filter((item) => item.consultatManagerId === appState.userId || item.id === appState.customer.id);
        } else if ( appState.role === Roles.customerAdmin.value) {
          //items = items.filter((item) => item.id === appState.customer.id);
          //Push to edit users since a customerAdmin should not be able to view this page.
          navigate({ pathname: AuthenticatedUrls.admin.subPages.users.path });
        }

        setStateCustomers( FillModel(CustomerModel, items) );

        //Scroll to the top of the page.
        window.scrollTo(0, 0);

        setStateIsLoading( false );
      }
    }

    asyncUseEffect();
  }, []);
  
  if ( stateIsLoading ) {
    return <Loading />;
  } else {
    return (
      <PropertyTable
        data={stateCustomers}
        model={CustomerModel}
        editUrl={AuthenticatedUrls.admin.subPages.editCustomers.path}
        header="Administrera kunder"
      />
    );
  }
}

export default Customer;