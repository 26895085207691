import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { setAppStateSetting } from "Features/AppState/AppStateSlice";
import EditView from "Components/EditView";
import { Roles, SectionTypes, Steps } from "Util/Const";
import {
  createCustomer,
  createSection,
  createStep,
  createSurvey,
  deleteCustomer,
  deleteCustomerSubscription,
  deleteSection,
  updateCustomer,
  updateSurvey,
} from "graphql/mutations";
import { AuthenticatedUrls } from "Routes/Urls";
import { DeleteItemById, GetItemById, GetAllItems, GetOrCreateAndGetItem, SaveOrUpdate } from "Util/Api";
import { getCustomer, getSection, listSurveyTemplates } from "graphql/queries";
import Auth from "@aws-amplify/auth";

const EditCustomer = () => {
  const location = useLocation();

  const appState = useSelector((state) => state.settings.appState);

  const [stateAfterSaveUrl, setStateAfterSaveUrl] = useState( "" );

  async function preDelete() {
    try {
      const customer = await GetItemById(location.state.id, getCustomer);
      for (let section of customer.Sections.items) {
        await DeleteItemById(section.id, deleteSection, null);
      }
      for (let section of customer.CustomerSubscriptions.items) {
        await DeleteItemById(section.id, deleteCustomerSubscription, null);
      }
      //should we delete users also?
    } catch (err) {
      console.error(err);
    }
  }

  //Additional things to do when saving customer.
  async function afterSave(newCustomerId) {
    try {
      if (location.state?.id != null) {
        //old customer
      } else {
        // new custoemr
        const sureveyTemplate = await GetAllItems(listSurveyTemplates, null);
        
        for (let sectionType of Object.values(SectionTypes)) {
          const newSection = { sectionType, active: true, customerId: newCustomerId };

          //Get or create the current section.
          const section = await GetOrCreateAndGetItem(newSection, getSection, createSection);

          if (section == null) {
            throw new Error("Could not get/create section from db , ", sectionType);
          } else {
            //Create new survey step of section
            const newSurveyStep = { done: false, sectionId: section.id, stepType: Steps.chapters.kartlaggning.value };
            const surveyStep = await SaveOrUpdate(newSurveyStep, null, createStep, null);
            //Go through the template and add these to the curstomers step.
            for (let templateSurvey of sureveyTemplate.filter((x) => x.sectionType === section.sectionType)) {
              let newSurvey = { stepId: surveyStep.id, checked: false, name: templateSurvey.name, order: templateSurvey.order };
              if ((await SaveOrUpdate(newSurvey, updateSurvey, createSurvey, null)) === null) {
                throw new Error("could not add Survey!", newSurvey);
              }
            }
          }
        }
      }

      //if we were sent there by onboarding we have a userID. This means that we should add our new customerID to that user.
      if (location.state.addCustomerToCurrentUser === true) {
        const user = await Auth.currentAuthenticatedUser();
        await Auth.updateUserAttributes(user, {
          "custom:customerId": newCustomerId,
        });
        const myCustomer = await GetItemById(newCustomerId, getCustomer);

        setAppStateSetting( {  customer: myCustomer } );

        setStateAfterSaveUrl( AuthenticatedUrls.admin.subPages.users.path );
      }
      //if we are saving as a consultantAdmin we can mark this customer as our.
      if ( appState.role === Roles.consultantAdmin.value ) {
        await SaveOrUpdate({ id: newCustomerId, consultatManagerId: appState.userId }, updateCustomer, null, null);
      }
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <EditView
      id={ location.state?.id }
      data={ location.state?.data }
      model={ location.state?.model }
      header={ location.state?.id ? "Redigera kund" : "Skapa kund" }
      dbCreate={ createCustomer }
      dbUpdate={ updateCustomer }
      dbDelete={ deleteCustomer }
      afterSaveUrl={stateAfterSaveUrl}
      afterSave={ afterSave }
      preDelete={ preDelete }
    />
  );
}

export default EditCustomer;
