import { ListUsers } from "Util/AdminQuerries";
//based on general guidelines of GDPR
const daysWarningLimit = 90;

export async function getDisabledUsers() {
  const users = (await ListUsers()).Users.filter((user) => user.Enabled === false).map((user) => ({
    id: user.Username,
    email: user.Attributes.find((a) => a.Name === "email")?.Value,
    familyName: user.Attributes.find((a) => a.Name === "family_name")?.Value,
    givenName: user.Attributes.find((a) => a.Name === "given_name")?.Value,
    customerId: user.Attributes.find((a) => a.Name === "custom:customerId")?.Value,
    title: user.Attributes.find((a) => a.Name === "custom:title")?.Value,
    lastModified: user.UserLastModifiedDate,
    days: getDays(user.UserLastModifiedDate),
    warning: getDays(user.UserLastModifiedDate) >= daysWarningLimit,
  }));
  return users;
}

function getDays(startDate) {
  const seconds = Math.floor((new Date() - new Date(startDate)) / 1000);
  return Math.floor(seconds / (3600 * 24));
}
