import React, { Fragment } from "react";
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AuthenticatedUrls } from "Routes/Urls";
import { TwKey } from "Components/StandardTextComponentsWithTranslation";

const ChapterSectionStepBar = ( props ) => {
  const appState = useSelector((state) => state.settings.appState);
  const navigate = useNavigate();

  return (
    <div
      className="chapterSectionStepBar"
    >
      <div
        className="chapterSectionStepBarContainer"
      >
        <ul className="chapterSectionStepBarSteps">
          {
            Object.values(AuthenticatedUrls.content.subPages.chapterSection.subPages)
              .filter((x) =>x.showInChapterSectionStepBar === true )
              .map((currentSubPage, idx) =>
            {
              //Insert correct URL to sectionChapter.
              const pagePath = currentSubPage.path.replace(":chapterSection", appState.currentChapterSectionTypeWithUrl?.subPath);
              
              //Mark currently active chapterSectionStep.
              let stepButtonClass = currentSubPage.chapterSectionStepType.value === appState.currentChapterSectionStepTypeWithUrl?.chapterSectionStepType.value ? "active" : "";

              //Mark as clickable.
              stepButtonClass = props.chapterSectionStepBarClickable ? stepButtonClass + " " + "chapterSectionStepBarItemActive" : stepButtonClass;
            
              return (
                <Fragment key={idx}>
                  {
                    props.chapterSectionStepBarClickable ?
                      <li
                        className={ stepButtonClass }
                        onClick={() =>  navigate(pagePath)}
                      >
                        <TwKey textKey={currentSubPage.headerTextKey} />
                        &nbsp;
                      </li> 
                    :
                      <li
                        className={ stepButtonClass }
                      >
                        <TwKey textKey={currentSubPage.headerTextKey} />
                        &nbsp;
                      </li> 
                  }
                </Fragment>
              );
            })}
        </ul>
      </div>
    </div>
  );
}

export default ChapterSectionStepBar;