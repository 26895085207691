import { ChapterSectionTypes, ChapterSectionStepTypes } from "Util/Const";

export const UnauthenticatedUrls = {
  index: { 
    path: "/",
    name: "index"
  },
  landing: {
    path: "/landing",
    name: "Landing",
    subPages: {
      login: {
          path: "/landing/login",
          name: "Login"
      },
      completeNewPassword: {
          path: "/landing/completeNewPassword",
          name: "Byt lösenord"
      },
      /*
        changePassword: {
          path: "/landing/changePassword",
          name: "Byt lösenord"
        },
      */
      recover: {
        path: "/landing/recover",
        name: "Återställ"
      },
      signup: {
        path: "/landing/signup",
        name: "Registrera"
      },
      verify: {
        path: "/landing/verify",
        name: "Verifiera"
      },
    },
  },
};

export const AuthenticatedUrls = {
  admin: {
    path: "/admin",
    name: "Admin",
    subPages: {
      customers: { path: "/admin/customers", name: "Administrera kunder" },
      editCustomers: { path: "/admin/customers/edit", name: "Redigera kund" },

      users: { path: "/admin/users", name: "Användare" },
      editUsers: { path: "/admin/users/edit", name: "Redigera användare" },

      customerSubscription: {
        path: "/admin/customerSubscription",
        name: "Prenumerationer",
      },
      editCustomerSubscription: {
        path: "/admin/customerSubscription/edit",
        name: "Redigera prenumeration",
      },
      riskAnalisysAndActionYears: {
        path: "/admin/riskanalisysandactionyear/riskanalisysandactionyear",
        name: "Hantera år",
      },
      addRiskAnalisysAndActionYears: {
        path: "/admin/riskanalisysandactionyear/addriskanalisysandactionyear",
        name: "Skapa nytt år",
      },
      handleMaterials: {
        path: "/admin/handleMaterials/handleMaterials",
        name: "Hantera material",
      },
      preparationItemTemplates: {
        path: "/admin/preparationItemTemplates/preparationItemTemplates",
        name: "Förberedelsemallar",
      },
      //Note: These are commented now since they will be implemented in a future version.
      // services: { path: "/admin/services", name: "Tjänster" },
      // editService: { path: "/admin/service/edit", name: "Redigera tjänster" },

      disabledUsersOverview: {
        path: "/admin/disabledUsersOverview",
        name: "Översikt avaktiverade användare",
      },
      editLocales: {
        path: "/admin/editLocales",
        name: "Redigera texter"
      }
    },
  },
  content: {
    pathId: "e81f449b-b291-44a9-a07d-ae26f1b7d30b",
    path: "/kapitel",
    name: "Hem",
    subPages: {
      welcome: {
        pathId: "d9a15db9-a007-41e9-ab3d-13a09daf9f1e",
        path: "/kapitel/valkommen",
        name: "Välkommen",
        chapterSectionType: ChapterSectionTypes.welcome,
        showInSideMenu: true,
      },
      introduction: {
        pathId: "d9f15db9-a007-41b9-ab3d-13a07daf9f1e",
        path: "/kapitel/introduktion",
        name: "Introduktion",
        chapterSectionType: ChapterSectionTypes.introduction,
        showInSideMenu: true,
      },
      materials: {
        pathId: "d9fd5db9-a007-41b9-ab3d-12a07daf9f1e",
        path: "/kapitel/material",
        name: "Material",
        chapterSectionType: ChapterSectionTypes.materials,
        showInSideMenu: true,
      },
      dbTest: {
        pathId: "d9fe5db9-a017-41b9-ab6d-12a07daf9f1e",
        path: "/kapitel/dbtest",
        name: "Förberedelser",
        chapterSectionType: ChapterSectionTypes.dbTest,
        showInSideMenu: true,
      },
      preparations: {
        pathId: "d9fe5db9-a017-41b9-ab3d-12a07daf9f1e",
        path: "/kapitel/forberedelser",
        name: "Förberedelser",
        chapterSectionType: ChapterSectionTypes.preparations,
        showInSideMenu: true,
      },
      chapterSection: {
        pathId: "d9f15db9-a007-41b9-ab3d-12a07daf9f1e",
        path: "/kapitel/:section",
        name: "Kapitel",
        showInSideMenu: true,
        subPages: {
          risks: {
            pathId: "e75f74fc-b94d-4358-9606-7f293f2a9a39",
            path: "/kapitel/:chapterSection/risker",
            name: "Undersöka risker",
            headerTextKey: "10081",
            showInChapterSectionStepBar: true,
            chapterSectionStepType: ChapterSectionStepTypes.userChangableChapters.risks,
          },
          risksEdit: {
            pathId: "f694c2b9-7a8e-4fdc-9a7c-b458a1f9ce25",
            path: "/kapitel/:chapterSection/risker/:id",
            name: "Redigera risk",
            headerTextKey: "10082",
            showInChapterSectionStepBar: false,
            chapterSectionStepType: ChapterSectionStepTypes.userChangableChapters.risks,
          },
          riskAnalysisAndAction: {
            pathId: "88a4f3f7-4e05-4caf-9bc5-6af117a2fa9a",
            path: "/kapitel/:chapterSection/analysochatgarder",
            name: "Analys och åtgärder",
            headerTextKey: "10083",
            showInChapterSectionStepBar: true,
            chapterSectionStepType: ChapterSectionStepTypes.userChangableChapters.riskAnalysisAndAction,
          },
          riskAnalysisAndActionEdit: {
            pathId: "59bbf276-30e4-40a3-98ad-ed5a71a2221d",
            path: "/kapitel/:chapterSection/analysochatgarder/:id",
            name: "Redigera analys och åtgärd",
            headerTextKey: "10084",
            showInChapterSectionStepBar: false,
            chapterSectionStepType: ChapterSectionStepTypes.userChangableChapters.riskAnalysisAndAction,
          },
          summery: {
            pathId: "a67bb9b2-8d74-4de4-8faf-5fae0bed1d75",
            path: "/kapitel/:chapterSection/summering",
            name: "Summering",
            headerTextKey: "10085",
            showInChapterSectionStepBar: true,
            chapterSectionStepType: ChapterSectionStepTypes.userChangableChapters.summery,
          },
        },
      },
      contact: {
        pathId: "a895a9e9-756b-4728-a639-a0ade7a84fcf",
        path: "/kapitel/kontakt",
        name: "Kontakt",
        showInSideMenu: false,
      },
      noSubscription: {
        pathId: "99d9d92d-fce4-4964-bbc6-79c624cfb1cc",
        path: "/kapitel/prenumeationsaknas",
        name: "PrenumerationSaknas",
        showInSideMenu: false,
      },
      equalTreatmentPlanStatus: {
        pathId: "135797cf-a09f-4b69-9c38-6ff49e8c7719",
        path: "/kapitel/status",
        name: "Status",
        headerTextKey: "10145",
        showInChapterSectionStepBar: true,
        chapterSectionStepType: ChapterSectionStepTypes.equalTreatmentPlan.equalTreatmentPlanStatus,
      },
      riskActionsAndFollowUp: {
        pathId: "ef1f3e6c-9e81-4e4e-8b63-f6b984dec418",
        path: "/kapitel/atgarder",
        name: "Åtgärder",
        headerTextKey: "10144",
        showInChapterSectionStepBar: true,
        chapterSectionStepType: ChapterSectionStepTypes.equalTreatmentPlan.riskActionsAndFollowUp
      },
      equalTreatmentPlanReport: {
        pathId: "56c6b282-a62b-4594-8ed7-482794fae50a",
        path: "/kapitel/likabehandlingsplan",
        name: "Likabehandlingsplan",
        headerTextKey: "10146",
        showInChapterSectionStepBar: true,
        chapterSectionStepType: ChapterSectionStepTypes.equalTreatmentPlan.equalTreatmentPlanReport,
      },
      profile: {
        pathId: "d8e28b63-2599-4da6-b9cb-3414c0379f59",
        path: "/kapitel/profil",
        name: "Profil",
      },
    },
  },
  onboarding: {
    pathId: "57a50701-73ed-47b1-97fc-be52cbc6ed3f",
    path: "/onboarding",
    name: "Onboarding",
  },
};

export const ChapterSectionTypeUrls = {
  welcome: {
    name: "Välkommen",
    subPath: "valkommen",
    chapterSectionType: ChapterSectionTypes.welcome,
  },
  introduction: {
    name: "Introduktion",
    subPath: "introduktion",
    chapterSectionType: ChapterSectionTypes.introduction,
  },
  materials: {
    name: "Material",
    subPath: "material",
    chapterSectionType: ChapterSectionTypes.materials,
  },
  preparations: {
    name: "Förberedelser",
    subPath: "forberedelser",
    chapterSectionType: ChapterSectionTypes.preparations,
  },
  workingConditions: {
      name: "Arbetsförhållanden",
      subPath: "arbetsforhallanden",
      chapterSectionType: ChapterSectionTypes.workingConditions,
  },
  employmentAndParenthood: {
    name: "Föräldraskap och arbete",
    subPath: "foraldraskapocharbete",
    chapterSectionType: ChapterSectionTypes.employmentAndParenthood,
  },
  wagesAndEmploymentTerms:  {
    name: "Löner och anställningsvillkor",
    subPath: "lonerochanstallningsvilkor",
    chapterSectionType: ChapterSectionTypes.wagesAndEmploymentTerms,
  },
  recruitmentAndPromotion:  {
    name: "Rekrytering och befordran",
    subPath: "rekryteringochbefordran",
    chapterSectionType: ChapterSectionTypes.recruitmentAndPromotion,
  },
  educationAndSkillsDevelopment: {
    name: "Utbildning och övrig kompetensutveckling",
    subPath: "utbildningochovrigkompetensutveckling",
    chapterSectionType: ChapterSectionTypes.educationAndSkillsDevelopment,
  },
  measuresToPreventHarassment: {
    name: "Förebygg sexuella trakasserier",
    subPath: "forebyggsexuellatrakasserier",
    chapterSectionType: ChapterSectionTypes.measuresToPreventHarassment,
  },
  equalGenderDistribution: {
    name: "Främja jämn könsfördelning",
    subPath: "framjajamnkonsfordelning",
    chapterSectionType: ChapterSectionTypes.equalGenderDistribution,
  },
  equalTreatmentPlanStatus: {
    name: "Likabehandlingsplan",
    subPath: "status",
    chapterSectionType: ChapterSectionTypes.equalTreatmentPlanStatus,
  },
  riskActionsAndFollowUp: {
    name: "Åtgärder och uppfölning",
    subPath: "atgarder",
    chapterSectionType: ChapterSectionTypes.riskActionsAndFollowUp,
  },
  equalTreatmentPlanReport: {
    name: "Likabehandlingsplan",
    subPath: "likabehandlingsplan",
    chapterSectionType: ChapterSectionTypes.equalTreatmentPlanReport,
  },
};

export const ChapterSectionStepTypeUrls = {
  risks: {
    name: "Undersöka risker",
    subPath: "risker",
    showInChapterSectionStepBar: true,
    chapterSectionStepType: ChapterSectionStepTypes.userChangableChapters.risks,
  },
  risksEdit: {
    name: "Redigera risk",
    subPath: "risker",
    showInChapterSectionStepBar: false,
    chapterSectionStepType: ChapterSectionStepTypes.userChangableChapters.risks,
  },
  riskAnalysisAndAction: {
    name: "Analys och åtgärder",
    subPath: "analysochatgarder",
    showInChapterSectionStepBar: true,
    chapterSectionStepType: ChapterSectionStepTypes.userChangableChapters.riskAnalysisAndAction,
  },
  riskAnalysisAndActionEdit: {
    name: "Redigera analys och åtgärd",
    subPath: "analysochatgarder",
    showInChapterSectionStepBar: false,
    chapterSectionStepType: ChapterSectionStepTypes.userChangableChapters.riskAnalysisAndAction,
  },
  summery: {
    subPath: "summering",
    name: "Summering",
    showInChapterSectionStepBar: true,
    chapterSectionStepType: ChapterSectionStepTypes.userChangableChapters.summery,
  },
  equalTreatmentPlanStatus: {
    name: "Status",
    subPath: "status",
    showInChapterSectionStepBar: true,
    chapterSectionStepType: ChapterSectionStepTypes.equalTreatmentPlan.equalTreatmentPlanStatus,
  },
  riskActionsAndFollowUp: {
    name: "Åtgärder",
    subPath: "atgarder",
    showInChapterSectionStepBar: true,
    chapterSectionStepType: ChapterSectionStepTypes.equalTreatmentPlan.riskActionsAndFollowUp,
  },
  equalTreatmentPlanReport: {
    name: "Likabehandlingsplan",
    subPath: "likabehandlingsplan",
    showInChapterSectionStepBar: true,
    chapterSectionStepType: ChapterSectionStepTypes.equalTreatmentPlan.equalTreatmentPlanReport,
  },
}

export const MenuUrls = [AuthenticatedUrls.content.subPages.welcome];

export const AdminMenuUrls = [
  AuthenticatedUrls.admin.subPages.customers,
  //Note: This are commented now since they will be implemented in a future version.
  // AuthenticatedUrls.admin.subPages.services,
  AuthenticatedUrls.admin.subPages.disabledUsersOverview,
  AuthenticatedUrls.admin.subPages.riskAnalisysAndActionYears,
  AuthenticatedUrls.admin.subPages.handleMaterials,
  AuthenticatedUrls.admin.subPages.preparationItemTemplates,
  AuthenticatedUrls.admin.subPages.editLocales,
];
export const ConsultantAdminMenuUrls = [
  AuthenticatedUrls.admin.subPages.customers,
  AuthenticatedUrls.admin.subPages.riskAnalisysAndActionYears,
];
export const CustomerAdminMenuUrls = [
  AuthenticatedUrls.admin.subPages.users,
  AuthenticatedUrls.admin.subPages.riskAnalisysAndActionYears,
];
export const OnboardingUrls = [
  AuthenticatedUrls.onboarding,
];