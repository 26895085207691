import React from "react";
import Loading from "Components/Loading";

const Updating = () => {
  return (
    <div
      className="popupCentered"
        onClick={() => null}>
        <Loading />
    </div>
  );
}

export default Updating;