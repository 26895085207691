import React, { useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { AuthenticatedUrls, UnauthenticatedUrls } from "Routes/Urls";
import { UrlsAreEqual } from "Util/Utils";
import ProtectedRoute from "Routes/ProtectedRoute";
import { Roles } from "Util/Const";
import Admin from "Views/Admin/Admin";
import Customer from "Views/Admin/Sections/Customer/Customer";
import EditCustomer from "Views/Admin/Sections/Customer/EditCustomer";
import CustomerSubscription from "Views/Admin/Sections/CustomerSubscription/CustomerSubscription";
import EditCustomerSubscription from "Views/Admin/Sections/CustomerSubscription/EditCustomerSubscription";
import DisabledUsersOverview from "Views/Admin/Sections/DisabledUsersOverview/DisabledUsersOverview";
import EditUsers from "Views/Admin/Sections/Users/EditUsers";
import Users from "Views/Admin/Sections/Users/Users";
import Chapters from "Views/Chapters/Chapters";
import Welcome from "Views/Chapters/Sections/Introduction/Welcome";
import Introduction from "Views/Chapters/Sections/Introduction/Introduction";
import Contact from "Views/Chapters/Sections/Contact";
import NoSubscription from "Views/Chapters/Sections/NoSubscription";
import Index from "Views/Index";
import Landing from "Views/Landing/Landing";
import Login from "Views/Landing/Sections/Login";
import CompleteNewPassword from "Views/Landing/Sections/CompleteNewPassword";
//import ChangePassword from "Views/Landing/Sections/ChangePassword";
import Recover from "Views/Landing/Sections/Recover";
import Signup from "Views/Landing/Sections/Signup";
import Verify from "Views/Landing/Sections/Verify";
import Onboarding from "Views/Onboarding/Onboarding";
import Profile from "Views/Profile/Profile";
import Risks from "Views/Chapters/Sections/ChapterSectionSteps/Risks";
import RiskAnalisysAndAction from "Views/Chapters/Sections/ChapterSectionSteps/RiskAnalisysAndAction";
import Summery from "Views/Chapters/Sections/ChapterSectionSteps/Summery";
import RiskActions from "Views/Chapters/Sections/EqualTreatmentPlan/RiskActions";
import EqualTreatmentPlanStatus from "Views/Chapters/Sections/EqualTreatmentPlan/EqualTreatmentPlanStatus";
import EqualTreatmentPlanPreview from "Views/Chapters/Sections/EqualTreatmentPlan/EqualTreatmentPlanPreview";
import RiskAnalisysAndActionYear from "Views/Admin/Sections/RiskAnalisysAndActionYear/RiskAnalisysAndActionYear";
import AddRiskAnalisysAndActionYear from "Views/Admin/Sections/RiskAnalisysAndActionYear/AddRiskAnalisysAndActionYear";
import Materials from "Views/Chapters/Sections/Introduction/Materials";
import Preparations from "Views/Chapters/Sections/Introduction/Preparations";
import HandleMaterials from "Views/Admin/Sections/HandleMaterials/HandleMaterials";
import PreparationItemTemplates from "Views/Admin/Sections/PreparationItemTemplates/PreparationItemTemplates";
import EditLocales from "Views/Admin/Sections/Locales/EditLocales";

const Router = () => {
  const location = useLocation()
  const navigate = useNavigate();

  useEffect(() => {
    // if( UrlsAreEqual( location.pathname, UnauthenticatedUrls.root.path ) ){
    //   navigate( UnauthenticatedUrls.landing.path, { replace: true } );
    // }
  },[]);
 
  return (
    <Routes>
      {/* -------------------------- UnAuthenticatedRoutes ------------------------*/}

      <Route
        path={ UnauthenticatedUrls.index.path }
        element={ <Index /> }
      ></Route>
      {/* Landing routes */}
      
      <Route
        path={ UnauthenticatedUrls.landing.path }
        element={ <Landing /> }
      >
        <Route
          path={ UnauthenticatedUrls.landing.subPages.login.path }
          element={ <Login /> }
        />
        <Route
          path={ UnauthenticatedUrls.landing.subPages.completeNewPassword.path }
          element={ <CompleteNewPassword /> }
        />
        {/* <Route
          path={ UnauthenticatedUrls.landing.subPages.changePassword.path }
          element={ <ChangePassword /> }
        /> */}
        <Route
          path={ UnauthenticatedUrls.landing.subPages.recover.path }
          element={ <Recover /> }
        />
        <Route
          path={ UnauthenticatedUrls.landing.subPages.signup.path }
          element={ <Signup /> }
        />
        <Route
          path={ UnauthenticatedUrls.landing.subPages.verify.path }
          element={ <Verify /> }
        />
      </Route>
    
      {/* -------------------------- AuthenticatedRoutes ------------------------*/}
      {/* Chapters routes */}
      <Route
        path={ AuthenticatedUrls.content.path }
        element={ 
          <ProtectedRoute
            allowedRoles={ [Roles.admin, Roles.consultantAdmin, Roles.customerAdmin, Roles.customer] }
            element={<Chapters /> }
          />
        }
      >
        <Route
          path={ AuthenticatedUrls.content.subPages.welcome.path }
          element={ 
            <ProtectedRoute
              element={ <Welcome /> }
              allowedRoles={ [Roles.admin, Roles.consultantAdmin, Roles.customerAdmin, Roles.customer] }   
            />
          }
        />
        <Route
          path={ AuthenticatedUrls.content.subPages.introduction.path }
          element={ 
            <ProtectedRoute
              element={ <Introduction /> }
              allowedRoles={ [Roles.admin, Roles.consultantAdmin, Roles.customerAdmin, Roles.customer] }   
            />
          }
        />
        <Route
          path={ AuthenticatedUrls.content.subPages.materials.path }
          element={ 
            <ProtectedRoute
              element={ <Materials /> }
              allowedRoles={ [Roles.admin, Roles.consultantAdmin, Roles.customerAdmin, Roles.customer] }   
            />
          }
        />
        <Route
          path={ AuthenticatedUrls.content.subPages.preparations.path }
          element={ 
            <ProtectedRoute
              element={ <Preparations /> }
              allowedRoles={ [Roles.admin, Roles.consultantAdmin, Roles.customerAdmin, Roles.customer] }   
            />
          }
        />
        <Route
          path={ AuthenticatedUrls.content.subPages.chapterSection.subPages.risks.path }
          element={ 
            <ProtectedRoute
              element={ <Risks /> }
              allowedRoles={ [Roles.admin, Roles.consultantAdmin, Roles.customerAdmin, Roles.customer] }   
            />
          }
        />
        <Route
          path={ AuthenticatedUrls.content.subPages.chapterSection.subPages.risksEdit.path  }
          element={ 
            <ProtectedRoute
              element={ <Risks /> }
              allowedRoles={ [Roles.admin, Roles.consultantAdmin, Roles.customerAdmin, Roles.customer] }   
            />
          }
        />
        <Route
          path={ AuthenticatedUrls.content.subPages.chapterSection.subPages.riskAnalysisAndAction.path }
          element={ 
            <ProtectedRoute
              element={ <RiskAnalisysAndAction /> }
              allowedRoles={ [Roles.admin, Roles.consultantAdmin, Roles.customerAdmin, Roles.customer] }    
            />
          }
        />
        <Route
          path={ AuthenticatedUrls.content.subPages.chapterSection.subPages.riskAnalysisAndActionEdit.path  }
          element={ 
            <ProtectedRoute
              element={ <RiskAnalisysAndAction /> }
              allowedRoles={ [Roles.admin, Roles.consultantAdmin, Roles.customerAdmin, Roles.customer] }   
            />
          }
        />
        <Route
          path={ AuthenticatedUrls.content.subPages.chapterSection.subPages.summery.path }
          element={ 
            <ProtectedRoute
              element={ <Summery /> }
              allowedRoles={ [Roles.admin, Roles.consultantAdmin, Roles.customerAdmin, Roles.customer] }   
            />
          }
        />
        <Route
          path={ AuthenticatedUrls.content.subPages.contact.path }
          element={ 
            <ProtectedRoute
              element={ <Contact /> }
              allowedRoles={ [Roles.admin, Roles.consultantAdmin, Roles.customerAdmin, Roles.customer] }   
            />
          }
        />
        <Route
          path={ AuthenticatedUrls.content.subPages.noSubscription.path }
          element={ 
            <ProtectedRoute
              element={ <NoSubscription /> }
              allowedRoles={ [Roles.admin, Roles.consultantAdmin, Roles.customerAdmin, Roles.customer] }   
            />
          }
        />
        <Route
          path={ AuthenticatedUrls.content.subPages.equalTreatmentPlanStatus.path }
          element={ 
            <ProtectedRoute
              element={ <EqualTreatmentPlanStatus /> }
              allowedRoles={ [Roles.admin, Roles.consultantAdmin, Roles.customerAdmin, Roles.customer] }   
            />
          }
        />
        <Route
          path={ AuthenticatedUrls.content.subPages.riskActionsAndFollowUp.path }
          element={ 
            <ProtectedRoute
              element={ <RiskActions /> }
              allowedRoles={ [Roles.admin, Roles.consultantAdmin, Roles.customerAdmin, Roles.customer] }   
            />
          }
        />
        <Route
          path={ AuthenticatedUrls.content.subPages.equalTreatmentPlanReport.path }
          element={ 
            <ProtectedRoute
              element={ <EqualTreatmentPlanPreview /> }
              allowedRoles={ [Roles.admin, Roles.consultantAdmin, Roles.customerAdmin, Roles.customer] }    
            />
          }
        />
        <Route
          path={ AuthenticatedUrls.content.subPages.profile.path }
          element={ 
            <ProtectedRoute
              element={ <Profile /> }
              allowedRoles={ [Roles.admin, Roles.consultantAdmin, Roles.customerAdmin, Roles.customer] }   
            />
          }
        />
      </Route>
      <Route
        path={ AuthenticatedUrls.onboarding.path }
        element={ 
          <ProtectedRoute
            element={ <Onboarding /> }
            allowedRoles={ [Roles.admin, Roles.consultantAdmin, Roles.customerAdmin] }    
          />
        }
      />

      {/* Admin Routes */}
      <Route
        path={ AuthenticatedUrls.admin.path }
        element={ 
          <ProtectedRoute
            element={ <Admin />}
            allowedRoles={ [Roles.admin, Roles.consultantAdmin, Roles.customerAdmin] }   
          />
        }
      >
        <Route
          path={ AuthenticatedUrls.admin.subPages.customers.path }
          element={ 
            <ProtectedRoute
              element={ <Customer /> }
              allowedRoles={ [Roles.admin, Roles.consultantAdmin, Roles.customerAdmin] }   
            />
          }
        />
        <Route
            path={ AuthenticatedUrls.admin.subPages.editCustomers.path }
            element={ 
              <ProtectedRoute
                element={ <EditCustomer /> }
                allowedRoles={ [Roles.admin, Roles.consultantAdmin, Roles.customerAdmin] }
              />
            }
          />
        <Route
          path={ AuthenticatedUrls.admin.subPages.users.path }
          element={ 
            <ProtectedRoute
              element={ <Users /> }
              allowedRoles={ [Roles.admin, Roles.consultantAdmin, Roles.customerAdmin] }    
            />
          }
        />
        <Route
          path={ AuthenticatedUrls.admin.subPages.editUsers.path }
          element={ 
            <ProtectedRoute
              element={ <EditUsers /> }
              allowedRoles={ [Roles.admin, Roles.consultantAdmin, Roles.customerAdmin] }   
            />
          }
        />
        <Route
          path={ AuthenticatedUrls.admin.subPages.customerSubscription.path }
          element={ 
            <ProtectedRoute
              element={ <CustomerSubscription /> }
              allowedRoles={ [Roles.admin, Roles.consultantAdmin] }   
            />
          }
        />
        <Route
          path={ AuthenticatedUrls.admin.subPages.editCustomerSubscription.path }
          element={ 
            <ProtectedRoute
              element={ <EditCustomerSubscription /> }
              allowedRoles={ [Roles.admin, Roles.consultantAdmin] }    
            />
          }
        />
        <Route
          path={ AuthenticatedUrls.admin.subPages.riskAnalisysAndActionYears.path }
          element={ 
            <ProtectedRoute
              element={ <RiskAnalisysAndActionYear /> }
              allowedRoles={ [Roles.admin, Roles.consultantAdmin, Roles.customerAdmin] }
            />
          }
        />
        <Route
          path={ AuthenticatedUrls.admin.subPages.addRiskAnalisysAndActionYears.path }
          element={ 
            <ProtectedRoute
              element={ <AddRiskAnalisysAndActionYear /> }
              allowedRoles={ [Roles.admin, Roles.consultantAdmin, Roles.customerAdmin] }
            />
          }
        />
        <Route
          path={ AuthenticatedUrls.admin.subPages.handleMaterials.path }
          element={ 
            <ProtectedRoute
              element={ <HandleMaterials /> }
              allowedRoles={ [Roles.admin] }
            />
          }
        />
        <Route
          path={ AuthenticatedUrls.admin.subPages.preparationItemTemplates.path }
          element={ 
            <ProtectedRoute
              element={ <PreparationItemTemplates /> }
              allowedRoles={ [Roles.admin] }
            />
          }
        />
        <Route
          path={ AuthenticatedUrls.admin.subPages.editLocales.path }
          element={ 
            <ProtectedRoute
              element={ <EditLocales /> }
              allowedRoles={ [Roles.admin] }
            />
          }
        />
        <Route
          path={ AuthenticatedUrls.admin.subPages.disabledUsersOverview.path }
          element={ 
            <ProtectedRoute
              element={ <DisabledUsersOverview /> }
              allowedRoles={ [Roles.admin] }    
            />
          }
        />
      </Route>
    </Routes>
  );
}

export default Router;
