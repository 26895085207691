import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { GetChapterSectionStepUrl } from "Util/Utils";
import { ChapterSectionStepTypeUrls } from "Routes/Urls";
import ChapterSectionStepBar from "Components/Steps/ChapterSectionStepBar";
import { Table, TableBody, TableCell, TableHead, TableRow, FormControl } from "@mui/material";
import { TextFieldWithPlaceholder, RequiredField, InfoText, TwKey, ArrowForwardIconLinkButton, AddButton, EditButton, SaveButton, CancelButton } from "Components/StandardTextComponentsWithTranslation";
import { RiskAnalisysAndActionItem } from "Classes/UserData";
import {
  useGetRiskAnalisysAndActionYearsForLoggedInCustomerQuery,
  useLazyGetRiskAnalisysAndActionYearsForLoggedInCustomerQuery,
  useUpdateRiskAnalisysAndActionYearsForLoggedInCustomerMutation
} from 'API/UserDataStorageAPI';
import {
  getRiskAnalisysAndActionOfTypeIdInActiveRiskAnalisysAndActionYear
} from 'API/SelectFromUserDataStorageItems';
import {
  addRiskAnalisysAndActionItemInRiskAnalisysAndActtionOfTypeIdInActiveRiskAnalisysAndActionYear,
  updateRiskAnalisysAndActionItemInRiskAnalisysAndActtionOfTypeIdInActiveRiskAnalisysAndActionYear
} from 'API/ModifyUserDataStorageItems';
import Loading from "Components/Loading";

const Risks = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const appState = useSelector((state) => state.settings.appState);
  
  const { data: riskAnalisysAndActionYears, error, isLoading: riskAnalisysAndActionYearsIsLoading, refetch } = useGetRiskAnalisysAndActionYearsForLoggedInCustomerQuery(null, { pollingInterval: 3000 } );

  const [ lazyGetRiskAnalisysAndActionYearsForLoggedInCustomer ] = useLazyGetRiskAnalisysAndActionYearsForLoggedInCustomerQuery();

  const [
    updateRiskAnalisysAndActionYearsForLoggedInCustomer, // This is the mutation trigger
    { isLoading: isUpdatingUserDataStorageForLoggedInCustomer }, // This is the destructured mutation result
  ] = useUpdateRiskAnalisysAndActionYearsForLoggedInCustomerMutation();

  const [stateIsLoading, setStateIsLoading] = useState(true);
  const [newRiskAnalisysAndActionItemState, setNewRiskAnalisysAndActionItemState] = useState(new RiskAnalisysAndActionItem(uuidv4()));
  const [editRiskAnalisysAndActionItemState, setEditRiskAnalisysAndActionItemState] = useState(new RiskAnalisysAndActionItem(uuidv4()));
  
  useEffect(() => {
    setStateIsLoading(true);

    async function asyncUseEffect(){
      //Check if the user has selected an id and open that one for edit if the user has.
      if( id ){
        const riskAnalisysAndActionYearsLazy = await lazyGetRiskAnalisysAndActionYearsForLoggedInCustomer().unwrap();

        editRiskAnalisysAndActionItemFunction( riskAnalisysAndActionYearsLazy, id );
      }

      //Scroll to the top of the page.
      window.scrollTo(0, 0);
    }

    asyncUseEffect();

    setStateIsLoading( false ); 
  }, []);

  async function addRiskAnalisysAndActionItemButtonOnClick(){
    addRiskAnalisysAndActionItemInt(newRiskAnalisysAndActionItemState.risk, newRiskAnalisysAndActionItemState.consequence);
  }

  async function addRiskAnalisysAndActionItemInt(risk, consequence){
    if( riskAnalisysAndActionYears ){
      const selectedRiskAnalisysAndAction = getRiskAnalisysAndActionOfTypeIdInActiveRiskAnalisysAndActionYear( riskAnalisysAndActionYears, appState.currentChapterSectionTypeWithUrl.chapterSectionType.Name );

      //Create a new RiskAnalisysAndActionItem with unique id.
      const riskAnalisysAndActionItemToAdd = new RiskAnalisysAndActionItem(uuidv4());

      //Fill data the risk item.
      riskAnalisysAndActionItemToAdd.riskAnalisysAndActionId = selectedRiskAnalisysAndAction.id;
      riskAnalisysAndActionItemToAdd.risk = risk;
      riskAnalisysAndActionItemToAdd.consequence = consequence;

      //Add the RiskAnalisysAndActionItem to the active RiskAnalisysAndActionYear.
      const riskAnalisysAndActionYearsToUpdate = await addRiskAnalisysAndActionItemInRiskAnalisysAndActtionOfTypeIdInActiveRiskAnalisysAndActionYear( appState.currentChapterSectionTypeWithUrl.chapterSectionType.Name, riskAnalisysAndActionItemToAdd );

      //Update database.
      var result = await updateRiskAnalisysAndActionYearsForLoggedInCustomer( riskAnalisysAndActionYearsToUpdate );

      const newRiskAnalisysAndActionItem = new RiskAnalisysAndActionItem(uuidv4());

      setNewRiskAnalisysAndActionItemState( newRiskAnalisysAndActionItem );
    } else {
      throw new Error("No RiskAnalisysAndActionYears defined!");
    }
  }

  async function editRiskAnalisysAndActionItemButtonOnClick( riskAnalisysAndActionItemId ){
    navigate(GetChapterSectionStepUrl(appState.currentChapterSectionTypeWithUrl, ChapterSectionStepTypeUrls.risks, riskAnalisysAndActionItemId, { replace: false } ));
    editRiskAnalisysAndActionItemFunction( riskAnalisysAndActionYears, riskAnalisysAndActionItemId );
  }

  async function editRiskAnalisysAndActionItemFunction( riskAnalisysAndActionYears, riskAnalisysAndActionItemId ){
    if( riskAnalisysAndActionYears ){
      const selectedRiskAnalisysAndAction = getRiskAnalisysAndActionOfTypeIdInActiveRiskAnalisysAndActionYear( riskAnalisysAndActionYears, appState.currentChapterSectionTypeWithUrl.chapterSectionType.Name );
      //Filter out the correct RiskAnalisysAndActionItem, i.e. the one that contains the RiskAnalisysAndActionItem with the id riskAnalisysAndActionItemId.
      const filteredRiskAnalisysAndActionItems = selectedRiskAnalisysAndAction.riskAnalisysAndActionItems.filter( (riskAnalisysAndActionItem) => riskAnalisysAndActionItem.id === riskAnalisysAndActionItemId);

      //We should only get one match since we filtered on the riskAnalisysAndActionItemId, therefore we assume that the RiskAnalisysAndActionItem we want is on spot 0.
      const riskAnalisysAndActionItemToEdit = filteredRiskAnalisysAndActionItems[0];

      //Set the state.
      setEditRiskAnalisysAndActionItemState( riskAnalisysAndActionItemToEdit );
    } else {
      throw new Error("No RiskAnalisysAndActionYears defined!");
    }
  }

  async function saveEditedRiskAnalisysAndActionItemButtonOnClick( riskAnalisysAndActionItemId ){
    if( riskAnalisysAndActionYears ){
      const selectedRiskAnalisysAndAction = getRiskAnalisysAndActionOfTypeIdInActiveRiskAnalisysAndActionYear( riskAnalisysAndActionYears, appState.currentChapterSectionTypeWithUrl.chapterSectionType.Name );

      //Filter out the correct RiskAnalisysAndActionItem, i.e. the one that contains the RiskAnalisysAndActionItem with the id riskAnalisysAndActionItemId.
      const filteredRiskAnalisysAndActionItems = selectedRiskAnalisysAndAction.riskAnalisysAndActionItems.filter( (riskAnalisysAndActionItem) => riskAnalisysAndActionItem.id === riskAnalisysAndActionItemId);

      //We should only get one match since we filtered on the riskAnalisysAndActionItemId, therefore we assume that the RiskAnalisysAndActionItem we want is on spot 0.
      var updatedRiskAnalisysAndActionItem = filteredRiskAnalisysAndActionItems[0];

      //Set the edited RiskAnalisysAndActionItem to the correct riskAnalisysAndActionItem.
      updatedRiskAnalisysAndActionItem.risk = editRiskAnalisysAndActionItemState.risk;
      updatedRiskAnalisysAndActionItem.consequence = editRiskAnalisysAndActionItemState.consequence;

      //Update the RiskAnalisysAndActionItem in active year in RiskAnalisysAndActionYears.
      const riskAnalisysAndActionYearsToUpdate = await updateRiskAnalisysAndActionItemInRiskAnalisysAndActtionOfTypeIdInActiveRiskAnalisysAndActionYear( appState.currentChapterSectionTypeWithUrl.chapterSectionType.Name, updatedRiskAnalisysAndActionItem );

      //Update DB.
      const result = await updateRiskAnalisysAndActionYearsForLoggedInCustomer( riskAnalisysAndActionYearsToUpdate );
      
      //Set the current edit RiskAnalisysAndActionItem to a new one (should not be null).
      const editRiskAnalisysAndActionItem = new RiskAnalisysAndActionItem(uuidv4());

      setEditRiskAnalisysAndActionItemState(editRiskAnalisysAndActionItem );

      //navigate(GetChapterSectionStepUrl(appState.currentChapterSectionTypeWithUrl, ChapterSectionStepTypeUrls.risks));
    } else {
      throw new Error("No RiskAnalisysAndActionYears defined!");
    }
  }

  async function cancelEditRiskAnalisysAndActionItem(){
    //Set the current edit RiskAnalisysAndActionItem to a new one (should not be null).
    const editRiskAnalisysAndActionItem = new RiskAnalisysAndActionItem(uuidv4());

    setEditRiskAnalisysAndActionItemState(editRiskAnalisysAndActionItem );

    //navigate(GetChapterSectionStepUrl(appState.currentChapterSectionTypeWithUrl, ChapterSectionStepTypeUrls.risks));
  }

  if ( stateIsLoading || riskAnalisysAndActionYearsIsLoading ) {
    return <Loading />;
  } else {
    const selectedRiskAnalisysAndAction = getRiskAnalisysAndActionOfTypeIdInActiveRiskAnalisysAndActionYear( riskAnalisysAndActionYears, appState.currentChapterSectionTypeWithUrl.chapterSectionType.Name );
    
    return (
      <>
      <div className="innerContentWrapper">
        <ChapterSectionStepBar chapterSectionStepBarClickable={ selectedRiskAnalisysAndAction.riskAnalisysAndActionItems ? (selectedRiskAnalisysAndAction.riskAnalisysAndActionItems.length > 0 ? true : true) : false } />
        
        <div className="outerContainerForAccordion">
          <div className="chapterStep">
            <div className="chapterStepTitle">
              <h2><TwKey textKey="10009"/>&nbsp;<InfoText textKey="10223"/></h2>
            </div>
            <div className="chapterStepContent">
              <Table>
                <TableHead style={{ backgroundColor: "#FFFFFF" }}>
                  <TableRow style={{ backgroundColor: "#FFFFFF" }}>
                    <TableCell style={{ backgroundColor: "#FFFFFF", width: "300px" }}>
                      <b><TwKey textKey="10001" />
                      <RequiredField />
                      </b>&nbsp;
                      <InfoText textKey={ appState.currentChapterSectionTypeWithUrl.chapterSectionType.RisksRiskInfoTextKey } />
                    </TableCell>
                    <TableCell style={{ backgroundColor: "#FFFFFF" }}>
                      <b><TwKey textKey="10002" />
                      <RequiredField />
                      </b>&nbsp;
                      <InfoText textKey={ appState.currentChapterSectionTypeWithUrl.chapterSectionType.RisksConsequenceInfoTextKey } />
                    </TableCell>
                    <TableCell style={{width: "150px"}}>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    selectedRiskAnalisysAndAction.riskAnalisysAndActionItems.map(( riskAnalisysAndActionItem, idx ) => (
                      <TableRow key={idx} style={{verticalAlign: "Middle"}}>
                        <TableCell>
                          {
                            editRiskAnalisysAndActionItemState?.id === riskAnalisysAndActionItem.id ?
                              <TextFieldWithPlaceholder
                                placeholderTextKey="10118"
                                value={editRiskAnalisysAndActionItemState.risk}
                                onChange={(event) => setEditRiskAnalisysAndActionItemState( { ...editRiskAnalisysAndActionItemState, risk: event.target.value })}
                                disabled={selectedRiskAnalisysAndAction.risksDefinedAndAnalyzed}
                              />
                            :
                              riskAnalisysAndActionItem.risk
                          }
                        </TableCell>
                        <TableCell>
                          {
                            editRiskAnalisysAndActionItemState?.id === riskAnalisysAndActionItem.id ?
                              <TextFieldWithPlaceholder
                                placeholderTextKey="10119"
                                value={editRiskAnalisysAndActionItemState.consequence}
                                onChange={(event) => setEditRiskAnalisysAndActionItemState( { ...editRiskAnalisysAndActionItemState, consequence: event.target.value })}
                                disabled={selectedRiskAnalisysAndAction.risksDefinedAndAnalyzed}
                              />
                            :
                              riskAnalisysAndActionItem.consequence
                          }
                        </TableCell>
                        <TableCell  style={{ textAlign: "Right"}}>
                          {
                            editRiskAnalisysAndActionItemState?.id === riskAnalisysAndActionItem.id ?
                              <>
                                <FormControl>
                                  <SaveButton
                                    buttonTextKey="10105"
                                    toolTipTitleTextKey="10106"
                                    disabled={ editRiskAnalisysAndActionItemState.risk !== "" && editRiskAnalisysAndActionItemState.consequence !== "" ? false : true }
                                    onClick={ async () => await saveEditedRiskAnalisysAndActionItemButtonOnClick(riskAnalisysAndActionItem.id) }
                                  />
                                </FormControl>
                                &nbsp;
                                <FormControl>
                                  <CancelButton
                                    buttonTextKey="10107"
                                    toolTipTitleTextKey="10108"
                                    onClick={ async () => await cancelEditRiskAnalisysAndActionItem(riskAnalisysAndActionItem.id) }
                                  />
                                </FormControl>
                              </>
                            :
                              <>
                                <FormControl>
                                  <EditButton
                                    buttonTextKey="10092"
                                    toolTipTitleTextKey="10093"
                                    onClick={ () => editRiskAnalisysAndActionItemButtonOnClick( riskAnalisysAndActionItem.id )}
                                    disabled={selectedRiskAnalisysAndAction.risksDefinedAndAnalyzed}
                                  />
                                  </FormControl>
                                  &nbsp;
                                  <FormControl>
                                    <ArrowForwardIconLinkButton
                                      buttonTextKey="10090"
                                      toolTipTitleTextKey="10091"
                                      to={ GetChapterSectionStepUrl(appState.currentChapterSectionTypeWithUrl, ChapterSectionStepTypeUrls.riskAnalysisAndAction, riskAnalisysAndActionItem.id ) }
                                    />
                                  </FormControl>
                                
                            </>
                          }
                        </TableCell>
                      </TableRow>
                    ))
                  }
                  <TableRow style={{verticalAlign: "Middle"}}>
                    <TableCell>
                      <TextFieldWithPlaceholder
                        placeholderTextKey="10115"
                        value={newRiskAnalisysAndActionItemState.risk}
                        onChange={(event) => setNewRiskAnalisysAndActionItemState( { ...newRiskAnalisysAndActionItemState, risk: event.target.value })}
                        disabled={selectedRiskAnalisysAndAction.risksDefinedAndAnalyzed}
                      />
                    </TableCell>
                    <TableCell>
                      <TextFieldWithPlaceholder
                        placeholderTextKey="10116"
                        value={newRiskAnalisysAndActionItemState.consequence}
                        onChange={(event) => setNewRiskAnalisysAndActionItemState( { ...newRiskAnalisysAndActionItemState, consequence: event.target.value })}
                        disabled={selectedRiskAnalisysAndAction.risksDefinedAndAnalyzed}
                      />
                    </TableCell>
                    <TableCell style={{ textAlign: "Right"}}>
                      <FormControl>
                        <AddButton
                          disabled={ selectedRiskAnalisysAndAction.risksDefinedAndAnalyzed ||
                                          (newRiskAnalisysAndActionItemState.risk === "" && newRiskAnalisysAndActionItemState.consequence === "")
                                          ? true : false
                                    }
                          buttonTextKey="10088"
                          toolTipTitleTextKey="10094"
                          onClick={async () => await addRiskAnalisysAndActionItemButtonOnClick()}
                        />
                      </FormControl>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </div>
        </div>
      </div>
      </>
    );
  }
}

export default Risks;