import React, { Fragment, useEffect, useState } from "react";
import { View, Text } from "@react-pdf/renderer";

export const ReactPdfTable = ( props ) => {
  const [stateTableData, setStateTableData] = useState();

  const styles = props.styleSheet;

  useEffect(() => {
    if ( props.data !== undefined ){
      setStateTableData(props.data);
    } 
  }, []);

  return (
    <>
      {stateTableData &&
        (
          <Fragment>
            { 
              <>
                <View style={styles.rowView}>
                  {
                    stateTableData[0].columns.map((column, columnIndex) =>
                      <Text key={columnIndex} style={{ width: `${column.columnWitdh}%`, ...styles.tableHeaderText }}>
                        {column.headerText}
                      </Text>
                    )
                  }
                </View>
                {
                  stateTableData[0].tableData ? 
                    stateTableData[0].tableData.map((rowData, rowDataIndex) =>
                        <View key={rowDataIndex} style={styles.rowView}>
                        {   
                            stateTableData[0].columns.map((column, columnIndex) =>
                            <Text key={columnIndex} style={{ width: `${column.columnWitdh}%`, ...styles.text, ...styles.columnView }}>
                                {rowData.find((row) => row.columnName === column.columnName).cellData }
                            </Text>
                            )
                        }
                        </View>
                    )
                    :
                    ""
                }
              </>
            }
          </Fragment>
        )}
    </>
  )
}

export default ReactPdfTable;