import React from "react";
import { TwKey} from "Components/StandardTextComponentsWithTranslation";

const Welcome = () => {
  return (
    <>
      <div className="innerContentWrapper">
        <div className="introduction">
          <div className="introductionTitle">
            <h2><TwKey textKey="10391"/></h2>
          </div>
          <div className="introductionContent">
            <p><TwKey textKey="10392"/></p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Welcome;