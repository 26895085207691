import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { userSignIn } from "Features/AppState/AppStateSlice";
import { Auth } from "aws-amplify";
import Loading from "Components/Loading";
import { UnauthenticatedUrls } from "Routes/Urls";
import { FormControl, InputLabel, Button, TextField, InputAdornment, IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import { EmailValid, PopupConfirmYesNo, PopupError, PopupWithCallBack } from "Util/Utils";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const Recover = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  
  const [stateIsLoading, setStateIsLoading] = useState(true);
  const [stateEmail, setStateEmail] = useState( location.state?.email || "" );
  const [stateOldPassword, setStateOldPassword] = useState( location.state?.password );
  const [stateNewPassword, setStateNewPassword] = useState( "" );
  const [stateShowPassword, setStateShowPassword] = useState( false );
  const [stateVerificationCode, setStateVerificationCode] = useState( "" );
  
  useEffect(() => {
    //Scroll to the top of the page.
    window.scrollTo(0, 0);

    setStateIsLoading( false );
  }, []);

  async function forgotPassword() {
    try {
      await Auth.forgotPassword(stateEmail);
      PopupWithCallBack("Ett email har skickats till din adress med en aktiveringskod för att skapa ett nytt lösenord",
        () => { navigate( UnauthenticatedUrls.landing.subPages.recover.path, { state: { email: stateEmail, code: true } } );
      });
    } catch (err) {
      if (err.code === "NotAuthorizedException") {
        navigate( UnauthenticatedUrls.landing.subPages.recover.path, { state: { email: stateEmail, code: true } } );
      }
      console.error(err);
    }
  }

  async function validateLogin() {
    return stateNewPassword.length > 7 && stateEmail !== "";
  }

  async function login(event) {
    event.preventDefault();

    try {
      setStateIsLoading( true );

      let user = null;

      if (location.state?.code) {
        await Auth.forgotPasswordSubmit(stateEmail, stateVerificationCode, stateNewPassword);
        user = await Auth.signIn(stateEmail, stateNewPassword);
      } else {
        if (user == null) {
          user = await Auth.signIn(stateEmail, stateOldPassword);
        }
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          user = await Auth.completeNewPassword(user, stateNewPassword);
        }
      }

      await dispatch(userSignIn());
    } catch (err) {
      if (err.code === "UserNotFoundException") {
        PopupError("Användaren finns inte.");
      } else {
        console.error("Login:", err);
      }
    }

    setStateIsLoading( false );
  }

  async function handleClickShowPassword() {
    setStateShowPassword( !stateShowPassword );
  }
  
  async function handleMouseDownPassword(event){
    event.preventDefault();
  };

  if (stateIsLoading) {
    return <Loading />;
  } else {
    return (
      <>
        <h1 style={{ textAlign: "center"}}>Glömt lösenord</h1>
        <hr className="seperatorGrey" />
        <div className="utvecklaTextInput">
          <FormControl
            variant="outlined"
            fullWidth={true}
          >
            <InputLabel>
              E-post
            </InputLabel><br/>
            <TextField
              label=""
              autoComplete="email"                    
              placeholder="Ange epostadress"
              value={stateEmail}
              error={!EmailValid(stateEmail)}
              onChange={(event) => setStateEmail( event.target.value )}
              margin="normal"
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: true,
                classes: {
                  root: "textFieldLabel",
                  focused: "textFieldFocused",
                },
              }}
              InputProps={{ 
                classes: {
                  root: "textFieldOutlinedInput",
                  focused: "textFieldFocused",
                  notchedOutline: "notchedOutline",
                }
              }}
            />
          </FormControl>
        </div>
        <div style={{ marginTop: "20px" }}>
          <Button
            style={{ background: "rgba(70, 140, 37, 0.1)", color: "#468C25", border: "1.5px solid #468C25" }}
            variant="contained"
            disabled={!EmailValid(stateEmail)}
            onClick={() => PopupConfirmYesNo("Är du säker på att du vill återställa ditt lösenord", forgotPassword)}
            fullWidth={true}
          >
            Återställ lösenord
          </Button>
        </div>
        {location.state?.code && (
          <div>
            <div className="utvecklaTextInput">
              <FormControl
                variant="outlined"
                fullWidth={true}
              >
                <InputLabel>
                  Verifieringskod
                </InputLabel><br/>
                <TextField
                  label=""
                  placeholder="Ange verifieringskod"
                  autoComplete="new-password"
                  value={stateVerificationCode}
                  error={stateVerificationCode.length !== 6}
                  onChange={(event) => setStateVerificationCode( event.target.value )}
                  margin="normal"
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                    classes: {
                      root: "textFieldLabel",
                      focused: "textFieldFocused",
                    },
                  }}
                  InputProps={{ 
                    classes: {
                      root: "textFieldOutlinedInput",
                      focused: "textFieldFocused",
                      notchedOutline: "notchedOutline",
                    }
                  }}
                />
              </FormControl>
            </div>                      
            <div className="utvecklaTextInput">
              <FormControl
                variant="outlined"
                fullWidth={true}
              >
                <InputLabel>
                  Nytt lösenord
                </InputLabel><br/>
                <TextField
                  label=""
                  type={stateShowPassword ? "text" : "password"}
                  placeholder="Ange lösenord"
                  autoComplete="new-password"
                  value={stateNewPassword}
                  onChange={(event) => setStateNewPassword( event.target.value )}
                  margin="normal"
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                    classes: {
                      root: "textFieldLabel",
                      focused: "textFieldFocused",
                    },
                  }}
                  InputProps={{ 
                    classes: {
                      root: "textFieldOutlinedInput",
                      focused: "textFieldFocused",
                      notchedOutline: "notchedOutline",
                    },
                    endAdornment:(
                      <InputAdornment position="end">
                        <IconButton
                          onClick={ async () => await handleClickShowPassword() }
                          onMouseDown={ async (event) => await handleMouseDownPassword(event) }
                          edge="end"
                          size="large">
                          { stateShowPassword ? <VisibilityOff /> : <Visibility /> }
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </FormControl>
            </div>
            <p style={{textAlign: "center"}}>
              OBS! Använd minst 8 tecken i det nya lösenordet!
            </p>
            <div style={{ marginTop: "20px" }}>
              <Button
                className="buttonBorderGreenInnerLightGreen"
                variant="contained"
                disabled={!validateLogin()} onClick={ async (event) => await login(event) }
                fullWidth={true}
              >
                Spara och Logga in
              </Button>
            </div>
            <hr className="seperatorButtonGrey" />
          </div>
        )}
        <div style={{ marginTop: "20px" }}>
          <Link to={UnauthenticatedUrls.landing.subPages.login.path} style={{ textDecoration: "none" }}>
            <div>
              <Button
                variant="contained"
                className="buttonBorderGreenInnerWhite"
                fullWidth={true}
              >
                Tillbaka
              </Button>
            </div>
          </Link>
        </div>
      </>
    );
  }
}

export default Recover;