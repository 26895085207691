import React, { Fragment, useState } from "react";
import { Document, Page, Text, View, StyleSheet, Image, Font } from '@react-pdf/renderer';
import moment from "moment";
import { getText } from 'Components/StandardTextComponentsWithTranslation';
import ReactPdfTable from "Views/Chapters/Sections/EqualTreatmentPlan/ReactPdfTable";
import WayOfWorkingCircle from "Assets/Images/Report/WayOfWorkingCircle.png";
import HowToDoSurvey from "Assets/Images/Report/HowToDoSurvey.png";
import Manrope from "Assets/Fonts/Manrope-Regular.ttf"
import ManropeBold from "Assets/Fonts/Manrope-Bold.ttf"

//Fix error with images.
//https://github.com/diegomura/react-pdf/issues/2089

const EqualTreatmentPlanPdf = ( props ) => {
  const riskAnalisysAndActionYear = props.riskAnalisysAndActionYear;

  const [stateChapterIndex, setStateChapterIndex] = useState([]);
  
  Font.register({
    family: 'Manrope',
    format: "truetype",
    src: Manrope
  });

  Font.register({
    family: 'ManropeBold',
    format: "truetype",
    src: ManropeBold
  });
  
  const styles = StyleSheet.create({
    body: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35,
    },
    author: {
      fontSize: 12,
      fontFamily: 'Manrope',
      textAlign: 'center',
      marginBottom: 40,
    },
    h1: {
      fontSize: 26,
      fontFamily: 'Manrope',
      marginBottom: 100,
      textAlign: 'center',
    },
    h2: {
      fontSize: 20,
      fontFamily: 'Manrope',
      marginBottom: 8,
      textAlign: 'left',
    },
    h3: {
      fontSize: 16,
      fontFamily: 'Manrope',
      marginBottom: 8,
      textAlign: 'left',
    },
    h4: {
      fontSize: 14,
      fontFamily: 'Manrope',
      marginBottom: 8,
      textAlign: 'left',
    },
    h5: {
      fontSize: 12,
      fontFamily: 'Manrope',
      marginBottom: 8,
      textAlign: 'left',
    },
    textSection: {
      fontSize: 11,
      fontFamily: 'Manrope',
      marginBottom: 8,
      textAlign: 'left',
    },
    textSectionWithGreyBackground: {
      fontSize: 11,
      fontFamily: 'Manrope',
      marginBottom: 8,
      textAlign: 'left',
      backgroundColor: '#EBEBEB',
      padding: '20px',
      margin: '10px',
    },
    text: {
      fontSize: 11,
      fontFamily: 'Manrope',
      textAlign: 'left',
    },
    boldText: {
      fontSize: 11,
      fontFamily: 'ManropeBold',
      textAlign: 'left',
    },
    atgardText: {
      fontSize: 11,
      fontFamily: 'Manrope',
      marginLeft: 12,
      marginBottom: 8,
      textAlign: 'left',
    },
    tableHeaderText: {
      fontSize: 12,
      fontFamily: 'Manrope',
      fontWeight: 'bold',
      textOverflow: '-',
      marginTop: 0,
      marginBottom: 0,
      paddingLeft: 5,
      paddingTop: 0,
      paddingBottom: 0,
      borderBottom: '1px solid #EEE',
      borderLeft: '1px solid #EEE',
      borderRight: '1px solid #EEE',
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      fontSize: 11,
      fontFamily: 'Manrope',
      textAlign: 'left',
    },
    bullet: {
      height: '100%',
      fontSize: 11,
      fontFamily: 'Manrope',
      textAlign: 'left',
    },
    rowView: {
      display: 'flex',
      flexDirection: 'row',
      // borderTop: '1px solid #EEE',
      // borderBottom: '1px solid #EEE',
      // borderLeft: '1px solid #EEE',
      // borderRight: '1px solid #EEE',
      marginTop: 0,
      marginBottom: 0,
      paddingTop: 0,
      paddingBottom: 0,
      textAlign: 'left'
    },
    columnView: {
      borderTop: '1px solid #EEE',
      borderBottom: '1px solid #EEE',
      borderLeft: '1px solid #EEE',
      borderRight: '1px solid #EEE',
      marginTop: 0,
      marginBottom: 0,
      paddingLeft: 5,
      paddingTop: 0,
      paddingBottom: 0,
      textAlign: 'left'
    },
    tableOfContent: {
      display: 'flex',
      flexDirection: 'row',
      paddingTop: 8,
      paddingBottom: 8,
      textAlign: 'left'
    },
    tableOfContentChapterName: {
      fontSize: 11,
      fontFamily: 'Manrope',
      textAlign: 'left',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'clip',
      width: '98%'
    },
    tableOfContentPageNumber: {
      fontSize: 11,
      fontFamily: 'Manrope',
      textAlign: 'left',
      width: '2%'
    },
    // image: {
    //   marginVertical: 15,
    //   marginHorizontal: 100,
    // },
    header: {
      fontSize: 10,
      fontFamily: 'Manrope',
      marginBottom: 20,
      textAlign: 'left',
      color: 'grey',
    },
    pageNumber: {
      fontSize: 10,
      fontFamily: 'Manrope',
      position: 'absolute',
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: 'center',
      color: 'grey',
    },
  });

  //Support for Bullet List.
  const ListItem = ({ children }) => {
    return (
        <View style={styles.row}>
            <View style={styles.bullet}>
                <Text>{'\u2022' + " "}</Text>
            </View>
            <Text>{children}</Text>
        </View>
    )
  }

  //Create bullet list of checked preparation list items.
  const PreparationItemBulletList = () => {
    return (
      <View>
        {
          riskAnalisysAndActionYear.preparations
            .filter((preparationItem) => preparationItem.checked == true)
            .map( (preparationItem, idx) => (
              <ListItem key={idx}>{preparationItem.name}</ListItem>
            ))
        }
      </View>
    )
  }

  function getTableForAllRiskAnalisysTaskItems ( riskAnalisysAndActions ){
    let tableColumns = [
      { columnName: "column1", headerText: "Åtgärd", columnWitdh: 33.3 },
      { columnName: "column2", headerText: "Åtgärdad risk", columnWitdh: 33.3 },
      { columnName: "column3", headerText: "Område", columnWitdh: 33.3 },
    ];
  
    let tableData = [];

    //Loop over all risk analisys and task items
    for ( const riskAnalisysAndAction of Object.values( riskAnalisysAndActions ) ) {
      for ( const riskAnalisysAndActionItem of Object.values( riskAnalisysAndAction.riskAnalisysAndActionItems ) ) {
        for ( const riskAnalisysAndActionTaskItem of Object.values( riskAnalisysAndActionItem.riskAnalisysAndActionTaskItems ) ) {
          //Get taskText
          let taskText = riskAnalisysAndActionTaskItem.task;

          //Get the current risk.
          let riskText = riskAnalisysAndActionItem.risk;

          //Get the current chapter.
          let currentChapter = getText( riskAnalisysAndAction.titleTextKey );

          //Get the complete row.
          const tableDataRow = [
            { "columnName": "column1", "cellData": taskText },
            { "columnName": "column2", "cellData": riskText },
            { "columnName": "column3", "cellData": currentChapter },
          ];

          tableData.push(tableDataRow);
        }
      }
    }

    const returnData = {
      "columns": tableColumns,
      "tableData" : tableData
    }
  
    return returnData;
  }

  function getTableData( riskAnalisysAndActions ){
    var tableWithAnalisysAndActionTaskItems = [];
    
    const riskAnalisysAndActionTaskItemTable = getTableForAllRiskAnalisysTaskItems( riskAnalisysAndActions );

    tableWithAnalisysAndActionTaskItems.push( riskAnalisysAndActionTaskItemTable );

    return tableWithAnalisysAndActionTaskItems;
  }

  function getChapterPageViews(riskAnalisysAndActions) {    
    //Add the background chapter.
    let chapterPageViews = [
      {
        "chapterName": getText(10195),
        "chapterData": 
          <View>
            <Text style={styles.h2}>
              { getText(10396) }
            </Text>
            <Text style={styles.h3}>
              { getText(10184) }
            </Text>
            <Text style={styles.textSection}>
              { getText(10185) }
            </Text>
            <Text style={styles.textSection}>
              { getText(10186) }
            </Text>
            <View style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Image source={WayOfWorkingCircle} style={{ width: "175px", height: "175px" }} />
            </View>
            <Text style={styles.textSection}>
              { getText(10187) }
            </Text>
            <Text style={styles.h3}>
              { getText(10188) }
            </Text>
            <Text style={styles.textSection}>
              { getText(10189, riskAnalisysAndActionYear.authors ) }
            </Text>
            <Text style={styles.textSection}>
              { getText(10190) }
            </Text>
            <View>
              <ListItem>
                { getText(10191) }
              </ListItem>
              <ListItem>
                { getText(10327) }
              </ListItem>
              <ListItem>
                { getText(10328) }
              </ListItem>
              <ListItem>
                { getText(10329) }
              </ListItem>
              <ListItem>
              { getText(10330) }
              </ListItem>
              <ListItem>
                { getText(10331) }
              </ListItem>
              <ListItem>
                { getText(10332) }
              </ListItem>
            </View>
            <Text style={styles.textSection}>
              &nbsp;
            </Text>
            <Text style={styles.textSection}>
              { getText(10333) }
            </Text>
            <PreparationItemBulletList />
            <Text style={styles.h4}>
              { getText(10386) }
            </Text>
            <View style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Image source={HowToDoSurvey} style={{ width: "527", height: "296" }} />
            </View>
          </View>
      }
    ];

    /*
      //Fact box
      chapterPageViews.push({
        "chapterName": "",
        "chapterData": 
          <View style={styles.textSectionWithGreyBackground}>
            <Text style={styles.h3}>{getText(10341)}</Text>
            <Text style={styles.textSection}>{getText(10342)}</Text>
          </View>
      });
    */
    
    for( let riskAnalisysAndAction of Object.values(riskAnalisysAndActions) ){
      chapterPageViews.push(
        {
          "chapterName": getText(riskAnalisysAndAction.titleTextKey),
          "chapterData": 
            <View>
              <Text style={styles.h2}>{ getText( riskAnalisysAndAction.titleTextKey )}</Text>
              <Text style={styles.textSection}>{ getText( riskAnalisysAndAction.chapterDescriptionTextKey )}</Text>
              {/* Present the risks in this chapter */}
              <Text style={styles.h3}>{ getText(10206) }</Text>
              <Text style={styles.textSection}>{ getText( 10207 )}</Text>
              {
                Object.values(riskAnalisysAndAction.riskAnalisysAndActionItems).length > 0 ? 
                  riskAnalisysAndAction.riskAnalisysAndActionItems.map(( riskAnalisysAndActionItem, riskAnalisysAndActionItemIndex ) => (
                      <Text style={styles.textSection}>
                        { getText( 10338 ) } { riskAnalisysAndActionItemIndex + 1 }: { riskAnalisysAndActionItem.risk }
                      </Text>
                ))
              :
                  <Text style={styles.textSection}>{ getText(10181) }</Text>
              }
              {/* Present the analisys of the risks in this chapter */}
              <Text style={styles.h3}>{ getText( 10334 )}</Text>
              {
                Object.values(riskAnalisysAndAction.riskAnalisysAndActionItems).length > 0 ? 
                  riskAnalisysAndAction.riskAnalisysAndActionItems.map(( riskAnalisysAndActionItem, riskAnalisysAndActionItemIndex ) => (
                      <Text style={styles.textSection}>
                        { getText( 10339 ) } { riskAnalisysAndActionItemIndex + 1}: { riskAnalisysAndActionItem.analisys }
                      </Text>
                ))
              :
                  <Text style={styles.textSection}>{ getText(10394) }</Text>
              }
              {/* Present the tasks, if any, of the risks in this chapter */}
              <Text style={styles.h3}>{ getText( 10335 )}</Text>
              <Text style={styles.textSection}>{ getText( 10336 )}</Text>
              {
                Object.values(riskAnalisysAndAction.riskAnalisysAndActionItems).length > 0 ? 
                  riskAnalisysAndAction.riskAnalisysAndActionItems.map(( riskAnalisysAndActionItem, riskAnalisysAndActionItemIndex ) => (
                      <>
                        <Text style={styles.textSection}><Text style={styles.boldText}>{ riskAnalisysAndActionItemIndex + 1 }. { getText( 10340 ) }:</Text><Text style={styles.text}> { riskAnalisysAndActionItem.risk }</Text></Text>
                        {
                          Object.values(riskAnalisysAndActionItem.riskAnalisysAndActionTaskItems).length > 0 ? 
                            riskAnalisysAndActionItem.riskAnalisysAndActionTaskItems.map(( riskAnalisysAndActionTaskItem, riskAnalisysAndActionTaskItemsIndex ) => (
                              <View key={riskAnalisysAndActionTaskItemsIndex} style={styles.atgardText}>
                                <Text style={styles.textSection}><Text style={styles.boldText}>{ getText( 10337 ) }:</Text><Text style={styles.text}>{ riskAnalisysAndActionTaskItem.task }</Text></Text>
                              </View>
                            ))
                          :
                            <Text style={styles.textSection}>{ getText(10395) }</Text>
                        }
                      </>
                ))
              :
                  <Text style={styles.textSection}>{ getText(10179) }</Text>
              }
            </View>
        }
      )
    }

    const riskAnalisysAndActionTaskItemsTable = getTableData( riskAnalisysAndActions );

    chapterPageViews.push({
      "chapterName": getText(10344),
      "chapterData": 
        <View>
          <Text style={styles.h3}>{getText(10344)}</Text>
          <Text style={styles.textSection}>{getText(10345)}</Text>
          <ReactPdfTable data={ riskAnalisysAndActionTaskItemsTable } styleSheet={ styles }/>
        </View>
    });
  
    return chapterPageViews;
  }

  const newChapterIndex = [];
  const equals = (a, b) => a.length === b.length && a.every((value, i) => value.pageNumber === b[i].pageNumber);

  const recalculateIndex = (chapterName, chapterIndex, pageNumber, totalPages) => {
    newChapterIndex[chapterIndex] = newChapterIndex[chapterIndex] ? newChapterIndex[chapterIndex] : { "chapterName": chapterName, "pageNumber": pageNumber };
    
    if (pageNumber === totalPages && !equals(stateChapterIndex, newChapterIndex)) {

      // SET NEW INDEX
      setStateChapterIndex(newChapterIndex);
    }
  }

  const getPageNumber = (chapterName, chapterIndex, pageNumber, totalPages) => {
    recalculateIndex(chapterName, chapterIndex, pageNumber, totalPages);
    
    return (`${pageNumber} / ${totalPages}`);
  }

  const chapterPageViews = getChapterPageViews(riskAnalisysAndActionYear.riskAnalisysAndActions);

  return (
    <Document>
      <Page size="A4" style={styles.body}>
        <View>
          <Text style={styles.h1}>
            { getText(10192) }
          </Text>
          <Text style={styles.author}>
            { getText(10215) } { moment(riskAnalisysAndActionYear.fromDate).format("YYYY-MM-DD") } - { moment(riskAnalisysAndActionYear.toDate).format("YYYY-MM-DD") }
          </Text>
          <Text style={styles.author}>
            Utskriftsdatum: { moment(new Date()).format("YYYY-MM-DD") }
          </Text>
        </View>
      </Page>
      <Page size="A4" style={styles.body}>
        {/* This is the page with the Table Of Content */}
        <View>
          <Text style={styles.header} fixed>
          { getText(10193) }
          </Text>
          <Text style={styles.h2}>
            { getText(10194) }
          </Text>
            {
              stateChapterIndex && stateChapterIndex.map((chapterIndex, i) => (
                <>
                  {
                    chapterIndex.chapterName !== "" ?
                      <View key={i} style={styles.tableOfContent}>
                        <Text style={ styles.tableOfContentChapterName }>
                            { chapterIndex.chapterName }
                        </Text>
                        <Text style={ styles.tableOfContentPageNumber }>
                            { chapterIndex.pageNumber }
                        </Text>
                      </View>
                    :
                      ""
                  }
                </>
              ))
            }
        </View>
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
          `${pageNumber} / ${totalPages}`
        )} fixed />
      </Page>
      {
        Object.values(chapterPageViews).map(( chapterPageView, chapterPageViewIndex ) => (
          <Page key={ chapterPageViewIndex } size="A4" style={styles.body}>
            <Text style={styles.header} fixed>
              { getText(10193) }
            </Text>
            { chapterPageView.chapterData }
            <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
              getPageNumber(chapterPageView.chapterName, chapterPageViewIndex, pageNumber, totalPages)
            )} fixed />
          </Page>
        ))
      }
    </Document>
  );
}

export default EqualTreatmentPlanPdf;