import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Loading from "Components/Loading";
import PropertyTable from "Components/PropertyTable";
import { getCustomer } from "graphql/queries";
import { AuthenticatedUrls } from "Routes/Urls";
import { CustomerSubscriptionModel } from "Models/Models";
import { FillModel } from "Models/FillModel";
import { GetItemById } from "Util/Api";

const CustomerSubscription = () => {
  const location = useLocation();
  const appState = useSelector((state) => state.settings.appState);

  const [stateIsLoading, setStateIsLoading] = useState(false);
  const [stateCustomerSubscriptions, setStateCustomerSubscriptions] = useState( [] );

  useEffect(() => {
    async function asyncUseEffect(){
      //Get the customer object.
      const customer = await GetItemById( appState.customer.id, getCustomer );
      
      const items = customer.CustomerSubscriptions.items;
      setStateCustomerSubscriptions( FillModel( CustomerSubscriptionModel, items ));

      //Scroll to the top of the page.
      window.scrollTo(0, 0);

      setStateIsLoading( false );
    }

    asyncUseEffect();
  }, []);

  if ( stateIsLoading ) {
    return <Loading />;
  } else {
    return (
      <PropertyTable
        parentId={ location.state?.parentId }
        data={ stateCustomerSubscriptions }
        model={ CustomerSubscriptionModel }
        editUrl={ AuthenticatedUrls.admin.subPages.editCustomerSubscription.path }
        header="Prenumerationer"
      />
    );
  }
}

export default CustomerSubscription;