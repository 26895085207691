/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      name
      description
      image
      responsible
      consultatManagerId
      invoiceAdress1
      invoiceAdress2
      invoicePostalCode
      invoiceCity
      CustomerSubscriptions {
        items {
          id
          customerId
          startDate
          endDate
          serviceId
          Service {
            id
            serviceType
            createdById
            changedById
            createdAt
            updatedAt
          }
          createdById
          changedById
          createdAt
          updatedAt
        }
        nextToken
      }
      Sections {
        items {
          id
          customerId
          sectionType
          Steps {
            items {
              id
              sectionId
              done
              stepType
              Files {
                items {
                  id
                  stepId
                  fileName
                  path
                  order
                  createdById
                  changedById
                  createdAt
                  updatedAt
                }
                nextToken
              }
              Documentation {
                items {
                  id
                  stepId
                  conclusion
                  createdById
                  changedById
                  createdAt
                  updatedAt
                }
                nextToken
              }
              MeasuresAndFollowUp {
                items {
                  id
                  stepId
                  measure
                  preformedBy
                  lastFollowUpDate
                  followUpDate
                  ongoing
                  createdById
                  changedById
                  createdAt
                  updatedAt
                }
                nextToken
              }
              Survey {
                items {
                  id
                  stepId
                  name
                  checked
                  order
                  createdById
                  changedById
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdById
              changedById
              createdAt
              updatedAt
            }
            nextToken
          }
          active
          createdById
          changedById
          createdAt
          updatedAt
        }
        nextToken
      }
      createdById
      changedById
      createdAt
      updatedAt
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        image
        responsible
        consultatManagerId
        invoiceAdress1
        invoiceAdress2
        invoicePostalCode
        invoiceCity
        CustomerSubscriptions {
          items {
            id
            customerId
            startDate
            endDate
            serviceId
            Service {
              id
              serviceType
              createdById
              changedById
              createdAt
              updatedAt
            }
            createdById
            changedById
            createdAt
            updatedAt
          }
          nextToken
        }
        Sections {
          items {
            id
            customerId
            sectionType
            Steps {
              items {
                id
                sectionId
                done
                stepType
                Files {
                  items {
                    id
                    stepId
                    fileName
                    path
                    order
                    createdById
                    changedById
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                Documentation {
                  items {
                    id
                    stepId
                    conclusion
                    createdById
                    changedById
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                MeasuresAndFollowUp {
                  items {
                    id
                    stepId
                    measure
                    preformedBy
                    lastFollowUpDate
                    followUpDate
                    ongoing
                    createdById
                    changedById
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                Survey {
                  items {
                    id
                    stepId
                    name
                    checked
                    order
                    createdById
                    changedById
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdById
                changedById
                createdAt
                updatedAt
              }
              nextToken
            }
            active
            createdById
            changedById
            createdAt
            updatedAt
          }
          nextToken
        }
        createdById
        changedById
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCustomerSubscription = /* GraphQL */ `
  query GetCustomerSubscription($id: ID!) {
    getCustomerSubscription(id: $id) {
      id
      customerId
      startDate
      endDate
      serviceId
      Service {
        id
        serviceType
        createdById
        changedById
        createdAt
        updatedAt
      }
      createdById
      changedById
      createdAt
      updatedAt
    }
  }
`;
export const listCustomerSubscriptions = /* GraphQL */ `
  query ListCustomerSubscriptions(
    $filter: ModelCustomerSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomerSubscriptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerId
        startDate
        endDate
        serviceId
        Service {
          id
          serviceType
          createdById
          changedById
          createdAt
          updatedAt
        }
        createdById
        changedById
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getService = /* GraphQL */ `
  query GetService($id: ID!) {
    getService(id: $id) {
      id
      serviceType
      createdById
      changedById
      createdAt
      updatedAt
    }
  }
`;
export const listServices = /* GraphQL */ `
  query ListServices(
    $filter: ModelServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listServices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        serviceType
        createdById
        changedById
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSection = /* GraphQL */ `
  query GetSection($id: ID!) {
    getSection(id: $id) {
      id
      customerId
      sectionType
      Steps {
        items {
          id
          sectionId
          done
          stepType
          Files {
            items {
              id
              stepId
              fileName
              path
              order
              createdById
              changedById
              createdAt
              updatedAt
            }
            nextToken
          }
          Documentation {
            items {
              id
              stepId
              conclusion
              createdById
              changedById
              createdAt
              updatedAt
            }
            nextToken
          }
          MeasuresAndFollowUp {
            items {
              id
              stepId
              measure
              preformedBy
              lastFollowUpDate
              followUpDate
              ongoing
              createdById
              changedById
              createdAt
              updatedAt
            }
            nextToken
          }
          Survey {
            items {
              id
              stepId
              name
              checked
              order
              createdById
              changedById
              createdAt
              updatedAt
            }
            nextToken
          }
          createdById
          changedById
          createdAt
          updatedAt
        }
        nextToken
      }
      active
      createdById
      changedById
      createdAt
      updatedAt
    }
  }
`;
export const listSections = /* GraphQL */ `
  query ListSections(
    $filter: ModelSectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSections(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        customerId
        sectionType
        Steps {
          items {
            id
            sectionId
            done
            stepType
            Files {
              items {
                id
                stepId
                fileName
                path
                order
                createdById
                changedById
                createdAt
                updatedAt
              }
              nextToken
            }
            Documentation {
              items {
                id
                stepId
                conclusion
                createdById
                changedById
                createdAt
                updatedAt
              }
              nextToken
            }
            MeasuresAndFollowUp {
              items {
                id
                stepId
                measure
                preformedBy
                lastFollowUpDate
                followUpDate
                ongoing
                createdById
                changedById
                createdAt
                updatedAt
              }
              nextToken
            }
            Survey {
              items {
                id
                stepId
                name
                checked
                order
                createdById
                changedById
                createdAt
                updatedAt
              }
              nextToken
            }
            createdById
            changedById
            createdAt
            updatedAt
          }
          nextToken
        }
        active
        createdById
        changedById
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getStep = /* GraphQL */ `
  query GetStep($id: ID!) {
    getStep(id: $id) {
      id
      sectionId
      done
      stepType
      Files {
        items {
          id
          stepId
          fileName
          path
          order
          createdById
          changedById
          createdAt
          updatedAt
        }
        nextToken
      }
      Documentation {
        items {
          id
          stepId
          conclusion
          createdById
          changedById
          createdAt
          updatedAt
        }
        nextToken
      }
      MeasuresAndFollowUp {
        items {
          id
          stepId
          measure
          preformedBy
          lastFollowUpDate
          followUpDate
          ongoing
          createdById
          changedById
          createdAt
          updatedAt
        }
        nextToken
      }
      Survey {
        items {
          id
          stepId
          name
          checked
          order
          createdById
          changedById
          createdAt
          updatedAt
        }
        nextToken
      }
      createdById
      changedById
      createdAt
      updatedAt
    }
  }
`;
export const listSteps = /* GraphQL */ `
  query ListSteps(
    $filter: ModelStepFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSteps(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sectionId
        done
        stepType
        Files {
          items {
            id
            stepId
            fileName
            path
            order
            createdById
            changedById
            createdAt
            updatedAt
          }
          nextToken
        }
        Documentation {
          items {
            id
            stepId
            conclusion
            createdById
            changedById
            createdAt
            updatedAt
          }
          nextToken
        }
        MeasuresAndFollowUp {
          items {
            id
            stepId
            measure
            preformedBy
            lastFollowUpDate
            followUpDate
            ongoing
            createdById
            changedById
            createdAt
            updatedAt
          }
          nextToken
        }
        Survey {
          items {
            id
            stepId
            name
            checked
            order
            createdById
            changedById
            createdAt
            updatedAt
          }
          nextToken
        }
        createdById
        changedById
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDocumentation = /* GraphQL */ `
  query GetDocumentation($id: ID!) {
    getDocumentation(id: $id) {
      id
      stepId
      conclusion
      createdById
      changedById
      createdAt
      updatedAt
    }
  }
`;
export const listDocumentations = /* GraphQL */ `
  query ListDocumentations(
    $filter: ModelDocumentationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDocumentations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        stepId
        conclusion
        createdById
        changedById
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMeasuresAndFollowUp = /* GraphQL */ `
  query GetMeasuresAndFollowUp($id: ID!) {
    getMeasuresAndFollowUp(id: $id) {
      id
      stepId
      measure
      preformedBy
      lastFollowUpDate
      followUpDate
      ongoing
      createdById
      changedById
      createdAt
      updatedAt
    }
  }
`;
export const listMeasuresAndFollowUps = /* GraphQL */ `
  query ListMeasuresAndFollowUps(
    $filter: ModelMeasuresAndFollowUpFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMeasuresAndFollowUps(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        stepId
        measure
        preformedBy
        lastFollowUpDate
        followUpDate
        ongoing
        createdById
        changedById
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSurvey = /* GraphQL */ `
  query GetSurvey($id: ID!) {
    getSurvey(id: $id) {
      id
      stepId
      name
      checked
      order
      createdById
      changedById
      createdAt
      updatedAt
    }
  }
`;
export const listSurveys = /* GraphQL */ `
  query ListSurveys(
    $filter: ModelSurveyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSurveys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        stepId
        name
        checked
        order
        createdById
        changedById
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFile = /* GraphQL */ `
  query GetFile($id: ID!) {
    getFile(id: $id) {
      id
      stepId
      fileName
      path
      order
      createdById
      changedById
      createdAt
      updatedAt
    }
  }
`;
export const listFiles = /* GraphQL */ `
  query ListFiles(
    $filter: ModelFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        stepId
        fileName
        path
        order
        createdById
        changedById
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSurveyTemplate = /* GraphQL */ `
  query GetSurveyTemplate($id: ID!) {
    getSurveyTemplate(id: $id) {
      id
      name
      order
      sectionType
      createdById
      changedById
      createdAt
      updatedAt
    }
  }
`;
export const listSurveyTemplates = /* GraphQL */ `
  query ListSurveyTemplates(
    $filter: ModelSurveyTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSurveyTemplates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        order
        sectionType
        createdById
        changedById
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMaterials = /* GraphQL */ `
  query GetMaterials($id: ID!) {
    getMaterials(id: $id) {
      id
      name
      path
      type
      order
      createdById
      changedById
      createdAt
      updatedAt
    }
  }
`;
export const listMaterialss = /* GraphQL */ `
  query ListMaterialss(
    $filter: ModelMaterialsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMaterialss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        path
        type
        order
        createdById
        changedById
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserDataStorage = /* GraphQL */ `
  query GetUserDataStorage($id: ID!) {
    getUserDataStorage(id: $id) {
      id
      data
      div
      createdById
      changedById
      createdAt
      updatedAt
    }
  }
`;
export const listUserDataStorages = /* GraphQL */ `
  query ListUserDataStorages(
    $filter: ModelUserDataStorageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserDataStorages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        data
        div
        createdById
        changedById
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
