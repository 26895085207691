import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, Outlet} from 'react-router-dom';
import { AuthenticatedUrls } from 'Routes/Urls';
import Loading from "Components/Loading";
import Navbar from "Components/Navbar";
import SideMenu from "Components/SideMenu";
import { UrlsAreEqual } from "Util/Utils";

const Admin = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [stateIsLoading, setStateIsLoading] = useState(false);

  useEffect(() => {
    if( UrlsAreEqual(location.pathname, AuthenticatedUrls.admin.path ) ){
      navigate( AuthenticatedUrls.admin.subPages.customers.path, { replace: true } );
    }

    setStateIsLoading( false );
  }, []);

  if ( stateIsLoading ) {
    return <Loading />;
  } else {
    return (
      <>
        <div className="pageContainer">
          <div className="wrapperOuter">
            <SideMenu />
            <div className="wrapperInner">
              <Navbar />
              <div className="adminContainer">
                <div className="adminInnerContainer">    
                  <div className="contentWrapper">
                    <div className='contentWrapperMid'>
                      <Outlet />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Admin;
