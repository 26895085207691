import { createSlice, createAsyncThunk  } from '@reduxjs/toolkit';
import { Auth } from "aws-amplify";
import AppState from "Classes/AppState";
import { GetItemById } from "Util/Api";
import { getUserDataStorage, getCustomer } from "graphql/queries";
import { push } from "redux-first-history";
import { AuthenticatedUrls, UnauthenticatedUrls } from "Routes/Urls";
import { store } from "App/store";
import { LoadLocaleFileFromS3 } from "Util/LocaleHandling";

//https://redux-toolkit.js.org/rtk-query/usage/examples

const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    otherSettings: { ok: true },
    appState: new AppState(1),
  },
  reducers: {
    setAppStateSetting: (state, action) => {
      state.appState = { ...state.appState, ...action.payload };
    },
  },
});

export const { setAppStateSetting } = settingsSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
//https://stackoverflow.com/questions/69785965/redux-toolkit-update-a-key-value-in-an-object
export const setAppStateSettingAsync = (action) => (dispatch) => {
  setTimeout(() => {
    dispatch(setAppStateSetting((action)))
  }, 1000)
}

// async function getUserFullName() {
//   const usersFirstName = (await Auth.currentUserInfo())?.attributes["given_name"];
//   const userLastName = (await Auth.currentUserInfo())?.attributes["family_name"];
//   const userFullName =  usersFirstName.concat(' ', userLastName);

//   return userFullName;
// }

//export async function userSignIn(){

async function GetCurrentUserGroup() {
  let user = await Auth.currentAuthenticatedUser();
  let groups = user.signInUserSession.accessToken.payload["cognito:groups"];
  return groups?.[0];
}

export const userSignIn = () => async (dispatch) => {
  try {
    const reduxStore = store.getState();
    const location = reduxStore.router.location;

    const userId = (await Auth.currentUserInfo())?.username;
    const customerId = (await Auth.currentUserInfo())?.attributes["custom:customerId"] || null;
    
    // let userFullName = await getUserFullName();
    // dispatch( setAppStateSetting( {  userFullName: userFullName } ) );
    
    let customer = null;
    if (customerId != null) {
      customer = await GetItemById(customerId, getCustomer, null);
    }

    const role = await GetCurrentUserGroup();

    if (userId) {
      dispatch( setAppStateSetting( {  isAuthenticated: true } ) );

      //Set the role for this user.
      dispatch( setAppStateSetting( {  role: role } ) );

      //Set the customer object.
      dispatch( setAppStateSetting( {  customer: customer } ) );

      //Set the userId object.
      dispatch( setAppStateSetting( {  userId: userId } ) );

      //Load locale files.
      await LoadLocaleFileFromS3( "se", "translation" );

      if (customerId == null) {
        dispatch( push( AuthenticatedUrls.onboarding.path ) );
      } else if ( location.pathname === "/"
                    || location.pathname === UnauthenticatedUrls.landing.path
                    || location.pathname === UnauthenticatedUrls.landing.subPages.login.path
                    || location.pathname === UnauthenticatedUrls.landing.subPages.recover.path) {
        dispatch( push( AuthenticatedUrls.content.subPages.welcome.path ) );
      } else {
        //Do nothing for now!
        //console.log("userSignIn - No Match!");
      }
    }
  } catch (err) {
    console.error("SignIn:", err);
  }

  return;
}

export const userSignOut = () => async (dispatch) => {
  try {
    await Auth.signOut();
    dispatch( setAppStateSetting( {  isAuthenticated: false } ) );
    dispatch( push( UnauthenticatedUrls.landing.path ));
  } catch (err) {
    console.error("Singout:", err);
  }

  return;
}

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.settings.appState)`
export const selectAppState = (state) => state.settings.appState;

export default settingsSlice.reducer