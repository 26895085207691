import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Loading from "Components/Loading";
import PropertyTable from "Components/PropertyTable";
import { FillModel } from "Models/FillModel";
import { UserModel, UserModelConsultantAndCustomerAdmin } from "Models/Models";
import { AuthenticatedUrls } from "Routes/Urls";
import { ListUsers, ListGroupsForUser } from "Util/AdminQuerries";
import { Roles, UserStates } from "Util/Const";
import { PopupError } from "Util/Utils";

const Users = () => {
  const location = useLocation();

  const appState = useSelector((state) => state.settings.appState);

  let customerId = location.state?.parentId;

  //As a customerAdmin we can only edit users of our own customer. 
  if ( appState.role === Roles.customerAdmin.value) {
    customerId = appState.customer.id;
  }

  const [stateIsLoading, setStateIsLoading] = useState( true );
  const [stateCustomerId, setStateCustomerId] = useState( customerId );
  // const [stateCustomer, setStateCustomer] = useState( null );
  const [stateUsers, setStateUsers] = useState( [] );

  useEffect(() => {
    async function asyncUseEffect() {
      const userList = await ListUsers();

      const users = userList?.Users.filter(
        (user) => user.Attributes.find((a) => a.Name === "custom:customerId" && a.Value === stateCustomerId ) != null
      ).map((user) => ({
        id: user.Username,
        email: user.Attributes.find((a) => a.Name === "email")?.Value,
        familyName: user.Attributes.find((a) => a.Name === "family_name")?.Value,
        givenName: user.Attributes.find((a) => a.Name === "given_name")?.Value,
        customerId: user.Attributes.find((a) => a.Name === "custom:customerId")?.Value,
        title: user.Attributes.find((a) => a.Name === "custom:title")?.Value,
        enabled: user.Enabled ? UserStates.enabled.value : UserStates.disabled.value,
      }));

      //Add group to user.
      for (let user of users) {
        try {
          const groups = (await ListGroupsForUser(user.id)).Groups;

          if (groups.length === 1) {
            const role = groups[0].GroupName;
            user.role = role;
          } else {
            PopupError("Mer än en grupp. Ej tillåtet");
            setStateIsLoading( false );
            // setStateCustomer( customer );
            setStateUsers( [] );
            return;
          }
        } catch (err) {

        }
      }

      // setStateCustomer( customer );
      setStateUsers( FillModel(UserModel, users) );

      //Scroll to the top of the page.
      window.scrollTo(0, 0);

      setStateIsLoading( false );
    }

    asyncUseEffect();
  }, []);

  if (stateIsLoading) {
    return <Loading />;
  } else {
    return (
      //Check what type of user this is and assing the propper User Model.
      <>
        { appState.role === Roles.admin.value ?
          <>
            <PropertyTable
              parentId={ stateCustomerId }
              data={stateUsers}
              model={UserModel}
              editUrl={AuthenticatedUrls.admin.subPages.editUsers.path}
              header="Användare"
            />
          </>
          :
            ""}
      
        { appState.role === Roles.consultantAdmin.value || appState.role === Roles.customerAdmin.value ?
          <>
            <PropertyTable
              parentId={ stateCustomerId } 
              data={stateUsers}
              model={UserModelConsultantAndCustomerAdmin}
              editUrl={AuthenticatedUrls.admin.subPages.editUsers.path}
              header="Användare"
            />
          </>
          :
          ""}
      </>
    );
  }
}

export default Users;