import React from "react";
import { TwKey} from "Components/StandardTextComponentsWithTranslation";
import StepOne from "Assets/Images/Introduction/StepOne.png";
import GetStarted from "Assets/Images/Introduction/GetStarted.png";
import Summery from "Assets/Images/Introduction/Summery.png";
import SettingsIcon from "Assets/Images/Introduction/SettingsIcon.png";

const Introduction = () => {
  return (
    <>
      <div className="innerContentWrapper">
        <div className="introduction">
          <div className="introductionTitle">
            <h2><TwKey textKey="10128"/></h2>
          </div>
          <div className="introductionContent">
            <p><TwKey textKey="10129"/></p>
            <h3><TwKey textKey="10130"/></h3>
            <p><TwKey textKey="10346"/></p>
            <p><b><TwKey textKey="10347"/></b></p>
            <p><TwKey textKey="10348"/></p>
            <p><b><TwKey textKey="10349"/></b></p>
            <p><TwKey textKey="10350"/></p>
            <p><b><TwKey textKey="10351"/></b></p>
            <p><TwKey textKey="10352"/></p>
            <h3><TwKey textKey="10353"/></h3>
            <p><TwKey textKey="10354"/></p>
            <ul>
              <li><TwKey textKey="10355"/></li>
              <li><TwKey textKey="10356"/></li>
              <li><TwKey textKey="10357"/></li>
              <li><TwKey textKey="10358"/></li>
              <li><TwKey textKey="10359"/></li>
              <li><TwKey textKey="10360"/></li>
              <li><TwKey textKey="10361"/></li>
            </ul>
            <p><TwKey textKey="10362"/></p>
            <ol>
              <li><TwKey textKey="10363"/></li>
              <li><TwKey textKey="10364"/></li>
              <li><TwKey textKey="10365"/></li>
            </ol>
            <p><TwKey textKey="10366"/></p>
            <img src={StepOne} />
            <p><TwKey textKey="10367"/></p>
            <img src={GetStarted}/>
            <p><TwKey textKey="10368"/></p>
            <h3><TwKey textKey="10369"/></h3>
            <p><TwKey textKey="10370"/></p>
            <p><b><TwKey textKey="10371"/></b></p>
            <p><TwKey textKey="10372"/></p>
            <p><b><TwKey textKey="10373"/></b></p>
            <p><TwKey textKey="10374"/></p>
            <img src={Summery}/>
            <p><TwKey textKey="10375"/></p>
            <p><b><TwKey textKey="10376"/></b></p>
            <p><TwKey textKey="10377"/></p>
            <h3><TwKey textKey="10378"/></h3>
            <p><TwKey textKey="10379"/><img src={SettingsIcon} /><TwKey textKey="10397"/></p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Introduction;