import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import EditView from "Components/EditView";
import {
  AddUserToGroup,
  AdminCreateUser,
  AdminDeleteUser,
  DisableUser,
  EnableUser,
  RemoveUserFromGroup,
  adminUpdateUserAttributes,
} from "Util/AdminQuerries";
import { Roles, UserStates } from "Util/Const";
import { UserModel, UserModelConsultantAndCustomerAdmin } from "Models/Models";
import { PopupWithCallBack, PopupErrorCallback } from "Util/Utils";

const EditUsers = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const appState = useSelector((state) => state.settings.appState);

  const [stateUserId, setStateUserId] = useState( location.state?.id );
  const [stateEdit, setStateEdit] = useState( location.state?.id != null );
  const [stateData, setStateData] = useState( location.state?.data != null ? JSON.parse(JSON.stringify(location.state?.data)) : null );
  
  async function deleteUser() {
    try {
      //Delete user
      await DisableUser(stateUserId);
      await AdminDeleteUser(stateUserId);
    } catch (err) {
      try {
        await EnableUser(stateUserId);
      } catch (err2) {
        console.error("could not restore failed Delete of user: ", err2);
      }
      console.error("Delete User: ", err);
    }
    navigate(-1);
  }

  async function createNewUser(data) {
    try {
      const email = data.find((d) => d.key === "email").value;
      const customerId = data.find((d) => d.key === "customerId").value;
      const role = data.find((d) => d.key === "role").value;
      const title = data.find((d) => d.key === "title").value;
      const givenName = data.find((d) => d.key === "givenName").value;
      const familyName = data.find((d) => d.key === "familyName").value;

      let result = await AdminCreateUser({ customerId, email, title, givenName, familyName });
      if (result != null) {
        const userId = result.result.User.Username;
        result = await AddUserToGroup(userId, role);
        if (result != null) {
          PopupWithCallBack("Användaren skapades!", () => navigate(-1));
        } else {
          throw new Error("Could not add user to group, unknown");
        }
      } else {
        throw new Error("Could not create user, unknown");
      }
    } catch (err) {
      console.error(err);
      PopupErrorCallback("Det gick inte att skapa användaren", () => navigate(-1));
    }
  }

  async function editUser(data) {
    try {
      //deal with change in role/group
      const role = data.find((d) => d.key === "role").value;
      const oldrole = stateData.find((d) => d.key === "role").value;

      let result = {};
      if (oldrole !== role) {
        result = await RemoveUserFromGroup(stateUserId, oldrole);
        if (result == null) {
          throw new Error("Could not remove user from group, unknown");
        }
        result = await AddUserToGroup(stateUserId, role);
        if (result == null) {
          throw new Error("Could not add user to group, unknown");
        }
      }

      //Deal with change of title and email.
      const title = data.find((d) => d.key === "title").value;
      const email = data.find((d) => d.key === "email").value;
      const givenName = data.find((d) => d.key === "givenName").value;
      const familyName = data.find((d) => d.key === "familyName").value;
      result = await adminUpdateUserAttributes(stateUserId, email, title, givenName, familyName);

      //Deal with diable/enable user.
      const enabled = data.find((d) => d.key === "enabled").value;
      const oldEnabled = stateData.find((d) => d.key === "enabled").value;

      if (enabled !== oldEnabled) {
        if (enabled === UserStates.disabled.value) {
          await DisableUser(stateUserId);
        } else if (enabled === UserStates.enabled.value) {
          await EnableUser(stateUserId);
        } else {
          console.error("enabled state unknown,", enabled);
        }
      }

      if (result != null) {
        PopupWithCallBack("Användaren uppdaterades!", () => navigate(-1));
      } else {
        throw new Error("Could update user properties, unknown");
      }
    } catch (err) {
      console.error(err);
      PopupErrorCallback("Det gick inte att skapa användaren", () => navigate(-1));
    }
  }

  return (
    //Check what type of user this is and assing the propper User Model.
    <>
      {
        appState.role === Roles.admin.value ?
          <EditView
            id={ location.state?.id }
            data={ location.state?.data }
            model={ UserModel }
            header={ location.state?.id ? "Redigera användare" : "Skapa användare" }
            onSave={ stateEdit ? editUser : createNewUser }
            onDelete={ deleteUser }
            disableDelete={ true }
          />
        :
          ""
      }
      {
        appState.role === Roles.consultantAdmin.value || appState.role === Roles.customerAdmin.value ?
          <EditView
            id={ location.state?.id }
            data={ location.state?.data }
            model={ UserModelConsultantAndCustomerAdmin }
            header={ location.state?.id ? "Redigera användare" : "Skapa användare" }
            onSave={ stateEdit ? editUser : createNewUser }
            onDelete={ deleteUser }
            disableDelete={ true }
          />
        :
          ""
      }
    </>
  );
}

export default EditUsers;
