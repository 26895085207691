import { v4 as uuidv4 } from 'uuid';
import { ChapterSectionTypes } from "Util/Const";
import { RiskAnalisysAndActionYear, RiskAnalisysAndAction, RiskAnalisysAndActionItem, RiskAnalisysAndActionTaskItem } from "Classes/UserData";

export async function copyRiskAnalisysAndActionTaskItemWithCheckBoxIfCheckedElseReturnNew( riskAnalisysAndActionTaskItemWithCheckBox ){
  let riskAnalisysAndActionTaskItem = null;

  //Copy properties if checked.
  if( riskAnalisysAndActionTaskItemWithCheckBox.checked === true ){
    riskAnalisysAndActionTaskItem = new RiskAnalisysAndActionTaskItem( uuidv4() );

    riskAnalisysAndActionTaskItem.riskAnalisysAndActionItemId = riskAnalisysAndActionTaskItemWithCheckBox.riskAnalisysAndActionItemId;
    riskAnalisysAndActionTaskItem.task = riskAnalisysAndActionTaskItemWithCheckBox.task;
    riskAnalisysAndActionTaskItem.responsible = riskAnalisysAndActionTaskItemWithCheckBox.responsible;
    riskAnalisysAndActionTaskItem.ongoing = riskAnalisysAndActionTaskItemWithCheckBox.ongoing;
    riskAnalisysAndActionTaskItem.followUpDate = riskAnalisysAndActionTaskItemWithCheckBox.followUpDate;
    riskAnalisysAndActionTaskItem.fixed = riskAnalisysAndActionTaskItemWithCheckBox.fixed;
    riskAnalisysAndActionTaskItem.fixedDate = riskAnalisysAndActionTaskItemWithCheckBox.fixedDate;
    riskAnalisysAndActionTaskItem.comment = riskAnalisysAndActionTaskItemWithCheckBox.comment;
    riskAnalisysAndActionTaskItem.createdBy = riskAnalisysAndActionTaskItemWithCheckBox.createdBy;
    riskAnalisysAndActionTaskItem.changedBy = riskAnalisysAndActionTaskItemWithCheckBox.changedBy;
  } else {
    riskAnalisysAndActionTaskItem = new RiskAnalisysAndActionTaskItem( uuidv4() )   
  }

  return riskAnalisysAndActionTaskItem;
}

export async function copyRiskAnalisysAndActionItemWithCheckBoxIfCheckedElseReturnNew( riskAnalisysAndActionItemWithCheckBox ){
  let riskAnalisysAndActionItem = null;
  
  if ( riskAnalisysAndActionItemWithCheckBox.checked === true ){
    riskAnalisysAndActionItem = new RiskAnalisysAndActionItem( uuidv4() );

    //Copy properties.
    riskAnalisysAndActionItem.riskAnalisysAndActionId = riskAnalisysAndActionItemWithCheckBox.riskAnalisysAndActionId;
    riskAnalisysAndActionItem.risk = riskAnalisysAndActionItemWithCheckBox.risk;
    riskAnalisysAndActionItem.consequence = riskAnalisysAndActionItemWithCheckBox.consequence;
    riskAnalisysAndActionItem.analisys = riskAnalisysAndActionItemWithCheckBox.analisys;
    riskAnalisysAndActionItem.remedy = riskAnalisysAndActionItemWithCheckBox.remedy;
    riskAnalisysAndActionItem.followUpText = riskAnalisysAndActionItemWithCheckBox.followUpText;
    riskAnalisysAndActionItem.createdBy = riskAnalisysAndActionItemWithCheckBox.createdBy;
    riskAnalisysAndActionItem.changedBy = riskAnalisysAndActionItemWithCheckBox.changedBy;

    //Copy all riskAnalisysAndActionTaskItems.
    if ( riskAnalisysAndActionItemWithCheckBox.riskAnalisysAndActionTaskItemsWithCheckBox ){
      for(let riskAnalisysAndActionTaskItemWithCheckBox of riskAnalisysAndActionItemWithCheckBox.riskAnalisysAndActionTaskItemsWithCheckBox ){
        const riskAnalisysAndActionTaskItem = await copyRiskAnalisysAndActionTaskItemWithCheckBoxIfCheckedElseReturnNew(riskAnalisysAndActionTaskItemWithCheckBox)  
        
        if( riskAnalisysAndActionTaskItem !== null ){
          riskAnalisysAndActionItem.riskAnalisysAndActionTaskItems.push( riskAnalisysAndActionTaskItem );
        }
      }
    }
  } else {
    riskAnalisysAndActionItem = new RiskAnalisysAndActionItem( uuidv4() );
  }

  return riskAnalisysAndActionItem;
}

export async function copyRiskAnalisysAndActionWithCheckBoxIfCheckedElseReturnNew( riskAnalisysAndActionWithCheckBox ){
    let riskAnalisysAndAction = null;
  
  if( riskAnalisysAndActionWithCheckBox.checked === true ) {
    riskAnalisysAndAction = new RiskAnalisysAndAction( uuidv4() );
    
    //Copy properties.
    riskAnalisysAndAction.chapterSectionTypeName = riskAnalisysAndActionWithCheckBox.chapterSectionTypeName;
    riskAnalisysAndAction.titleTextKey = riskAnalisysAndActionWithCheckBox.titleTextKey;
    riskAnalisysAndAction.informationTextKey = riskAnalisysAndActionWithCheckBox.informationTextKey;
    riskAnalisysAndAction.chapterDescriptionTextKey = riskAnalisysAndActionWithCheckBox.chapterDescriptionTextKey;
    riskAnalisysAndAction.tasksChapterInformationTextKey = riskAnalisysAndActionWithCheckBox.tasksChapterInformationTextKey;
    riskAnalisysAndAction.statusChapterInformationTextKey = riskAnalisysAndActionWithCheckBox.statusChapterInformationTextKey;
    riskAnalisysAndAction.risksDefinedAndAnalyzed = riskAnalisysAndActionWithCheckBox.risksDefinedAndAnalyzed;
    riskAnalisysAndAction.createdBy = riskAnalisysAndActionWithCheckBox.createdBy;
    riskAnalisysAndAction.changedBy = riskAnalisysAndActionWithCheckBox.changedBy;

    //Copy all riskAnalisysAndActionItems.
    if ( riskAnalisysAndActionWithCheckBox.riskAnalisysAndActionItemsWithCheckBox ) {
      for( const riskAnalisysAndActionItemWithCheckBox of riskAnalisysAndActionWithCheckBox.riskAnalisysAndActionItemsWithCheckBox ){
        const riskAnalisysAndActionItem = await copyRiskAnalisysAndActionItemWithCheckBoxIfCheckedElseReturnNew( riskAnalisysAndActionItemWithCheckBox );

        if ( riskAnalisysAndActionItem !== null ){
          riskAnalisysAndAction.riskAnalisysAndActionItems.push( riskAnalisysAndActionItem );
        }
      }
    }
  } else {
    riskAnalisysAndAction = new RiskAnalisysAndAction( uuidv4() );
  }

  return riskAnalisysAndAction;
}

/**
 * Copies the data from a riskAnalisysAndActionYearWithCheckBox to a riskAnalisysAndActionYear, but replaces fromDate and toDate.
 * Retrurns a new riskAnalisysAndActionYear if the riskAnalisysAndActionYearWithCheckBox was not checked.
 * @param {*} riskAnalisysAndActionYearWithCheckBox 
 * @param {*} fromDate 
 * @param {*} toDate 
 * @returns {*} riskAnalisysAndActionYear
 */
export async function copyRiskAnalisysAndActionYearWithCheckBoxElseReturnNew( riskAnalisysAndActionYearWithCheckBox, fromDate, toDate ){
  let riskAnalisysAndActionYear = null;
  
  if ( riskAnalisysAndActionYearWithCheckBox.checked === true ) {
    riskAnalisysAndActionYear = new RiskAnalisysAndActionYear( uuidv4() );
    
    //Copy properties.
    riskAnalisysAndActionYear.fromDate = new Date(fromDate);
    riskAnalisysAndActionYear.toDate = new Date(toDate);
    riskAnalisysAndActionYear.active = riskAnalisysAndActionYearWithCheckBox.active;
    riskAnalisysAndActionYear.createdBy = riskAnalisysAndActionYearWithCheckBox.createdBy;
    riskAnalisysAndActionYear.changedBy = riskAnalisysAndActionYearWithCheckBox.changedBy;
    riskAnalisysAndActionYear.riskAnalisysAndActions = {
        workingConditions: await copyRiskAnalisysAndActionWithCheckBoxIfCheckedElseReturnNew(riskAnalisysAndActionYearWithCheckBox.riskAnalisysAndActionsWithCheckBox[ChapterSectionTypes.workingConditions.Name]),
        employmentAndParenthood: await copyRiskAnalisysAndActionWithCheckBoxIfCheckedElseReturnNew(riskAnalisysAndActionYearWithCheckBox.riskAnalisysAndActionsWithCheckBox[ChapterSectionTypes.employmentAndParenthood.Name]),
        wagesAndEmploymentTerms: await copyRiskAnalisysAndActionWithCheckBoxIfCheckedElseReturnNew(riskAnalisysAndActionYearWithCheckBox.riskAnalisysAndActionsWithCheckBox[ChapterSectionTypes.wagesAndEmploymentTerms.Name]),
        recruitmentAndPromotion: await copyRiskAnalisysAndActionWithCheckBoxIfCheckedElseReturnNew(riskAnalisysAndActionYearWithCheckBox.riskAnalisysAndActionsWithCheckBox[ChapterSectionTypes.recruitmentAndPromotion.Name]),
        educationAndSkillsDevelopment: await copyRiskAnalisysAndActionWithCheckBoxIfCheckedElseReturnNew(riskAnalisysAndActionYearWithCheckBox.riskAnalisysAndActionsWithCheckBox[ChapterSectionTypes.educationAndSkillsDevelopment.Name]),
        measuresToPreventHarassment: await copyRiskAnalisysAndActionWithCheckBoxIfCheckedElseReturnNew(riskAnalisysAndActionYearWithCheckBox.riskAnalisysAndActionsWithCheckBox[ChapterSectionTypes.measuresToPreventHarassment.Name]),
        equalGenderDistribution: await copyRiskAnalisysAndActionWithCheckBoxIfCheckedElseReturnNew(riskAnalisysAndActionYearWithCheckBox.riskAnalisysAndActionsWithCheckBox[ChapterSectionTypes.equalGenderDistribution.Name]),
    };
  } else {
    riskAnalisysAndActionYear = new RiskAnalisysAndActionYear( uuidv4() );
    
    //Set correct from and to date, and set it as active.
    riskAnalisysAndActionYear.fromDate = fromDate;
    riskAnalisysAndActionYear.toDate = toDate;
    riskAnalisysAndActionYear.active = true;
  }

  return riskAnalisysAndActionYear;
}