import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, Link } from "react-router-dom";
import Loading from "Components/Loading";
import { AppBar, Box, Toolbar, Button, Typography, Menu, IconButton, Container } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { UnauthenticatedUrls } from "Routes/Urls";
import likaPlanLogo from "Assets/Images/LikaPlanLogo.png";
import globalGoals8 from "Assets/Images/Frontpage/GlobalGoals8.png";
import globalGoals5 from "Assets/Images/Frontpage/GlobalGoals5.png";
import globalGoals10 from "Assets/Images/Frontpage/GlobalGoals10.png";
import formatListNumbered from "Assets/Images/Frontpage/FormatListNumbered.png";
import playlistAddCheck from "Assets/Images/Frontpage/PlaylistAddCheck.png";
import assignment from "Assets/Images/Frontpage/Assignment.png";
import eitech_elektro_logo from "Assets/Images/Frontpage/eitech_elektro_logo.png";
import mellanskogLogoRGB from "Assets/Images/Frontpage/mellanskog-logotyp-rgb-20cm.png";
import placerum_logotyp_RGB from "Assets/Images/Frontpage/placerum_logotyp_RGB.png";
import elin from "Assets/Images/Frontpage/Elin.png";
import johanna from "Assets/Images/Frontpage/Johanna.png";
import paul from "Assets/Images/Frontpage/Paul.png";
import erik from "Assets/Images/Frontpage/Erik.png";
import thomas from "Assets/Images/Frontpage/Thomas.png";
import icon_Mail from "Assets/Images/Frontpage/icon_Mail.png";
import icon_Phone from "Assets/Images/Frontpage/icon_Phone.png";
import PropTypes from "prop-types";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { openInNewTab } from "Util/Utils";

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won"t need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won"t need it on your project.
   */
  window: PropTypes.func,
};

const Index = () => {
  const navigate = useNavigate();
  
  const [stateIsLoading, setStateIsLoading] = useState( true );
  const [stateAnchorElNav, setStateAnchorElNav] = useState(null);
  const [stateAnchorElUser, setStateAnchorElUser] = useState(null);
  const [stateActiveSection, setStateActiveSection] = useState( "home" );
  const sections = useRef([]);

  const handleScroll = () => {
    const pageYOffset = window.pageYOffset;
    let newActiveSection = null;

    sections.current = document.querySelectorAll("[data-section]");

    sections.current.forEach((section) => {
      const sectionOffsetTop = section.offsetTop - 75;
      const sectionHeight = section.offsetHeight + 75;

      if (pageYOffset >= sectionOffsetTop && pageYOffset < sectionOffsetTop + sectionHeight) {
        if (section.id !== stateActiveSection || section.id === "home") {
          newActiveSection = section.id;
          navigate("#" + section.id);
        }
      }
    });

    if(newActiveSection !== null){
      setStateActiveSection(newActiveSection);
    }
  };

  useEffect(() => {
    navigate("#home");
    //Scroll to the top of the page.
    window.scrollTo(0, 0);

    window.addEventListener("scroll", handleScroll);

    setStateIsLoading(false);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleOpenNavMenu = (event) => {
    setStateAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setStateAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setStateAnchorElNav(null);
  };

  const navigateAndHandleCloseNavMenu = ( url ) => {
    navigate( url );
    setStateAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setStateAnchorElUser(null);
  };
  
  if ( stateIsLoading ) {
    return <Loading />
  } else {
    return (
      <>
        <div className="pageIndexContainer">
          <div className="wrapperOuter">
            <div className="wrapperInner" style={{ width: "1200px", display: "flex", justifyContent: "space-between", backgroundColor: "#FFFFFF" }}>
              <div className="containerDiv" style={{ backgroundColor: "#FFFFFF", width: "100%", display: "flex", justifyContent: "space-between", paddingLeft: "20px", paddingRight: "20px"}}>
                <div>
                  <ElevationScroll>
                    <AppBar style={{background: "white", height: "75px" }}>
                      <Container
                         style={{background: "white", height: "100px", width: "1225px", paddingLeft: "25px", paddingRight: "0px"}}
                          maxWidth="xl"
                       >
                        <Toolbar disableGutters>
                          <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            href="#app-bar-with-responsive-menu"
                            sx={{
                              mr: 2,
                              display: { xs: "none", md: "flex" },
                              fontFamily: "monospace",
                              fontWeight: 700,
                              letterSpacing: ".3rem",
                              color: "white",
                              textDecoration: "none",
                            }}
                          >
                            <Link className="sideMenuItem" to="#home" style={{ textDecoration: "none", color: "#18191F" }}>
                              <img style={{ width: "200px" }} src={likaPlanLogo} alt="Likaplan logo" />
                            </Link>
                          </Typography>

                          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                            <IconButton
                              size="large"
                              aria-label="account of current user"
                              aria-controls="menu-appbar"
                              aria-haspopup="true"
                              onClick={handleOpenNavMenu}
                              color="white"
                            >
                              <MenuIcon />
                            </IconButton>
                            <Menu
                              id="menu-appbar"
                              anchorEl={stateAnchorElNav}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                              keepMounted
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                              open={Boolean(stateAnchorElNav)}
                              onClose={handleCloseNavMenu}
                              sx={{
                                display: { xs: "block", md: "none" },
                              }}
                            >
                              <MenuItem className={stateActiveSection === "home" ? "navMenuItem active": "navMenuItem"} onClick={ () => navigateAndHandleCloseNavMenu( "#home" )}>
                                <div className="navMenuItem">
                                  <div style={{ display: "flex", alignItems: "center" }}>
                                    <p>Hem</p>
                                  </div>
                                </div>
                              </MenuItem>
                              <MenuItem className={stateActiveSection === "product" ? "navMenuItem active": "navMenuItem"} onClick={ () => navigateAndHandleCloseNavMenu( "#product" )}>
                                <div className="navMenuItem">
                                  <div style={{ display: "flex", alignItems: "center" }}>
                                    <p>Produkten</p>
                                  </div>
                                </div>
                              </MenuItem>
                              <MenuItem className={stateActiveSection === "customers" ? "navMenuItem active": "navMenuItem"} onClick={ () => navigateAndHandleCloseNavMenu( "#customers" )}>
                                <div className="navMenuItem">
                                  <div style={{ display: "flex", alignItems: "center" }}>
                                    <p>Kunder</p>
                                  </div>
                                </div>
                              </MenuItem>
                              <MenuItem className={stateActiveSection === "team" ? "navMenuItem active": "navMenuItem"} onClick={ () => navigateAndHandleCloseNavMenu( "#team" )}>
                                <div className="navMenuItem">
                                  <div style={{ display: "flex", alignItems: "center" }}>
                                    <p>Om oss</p>
                                  </div>
                                </div>
                              </MenuItem>
                              <MenuItem className={stateActiveSection === "hrconsultant" ? "navMenuItem active": "navMenuItem"} onClick={ () => navigateAndHandleCloseNavMenu( "#hrconsultant" )}>
                                <div className="navMenuItem">
                                  <div style={{ display: "flex", alignItems: "center" }}>
                                    <p>HR-Konsult</p>
                                  </div>
                                </div>
                              </MenuItem>
                            </Menu>
                          </Box>
                          <Typography
                            variant="h5"
                            noWrap
                            component="a"
                            href="#app-bar-with-responsive-menu"
                            sx={{
                              mr: 2,
                              display: { xs: "flex", md: "none" },
                              flexGrow: 1,
                              fontFamily: "monospace",
                              fontWeight: 700,
                              letterSpacing: ".3rem",
                              color: "inherit",
                              textDecoration: "none",
                            }}
                          >
                            <Link className="navMenuItem" to="#home" style={{ textDecoration: "none", color: "#18191F" }}>
                              <img style={{ width: "225px" }} src={likaPlanLogo} alt="Likaplan logo" />
                            </Link>
                          </Typography>
                          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                            <MenuItem className={stateActiveSection === "home" ? "navMenuItem active": "navMenuItem"} onClick={ () => navigateAndHandleCloseNavMenu( "#home" )}>
                              <div className="navMenuItem">
                                <div style={{ display: "flex", alignItems: "center" }}>
                                  <p>Hem</p>
                                </div>
                              </div>
                            </MenuItem>
                            <MenuItem className={stateActiveSection === "product" ? "navMenuItem active": "navMenuItem"} onClick={ () => navigateAndHandleCloseNavMenu( "#product" )}>
                              <div className="navMenuItem">
                                <div style={{ display: "flex", alignItems: "center" }}>
                                  <p>Produkten</p>
                                </div>
                              </div>
                            </MenuItem>
                            <MenuItem className={stateActiveSection === "customers" ? "navMenuItem active": "navMenuItem"} onClick={ () => navigateAndHandleCloseNavMenu( "#customers" )}>
                              <div className="navMenuItem">
                                <div style={{ display: "flex", alignItems: "center" }}>
                                  <p>Kunder</p>
                                </div>
                              </div>
                            </MenuItem>
                            <MenuItem className={stateActiveSection === "hrconsultant" ? "navMenuItem active": "navMenuItem"} onClick={ () => navigateAndHandleCloseNavMenu( "#hrconsultant" )}>
                              <div className="navMenuItem">
                                <div style={{ display: "flex", alignItems: "center" }}>
                                  <p>HR-Konsult</p>
                                </div>
                              </div>
                            </MenuItem>
                            <MenuItem className={stateActiveSection === "team" ? "navMenuItem active": "navMenuItem"} onClick={ () => navigateAndHandleCloseNavMenu( "#team" )}>
                              <div className="navMenuItem">
                                <div style={{ display: "flex", alignItems: "center" }}>
                                  <p>Om oss</p>
                                </div>
                              </div>
                            </MenuItem>
                          </Box>
                          <Box sx={{ flexGrow: 0 }}>
                          <Button
                          style={{ width: "130px" }}
                          className={ "buttonBorderGreenInnerGreen" }
                          fullWidth={false}
                          variant="contained"
                          onClick={ () => navigate( UnauthenticatedUrls.landing.subPages.login.path ) }
                          >
                          Logga in
                        </Button>
                        &nbsp;
                        &nbsp;
                        <Button
                          style={{ width: "130px" }}
                          className={ "buttonBorderGreenInnerWhite" }
                          fullWidth={false}
                          variant="contained"
                          onClick={ () => navigate( UnauthenticatedUrls.landing.subPages.signup.path ) }
                        >
                          Skapa konto
                        </Button>
                          </Box>
                        </Toolbar>
                      </Container>
                    </AppBar>
                  </ElevationScroll>
                </div>
                <div data-section id="home" style={{ width: "100%", justifyContent: "center", display: "flex"}}>
                  <div style={{marginTop: "75px", width: "1100px", justifyContent: "space-between", alignItems: "center", display: "flex", paddingTop: "50px"}}>
                    <div style={{width: "480px"}}>
                      <div>
                        <p>
                          <span style={{color: "#4D4D4D", fontSize: "36px", fontWeight: "600", lineHeight: 1.2 }}>Uppfyll lagkraven om </span>
                          <span style={{color: "#00B012", fontSize: "36px", fontWeight: "600", lineHeight: 1.2 }}>aktiva åtgärder</span>
                          <span style={{color: "#4D4D4D", fontSize: "36px", fontWeight: "600", lineHeight: 1.2 }}> mot diskriminering i arbetslivet</span>
                        </p>
                        <span style={{color: "#717171"}}>
                          Arbeta kostnads- och tidseffektivt med att motverka diskriminering i arbetslivet enligt diskrimineringslagen.
                        </span>
                      </div>
                      <div style={{ paddingTop: "20px" }}>
                        <Button
                          className={ "buttonBorderGreenInnerGreen" }
                          fullWidth={false}
                          variant="contained"
                          onClick={ () => window.location.href = "mailto:johanna@likaplan.se" }
                        >
                          Boka demo nu!
                        </Button>
                      </div>
                      <div style={{textAlign: "center", color: "black"}}></div>
                    </div>
                    <div style={{width: "565px", height: 262 }}>
                      <div style={{width: "565px", height: 241.32, position: "relative"}}>
                        <img style={{width: 301.06, height: 168.68, left: 132, top: 0, position: "absolute", borderRadius: 25}} src={globalGoals5}  alt="Global goals 5" />
                        <img style={{width: 249, height: 137, left: 0, top: 75, position: "absolute", borderRadius: 27}} src={globalGoals8}  alt="Global goals 8" />
                        <img style={{width: 249.92, height: 138.32, left: 315, top: 103, position: "absolute", borderRadius: 25}} src={globalGoals10}  alt="Global goals 10"/>
                      </div>
                    </div>
                  </div>
                </div>

                <div data-section id="product">
                  <div style={{ width: "100%", justifyContent: "center", display: "flex"}}>
                    <div style={{marginTop: "75px", width: "100%", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", display: "inline-flex", paddingTop: "40px", background: "#FFFFFF"}}>
                      <div style={{width: 747, textAlign: "center", color: "#4D4D4D", fontSize: "36px"}}>Hela likabehandlingsarbetet i ett system</div>
                      <div style={{alignSelf: "stretch", textAlign: "center", color: "#717171", fontSize: "18px", paddingTop: "10px"}}>Stärk ert arbetsgivarvarumärke och lyft arbetsmiljön genom ett systematiskt arbete som utmynnar i en komplett likabehandlingsplan</div>
                    </div>
                  </div>
                
                  <div style={{width: "100%", justifyContent: "space-between", alignItems: "top", display: "inline-flex", paddingTop: "20px", background: "#FFFFFF"}}>
                    <div style={{width: 299, paddingLeft: 32, paddingRight: 32, paddingTop: 24, paddingBottom: 24, boxShadow: "0px 2px 4px rgba(171, 190, 209, 0.20)", borderRadius: "8px", flexDirection: "column", justifyContent: "flex-start", alignItems: "top", display: "inline-flex"}}>
                      <div style={{height: 108, flexDirection: "column", justifyContent: "flex-start", alignItems: "center", display: "flex"}}>  
                        <div style={{width: "30px", height: "30px"}}><img src={formatListNumbered} alt="Formatted list numbered"/></div>
                        <div style={{alignSelf: "stretch", textAlign: "center", color: "#4D4D4D", fontSize: "28px", paddingTop: "20px"}}>Processtöd</div>
                      </div>
                      <div style={{justifyContent: "center", alignItems: "flex-start", display: "inline-flex"}}>
                          <div style={{width: 251, textAlign: "center", color: "#717171", fontSize: "14px", }}>Ett enkelt sätt att uppfylla gällande lagkrav. Steg för steg instruktioner samt kunskapsunderlag och inspiration till arbetet</div>
                      </div>
                    </div>
                    <div style={{width: 299, paddingLeft: 32, paddingRight: 32, paddingTop: 24, paddingBottom: 24, boxShadow: "0px 2px 4px rgba(171, 190, 209, 0.20)", borderRadius: "8px", flexDirection: "column", justifyContent: "flex-start", alignItems: "top", display: "inline-flex"}}>
                      <div style={{height: 108, flexDirection: "column", justifyContent: "flex-start", alignItems: "center", display: "flex"}}>
                        <div style={{width: "30px", height: "30px"}}><img src={playlistAddCheck} alt="Play list and check"/></div>
                        <div style={{alignSelf: "stretch", textAlign: "center", color: "#4D4D4D", fontSize: "28px", paddingTop: "20px"}}>Aktiva åtgärder</div>
                      </div>
                      <div style={{justifyContent: "center", alignItems: "flex-start", display: "inline-flex"}}>
                        <div style={{width: 240, textAlign: "center", color: "#717171", fontSize: "14px"}}>Ett förebyggande och främjande arbete för att motverka diskriminering och verka för lika rättigheter och möjligheter på arbetsplatsen.</div>
                      </div>
                    </div>
                    <div style={{width: 299, paddingLeft: 32, paddingRight: 32, paddingTop: 24, paddingBottom: 24, boxShadow: "0px 2px 4px rgba(171, 190, 209, 0.20)", borderRadius: "8px", flexDirection: "column", justifyContent: "flex-start", alignItems: "top", display: "inline-flex"}}>
                      <div style={{height: 108, flexDirection: "column", justifyContent: "flex-start", alignItems: "center", display: "flex"}}>
                        <div style={{width: "30px", height: "30px"}}><img src={assignment} alt="Assignment"/></div>
                        <div style={{width: 231, textAlign: "center", color: "#4D4D4D", fontSize: "28px", paddingTop: "20px"}}>Dokumentation</div>
                      </div>
                      <div style={{justifyContent: "center", alignItems: "flex-start", display: "inline-flex"}}>
                          <div style={{width: 251, textAlign: "center", color: "#717171", fontSize: "14px"}}>Ditt arbete dokumenteras och utmynnar automatiskt i en likabehandlingsplan som går att skriva ut eller spara digitalt.</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div data-section id="customers">
                  <div style={{ width: "100%", justifyContent: "center", display: "flex"}}>
                    <div style={{marginTop: "75px", width: "100%", paddingTop: 20, paddingBottom: "20px", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", display: "inline-flex", background: "#FFFFFF"}}>
                      <div style={{height: 77.57, flexDirection: "column", justifyContent: "flex-start", alignItems: "center", display: "flex"}}>
                          <div style={{alignSelf: "stretch", textAlign: "center", color: "#4D4D4D", fontSize: "36px" }}>Våra kunder</div>
                          <div style={{alignSelf: "stretch", textAlign: "center", color: "#4D4D4D", fontSize: "18px", paddingTop: "20px" }}>Här är ett axplock av nöjda kunder som använder Likaplan</div>
                      </div>
                      <div style={{width: 799, height: 68, justifyContent: "space-between", alignItems: "center", display: "inline-flex"}}>
                        <a href="https://www.eitech.se/" target="_blank" rel="noreferrer"><img style={{width: 137, height: 55}} src={eitech_elektro_logo} alt="Eitech logo" /></a>
                        <a href="https://www.mellanskog.se/" target="_blank" rel="noreferrer"><img style={{width: 190, height: 42}} src={mellanskogLogoRGB} alt="Mellanskog logo" /></a>
                        <a href="https://placerum.se/" target="_blank" rel="noreferrer"><img style={{width: 180, height: 40}} src={placerum_logotyp_RGB} alt="Placerum logo" /></a>
                      </div>
                    </div>
                  </div>

                  <div style={{width: "100%", paddingTop: 40, paddingBottom: "20px", background: "#F5F7FA", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", display: "inline-flex"}}>
                    <div style={{width: 887, textAlign: "center", color: "#263238", fontSize: "36px"}}>Boka en demonstration nu!</div>
                    <div style={{textAlign: "center", color: "#717171", fontSize: "18px", paddingTop: "30px"}}>Vi visar dig hur Likaplan förenklar ditt arbete med att följa upp och dokumentera ditt arbete med aktiva åtgärder </div>
                    <div style={{paddingTop: "20px" }}>
                      <Button
                        className={ "buttonBorderGreenInnerGreen" }
                        fullWidth={false}
                        variant="contained"
                        onClick={ () => window.location.href = "mailto:johanna@likaplan.se" }
                        >
                        Boka en demo nu <ArrowForwardIcon style={{fontSize: "large", paddingTop: "2px"}} />
                      </Button>
                      
                    </div>
                  </div>
                </div>

                <div data-section id="hrconsultant" style={{ width: "100%", justifyContent: "center", display: "flex"}}>
                  <div style={{marginTop: "75px", width: "100%", background: "white", paddingTop: "20px", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", display: "inline-flex"}}>
                    <div style={{width: 656, textAlign: "center", color: "#4D4D4D", fontSize: "36px"}}>Är du en HR-konsult? </div>
                    <div style={{alignSelf: "stretch", textAlign: "center", color: "#717171", fontSize: "18px", paddingTop: "20px"  }}>Låt oss prata om hur Likaplan kan hjälpa dig med likabehandlingsarbetet gentemot dina kunder<br/></div>
                    <div style={{paddingTop: "20px" }}>
                      <Button
                        className={ "buttonBorderGreenInnerGreen" }
                        fullWidth={false}
                        variant="contained"
                        onClick={ () => window.location.href = "mailto:johanna@likaplan.se" }
                      >
                        Kontakta oss nu <ArrowForwardIcon style={{fontSize: "large", paddingTop: "2px"}} />
                      </Button>
                    </div>
                  </div>
                </div>

                <div data-section id="team">
                  <div style={{ width: "100%", justifyContent: "center", display: "flex"}}>
                    <div style={{marginTop: "75px", width: "100%", background: "white", paddingTop: "30px", paddingBottom: "20px", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", display: "inline-flex"}}>
                      <div style={{width: "100%", textAlign: "center", color: "#4D4D4D", fontSize: "36px"}}>Om oss</div>
                      <div style={{width: "100%", textAlign: "center", color: "#717171", fontSize: "16px", paddingTop: "10px"}}>Teamet bakom produkten Likaplan</div>
                    </div>
                  </div>

                  <div style={{width: "100%", justifyContent: "space-between", alignItems: "top", display: "inline-flex", paddingTop: "20px", background: "#FFFFFF"}}>
                    <div style={{ height: 225, width: 299, paddingTop: 24, paddingBottom: 24, background: "white", boxShadow: "0px 2px 4px rgba(171, 190, 209, 0.20)", borderRadius: "8px", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", display: "inline-flex"}}>
                      <div>
                        <img style={{width: 117, height: 117, borderRadius: 9999}} src={elin} alt="Elin Bjuhr" />
                      </div>
                      <div style={{flexDirection: "column", justifyContent: "flex-start", alignItems: "center", display: "flex"}}>
                        <div style={{alignSelf: "stretch", textAlign: "center", color: "#4D4D4D", fontSize: "28px", paddingBottom: "5px" }}>
                          Elin Bjuhr
                        </div>
                        <div style={{alignSelf: "stretch", textAlign: "center", color: "#717171", fontSize: "18px"}}>HR-konsult</div>
                        <div style={{alignSelf: "stretch", textAlign: "center", color: "#717171", fontSize: "14px"}}> med över 20 års erfarenhet. Driver dinhrpartner och är Initiativtagare till skapandet av Likaplan.</div>
                      </div>
                    </div>
                    <div style={{width: 299, paddingTop: 24, paddingBottom: 24, background: "white", boxShadow: "0px 2px 4px rgba(171, 190, 209, 0.20)", borderRadius: "8px", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", display: "inline-flex"}}>
                      <div style={{height: 225, flexDirection: "column", justifyContent: "flex-start", alignItems: "center", display: "flex"}}>
                        <div>
                          <img style={{width: 117, height: 117, borderRadius: 9999}} src={johanna}  alt="Johanna Engström"/>
                        </div>
                        <div style={{alignSelf: "stretch", textAlign: "center", color: "#4D4D4D", fontSize: "28px", paddingBottom: "5px" }}>
                          Johanna Engström
                        </div>
                        <div style={{alignSelf: "stretch", textAlign: "center", color: "#717171", fontSize: "18px"}}>Jämlikhetskonsult</div>
                        <div style={{alignSelf: "stretch", textAlign: "center", color: "#717171", fontSize: "14px"}}> Mångårig erfarenhet av praktiskt och strategiskt jämlikhetsarbete. Driver inkluderignsbyrån VKNA</div>
                      </div>
                    </div>
                    <div style={{width: 299, paddingTop: 24, paddingBottom: 24, background: "white", boxShadow: "0px 2px 4px rgba(171, 190, 209, 0.20)", borderRadius: "8px", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", display: "inline-flex"}}>
                      <div style={{height: 225, flexDirection: "column", justifyContent: "flex-start", alignItems: "center", display: "flex"}}>
                        <div>
                          <img style={{width: 117, height: 117, borderRadius: 9999}} src={paul} alt="Paul Alvarado Mendoza" />
                        </div>
                        <div style={{alignSelf: "stretch", textAlign: "center", color: "#4D4D4D", fontSize: "28px", paddingBottom: "5px" }}>
                          Paul Alvarado Mendoza
                        </div>
                        <div style={{alignSelf: "stretch", textAlign: "center", color: "#717171", fontSize: "18px"}}>Tjänstedesigner</div>
                        <div style={{alignSelf: "stretch", textAlign: "center", color: "#717171", fontSize: "14px"}}> Med över 15 års erfarenhet att ta fram digitala produkter och tjänster</div>
                      </div>
                    </div>
                  </div>

                  <div style={{width: "100%", background: "white", marginTop: "40px", paddingBottom: "20px"}}>
                    <div style={{paddingLeft: "250px", width: "700px"}}>
                      <div style={{width: "100%", justifyContent: "space-between", alignItems: "top", display: "inline-flex", background: "#FFFFFF"}}>
                        <div style={{width: 299, paddingTop: 24, paddingBottom: 24, background: "white", boxShadow: "0px 2px 4px rgba(171, 190, 209, 0.20)", borderRadius: "8px", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", display: "inline-flex"}}>
                          <div style={{height: 225, flexDirection: "column", justifyContent: "flex-start", alignItems: "center", display: "flex"}}>
                            <div>
                              <img style={{width: 117, height: 117, borderRadius: 9999}} src={erik} alt="Erik Andersson" />
                            </div>
                            <div style={{flexDirection: "column", justifyContent: "flex-start", alignItems: "center", display: "flex"}}>
                              <div style={{alignSelf: "stretch", textAlign: "center", color: "#4D4D4D", fontSize: "28px", paddingBottom: "5px" }}>
                                Erik Andersson
                              </div>
                              <div style={{alignSelf: "stretch", textAlign: "center", color: "#717171", fontSize: "18px"}}>Sälj och tillväxt</div>
                              <div style={{alignSelf: "stretch", textAlign: "center", color: "#717171", fontSize: "14px"}}>Arbetat med sälj och entreprenörskap i över 20 år och har ett stort kontaktnät</div>
                            </div>
                          </div>
                        </div>
                        <div style={{width: 299, paddingTop: 24, paddingBottom: 24, background: "white", boxShadow: "0px 2px 4px rgba(171, 190, 209, 0.20)", borderRadius: "8px", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", display: "inline-flex"}}>
                          <div style={{height: 225, flexDirection: "column", justifyContent: "flex-start", alignItems: "center", display: "flex"}}>
                            <div>
                              <img style={{width: 117, height: 117, borderRadius: 9999}} src={thomas} alt="Thomas Nyström" />
                            </div>
                            <div style={{alignSelf: "stretch", textAlign: "center", color: "#4D4D4D", fontSize: "28px", paddingBottom: "5px" }}>
                              Thomas Nyström
                            </div>
                            <div style={{alignSelf: "stretch", textAlign: "center", color: "#717171", fontSize: "18px"}}>Systemutvecklare</div>
                            <div style={{alignSelf: "stretch", textAlign: "center", color: "#717171", fontSize: "14px"}}>Arbetat med digitalisering och systemutveckling i över 20 år</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div style={{width: "100%", paddingTop: "30px", paddingBottom: "20px", background: "#F5F7FA", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", display: "inline-flex"}}>
                    <div style={{width: "100%", textAlign: "center", color: "#263238", fontSize: "36px"}}>Diskrimineringslagen</div>
                    <div style={{width: "100%", textAlign: "center", color: "#717171", fontSize: 18, paddingTop: "30px"}}>“Diskrimineringslagens syfte är att motverka diskriminering och främja lika rättigheter och möjligheter oavsett kön, könsöverskridande identitet eller uttryck, etnisk tillhörighet, religion eller annan trosuppfattning, funktionsnedsättning, sexuell läggning eller ålder.”<br/><br/>Lagen gäller för direkt, indirekt diskriminering, bristande tillgänglighet, trakasserier, sexuella trakasserier och instruktioner att diskriminera</div>
                    <div style={{ paddingTop: "20px"}}>
                    <Button
                      className={ "buttonBorderGreenInnerGreen" }
                      fullWidth={false}
                      variant="contained"
                      onClick={ () => openInNewTab("https://www.do.se/arbetsgivare-ska-forebygga-diskriminering/arbetsgivaren-ansvarar-for-aktiva-atgarder") }
                    >
                      Läs mer om diskrimineringsgrunderna <ArrowForwardIcon style={{fontSize: "large", paddingTop: "2px"}} />
                    </Button>
                    </div>
                  </div>

                  <div style={{width: "100%", textAlign: "center", background: "white", paddingTop: 10, paddingBottom: 20 }}>
                    <div style={{width: "300px", justifyContent: "space-between", alignItems: "top", display: "inline-flex", paddingTop: "20px", background: "#FFFFFF"}}>
                      <div style={{width: "150px"}}><a href="mailto:johanna@likaplan.se"><img style={{width: "18px", height: "18px"}} src={icon_Mail} alt="Send email" /><br/>johanna@likaplan.se</a></div>
                      <div style={{width: "1500px"}}><a href="tel:+46702457242"><img style={{width: "18px", height: "18px"}} src={icon_Phone} alt="Phone" /><br/>070-245 72 42</a></div>
                      {/* <img style={{width: "18px", height: "18px"}} src={icon_Linkedin} /> */}
                    </div>
                    <div style={{textAlign: "center", color: "#263238", fontSize: 14, paddingTop: "30px"}}>Copyright. All rights reserved. Likaplan - Aktiva Åtgärder - 2020-{ new Date().getFullYear() }</div>
                  </div>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Index;