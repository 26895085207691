import { v4 as uuidv4 } from 'uuid';
import { ChapterSectionTypes } from "Util/Const";
import { formatDate } from "Util/Utils";
import { RiskAnalisysAndActionYearWithCheckBox, RiskAnalisysAndActionWithCheckBox, RiskAnalisysAndActionItemWithCheckBox, RiskAnalisysAndActionTaskItemWithCheckBox } from "Models/UserDataWithCheckBoxes";

export async function toRiskAnalisysAndActionTaskItemWithCheckBox( riskAnalisysAndActionTaskItem ){
  let riskAnalisysAndActionTaskItemWithCheckBox =  new RiskAnalisysAndActionTaskItemWithCheckBox( riskAnalisysAndActionTaskItem.id )

  //Copy properties.
  riskAnalisysAndActionTaskItemWithCheckBox.riskAnalisysAndActionItemId = riskAnalisysAndActionTaskItem.riskAnalisysAndActionItemId;
  riskAnalisysAndActionTaskItemWithCheckBox.task = riskAnalisysAndActionTaskItem.task;
  riskAnalisysAndActionTaskItemWithCheckBox.responsible = riskAnalisysAndActionTaskItem.responsible;
  riskAnalisysAndActionTaskItemWithCheckBox.ongoing = riskAnalisysAndActionTaskItem.ongoing;
  riskAnalisysAndActionTaskItemWithCheckBox.followUpDate = riskAnalisysAndActionTaskItem.followUpDate;
  riskAnalisysAndActionTaskItemWithCheckBox.fixed = riskAnalisysAndActionTaskItem.fixed;
  riskAnalisysAndActionTaskItemWithCheckBox.fixedDate = riskAnalisysAndActionTaskItem.fixedDate;
  riskAnalisysAndActionTaskItemWithCheckBox.comment = riskAnalisysAndActionTaskItem.comment;
  riskAnalisysAndActionTaskItemWithCheckBox.createdBy = riskAnalisysAndActionTaskItem.createdBy;
  riskAnalisysAndActionTaskItemWithCheckBox.createdAt = riskAnalisysAndActionTaskItem.createdAt;
  riskAnalisysAndActionTaskItemWithCheckBox.changedBy = riskAnalisysAndActionTaskItem.changedBy;
  riskAnalisysAndActionTaskItemWithCheckBox.changedAt = riskAnalisysAndActionTaskItem.changedAt;

  return riskAnalisysAndActionTaskItemWithCheckBox;
}

export async function toRiskAnalisysAndActionItemWithCheckBox( riskAnalisysAndActionItem ){
  let riskAnalisysAndActionItemWithCheckBox = new RiskAnalisysAndActionItemWithCheckBox( riskAnalisysAndActionItem.id );

  //Copy properties.
  riskAnalisysAndActionItemWithCheckBox.riskAnalisysAndActionId = riskAnalisysAndActionItem.riskAnalisysAndActionId;
  riskAnalisysAndActionItemWithCheckBox.risk = riskAnalisysAndActionItem.risk;
  riskAnalisysAndActionItemWithCheckBox.consequence = riskAnalisysAndActionItem.consequence;
  riskAnalisysAndActionItemWithCheckBox.analisys = riskAnalisysAndActionItem.analisys;
  riskAnalisysAndActionItemWithCheckBox.remedy = riskAnalisysAndActionItem.remedy;
  riskAnalisysAndActionItemWithCheckBox.followUpText = riskAnalisysAndActionItem.followUpText;
  riskAnalisysAndActionItemWithCheckBox.createdBy = riskAnalisysAndActionItem.createdBy;
  riskAnalisysAndActionItemWithCheckBox.createdAt = riskAnalisysAndActionItem.createdAt;
  riskAnalisysAndActionItemWithCheckBox.changedBy = riskAnalisysAndActionItem.changedBy;
  riskAnalisysAndActionItemWithCheckBox.changedAt = riskAnalisysAndActionItem.changedAt;

  //Copy all riskAnalisysAndActionTaskItems.
  for(let riskAnalisysAndActionTaskItem of riskAnalisysAndActionItem.riskAnalisysAndActionTaskItems ){
      riskAnalisysAndActionItemWithCheckBox.riskAnalisysAndActionTaskItemsWithCheckBox.push( await toRiskAnalisysAndActionTaskItemWithCheckBox(riskAnalisysAndActionTaskItem) );
  }

  return riskAnalisysAndActionItemWithCheckBox;
}

export async function toRiskAnalisysAndActionWithCheckBox( riskAnalisysAndAction ){
  let riskAnalisysAndActionWithCheckBox = new RiskAnalisysAndActionWithCheckBox(riskAnalisysAndAction.id);

  //Copy properties.
  riskAnalisysAndActionWithCheckBox.checked = true; //Default is true since there is no checkbox and we assume that a user wants to copy this.
  riskAnalisysAndActionWithCheckBox.chapterSectionTypeName = riskAnalisysAndAction.chapterSectionTypeName;
  riskAnalisysAndActionWithCheckBox.titleTextKey = riskAnalisysAndAction.titleTextKey;
  riskAnalisysAndActionWithCheckBox.informationTextKey = riskAnalisysAndAction.informationTextKey;
  riskAnalisysAndActionWithCheckBox.chapterDescriptionTextKey = riskAnalisysAndAction.chapterDescriptionTextKey;
  riskAnalisysAndActionWithCheckBox.tasksChapterInformationTextKey = riskAnalisysAndAction.tasksChapterInformationTextKey;
  riskAnalisysAndActionWithCheckBox.statusChapterInformationTextKey = riskAnalisysAndAction.statusChapterInformationTextKey;
  riskAnalisysAndActionWithCheckBox.risksDefinedAndAnalyzed = riskAnalisysAndAction.risksDefinedAndAnalyzed;
  riskAnalisysAndActionWithCheckBox.createdBy = riskAnalisysAndAction.createdBy;
  riskAnalisysAndActionWithCheckBox.createdAt = riskAnalisysAndAction.createdAt;
  riskAnalisysAndActionWithCheckBox.changedBy = riskAnalisysAndAction.changedBy;
  riskAnalisysAndActionWithCheckBox.changedAt = riskAnalisysAndAction.changedAt;

  //Copy all riskAnalisysAndActionItems.
  for( const riskAnalisysAndActionItem of riskAnalisysAndAction.riskAnalisysAndActionItems ){
      riskAnalisysAndActionWithCheckBox.riskAnalisysAndActionItemsWithCheckBox.push( await toRiskAnalisysAndActionItemWithCheckBox( riskAnalisysAndActionItem ));
  }

  return riskAnalisysAndActionWithCheckBox;
}


export async function toRiskAnalisysAndActionYearWithCheckBox( riskAnalisysAndActionYear, fromDate, toDate ){
  let riskAnalisysAndActionYearWithCheckBox = new RiskAnalisysAndActionYearWithCheckBox(riskAnalisysAndActionYear.id);

  //Copy properties.
  riskAnalisysAndActionYearWithCheckBox.checked = true; //Default is true since there is no checkbox and we assume that a user wants to copy this.
  riskAnalisysAndActionYearWithCheckBox.fromDate = formatDate(fromDate);
  riskAnalisysAndActionYearWithCheckBox.toDate = formatDate(toDate);
  riskAnalisysAndActionYearWithCheckBox.active = riskAnalisysAndActionYear.active;
  riskAnalisysAndActionYearWithCheckBox.createdBy = riskAnalisysAndActionYear.createdBy;
  riskAnalisysAndActionYearWithCheckBox.createdAt = riskAnalisysAndActionYear.createdAt;
  riskAnalisysAndActionYearWithCheckBox.changedBy = riskAnalisysAndActionYear.changedBy;
  riskAnalisysAndActionYearWithCheckBox.changedAt = riskAnalisysAndActionYear.changedAt;
  riskAnalisysAndActionYearWithCheckBox.riskAnalisysAndActionsWithCheckBox = {
      workingConditions: await toRiskAnalisysAndActionWithCheckBox(riskAnalisysAndActionYear.riskAnalisysAndActions[ChapterSectionTypes.workingConditions.Name]),
      employmentAndParenthood: await toRiskAnalisysAndActionWithCheckBox(riskAnalisysAndActionYear.riskAnalisysAndActions[ChapterSectionTypes.employmentAndParenthood.Name]),
      wagesAndEmploymentTerms: await toRiskAnalisysAndActionWithCheckBox(riskAnalisysAndActionYear.riskAnalisysAndActions[ChapterSectionTypes.wagesAndEmploymentTerms.Name]),
      recruitmentAndPromotion: await toRiskAnalisysAndActionWithCheckBox(riskAnalisysAndActionYear.riskAnalisysAndActions[ChapterSectionTypes.recruitmentAndPromotion.Name]),
      educationAndSkillsDevelopment: await toRiskAnalisysAndActionWithCheckBox(riskAnalisysAndActionYear.riskAnalisysAndActions[ChapterSectionTypes.educationAndSkillsDevelopment.Name]),
      measuresToPreventHarassment: await toRiskAnalisysAndActionWithCheckBox(riskAnalisysAndActionYear.riskAnalisysAndActions[ChapterSectionTypes.measuresToPreventHarassment.Name]),
      equalGenderDistribution: await toRiskAnalisysAndActionWithCheckBox(riskAnalisysAndActionYear.riskAnalisysAndActions[ChapterSectionTypes.equalGenderDistribution.Name]),
  };

  return riskAnalisysAndActionYearWithCheckBox;
}