import React, { useState, useEffect } from 'react';
import stateImagePlaceholder from "Assets/Images/image_placeholder.jpg";
import { getPathFromStorage } from "Util/StorageManager";

const Image = ( props ) => {
  const [stateImage, setStateImage] = useState( stateImagePlaceholder );

  useEffect(() => {
    async function asyncUseEffect(){
      if (props.image != null) {
        const url = await getPathFromStorage(props.image);
        if (url != null) {
          setStateImage( url );
        }
      }
    }

    asyncUseEffect();
  }, []);

  return (
    <img src={stateImage} style={{ maxWidth: "100%", maxHeight: "100%", borderRadius:"5%", ...props.style }} alt="NoImage" />
  );
}

export default Image;
