import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormControl, TextField, Table, TableHead, TableBody, TableRow, TableCell, Button } from "@mui/material";
import { TextFieldWithPlaceholder, AddButton, SaveButton, CancelButton, EditButton, DeleteButton, PopupConfirmYesNoWithValue, TwKey, getText} from "Components/StandardTextComponentsWithTranslation";
import { DragIndicator } from "@mui/icons-material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { setAppStateSetting } from "Features/AppState/AppStateSlice";
import CustomOutput from "Components/CustomOutput";
import { Link } from "react-router-dom";
import { listMaterialss } from "graphql/queries";
import { GetAllItems, GetItemById, DeleteItemById,  SaveOrUpdate } from "Util/Api";
import { getMultipleFileUrl } from "Util/StorageManager";
import { PopupConfirm, reorderModel } from "Util/Utils";
import { AuthenticatedUrls } from 'Routes/Urls';
import { getStep } from "graphql/queries";
import Loading from "Components/Loading";
import Updating from "Components/Updating";
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { PreparationItem } from "Classes/UserData";
import { listSurveyTemplates } from "graphql/queries";
import { createSurveyTemplate, updateSurveyTemplate, deleteSurveyTemplate } from "graphql/mutations";
import { getPreparationsFromCurrentPreparationItemTemplateYear, addPreparationItem, setPreparationItem, deletePreparationItem, updatePreparationItem } from "Features/UserData/UserDataSlice";

const PreparationItemTemplates = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [stateIsLoading, setStateIsLoading] = useState( true );
  const [stateIsUpdating, setStateIsUpdating] = useState( false );
  const [stateNewItemName, setStateNewItemName] = useState( "" );
  const [stateEditPreparationItemTemplate, setStateEditPreparationItemTemplate] = useState( { id: uuidv4(), name: "", checked: false, order: -1} );
  const [statePreparationItemTemplates, setStatePreparationItemTemplates] = useState( [] );

  useEffect(() => {
    async function asyncUseEffect(){
      await getData();

      dispatch ( setAppStateSetting( {  title: getText(10307) } ) );

      //Scroll to the top of the page.
      window.scrollTo(0, 0);
    }

    asyncUseEffect();
  }, []);

  async function getData() {
    const allPreparationItemTemplates = await GetAllItems(listSurveyTemplates);

    //Get the ones that are in "general" since we currently only have general preparation items for users.

    let generalPreparationItemTemplates = allPreparationItemTemplates.filter( (preparationItemTemplate) => preparationItemTemplate.sectionType === "general");

    generalPreparationItemTemplates = generalPreparationItemTemplates.sort( (a, b) => a.order - b.order );

    setStatePreparationItemTemplates( generalPreparationItemTemplates );

    setStateIsLoading( false );
  }

  async function addItem() {
    try {
      setStateIsUpdating( true );

      const orderItems = statePreparationItemTemplates.map(function ( preparationItem ) { return preparationItem.order; } );

      const order =
        Math.max.apply(
          null,
          orderItems
        ) + 1;

        //Create the new item and set the sectionType to "general" since we currently only have general preparation items for users.
        const newPreparationItemTemplate = { name: stateNewItemName, sectionType: "general", order: order };

        //Save the new item to DB.
        SaveOrUpdate(newPreparationItemTemplate, null, createSurveyTemplate, null);
    } catch (err) {
      console.error(err);
    }

    setStateNewItemName( "" );
    getData();

    setStateIsUpdating( false );

    navigate(AuthenticatedUrls.admin.subPages.preparationItemTemplates.path);
  }

  async function onDragEnd(result) {
    try {
      // dropped outside the list
      if (!result.destination) {
        return;
      }
      const oldSurveyTemplate = [...statePreparationItemTemplates];

      let preparationItemTemplatesToUpdate = [];
      const preparationItemTemplates = reorderModel(statePreparationItemTemplates, result.source.index, result.destination.index);
      
      preparationItemTemplates.forEach((preparationItemTemplate, idx) => {
        if (preparationItemTemplate.id !== oldSurveyTemplate[idx].id) {
          preparationItemTemplatesToUpdate.push({ id: preparationItemTemplate.id, name: preparationItemTemplate.name, order: idx });
        }
      });

      setStatePreparationItemTemplates( preparationItemTemplates );
      let promises = [];

      //Add all operations to a list.
      for (const preparationItemTemplateToUpdate of preparationItemTemplatesToUpdate) {
        promises.push(SaveOrUpdate(preparationItemTemplateToUpdate, updateSurveyTemplate, null, null));
      }

      //Execute all operations and wait for all to finish.
      await Promise.all(promises);

      getData();

      navigate(AuthenticatedUrls.admin.subPages.preparationItemTemplates.path);
    } catch (err) {
      console.error(err);
    }
  }

  async function editPreparationItemTemplateButtonOnClick( preparationItemTemplateId ){
    //Get the correct PreparationItemTemplate.
    const preparationItemTemplateToEdit = Object.values(statePreparationItemTemplates).find( preparationItemTemplate => preparationItemTemplate.id === preparationItemTemplateId );

    setStateEditPreparationItemTemplate( preparationItemTemplateToEdit );
  }

  async function saveEditedPreparationItemTemplateButtonOnClick( preparationItemTemplateId ){
    //Get the correct PreparationItemTemplate.
    const preparationItemTemplate = Object.values(statePreparationItemTemplates).find( preparationItemTemplate => preparationItemTemplate.id === preparationItemTemplateId );

    //Set the edited properties.
    const preparationItemTemplateToUpdate = { id: preparationItemTemplate.id, name: stateEditPreparationItemTemplate.name, order: preparationItemTemplate.order }

    await SaveOrUpdate(preparationItemTemplateToUpdate, updateSurveyTemplate, null, null);

    setStateEditPreparationItemTemplate( { id: uuidv4(), name: "", checked: false, order: -1} );

    getData();

    navigate(AuthenticatedUrls.admin.subPages.preparationItemTemplates.path);
  }

  async function cancelEditPreparationItemTemplateButtonOnClick(){
    setStateEditPreparationItemTemplate( { id: uuidv4(), name: "", checked: false, order: -1} );
  }

  async function deletePreparationItemTemplateButtonOnClick( preparationItemTemplateId ){
    await DeleteItemById( preparationItemTemplateId, deleteSurveyTemplate);

    getData();

    navigate(AuthenticatedUrls.admin.subPages.preparationItemTemplates.path);
  }

  if ( stateIsLoading) {
    return <Loading />;
  } else {
    return (
      <>
        {
          stateIsUpdating ? 
            <Updating />
          :
            <>
              <h2><TwKey textKey="10308"/></h2>
              <p><TwKey textKey="10309"/></p>
              <div>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided, snapshot) => (
                        <Table aria-label="simple table" {...provided.droppableProps} ref={provided.innerRef}>
                          <TableHead></TableHead>
                          <TableBody>
                            { statePreparationItemTemplates
                              .map((preparationItemTemplate, index) => (
                                <Draggable key={preparationItemTemplate.id} draggableId={preparationItemTemplate.id} index={index}>
                                  {(provided, snapshot) => (
                                    <TableRow
                                      key={preparationItemTemplate.id}
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={provided.draggableProps.style}
                                    >
                                      <TableCell>
                                        <DragIndicator />
                                      </TableCell>
                                      <TableCell key={preparationItemTemplate.id}>
                                        {
                                          stateEditPreparationItemTemplate?.id === preparationItemTemplate.id ?
                                            <TextFieldWithPlaceholder
                                              placeholderTextKey="10295"
                                              value={ stateEditPreparationItemTemplate.name }
                                              onChange={(event) => setStateEditPreparationItemTemplate( { ...stateEditPreparationItemTemplate, name: event.target.value })}
                                            />
                                          :
                                            preparationItemTemplate.name
                                        }
                                      </TableCell>
                                      {!snapshot.isDragging && (
                                        <TableCell style={{ textAlign: "right" }}>
                                          {
                                            stateEditPreparationItemTemplate?.id === preparationItemTemplate.id ?
                                              <>
                                                <FormControl>
                                                  <SaveButton
                                                    buttonTextKey="10296"
                                                    toolTipTitleTextKey="10297"
                                                    disabled={ stateEditPreparationItemTemplate.task !== "" ? false : true }
                                                    onClick={ async () => await saveEditedPreparationItemTemplateButtonOnClick( preparationItemTemplate.id ) }
                                                  />
                                                </FormControl>
                                                &nbsp;
                                                <FormControl>
                                                  <CancelButton
                                                    buttonTextKey="10298"
                                                    toolTipTitleTextKey="10299"
                                                    onClick={ async () => await cancelEditPreparationItemTemplateButtonOnClick() }
                                                  />
                                                </FormControl>
                                              </>
                                            :
                                              <>
                                                <FormControl>
                                                  <EditButton
                                                    buttonTextKey = "10300"
                                                    toolTipTitleTextKey = "10301"
                                                    onClick={ async () => await editPreparationItemTemplateButtonOnClick( preparationItemTemplate.id ) }
                                                  />
                                                </FormControl>
                                                &nbsp;
                                                <FormControl>
                                                  <DeleteButton
                                                    buttonTextKey = "10302"
                                                    toolTipTitleTextKey = "10303"
                                                    onClick={ () => PopupConfirmYesNoWithValue( "10304", preparationItemTemplate.name, async () => await deletePreparationItemTemplateButtonOnClick( preparationItemTemplate.id )) }
                                                  />
                                                </FormControl>
                                              </>
                                          }
                                        </TableCell>
                                      )}
                                    </TableRow>
                                  )}
                                </Draggable>
                              ))}
                              <TableRow>
                                <TableCell>

                                </TableCell>
                                <TableCell>
                                  <TextField
                                    variant="outlined"
                                    value={stateNewItemName}
                                    fullWidth
                                    onChange={(event) => setStateNewItemName( event.target.value )} />
                                </TableCell>
                                <TableCell style={{ textAlign: "right" }}>
                                  <AddButton
                                    buttonTextKey = "10305"
                                    toolTipTitleTextKey = "10306"
                                    onClick={async () => await addItem() }
                                  />
                                </TableCell>
                              </TableRow>
                          </TableBody>
                        </Table>
                      )}
                    </Droppable>
                  </DragDropContext>
              </div>
            </>
        }
      </>
    );
  }
}

export default PreparationItemTemplates;