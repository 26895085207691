import React, { useState, useEffect } from 'react';
import { useNavigate, Outlet, useLocation } from 'react-router-dom';
import { UrlsAreEqual } from 'Util/Utils';
import { UnauthenticatedUrls } from 'Routes/Urls';
import Loading from "Components/Loading";
import { Grid, Paper } from "@mui/material";
import likaPlanLogo from "Assets/Images/LikaPlanLogo.png";

const Landing = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [stateIsLoading, setStateIsLoading] = useState(true);
  
  useEffect(() => {
    if( UrlsAreEqual( location.pathname, UnauthenticatedUrls.landing.path ) ){
      navigate( UnauthenticatedUrls.landing.subPages.login.path, { replace: true } );
    }

    //Scroll to the top of the page.
    window.scrollTo(0, 0);

    setStateIsLoading(false);
  }, []);

  if ( stateIsLoading ) {
    return <Loading />;
  } else {
    return (
      <div className="pageContainerLanding">
        <div className="wrapperOuter">
          <div className="wrapperInner" style={{ width: "600px", display: "flex", justifyContent: "space-between", backgroundColor: "#F4F6F9" }}>
            <div className="containerDiv" style={{width: "100%", display: "flex", justifyContent: "space-between", backgroundColor: "#F4F6F9"}}>
              <div className="row topDiv" style={{height: "50%", backgroundColor: "#F4F6F9"}}>
              </div>
              <div style={{width: "600px", height: "930px", display: "flex", justifyContent: "space-between" }}>
                <Grid container style={{ display: "flex", justifyContent: "center" }}>
                  <Grid item xs={10} sm={10}>
                    <Paper className="unauthenticatedPaper">
                      <div style={{ display: "flex", justifyContent: "center", paddingTop: "40px" }}>
                        <img src={likaPlanLogo} style={{ width: "50%", height: "auto" }} alt="Likaplan logo i grönt." />
                      </div>
                      <div>
                      <Outlet />
                      </div>
                    </Paper>
                  </Grid>
                </Grid>
              </div>
              <div>
                <div className="row bottomDiv" style={{ width: "600px", display: "flex", justifyContent: "space-between", height: "58px" }}>
                  <Grid container style={{ display: "flex", flex: 1, justifyContent: "center" }}>
                    <Grid item xs={11} sm={11}>
                        <p className="footerText">
                          &copy; { new Date().getFullYear() } Likaplan. All rights reserverd  | {/* <a href="#"> */}
                            Privacy Policy
                            {/* </a> */}
                          <br/>
                          Version {process.env.REACT_APP_VERSION}
                        </p>
                    </Grid>
                  </Grid>
                </div>
              </div>
              <div className="row bottomDiv" style={{ width: "600px", display: "flex", justifyContent: "space-between", height: "50%"}}>
              <Grid container style={{ display: "flex", flex: 1, justifyContent: "center", height: "100%"}}>
                  <Grid item xs={12} sm={6} className="backGroundColor5">
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Landing;