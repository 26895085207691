import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Types from "Models/Types";
import Image from "Components/Image";
import NewlineText from "Components/NewlineText";
import Button from "@mui/material/Button";
import { GetItemById } from "Util/Api";

const CustomOutput = ( props ) => {
  const navigate = useNavigate();

  const [stateChoice, setStateChoice] = useState( "" );
  
  useEffect(() => {
    async function asyncUseEffect(){
      if (props.property.type === Types.choiceFromTable && props.property?.choicesGetQuerry != null) {
        const item = await GetItemById(props.property.value, props.property.choicesGetQuerry);
        setStateChoice( item?.[props.property.choiceKey] || "inget valt" );
      }
    }

    asyncUseEffect();
  }, []);

  switch (props.property.type) {
    case Types.label:
      return (
        <div>
          <p>
            <b>{props.property.label}</b>
          </p>
        </div>
      );
    case Types.text:
    case Types.integer:
      return (
        <div>
          <p>{props.property.value} </p>
        </div>
      );
    case Types.choiceFromTable:
      return (
        <div>
          <p>{stateChoice} </p>
        </div>
      );
    case Types.date:
      return (
        <div>
          <p>
            {(new Date(props.property.value)).toLocaleString("sv-SE", {timeZone: "Europe/Stockholm"})}{" "}
            {/* {props.property.label}: {props.property.value}{" "} */}
          </p>
        </div>
      );
    case Types.order:
      return (
        <div>
          <p>#{props.property.value} </p>
        </div>
      );
    case Types.multilineText:
      return (
        <div>
          <NewlineText text={props.property.value} />
        </div>
      );
    case Types.image:
      if (props.property?.value === "") {
        return null;
      } else {
        return (
          <div style={{ width: "300px", height: "200px", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Image image={props.property.value} />
          </div>
        );
      }
    case Types.linkToChild:
      return (
        <div>
          <Button
            variant="contained"
            // color="default"
            fullWidth
            onClick={ () => navigate( props.property.value.path, { state: { parentId: props.id } } ) }
          >
            {props.property.value.name}
          </Button>
        </div>
      );
    case Types.link:
      return (
        <div>
          <Button
            variant="contained"
            /*color="default"*/
            fullWidth
            onClick={ () => navigate( props.property.value.path + props.id )}
          >
            {props.property.value.name}
          </Button>
        </div>
      );
    case Types.multichoice:
      return (
        <div>
          <p>
            {props.property.choices.find((x) => x[props.property.choiceKey] === props.property.value)?.[props.property.choiceLabel] ||
              "--"}{" "}
          </p>
        </div>
      );

    default:
      return <></>;
  }
}

export default CustomOutput;
