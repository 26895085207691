import i18next from "i18n";
import { getFileUrl, uploadLocaleFile } from "Util/StorageManager";

export async function GetLocaleFileAsJson( language="se", namespace="translation" ){
    //Load translation files.
    let fileContent = null;

    try {
        //Load translation file.
        const pathToTranslationFile = await getFileUrl("locales/" + language + "/" + namespace + ".json");

        if ( pathToTranslationFile !== null ) {
            const responese = await fetch(pathToTranslationFile);

            fileContent = await responese.json();
        } else {
            fileContent = null;
        }
    } catch (err) {
        //Do nothing for now.
        fileContent = null;
    }

    return fileContent;
}

export async function LoadLocaleFileFromS3( language="se", namespace="translation" ){
    const localeFileAsJson = await GetLocaleFileAsJson( language, namespace );
    
    if ( localeFileAsJson !== null ){
        //Load Swedish.
        i18next.addResourceBundle( language, namespace, localeFileAsJson, true, true);
    }
}

export async function SaveLocaleFileAsJsonTextFileToS3(json, language = "se", namespace = "translation" ){
    try {
        const fileName = namespace + ".json";

        var file = new File([json], fileName);

        uploadLocaleFile(language, file);
    } catch (err) {
        console.error("Error saving file to S3: ", err);
    }

    return;
}