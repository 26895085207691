import { Auth } from "aws-amplify";
import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from "react-router-dom";
import Loading from "Components/Loading";
import { UnauthenticatedUrls } from "Routes/Urls";
import { EmailValid } from "Util/Utils";
import { Button, Grid, TextField, InputAdornment, IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const Signup = () => {
  const navigate = useNavigate();

  const [stateIsLoading, setStateIsLoading] = useState( true );
  const [stateEmail, setStateEmail] = useState( "" );
  const [statePassword, setStatePassword] = useState( "" );
  const [statePassword2, setStatePassword2] = useState( "" );
  const [stateGivenName, setStateGivenName] = useState( "" );
  const [stateFamilyName, setStateFamilyName] = useState( "" );
  const [stateTitle, setStateTitle] = useState( "" );
  const [stateShowPassword, setStateShowPassword] = useState( "" );

  useEffect(() => {
    //Scroll to the top of the page.
    window.scrollTo(0, 0);

    setStateIsLoading( false );
  }, []);

  async function validateSignup() {
    return statePassword.length > 7 && stateEmail !== "" && EmailValid( stateEmail) && statePassword2 === statePassword;
  }

  async function signup() {
    try {
      setStateIsLoading( true );

      let res = await Auth.signUp({
        username: stateEmail,
        password: statePassword,
        attributes: {
          family_name: stateFamilyName,
          given_name: stateGivenName,
          "custom:title": stateTitle,
        },
      });
      if (res != null) {
        navigate({ pathname: UnauthenticatedUrls.landing.subPages.verify.path, state: stateEmail });
      } else {
        setStateIsLoading( false );
      }
    } catch (err) {
      console.error(err);
      setStateIsLoading( false );
    }
  }

  async function handleClickShowPassword() {
    setStateShowPassword( !stateShowPassword );
  }
  
  async function handleMouseDownPassword(event){
    event.preventDefault();
  }

  if ( stateIsLoading ) {
    return <Loading />;
  } else {
    return (
      <>
        <h1 style={{ textAlign: "center"}}>Registrera konto</h1>
        <hr className="seperatorGrey" />
        <div className="utvecklaTextInput">
        <TextField
          label="E-post"
          placeholder="Ange epostadress"
          value={stateEmail}
          error={!EmailValid(stateEmail)}
          onChange={(event) => setStateEmail( event.target.value )}
          margin="normal"
          fullWidth
          variant="outlined"
          InputLabelProps={{
            shrink: true,
            classes: {
              root: "textFieldLabel",
              focused: "textFieldFocused",
            },
          }}
          InputProps={{ 
            classes: {
              root: "textFieldOutlinedInput",
              focused: "textFieldFocused",
              notchedOutline: "notchedOutline",
            }
          }}
        />
        </div>
        <Grid container style={{ display: "flex", flex: 1, justifyContent: "center" }}>
          <Grid item xs={6} sm={6}>
            <div className="utvecklaTextInput">
              <TextField
                label="Lösenord"
                type={stateShowPassword ? "text" : "password"}
                autoComplete="new-password"
                placeholder="Starkt lösenord"
                error={statePassword.length <= 7}
                value={statePassword}
                onChange={(event) => setStatePassword( event.target.value )}
                margin="normal"
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                  classes: {
                    root: "textFieldLabel",
                    focused: "textFieldFocused",
                  },
                }}
                InputProps={{ 
                  classes: {
                    root: "textFieldOutlinedInput",
                    focused: "textFieldFocused",
                    notchedOutline: "notchedOutline",
                  },
                  endAdornment:(
                    <InputAdornment position="end">
                      <IconButton
                        style={{padding: "0px"}}
                        onClick={ async ()  => await handleClickShowPassword() }
                        onMouseDown={ async ( event ) => await handleMouseDownPassword( event )}
                        edge="end"
                        size="large">
                        { stateShowPassword ? <VisibilityOff /> : <Visibility /> }
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </div>
          </Grid>
          {/* <Grid item xs={1} sm={1}></Grid> */}
          <Grid item xs={6} sm={6}>
          <TextField
            label="Upprepa lösenord"
            autoComplete="new-password"
            type={stateShowPassword ? "text" : "password"}
            placeholder="Starkt lösenord"
            value={statePassword2}
            error={statePassword2.length <= 7 || statePassword !== statePassword2 || statePassword2 === ""}
            onChange={(event) => setStatePassword2( event.target.value )}
            margin="normal"
            fullWidth
            variant="outlined"
            InputLabelProps={{
              shrink: true,
              classes: {
                root: "textFieldLabel",
                focused: "textFieldFocused",
              },
            }}
            InputProps={{ 
              classes: {
                root: "textFieldOutlinedInput",
                focused: "textFieldFocused",
                notchedOutline: "notchedOutline",
              },
              endAdornment:(
                <InputAdornment position="end">
                  <IconButton
                    style={{padding: "0px !important"}}
                    onClick={ async () => await handleClickShowPassword() }
                    onMouseDown={ async ( event ) => await handleMouseDownPassword( event )}
                    edge="end"
                    size="large">
                    { stateShowPassword ? <VisibilityOff /> : <Visibility /> }
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          </Grid>
        </Grid>
        <div className="utvecklaTextInput">
          <TextField
            label="Titel"
            placeholder="T.ex. VD, HR Chef, mm."
            error={ stateTitle.length === 0}
            value={ stateTitle}
            onChange={(event) => setStateTitle( event.target.value )}
            margin="normal"
            fullWidth
            variant="outlined"
            InputLabelProps={{
              shrink: true,
              classes: {
                root: "textFieldLabel",
                focused: "textFieldFocused",
              },
            }}
            InputProps={{ 
              classes: {
                root: "textFieldOutlinedInput",
                focused: "textFieldFocused",
                notchedOutline: "notchedOutline",
              }
            }}
          />
        </div>
        <Grid container style={{ display: "flex", flex: 1, justifyContent: "center" }}>
          <Grid item xs={6} sm={6}>
          <div className="utvecklaTextInput">
            <TextField
              label="Förnamn"
              placeholder="John"
              error={ stateGivenName.length === 0}
              value={ stateGivenName}
              onChange={ (event) => setStateGivenName( event.target.value )}
              margin="normal"
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: true,
                classes: {
                  root: "textFieldLabel",
                  focused: "textFieldFocused",
                },
              }}
              InputProps={{ 
                classes: {
                  root: "textFieldOutlinedInput",
                  focused: "textFieldFocused",
                  notchedOutline: "notchedOutline",
                },
              }}
            />
          </div>
        </Grid>
        {/* <Grid item xs={1} sm={1}></Grid> */}
        <Grid item xs={6} sm={6}>
          <div className="utvecklaTextInput">
            <TextField
              label="Efternamn"
              placeholder="Doe"
              error={ stateFamilyName.length === 0}
              value={ stateFamilyName}
              onChange={(event) => setStateFamilyName( event.target.value )}
              margin="normal"
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: true,
                classes: {
                  root: "textFieldLabel",
                  focused: "textFieldFocused",
                },
              }}
              InputProps={{ 
                classes: {
                  root: "textFieldOutlinedInput",
                  focused: "textFieldFocused",
                  notchedOutline: "notchedOutline",
                },
              }}
            />
          </div>
          </Grid>
        </Grid>
        <div style={{ marginTop: "20px" }}>
          <Button
            className="buttonBorderGreenInnerGreen"
            variant="contained"
            disabled={ !validateSignup()}
            onClick={ async () => await signup() }
            fullWidth={true}
          >
            Skapa konto
          </Button>
        </div>
        <hr className="seperatorButtonGrey" />
        <div style={{ marginTop: "20px" }}>
          <Link to={UnauthenticatedUrls.landing.subPages.login.path} style={{ textDecoration: "none" }}>
            <Button
              className="buttonBorderGreenInnerWhite"
              variant="contained"
              fullWidth={true}
            >
              Tillbaka till inloggning
            </Button>
          </Link>
        </div>
      </>
    );
  }
}

export default Signup;
