import React from "react";

//Breaks text into new paragraph after each newline
const NewlineText = ( props ) => {
  return (
    <>
      { props?.text.split("\n").map((text, idx) => (
        <p key={("newlineText-", idx)}>{text}</p>
      ))}
    </>
  );
}

export default NewlineText;
