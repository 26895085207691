import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Grid, FormControl, TextField, Table, TableHead, TableBody, TableRow, TableCell } from "@mui/material";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import { Autosave } from 'react-autosave';
import useForceUpdate from 'use-force-update';
import { GetAllItems } from "Util/Api";
import { listSurveyTemplates } from "graphql/queries";
import { TextFieldWithPlaceholder, AddButton, SaveButton, CancelButton, EditButton, DeleteButton, PopupConfirmYesNoWithValue, TwKey } from "Components/StandardTextComponentsWithTranslation";
import Loading from "Components/Loading";
import { v4 as uuidv4 } from 'uuid';
import { PreparationItem } from "Classes/UserData";
import { useGetRiskAnalisysAndActionYearsForLoggedInCustomerQuery, useLazyGetRiskAnalisysAndActionYearsForLoggedInCustomerQuery, useUpdateRiskAnalisysAndActionYearsForLoggedInCustomerMutation } from 'API/UserDataStorageAPI';
import {
  getActiveRiskAnalisysAndActionYear,
  getPreparationsFromActiveRiskAnalisysAndActionYear,
} from 'API/SelectFromUserDataStorageItems';
import {
  updateActiveRiskAnalisysAndActionYearAuthors,
  addPreparationItemToActiveRiskAnalisysAndActionYear,
  addPreparationItemsFromGeneralPreparationItemTemplatesToActiveRiskAnalisysAndActionYear,
  updatePreparationItemInActiveRiskAnalisysAndActionYear,
  deletePreparationItemInActiveRiskAnalisysAndActionYear
} from 'API/ModifyUserDataStorageItems';

const Preparations = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const forceUpdate = useForceUpdate();

  const { data: riskAnalisysAndActionYears, error, isLoading: riskAnalisysAndActionYearsIsLoading, refetch } = useGetRiskAnalisysAndActionYearsForLoggedInCustomerQuery(null, { pollingInterval: 3000 } );

  const [ lazyGetRiskAnalisysAndActionYearsForLoggedInCustomer ] = useLazyGetRiskAnalisysAndActionYearsForLoggedInCustomerQuery();

  const [
    updateRiskAnalisysAndActionYearsForLoggedInCustomer, // This is the mutation trigger
    { isLoading: isUpdatingUserDataStorageForLoggedInCustomer }, // This is the destructured mutation result
  ] = useUpdateRiskAnalisysAndActionYearsForLoggedInCustomerMutation()

  const [stateIsLoading, setStateIsLoading] = useState( true );
  const [stateIsUpdating, setStateIsUpdating] = useState( false );
  const [statePreparationItems, setStatePreparationItems] = useState( false );
  const [stateAuthorsText, setStateAuthorsText] = useState("");
  const [stateNewItemName, setStateNewItemName] = useState( "" );
  const [stateEditPreparationItem, setStateEditPreparationItem] = useState( new PreparationItem( uuidv4(), "", false, -1 ) );

  useEffect(() => {
    setStateIsLoading( true );

    async function asyncUseEffect(){
      //Get current riskAnalisysAndActionYears
      const riskAnalisysAndActionYearsLazy = await lazyGetRiskAnalisysAndActionYearsForLoggedInCustomer().unwrap();

      //Get current active RiskAalisysAndAction year.
      const activeRiskAnalisysAndActionYear = getActiveRiskAnalisysAndActionYear(riskAnalisysAndActionYearsLazy);

      if ( activeRiskAnalisysAndActionYear ){
        setStateAuthorsText( activeRiskAnalisysAndActionYear.authors );
      }

      const preparationItems = getPreparationsFromActiveRiskAnalisysAndActionYear( riskAnalisysAndActionYearsLazy );

      setStatePreparationItems(preparationItems);

      await getPreparationItemTemplates();
    }

    asyncUseEffect();

    //Scroll to the top of the page.
    window.scrollTo(0, 0);

    setStateIsLoading( false );
  }, []);

  //Only run if riskAnalisysAndActionYears is changed.
  useEffect(() => {
    setStateIsLoading( true );

    async function asyncUseEffect(){
      if( riskAnalisysAndActionYears ){
        //Get current active RiskAalisysAndAction year.
        const activeRiskAnalisysAndActionYear = getActiveRiskAnalisysAndActionYear( riskAnalisysAndActionYears );

        if ( activeRiskAnalisysAndActionYear ){
          setStateAuthorsText( activeRiskAnalisysAndActionYear.authors );
        }

        const preparationItems = getPreparationsFromActiveRiskAnalisysAndActionYear( riskAnalisysAndActionYears );

        setStatePreparationItems(preparationItems);

        await getPreparationItemTemplates();
      }
    }

    asyncUseEffect(); 

    setStateIsLoading( false );
  }, [riskAnalisysAndActionYears]);

  async function getPreparationItemTemplates() {
    //Get current active RiskAalisysAndAction year.
    const activeRiskAnalisysAndActionYear = getActiveRiskAnalisysAndActionYear( riskAnalisysAndActionYears );

    if( activeRiskAnalisysAndActionYear.preparations.length < 1 ){
      const allPreparationItemTemplates = await GetAllItems(listSurveyTemplates); //Fetch preparation template items from database.

      let generalPreparationItemTemplates = allPreparationItemTemplates.filter( (preparationItemTemplate) => preparationItemTemplate.sectionType === "general");

      if( generalPreparationItemTemplates ){
        generalPreparationItemTemplates = generalPreparationItemTemplates.sort( (a, b) => a.order - b.order );
      
        const riskAnalisysAndActionYearsToUpdate = await addPreparationItemsFromGeneralPreparationItemTemplatesToActiveRiskAnalisysAndActionYear( generalPreparationItemTemplates );

        //Update DB.
        const result = await updateRiskAnalisysAndActionYearsForLoggedInCustomer( riskAnalisysAndActionYearsToUpdate );

        //Force the component to rerender.
        forceUpdate();
      }
    }
  }

  async function updateAuthorsText(){
    updateAuthorsText( stateAuthorsText );
  }

  async function updateAuthorsText( authorsText ){
    const riskAnalisysAndActionYearsToUpdate = await updateActiveRiskAnalisysAndActionYearAuthors( authorsText );
    
    //Save to database.
    const updateResult = await updateRiskAnalisysAndActionYearsForLoggedInCustomer( riskAnalisysAndActionYearsToUpdate );

    setStateAuthorsText( authorsText );

    //Force the component to rerender.
    forceUpdate();
  }

  async function addPreparationItem() {
    setStateIsUpdating( true );

    if( riskAnalisysAndActionYears ){
      let preparationItems = getPreparationsFromActiveRiskAnalisysAndActionYear( riskAnalisysAndActionYears );

      try {
        const orderItems = preparationItems.map(function ( preparationItem ) { return preparationItem.order; } );
  
        const order =
          Math.max.apply(
            null,
            orderItems
            ) + 1;
  
          const newPreparationItem = new PreparationItem(uuidv4(), stateNewItemName, false, order );
  
          const riskAnalisysAndActionYearsToUpdate = await addPreparationItemToActiveRiskAnalisysAndActionYear( newPreparationItem );

          let result = await updateRiskAnalisysAndActionYearsForLoggedInCustomer( riskAnalisysAndActionYearsToUpdate );
      } catch (err) {
        console.error(err);
      }
    } else {
      throw new Error("No RiskAnalisysAndActionYears defined!");
    }

    setStateNewItemName( "" );
    setStateIsUpdating( false );
  }

  async function editPreparationItemButtonOnClick( preparationItemId ){
    if( riskAnalisysAndActionYears ){
      let preparationItems = getPreparationsFromActiveRiskAnalisysAndActionYear( riskAnalisysAndActionYears );

      //Get the correct PreparationItem.
      const PreparationItemToEdit = Object.values(preparationItems).find( preparationItem => preparationItem.id === preparationItemId );

      setStateEditPreparationItem( PreparationItemToEdit );
    } else {
      throw new Error("No RiskAnalisysAndActionYears defined!");
    }
  }

  async function saveEditedPreparationItemButtonOnClick( preparationItemId ){
    if( riskAnalisysAndActionYears ){
      let preparationItems = getPreparationsFromActiveRiskAnalisysAndActionYear( riskAnalisysAndActionYears );

      //Get the correct PreparationItem.
      let preparationItemToUpdate = preparationItems.find( preparationItem => preparationItem.id === preparationItemId );

      //Set the edited properties.
      preparationItemToUpdate.name = stateEditPreparationItem.name;

      const riskAnalisysAndActionYearsToUpdate = await updatePreparationItemInActiveRiskAnalisysAndActionYear( preparationItemToUpdate );

      let result = await updateRiskAnalisysAndActionYearsForLoggedInCustomer( riskAnalisysAndActionYearsToUpdate );

      setStateEditPreparationItem( new PreparationItem( uuidv4(), "", false, -1 ) );
    } else {
      throw new Error("No RiskAnalisysAndActionYears defined!");
    }
  }

  async function cancelEditPreparationItemButtonOnClick(){
    setStateEditPreparationItem( { id: uuidv4(), name: "", checked: false, order: -1} );
  }

  //Something is very wrong with this function!!!
  async function toggleOption( preparationItemId ) {
    if( riskAnalisysAndActionYears ){
      setStateIsUpdating( true );

      try {
        if (preparationItemId == null) {
          throw new Error("Tried to toggle preparationItem without id: ", preparationItemId);
        } else {
          let preparationItems = getPreparationsFromActiveRiskAnalisysAndActionYear( riskAnalisysAndActionYears );

          //Get the correct PreparationItem.
          let preparationItemToUpdate = preparationItems.find( preparationItem => preparationItem.id === preparationItemId );

          preparationItemToUpdate.checked = !preparationItemToUpdate.checked;

          const riskAnalisysAndActionYearsToUpdate = await updatePreparationItemInActiveRiskAnalisysAndActionYear( preparationItemToUpdate );

          let result = await updateRiskAnalisysAndActionYearsForLoggedInCustomer( riskAnalisysAndActionYearsToUpdate );
        }
      } catch (err) {
        console.error(err);
      }

      
      //navigate( AuthenticatedUrls.content.subPages.preparations.path );
    } else {
      throw new Error("No RiskAnalisysAndActionYears defined!");
    }

    setStateIsUpdating( false );
  }

  async function deletePreparationItemButtonOnClick( preparationItemToDeleteId ) {
    if( riskAnalisysAndActionYears ){
      try {
        setStateIsUpdating( true );

        if (preparationItemToDeleteId == null) {
          throw new Error("Tried to delete preparationItem without id: ", preparationItemToDeleteId);
        } else {
          const riskAnalisysAndActionYearsToUpdate = await deletePreparationItemInActiveRiskAnalisysAndActionYear( preparationItemToDeleteId );

          let result = await updateRiskAnalisysAndActionYearsForLoggedInCustomer( riskAnalisysAndActionYearsToUpdate );
        
          //navigate( AuthenticatedUrls.content.subPages.preparations.path );
        }
      } catch (err) {
        console.error(err);
      }
      setStateIsUpdating( false );
    } else {
      throw new Error("No RiskAnalisysAndActionYears defined!");
    }
  }
  
  if ( stateIsLoading || riskAnalisysAndActionYearsIsLoading ) {
    return <Loading />;
  } else {
    return (
      <>
        {
          <>
            <div className="innerContentWrapper">
              <div className="introduction">
                <div className="introductionTitle">
                  <h2><TwKey textKey="10324"/></h2>
                </div>
                <div className="introductionContent">
                  <Grid container spacing={3}>
                    <Grid item xs={10}>
                        <p><TwKey textKey="10325"/></p>
                        <TextFieldWithPlaceholder
                          placeholderTextKey="10326"
                          value={ stateAuthorsText }
                          onChange={(event) => setStateAuthorsText( event.target.value )}
                        />
                        <Autosave data={stateAuthorsText} onSave={ async () => await updateAuthorsText(stateAuthorsText) } />
                    </Grid>
                  </Grid>
                </div>
              </div>
              <div className="introduction" style={{ marginTop: "30px"}}>
                <div className="introductionTitle">
                  <h2><TwKey textKey="10310"/></h2>
                </div>
                <div className="introductionContent">
                <div style={{ width: "100%" }}>
                  <p><TwKey textKey="10311"/></p>
                  <br></br>
                  <Table style={{ width: "100%"}}>
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        statePreparationItems ? 
                          statePreparationItems
                            .slice()
                            .sort((a, b) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 0))
                            .map((preparationItem, idx) => (
                              <TableRow key={idx + "--"}>
                                <TableCell key={idx + "--"}>
                                  {
                                    preparationItem.checked ?
                                      <CheckBox onClick={ async () => await toggleOption( preparationItem.id )} />
                                    :
                                      <CheckBoxOutlineBlank onClick={ async () => await toggleOption( preparationItem.id )} />
                                  }
                                </TableCell>
                                <TableCell>
                                {
                                  preparationItem?.id === stateEditPreparationItem.id ?
                                    <TextFieldWithPlaceholder
                                      placeholderTextKey="10312"
                                      value={ stateEditPreparationItem.name }
                                      onChange={(event) => setStateEditPreparationItem( { ...preparationItem, name: event.target.value })}
                                    />
                                  :
                                      preparationItem.name
                                }
                                </TableCell>
                                <TableCell
                                  style={{ textAlign: "right" }}
                                >
                                  {
                                    stateEditPreparationItem?.id === preparationItem.id ?
                                      <>
                                        <FormControl>
                                          <SaveButton
                                          buttonTextKey="10313"
                                            toolTipTitleTextKey="10314"
                                            disabled={ stateEditPreparationItem.task !== "" ? false : true }
                                            onClick={ async () => await saveEditedPreparationItemButtonOnClick( preparationItem.id ) }
                                          />
                                        </FormControl>
                                        &nbsp;
                                        <FormControl>
                                          <CancelButton
                                            buttonTextKey="10315"
                                            toolTipTitleTextKey="10316"
                                            onClick={ async () => await cancelEditPreparationItemButtonOnClick() }
                                          />
                                        </FormControl>
                                      </>
                                    :
                                      <>
                                        <FormControl>
                                          <EditButton
                                            buttonTextKey = "10317"
                                            toolTipTitleTextKey = "10318"
                                            onClick={ async () => await editPreparationItemButtonOnClick( preparationItem.id ) }
                                          />
                                        </FormControl>
                                        &nbsp;
                                        <FormControl>
                                          <DeleteButton
                                            buttonTextKey = "10319"
                                            toolTipTitleTextKey = "10320"
                                            onClick={ async () => await PopupConfirmYesNoWithValue( "10321", preparationItem.name, async () => await deletePreparationItemButtonOnClick( preparationItem.id ) ) }
                                          />
                                        </FormControl>
                                      </>
                                  }
                                </TableCell>
                              </TableRow>
                          ))
                        :
                            ""
                        }
                      <TableRow>
                        <TableCell>

                        </TableCell>
                        <TableCell>
                          <TextField
                            variant="outlined"
                            value={stateNewItemName}
                            fullWidth
                            onChange={(event) => setStateNewItemName( event.target.value )} />
                        </TableCell>
                        <TableCell style={{ textAlign: "right" }}>
                          <FormControl>
                            <AddButton
                              buttonTextKey = "10322"
                              toolTipTitleTextKey = "10323"
                              onClick={ async () => await addPreparationItem() }
                            />
                          </FormControl>
                        </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </>
        }
      </>
    );
  }
}

export default Preparations;
