import { combineReducers } from "redux";
import { createReduxHistoryContext } from "redux-first-history";
import { createBrowserHistory } from "history";
import { configureStore } from "@reduxjs/toolkit";
import thunk from 'redux-thunk'; 
import createSagaMiddleware from "redux-saga";
import LocationChange from "Sagas/LocationChange";
import appStateReducer from "Features/AppState/AppStateSlice";
import userDataReducer from "Features/UserData/UserDataSlice";
import { userDataStorageApi } from 'API/UserDataStorageAPI'
import { setupListeners } from '@reduxjs/toolkit/query'

//Doc: https://codesandbox.io/s/wy5qw1125l?file=/src/store.js:774-801
//Doc: https://redux-saga.js.org/docs/introduction/GettingStarted

const sagaMiddleware = createSagaMiddleware();

const {
  createReduxHistory,
  routerMiddleware,
  routerReducer
} = createReduxHistoryContext({ history: createBrowserHistory() });

export const store = configureStore({
  reducer: combineReducers({
    router: routerReducer,
    settings: appStateReducer,
    userDataStorageApi: userDataStorageApi.reducer,
  }),
  middleware: [ sagaMiddleware, routerMiddleware, thunk, userDataStorageApi.middleware ]
});

sagaMiddleware.run(LocationChange);

setupListeners(store.dispatch);

export const history = createReduxHistory(store);