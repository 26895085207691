import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { userSignIn } from "Features/AppState/AppStateSlice";
import { Auth } from "aws-amplify";
import Loading from "Components/Loading";
import { AuthenticatedUrls, UnauthenticatedUrls } from "Routes/Urls";
import { EmailValid, PopupError } from "Util/Utils";
import { Link } from "react-router-dom";
import { Button, TextField, InputAdornment, IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [stateIsLoading, setStateIsLoading] = useState( true );
  const [stateEmail, setStateEmail] = useState( location.state?.email || "" );
  const [statePassword, setStatePassword] = useState( "" );
  const [stateShowPassword, setStateShowPassword] = useState( false );

  useEffect(() => {
    //Scroll to the top of the page.
    window.scrollTo(0, 0);

    setStateIsLoading(false);
  }, []);

  function validateLogin() {
    return statePassword.length > 7 && stateEmail !== "" && EmailValid(stateEmail);
  }

  async function Login(event) {
    event.preventDefault();
    try {
      setStateIsLoading( true );

      let user = await Auth.signIn(stateEmail, statePassword);

      if (user?.challengeName === "NEW_PASSWORD_REQUIRED") {
        const state = { email: stateEmail };
        navigate( UnauthenticatedUrls.landing.subPages.completeNewPassword.path, { state } );
      } else {
        await dispatch( userSignIn( AuthenticatedUrls.content.subPages.welcome.path ));
      }
    } catch (err) {
      if (err.code === "UserNotFoundException") {
        PopupError("Användaren finns inte.");
      } else if (err.code === "NotAuthorizedException") {
        console.error(err);
        if (err.message === "User is disabled") {
          PopupError("Användaren är avstängd. Kontakta administratör om du vill aktivera användaren.");
        } else if (err.message === "Incorrect username or password.") {
          PopupError("Fel email eller lösenord");
        } else {
          PopupError("Ej tillåten");
          console.error("Login:", err);
        }
      } else {
        console.error("Login:", err);
      }
      setStateIsLoading( false );
    }
  }  

  async function showPasswordButtonOnClick() {
    setStateShowPassword( !stateShowPassword );
  }
  
  async function handleMouseDownPassword( event ){
    event.preventDefault();
  };
  
  if ( stateIsLoading ) {
    return <Loading />;
  } else {
    return (
      <>
        <h1 style={{ textAlign: "center"}}>Logga in</h1>
        <div className="utvecklaTextInput">
          <TextField
            label="E-post"
            autoComplete="email"                    
            placeholder="Ange epostadress"
            value={stateEmail}
            error={!EmailValid(stateEmail)}
            onChange={(event) => setStateEmail( event.target.value )}
            margin="normal"
            fullWidth
            variant="outlined"
            InputLabelProps={{
              shrink: true,
              classes: {
                root: "textFieldLabel",
                focused: "textFieldFocused",
              },
            }}
            InputProps={{ 
              classes: {
                root: "textFieldOutlinedInput",
                focused: "textFieldFocused",
                notchedOutline: "notchedOutline",
              }
            }}
          />
        </div>
        <div className="utvecklaTextInput">
            <TextField
              label="Lösenord"
              type={stateShowPassword ? "text" : "password"}
              placeholder="Ange lösenord"
              value={statePassword}
              onChange={async (event) => await setStatePassword( event.target.value )}
              margin="normal"
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: true,
                classes: {
                  root: "textFieldLabel",
                  focused: "textFieldFocused",
                },
              }}
              InputProps={{ 
                classes: {
                  root: "textFieldOutlinedInput",
                  focused: "textFieldFocused",
                  notchedOutline: "notchedOutline",
                },
                endAdornment:(
                  <InputAdornment position="end">
                    <IconButton
                      style={{padding: "0px !important"}}
                      onClick={ async () => await showPasswordButtonOnClick() }
                      onMouseDown={ async ( event ) => await handleMouseDownPassword( event ) }
                      edge="end"
                      size="large">
                      { stateShowPassword ? <VisibilityOff /> : <Visibility /> }
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
        </div>
        <div style={{ marginTop: "20px" }}>
          <Button
            className={ validateLogin() ? "buttonBorderGreenInnerGreen" : "colorButtonHoverBG" }
            fullWidth={true}
            variant="contained"
            disabled={ !validateLogin() }
            onClick={ async ( event ) => await Login( event ) }>
            Logga in
          </Button>
        </div>
        <div style={{ marginTop: "0px", textAlign: "center" }}>
          <Link to={UnauthenticatedUrls.landing.subPages.recover.path} style={{ textDecoration: "none" }}>
            <div>
              <Button
                className="buttonBorderInnerWhite"
                variant="text"
                fullWidth={true}
              >
                Glömt lösenord?
              </Button>
            </div>
          </Link>
        </div>
        <div style={{ marginTop: "0px" }}>
          <Link to={UnauthenticatedUrls.landing.subPages.signup.path} style={{ textDecoration: "none" }}>
            <div>
              <Button
                className="buttonBorderGreenInnerWhite"
                variant="contained"
                fullWidth={true}
              >
                Registrera Konto
              </Button>
            </div>
          </Link>
        </div>
        <div style={{ marginTop: "20px" }}>
          <Link to={UnauthenticatedUrls.landing.subPages.verify.path} style={{ textDecoration: "none" }}>
            <div>
              <Button
                className="buttonBorderGreenInnerLightGreen"
                variant="contained"
                fullWidth={true}
              >
                Verifiera Konto
              </Button>
            </div>
          </Link>
        </div>
      </>
    );
  }
}

export default Login;