import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Auth } from "aws-amplify";
import Loading from "Components/Loading";
import { UnauthenticatedUrls } from "Routes/Urls";
import { Link } from "react-router-dom";
import { Button, TextField, InputAdornment, IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const CompleteNewPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [stateIsLoading, setStateIsLoading] = useState( true );
  const [stateEmail, setStateEmail] = useState( location.state?.email || "" );
  const [stateOldPassword, setStateOldPassword] = useState( "" );
  const [stateNewPassword, setStateNewPassword] = useState( "" );
  const [stateConfirmNewPassword, setStateConfirmNewPassword] = useState( "" );
  const [stateShowPassword, setStateShowPassword] = useState(false);

  useEffect(() => {
    //Scroll to the top of the page.
    window.scrollTo(0, 0);

    setStateIsLoading( false );
  }, []);

  async function validateSamePassword() {
    return stateNewPassword.length > 7 && stateConfirmNewPassword.length > 7 && stateNewPassword === stateConfirmNewPassword;
  }

  async function handleClickShowPassword() {
    setStateShowPassword( !stateShowPassword );
  }
  
  async function handleMouseDownPassword(event){
    event.preventDefault();
  }

  //https://serverless-stack.com/chapters/handle-forgot-and-reset-password.html
  //https://docs.amplify.aws/lib/auth/manageusers/q/platform/js#complete-new-password

  async function handleCompleteNewPasswordClick(event) {
    event.preventDefault();

    try {
      //Get the user object by using the pushed history.
      let user = await Auth.signIn(stateEmail, stateOldPassword);

      //Double check that the user really needs to change its password.
      if (user?.challengeName === "NEW_PASSWORD_REQUIRED") {
        //Complete the password change using the information the user provided on this page.
        await Auth.completeNewPassword(user, stateNewPassword);
      }
      
      //await dispatch( userSignIn( AuthenticatedUrls.content.subPages.welcome.path ) );
      navigate( UnauthenticatedUrls.landing.subPages.login.path)
    } catch (error) {
      //console.log("An error has occurred: ", error);
    }
  }
  
  if ( stateIsLoading ){
    return <Loading />;
  } else {
    return (
      <>
        <h1 style={{ textAlign: "center"}}>Byt lösenord</h1>
        <hr className="seperatorGrey" />
        <div className="utvecklaTextInput">
            <TextField
              label="Gammalt Lösenord"
              type={ stateShowPassword ? "text" : "password" }
              placeholder="Ange ditt gamla lösenord"
              value={ stateOldPassword }
              onChange={(event) => setStateOldPassword( event.target.value )}
              margin="normal"
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: true,
                classes: {
                  root: "textFieldLabel",
                  focused: "textFieldFocused",
                },
              }}
              InputProps={{ 
                classes: {
                  root: "textFieldOutlinedInput",
                  focused: "textFieldFocused",
                  notchedOutline: "notchedOutline",
                },
                endAdornment:(
                  <InputAdornment position="end">
                    <IconButton
                      style={{padding: "0px !important"}}
                      onClick={ async () => await handleClickShowPassword() }
                      onMouseDown={ handleMouseDownPassword}
                      edge="end"
                      size="large">
                      { stateShowPassword ? <VisibilityOff /> : <Visibility /> }
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
        </div>
        <div className="utvecklaTextInput">
            <TextField
              label="Nytt Lösenord"
              type={ stateShowPassword ? "text" : "password" }
              placeholder="Ange ditt nya lösenord"
              value={ stateNewPassword }
              onChange={ async (event) => await setStateNewPassword( event.target.value )}
              margin="normal"
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: true,
                classes: {
                  root: "textFieldLabel",
                  focused: "textFieldFocused",
                },
              }}
              InputProps={{ 
                classes: {
                  root: "textFieldOutlinedInput",
                  focused: "textFieldFocused",
                  notchedOutline: "notchedOutline",
                },
                endAdornment:(
                  <InputAdornment position="end">
                    <IconButton
                      style={{padding: "0px !important"}}
                      onClick={ async () => await handleClickShowPassword() }
                      onMouseDown={ handleMouseDownPassword }
                      edge="end"
                      size="large">
                      { stateShowPassword ? <VisibilityOff /> : <Visibility /> }
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
        </div>
        <div className="utvecklaTextInput">
            <TextField
              label="Upprepa Lösenord"
              type={ stateShowPassword ? "text" : "password"}
              placeholder="Ange ditt nya lösenord igen"
              value={ stateConfirmNewPassword}
              onChange={ async (event) => await setStateConfirmNewPassword( event.target.value )}
              margin="normal"
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: true,
                classes: {
                  root: "textFieldLabel",
                  focused: "textFieldFocused",
                },
              }}
              InputProps={{ 
                classes: {
                  root: "textFieldOutlinedInput",
                  focused: "textFieldFocused",
                  notchedOutline: "notchedOutline",
                },
                endAdornment:(
                  <InputAdornment position="end">
                    <IconButton
                      style={{padding: "0px !important"}}
                      onClick={ async () => await handleClickShowPassword() }
                      onMouseDown={ async ( event ) => handleMouseDownPassword( event ) }
                      edge="end"
                      size="large">
                      { stateShowPassword ? <VisibilityOff /> : <Visibility /> }
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
        </div>
        <div style={{ marginTop: "20px" }}>
          <Button
            className="buttonBorderGreenInnerGreen"
            variant="contained"
            disabled={ !validateSamePassword() }
            onClick={ async ( event ) => await handleCompleteNewPasswordClick( event ) }
            fullWidth={ true }
          >
            Byt lösenord
          </Button>
        </div>
        <div style={{ marginTop: "20px" }}>
          <Link to={ UnauthenticatedUrls.landing.subPages.login.path } style={{ textDecoration: "none" }}>
            <div>
              <Button
                variant="contained"
                className="buttonBorderGreenInnerWhite"
                fullWidth={true}
              >
                Tillbaka
              </Button>
            </div>
          </Link>
        </div>
      </>
    );
  }
}

export default CompleteNewPassword;