import React, { Suspense } from 'react';
import { Provider } from "react-redux";
import { HistoryRouter as Router } from "redux-first-history/rr6";
import { store, history } from "App/store";
import "@fontsource/manrope"
import "@fontsource/josefin-sans"
import 'style.css';
import 'i18n';
import App from 'App';
import reportWebVitals from 'reportWebVitals';
import { Amplify } from "aws-amplify";
import awsExports from "aws-exports";
import { createRoot } from 'react-dom/client';

Amplify.configure(awsExports);

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Suspense fallback={null}>
    <Provider store={store}>
      <Router history={history}>
        <App />
      </Router>
    </Provider>
  </Suspense>  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();