/*
    Note:
    It is very important in Javascript to set an initial value since it will determine the type of the variable.

    Examples:
    * Strings: Set to "".
    * Date: Set to new Date()

    Only set "null" if "null" is a valid value, for instance if you need to store an object.
*/

import { Roles } from "Util/Const";

class AppState {
  id;
  userId;
  userFullName;
  isAuthenticated;
  customer;
  role;
  sideMenuOpen;
  title;
  titleTextKey;
  chapterInformationTextKey;
  currentChapterSectionTypeWithUrl;
  currentChapterSectionStepTypeWithUrl;
  showTextKeys;
  rerenderSideMenu;
  constructor(id) {
      this.id = id;
      this.userId = null;
      this.userFullName = "";
      this.isAuthenticated = false;
      this.customer = null;
      this.role = Roles.customer.value;
      this.sideMenuOpen = true;
      this.sideMenuPinned = true;
      this.title = "";
      this.titleTextKey = "";
      this.chapterInformationTextKey = "";
      this.currentChapterSectionTypeWithUrl = null;
      this.currentChapterSectionStepTypeWithUrl = null;
      this.showTextKeys = false;
      this.rerenderSideMenu = false;
  }
}
  
export default AppState;