import React, { useState, useEffect } from 'react';
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import image_placeholder from "Assets/Images/image_placeholder.jpg";
import Types from "Models/Types";
import { getImage } from "Util/StorageManager";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { GetAllItems } from "Util/Api";
import { MenuItem, Select } from "@mui/material";
import { GetFirstDayOfYear, GetLastDayOfYear, formatDate } from "Util/Utils";

const CustomInput = ( props ) => {
  const [stateImage, setStateImage] = useState( image_placeholder );
  const [stateLocalImage, setStateLocalImage] = useState( false );
  const [stateChoices, setStateChoices] = useState( [] );
  const [stateYearFromDate, setStateYearFromDate] = useState( "" );
  const [stateYearToDate, setStateYearToDate] = useState( "" );
  
  useEffect(() => {
    async function asyncUseEffect(){
      if ( props.property.type === Types.image && props.property.value !== "") {
        const url = await getImage( props.property.value);
        
        if (url != null) {
          setStateImage( url );
        }
      }

      if ( props.property.type === Types.choiceFromTable && props.property?.choicesListQuerry != null) {
        const items = (await GetAllItems( props.property.choicesListQuerry))?.map((item) => {
          let res = { id: item.id };
          res[ props.property.choiceKey ] = item[ props.property.choiceKey ];
          return res;
        });
        setStateChoices( items );
      }

      if( props.property.type === Types.yearFromAndToDate ){
        //Set the first day of the year.
        setStateYearFromDate( formatDate( GetFirstDayOfYear( new Date().getFullYear() ) ) );

        //Set the end of the year
        setStateYearToDate( formatDate( GetLastDayOfYear( new Date().getFullYear() ) ) );
      }
    }

    asyncUseEffect();
  }, []);

  async function updateProperty(newValue) {
    let updatedProperty = props.property;
    updatedProperty.value = newValue;
    props.updateProperty(updatedProperty);
  }

  async function updatePropertyYearFromDate(newValue) {
    setStateYearFromDate(newValue);
    let updatedProperty = props.property;
    updatedProperty.value = newValue + "|" + stateYearToDate;
    props.updateProperty(updatedProperty);
  }

  async function updatePropertyYearToDate(newValue) {
    setStateYearToDate(newValue);
    let updatedProperty = props.property;
    updatedProperty.value = stateYearFromDate + "|" + newValue;
    props.updateProperty(updatedProperty);
  }
  

  async function addImageFile(event) {
    if (event?.target?.files[0]) {
      //Update property with adding a file-reference.
      let updatedProperty = props.property;
      updatedProperty.imageFile = event.target.files[0];
      props.updateProperty(updatedProperty);
      //Add imagefile to state so we can show the image.
      setStateImage( URL.createObjectURL(event.target.files[0]) );
      setStateLocalImage( true );
    }
  }

  async function removeImage() {
    //Update property with removing  file-reference.
    let updatedProperty = props.property;
    //Qeue remote image for removal
    if ( !props.property.removeImage) {
      updatedProperty.removeImage = true;
    } else {
      updatedProperty.imageFile = null;
    }
    //Add imagefile to state so we can show the image.
    let image = image_placeholder;
    if ( props.property.value !== "" && !props.property.removeImage) {
      const url = await getImage( props.property.value);
      //Todo: get url from s3 imagepath.
      image = url;
    }

    props.updateProperty(updatedProperty);
    
    setStateLocalImage( false );
    setStateImage( image );
  }

  switch ( props.property.type) {
    case Types.integer:
    case Types.order:
      return (
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Paper elevation={0}>
              <label>
                { props.property.label}
                { props.property.mandatory ? "* " : ""}
              </label>
            </Paper>
          </Grid>
          <Grid item xs={8}>
            <Paper elevation={0}>
              <TextField
                fullWidth
                variant="outlined"
                type="number"
                value={ props.property.value}
                onChange={async (event) => await updateProperty( event.target.value )}
              />
            </Paper>
          </Grid>
        </Grid>
      );

    case Types.label:
      return (
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Paper elevation={0}>
              <label>
                <b>{ props.property.label}</b>
              </label>
            </Paper>
          </Grid>
        </Grid>
      );

    case Types.text:
      return (
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Paper elevation={0}>
              <label>
                { props.property.label}
                { props.property.mandatory ? "* " : ""}
              </label>
            </Paper>
          </Grid>
          <Grid item xs={8}>
            <Paper elevation={0}>
              <TextField fullWidth variant="outlined" value={ props.property.value} onChange={(event) => updateProperty(event.target.value)} />
            </Paper>
          </Grid>
        </Grid>
      );

    case Types.multilineText:
      return (
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Paper elevation={0}>
              <label>
                { props.property.label}
                { props.property.mandatory ? "* " : ""}
              </label>
            </Paper>
          </Grid>
          <Grid item xs={8}>
            <Paper elevation={0}>
              <TextField
                variant="outlined"
                multiline
                fullWidth
                style={{ resize: "none" }}
                rows={3}
                cols={40}
                value={ props.property.value}
                onChange={ async (event) => await updateProperty(event.target.value)}
              />
            </Paper>
          </Grid>
        </Grid>
      );

    case Types.date:
      return (
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Paper elevation={0}>
              <label>
                { props.property.label}
                { props.property.mandatory ? "* " : ""}
              </label>
            </Paper>
          </Grid>
          <Grid item xs={8}>
            <Paper elevation={0}>
              <TextField
                type="date"
                fullWidth
                variant="outlined"
                value={ props.property.value}
                onChange={ async (event) => await updateProperty(event.target.value)}
              />
            </Paper>
          </Grid>
        </Grid>
      );
    case Types.yearFromAndToDate:
      return (
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Paper elevation={0}>
              <label>
                { props.property.label}
                { props.property.mandatory ? "* " : ""}
              </label>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper elevation={0}>
              <TextField
                type="date"
                fullWidth
                variant="outlined"
                value={ stateYearFromDate }
                onChange={ async (event) => await updatePropertyYearFromDate(event.target.value)}
              />
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper elevation={0}>
              <TextField
                type="date"
                fullWidth
                variant="outlined"
                value={ stateYearToDate }
                onChange={ async (event) => await updatePropertyYearToDate(event.target.value)}
              />
            </Paper>
          </Grid>
        </Grid>
      );
    case Types.choiceFromTable:
      return (
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Paper elevation={0}>
              <label>
                { props.property.label}
                { props.property.mandatory ? "* " : ""}
              </label>
            </Paper>
          </Grid>
          <Grid item xs={8}>
            <Paper elevation={0}>
              <Select
                variant="outlined"
                defaultValue=""
                value={ props.property.value}
                onChange={ async (event) => await updateProperty(event.target.value)}>
                { stateChoices.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item[ props.property.choiceKey]}
                  </MenuItem>
                ))}
              </Select>
            </Paper>
          </Grid>
        </Grid>
      );

    case Types.multichoice:
      return (
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Paper elevation={0}>
              <label>
                { props.property.label}
                { props.property.mandatory ? "* " : ""}
              </label>
            </Paper>
          </Grid>
          <Grid item xs={8}>
            <Paper elevation={0}>
              <Select
                variant="outlined"
                defaultValue={ props.property.defaultValue[ props.property.choiceKey]}
                value={ props.property.value}
                onChange={ async (event) => await updateProperty(event.target.value)}>
                { props.property.choices.map((item, idx) => (
                  <MenuItem key={idx} value={item[ props.property.choiceKey]}>
                    { item[ props.property.choiceLabel]}
                  </MenuItem>
                ))}
              </Select>
            </Paper>
          </Grid>
        </Grid>
      );

    case Types.color:
      return (
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Paper elevation={0}>
              <label>
                { props.property.label}
                { props.property.mandatory ? "* " : ""}
              </label>
            </Paper>
          </Grid>
          <Grid item xs={8}>
            <Paper elevation={0}>
              <input type="text" value={ props.property.value} onChange={ async (event) => await updateProperty(event.target.value)} />
            </Paper>
          </Grid>
        </Grid>
      );

    case Types.image:
      //om bilden på servern ska tas bort ska lokal eller defaultbilden visas.
      let imgUrl = props.property.removeImage ? ( stateLocalImage ? stateImage : image_placeholder) : stateImage;
      return (
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Paper elevation={0}>
              <label>
                { props.property.label}
                { props.property.mandatory ? "* " : ""}
              </label>
            </Paper>
          </Grid>
          <Grid item xs={8}>
            <Paper elevation={0}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Paper elevation={0}>
                    <div style={{ width: "300px", height: "200px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <img src={imgUrl} style={{ maxWidth: "100%", maxHeight: "100%" }} alt="NoImage" />
                    </div>
                  </Paper>
                </Grid>
                <Grid item xs={6}>
                  <form>
                    <input
                      id={"choose-image" + props.property.key}
                      type="file"
                      className="standardInput"
                      style={{ display: "none" }}
                      onChange={ async () => await addImageFile() }
                    />
                    <label htmlFor={"choose-image" + props.property.key}>
                      <div>
                        <Button variant="contained" component="span" className="buttonBorderGreenInnerGreen">
                          Välj bild
                        </Button>
                        <br/>
                        <br/>
                      </div>
                    </label>
                  </form>
                </Grid>
                <Grid item xs={6}>
                  {( stateLocalImage || (!props.property.removeImage && props.property.value !== "")) && (
                    <div>
                      <Button variant="contained" color="secondary" fullWidth onClick={ async () => await removeImage() }>
                        Ta bort bild
                      </Button>
                    </div>
                  )}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      );

    case Types.boolean:
      return (
        <div className="flexRowAlignedCenter">
          <label>
            { props.property.label}
            { props.property.mandatory ? "* " : ""}
          </label>

          <div onClick={ async () => await updateProperty( !props.property.value)} style={{cursor:"pointer"}}>
            { !props.property?.value && <CheckBoxOutlineBlank className="item" />}
            { props.property?.value && <CheckBox className="item" />}
          </div>
        </div>
      );
    default:
      return <></>;
  }
}

export default CustomInput;
