import { v4 as uuidv4 } from 'uuid';
import { store } from 'App/store';
import { userDataStorageApi } from "API/UserDataStorageAPI";
import { PreparationItem } from "Classes/UserData";
import { copyObject } from 'Util/Utils';
import { GetOrCreateUserDataStorageItemForLoggedInCustomerAndReturnTheItem, UpdateUserDataStorageItem } from "Util/Api";

/**
 * Function to get the current logged in user.
 */
async function getCurrentLoggedInUserId(){
  const state = store.getState();

  return state.settings.appState.userId;
}

/**
 * Function to get User Data directly from database without using the cache.
 */
async function getRiskAnalisysAndActionYearsDirectlyFromDB(){
  const result = await GetOrCreateUserDataStorageItemForLoggedInCustomerAndReturnTheItem();

  return copyObject(result);
}


/**
 * Add a RiskAnalisysAndActionYear to the array of RistAnalisysAndActionYears. 
 * @param {*} riskAnalisysAndActionYear 
 * @returns 
 */
export async function addRiskAnalisysAndActionYear( riskAnalisysAndActionYear ){
  //Get the current RiskAnalisysAndActionYears from DB.
  const riskAnalisysAndActionYears = await getRiskAnalisysAndActionYearsDirectlyFromDB();
 
  if( riskAnalisysAndActionYears ){
    //Set the current logged in user id as createdBy and changedBy and set createdAt and changedAt to current Date and Time.
    riskAnalisysAndActionYear.createdBy = await getCurrentLoggedInUserId();
    riskAnalisysAndActionYear.createdAt = new Date();
    riskAnalisysAndActionYear.changedBy = await getCurrentLoggedInUserId();
    riskAnalisysAndActionYear.changedAt = new Date();

    riskAnalisysAndActionYears.push(riskAnalisysAndActionYear);

    return riskAnalisysAndActionYears;
  } else {
    throw new Error("No RiskAnalisysAndActionYears defined!");
  }
}

/**
 * Set active in RiskAnlisysAndActionYear.
 * @param {*} riskAnalisysAndActionYearId 
 * @param {*} active
 */
export async function updateActiveRiskAnalisysAndActionYear( riskAnalisysAndActionYearId, active ){
  //Get the current RiskAnalisysAndActionYears from DB.
  const riskAnalisysAndActionYears = await getRiskAnalisysAndActionYearsDirectlyFromDB();
  
  if( riskAnalisysAndActionYears ){
    //Get the index of the active year.
    const indexOfActiveRiskAnalisysAndActionYear = riskAnalisysAndActionYears.findIndex((riskAnalisysAndActionOfYear) => riskAnalisysAndActionOfYear.id === riskAnalisysAndActionYearId);

    riskAnalisysAndActionYears[indexOfActiveRiskAnalisysAndActionYear].active = active;

    //Set the current logged in user id as changedBy and set changedAt to current Date and Time.
    riskAnalisysAndActionYears[indexOfActiveRiskAnalisysAndActionYear].changedBy = await getCurrentLoggedInUserId();
    riskAnalisysAndActionYears[indexOfActiveRiskAnalisysAndActionYear].changedAt = new Date();

    return riskAnalisysAndActionYears;
  } else {
    throw new Error("No RiskAnalisysAndActionYears defined!");
  }
}

/**
 * Update the Active RiskAnalisysAndActionYear
 * @param {*} authors 
 * @returns 
 */
export async function updateActiveRiskAnalisysAndActionYearAuthors( authors ) {
  //Get the current RiskAnalisysAndActionYears from DB.
  let riskAnalisysAndActionYears = await getRiskAnalisysAndActionYearsDirectlyFromDB();

  if( riskAnalisysAndActionYears ){
    
    //Get the index of the active year.
    let indexOfActiveRiskAnalisysAndActionYear = riskAnalisysAndActionYears.findIndex((riskAnalisysAndActionOfYear) => riskAnalisysAndActionOfYear.active === true);

    if( indexOfActiveRiskAnalisysAndActionYear > -1 ){
      //Set the author
      riskAnalisysAndActionYears[indexOfActiveRiskAnalisysAndActionYear].authors = authors;

      // //Set the current logged in user id as changedBy and set changedAt to current Date and Time.
      riskAnalisysAndActionYears[indexOfActiveRiskAnalisysAndActionYear].changedBy = await getCurrentLoggedInUserId();
      riskAnalisysAndActionYears[indexOfActiveRiskAnalisysAndActionYear].changedAt = new Date();

      return riskAnalisysAndActionYears;
    } else {
      throw new Error("Critical error! No year is active!");
    }
  } else {
    throw new Error("No RiskAnalisysAndActionYears defined!");
  }
};

/**
 * Add a preparation item to Active RiskAnalisys and action year.
 * @param {*} newPreparationItem 
 */
export async function addPreparationItemToActiveRiskAnalisysAndActionYear( newPreparationItem ){
  //Get the current RiskAnalisysAndActionYears from DB.
  const riskAnalisysAndActionYears = await getRiskAnalisysAndActionYearsDirectlyFromDB();

  if( riskAnalisysAndActionYears ){
    //Get the index of the active year.
    const indexOfActiveRiskAnalisysAndActionYear = riskAnalisysAndActionYears.findIndex((riskAnalisysAndActionOfYear) => riskAnalisysAndActionOfYear.active === true);

    if( indexOfActiveRiskAnalisysAndActionYear > -1 ){
      //Set the current logged in user id as createdBy and changedBy and set createdAt and changedAt to current Date and Time.
      newPreparationItem.createdBy = await getCurrentLoggedInUserId();
      newPreparationItem.createdAt = new Date();
      newPreparationItem.changedBy = await getCurrentLoggedInUserId();
      newPreparationItem.changedAt = new Date();

      //Add the preparation to the active year.
      riskAnalisysAndActionYears[indexOfActiveRiskAnalisysAndActionYear].preparations.push(newPreparationItem);

      return riskAnalisysAndActionYears;
    } else {
      throw new Error("Critical error! No year is active!");
    }
  } else {
    throw new Error("No RiskAnalisysAndActionYears defined!");
  }
}

/**
 * Add a preparation item from general preparation item template to Active RiskAnalisys and action year.
 * @param {*} newPreparationItem 
 */
export async function addPreparationItemsFromGeneralPreparationItemTemplatesToActiveRiskAnalisysAndActionYear( generalPreparationItemTemplates ){
  //Get the current RiskAnalisysAndActionYears from DB.
  const riskAnalisysAndActionYears = await getRiskAnalisysAndActionYearsDirectlyFromDB();

  if( riskAnalisysAndActionYears ){
    //Get the index of the active year.
    const indexOfActiveRiskAnalisysAndActionYear = riskAnalisysAndActionYears.findIndex((riskAnalisysAndActionOfYear) => riskAnalisysAndActionOfYear.active === true);

    if( indexOfActiveRiskAnalisysAndActionYear > -1 ){
      for( const generalPreparationItemTemplate of generalPreparationItemTemplates ){
        if( generalPreparationItemTemplate.name !== "" ){
          const newPreparationItem = new PreparationItem(uuidv4(), generalPreparationItemTemplate.name, false, generalPreparationItemTemplate.order );

          //Set the current logged in user id as createdBy and changedBy and set createdAt and changedAt to current Date and Time.
          newPreparationItem.createdBy = await getCurrentLoggedInUserId();
          newPreparationItem.createdAt = new Date();
          newPreparationItem.changedBy = await getCurrentLoggedInUserId();
          newPreparationItem.changedAt = new Date();

          //Add the preparation to the active year.
          riskAnalisysAndActionYears[indexOfActiveRiskAnalisysAndActionYear].preparations.push(newPreparationItem);
        }
      }

      return riskAnalisysAndActionYears;
    } else {
      throw new Error("Critical error! No year is active!");
    }
  } else {
    throw new Error("No RiskAnalisysAndActionYears defined!");
  }
}

/**
 * Update preparation item in the Active RiskAnalisys and action year.
 * @param {*} preparationItemId 
 * @param {*} updatedPreparationItem 
 */
export async function updatePreparationItemInActiveRiskAnalisysAndActionYear( updatedPreparationItem ){
  //Get the current RiskAnalisysAndActionYears from DB.
  const riskAnalisysAndActionYears = await getRiskAnalisysAndActionYearsDirectlyFromDB();

  if( riskAnalisysAndActionYears ){
    //Get the index of the active year.
    const indexOfActiveRiskAnalisysAndActionYear = riskAnalisysAndActionYears.findIndex( (riskAnalisysAndActionOfYear) => riskAnalisysAndActionOfYear.active === true);

    if( indexOfActiveRiskAnalisysAndActionYear > -1 ){
      //Get the index of the selected preparation.
      const preparationItemIndex = riskAnalisysAndActionYears[indexOfActiveRiskAnalisysAndActionYear].preparations.findIndex((preparationItem => preparationItem.id === updatedPreparationItem.id ));

      //Set the current logged in user id as changedBy and set changedAt to current Date and Time.
      updatedPreparationItem.changedBy = await getCurrentLoggedInUserId();
      updatedPreparationItem.changedAt = new Date();
            
      //Put it back into the state.
      riskAnalisysAndActionYears[indexOfActiveRiskAnalisysAndActionYear].preparations[preparationItemIndex] = updatedPreparationItem;

      return riskAnalisysAndActionYears;
    } else {
      throw new Error("Critical error! No year is active!");
    }
  } else {
    throw new Error("No RiskAnalisysAndActionYears defined!");
  }
}

/**
 * Delete preparation item in the Active RiskAnalisys and action year.
 * @param {*} preparationItemToDeleteId 
 * @returns 
 */
export async function deletePreparationItemInActiveRiskAnalisysAndActionYear(preparationItemToDeleteId){
  //Get the current RiskAnalisysAndActionYears from DB.
  const riskAnalisysAndActionYears = await getRiskAnalisysAndActionYearsDirectlyFromDB();

  if( riskAnalisysAndActionYears ){
    //Get the index of the active year.
    const indexOfActiveRiskAnalisysAndActionYear = riskAnalisysAndActionYears.findIndex((riskAnalisysAndActionOfYear) => riskAnalisysAndActionOfYear.active === true);

    if( indexOfActiveRiskAnalisysAndActionYear > -1 ){
      const preparations = riskAnalisysAndActionYears[indexOfActiveRiskAnalisysAndActionYear].preparations;
  
      //Filter out the preparationItem to be deleted.
      const updatedPreparations = preparations.filter(( preparationItem) => preparationItem.id !== preparationItemToDeleteId );

      //Put it back into the state.
      riskAnalisysAndActionYears[indexOfActiveRiskAnalisysAndActionYear].preparations = updatedPreparations;

      return riskAnalisysAndActionYears;
    } else {
      throw new Error("Critical error! No year is active!");
    }
  } else {
    throw new Error("No RiskAnalisysAndActionYears defined!");
  }
}

/**
 * Update RisksDefinedAndAnalyzed in RiskAnalisysAndActtion of type riskAnalisysAndActionTypeId in active RiskAnalisysAndActioNYear.
 * @param {*} riskAnalisysAndActionTypeId 
 * @param {*} risksDefinedAndAnalyzed 
 * @returns 
 */
export async function updateRisksDefinedAndAnalyzedInRiskAnalisysAndActtionOfTypeIdInActiveRiskAnalisysAndActionYear( riskAnalisysAndActionTypeId, risksDefinedAndAnalyzed ){
  //Get the current RiskAnalisysAndActionYears from DB.
  const riskAnalisysAndActionYears = await getRiskAnalisysAndActionYearsDirectlyFromDB();

  if( riskAnalisysAndActionYears ){
    //Get the index of the active year.
    const indexOfActiveRiskAnalisysAndActionYear = riskAnalisysAndActionYears.findIndex((riskAnalisysAndActionOfYear) => riskAnalisysAndActionOfYear.active === true);

    if( indexOfActiveRiskAnalisysAndActionYear > -1 ){
      //Get the riskAnalisysAndAction of the specified year and type.
      const riskAnalisysAndActionOfYearAndType = riskAnalisysAndActionYears[indexOfActiveRiskAnalisysAndActionYear].riskAnalisysAndActions[riskAnalisysAndActionTypeId];
      
      //Set the correct value
      riskAnalisysAndActionOfYearAndType.risksDefinedAndAnalyzed = risksDefinedAndAnalyzed;

      //Set the current logged in user id as changedBy and set changedAt to current Date and Time.
      riskAnalisysAndActionOfYearAndType.changedBy = await getCurrentLoggedInUserId();
      riskAnalisysAndActionOfYearAndType.changedAt = new Date();

      //Put it back into the state.
      riskAnalisysAndActionYears[indexOfActiveRiskAnalisysAndActionYear].riskAnalisysAndActions[riskAnalisysAndActionTypeId] = riskAnalisysAndActionOfYearAndType;

      return riskAnalisysAndActionYears;
    } else {
      throw new Error("Critical error! No year is active!");
    }
  } else {
    throw new Error("No RiskAnalisysAndActionYears defined!");
  }
}

/**
 * Add RiskAnalisysAndActionItem in RiskAnalisysAndActtion of TypeId riskAnalisysAndActionTypeId in active RiskAnalisysAndActioNYear.
 * @param {*} riskAnalisysAndActionTypeId 
 * @param {*} riskAnalisysAndActionItem 
 * @returns 
 */
export async function addRiskAnalisysAndActionItemInRiskAnalisysAndActtionOfTypeIdInActiveRiskAnalisysAndActionYear( riskAnalisysAndActionTypeId, riskAnalisysAndActionItem){
  //Get the current RiskAnalisysAndActionYears from DB.
  const riskAnalisysAndActionYears = await getRiskAnalisysAndActionYearsDirectlyFromDB();

  if( riskAnalisysAndActionYears ){
    //Get the index of the active year.
    const indexOfActiveRiskAnalisysAndActionYear = riskAnalisysAndActionYears.findIndex((riskAnalisysAndActionOfYear) => riskAnalisysAndActionOfYear.active === true);

    if( indexOfActiveRiskAnalisysAndActionYear > -1 ){
      //Get the riskAnalisysAndAction of the specified year and type.
      const riskAnalisysAndActionOfYearAndType = riskAnalisysAndActionYears[indexOfActiveRiskAnalisysAndActionYear].riskAnalisysAndActions[riskAnalisysAndActionTypeId];

      //Set the current logged in user id as changedBy and set changedAt to current Date and Time.
      riskAnalisysAndActionItem.createdBy = await getCurrentLoggedInUserId();
      riskAnalisysAndActionItem.createdAt = new Date();
      riskAnalisysAndActionItem.changedBy = await getCurrentLoggedInUserId();
      riskAnalisysAndActionItem.changedAt = new Date();
            
      //Push the new item.
      riskAnalisysAndActionOfYearAndType.riskAnalisysAndActionItems.push(riskAnalisysAndActionItem);

      //Put it back into the state.
      riskAnalisysAndActionYears[indexOfActiveRiskAnalisysAndActionYear].riskAnalisysAndActions[riskAnalisysAndActionTypeId] = riskAnalisysAndActionOfYearAndType;

      return riskAnalisysAndActionYears;
    } else {
      throw new Error("Critical error! No year is active!");
    }
  } else {
    throw new Error("No RiskAnalisysAndActionYears defined!");
  }
}

/**
 * Update RiskAnalisysAndActionItem in RiskAnalisysAndActtion of typeId riskAnalisysAndActionTypeId in active RiskAnalisysAndActioNYear.
 *
 * @param {*} riskAnalisysAndActionTypeId 
 * @param {*} updatedRiskAnalisysAndActionItem 
 * @returns 
 */
export async function updateRiskAnalisysAndActionItemInRiskAnalisysAndActtionOfTypeIdInActiveRiskAnalisysAndActionYear( riskAnalisysAndActionTypeId, updatedRiskAnalisysAndActionItem ){
  //Get the current RiskAnalisysAndActionYears from DB.
  const riskAnalisysAndActionYears = await getRiskAnalisysAndActionYearsDirectlyFromDB();

  if( riskAnalisysAndActionYears ){
    //Get the index of the active year.
    const indexOfActiveRiskAnalisysAndActionYear = riskAnalisysAndActionYears.findIndex((riskAnalisysAndActionOfYear) => riskAnalisysAndActionOfYear.active === true);

    if( indexOfActiveRiskAnalisysAndActionYear > -1 ){
      //Get the riskAnalisysAndAction of the specified year and type.
      const riskAnalisysAndActionOfYearAndType = riskAnalisysAndActionYears[indexOfActiveRiskAnalisysAndActionYear].riskAnalisysAndActions[riskAnalisysAndActionTypeId];

      //Get the index of the item we want to update.
      const indexOfRiskAnalisysAndActionItemToUpdate = riskAnalisysAndActionOfYearAndType.riskAnalisysAndActionItems.findIndex(( riskAnalisysAndActionItem ) => riskAnalisysAndActionItem.id === updatedRiskAnalisysAndActionItem.id);

      //Set the current logged in user id as changedBy and set changedAt to current Date and Time.
      updatedRiskAnalisysAndActionItem.changedBy = await getCurrentLoggedInUserId();
      updatedRiskAnalisysAndActionItem.changedAt = new Date();

      //Replace at the index we found.
      riskAnalisysAndActionOfYearAndType.riskAnalisysAndActionItems[indexOfRiskAnalisysAndActionItemToUpdate] = updatedRiskAnalisysAndActionItem;

      //Put it back in the state.
      riskAnalisysAndActionYears[indexOfActiveRiskAnalisysAndActionYear].riskAnalisysAndActions[riskAnalisysAndActionTypeId] = riskAnalisysAndActionOfYearAndType;

      return riskAnalisysAndActionYears;
    } else {
      throw new Error("Critical error! No year is active!");
    }
  } else {
    throw new Error("No RiskAnalisysAndActionYears defined!");
  }
}

/**
 * Delete RiskAnalisysAndActionItem in RiskAnalisysAndActtion of type id riskAnalisysAndActionTypeId in active RiskAnalisysAndActioNYear.
 * @param {*} riskAnalisysAndActionTypeId 
 * @param {*} riskAnalisysAndActionItemToDeleteId 
 * @returns 
 */
export async function deleteRiskAnalisysAndActionItemInRiskAnalisysAndActtionOfTypeIdInActiveRiskAnalisysAndActionYear( riskAnalisysAndActionTypeId, riskAnalisysAndActionItemToDeleteId ){
  //Get the current RiskAnalisysAndActionYears from DB.
  const riskAnalisysAndActionYears = await getRiskAnalisysAndActionYearsDirectlyFromDB();

  if( riskAnalisysAndActionYears ){
    //Get the index of the active year.
    const indexOfActiveRiskAnalisysAndActionYear = riskAnalisysAndActionYears.findIndex((riskAnalisysAndActionOfYear) => riskAnalisysAndActionOfYear.active === true);

    if( indexOfActiveRiskAnalisysAndActionYear > -1 ){
      //Get the riskAnalisysAndAction of the specified year and type.
      const riskAnalisysAndActionOfYearAndType = riskAnalisysAndActionYears[indexOfActiveRiskAnalisysAndActionYear].riskAnalisysAndActions[riskAnalisysAndActionTypeId];

      //Filter on the supplied Id so that we get a new array of riskAnalisysAndActionItems that dont have the supplied item.
      riskAnalisysAndActionOfYearAndType.riskAnalisysAndActionItems = riskAnalisysAndActionOfYearAndType.riskAnalisysAndActionItems.filter(( riskAnalisysAndActionItem) => riskAnalisysAndActionItem.id !== riskAnalisysAndActionItemToDeleteId );

      //Put it back in the state.
      riskAnalisysAndActionYears[indexOfActiveRiskAnalisysAndActionYear].riskAnalisysAndActions[riskAnalisysAndActionTypeId] = riskAnalisysAndActionOfYearAndType;

      return riskAnalisysAndActionYears;
    } else {
      throw new Error("Critical error! No year is active!");
    }
  } else {
    throw new Error("No RiskAnalisysAndActionYears defined!");
  }
}

/**
 * Add RiskAnalisysAndActionTaskItem in RiskAnalisysAndActtionItem in RiskAnalisysAndAction of TypeId riskAnalisysAndActionTypeId in active RiskAnalisysAndActioNYear.
 * @param {*} riskAnalisysAndActionTypeId 
 * @param {*} riskAnalisysAndActionItemId
 * @param {*} newRiskAnalisysAndActionTaskItem 
 * @returns 
 */
export async function addRiskAnalisysAndActionTaskItemInRiskAnalisysAndActtionItemInRiskAnalisysAndActtionOfTypeIdInActiveRiskAnalisysAndActionYear( riskAnalisysAndActionTypeId, riskAnalisysAndActionItemId, newRiskAnalisysAndActionTaskItem){
  //Get the current RiskAnalisysAndActionYears from DB.
  const riskAnalisysAndActionYears = await getRiskAnalisysAndActionYearsDirectlyFromDB();

  if( riskAnalisysAndActionYears ){
    //Get the index of the active year.
    const indexOfActiveRiskAnalisysAndActionYear = riskAnalisysAndActionYears.findIndex((riskAnalisysAndActionOfYear) => riskAnalisysAndActionOfYear.active === true);

    if( indexOfActiveRiskAnalisysAndActionYear > -1 ){
      //Get the riskAnalisysAndAction of the specified year and type.
      const riskAnalisysAndActionOfYearAndType = riskAnalisysAndActionYears[indexOfActiveRiskAnalisysAndActionYear].riskAnalisysAndActions[riskAnalisysAndActionTypeId];

      //Get the index of the riskAnalisysAndActionItem where you want to add a new riskAnalisysAndActionTaskItem.
      const indexOfRiskAnalisysAndActionItemToAddTo = riskAnalisysAndActionOfYearAndType.riskAnalisysAndActionItems.findIndex(( riskAnalisysAndActionItem ) => riskAnalisysAndActionItem.id === riskAnalisysAndActionItemId);

      //Set the current logged in user id as changedBy and set changedAt to current Date and Time.
      newRiskAnalisysAndActionTaskItem.createdBy = await getCurrentLoggedInUserId();
      newRiskAnalisysAndActionTaskItem.createdAt = new Date();
      newRiskAnalisysAndActionTaskItem.changedBy = await getCurrentLoggedInUserId();
      newRiskAnalisysAndActionTaskItem.changedAt = new Date();

      //Push the new item at the index we found.
      riskAnalisysAndActionOfYearAndType.riskAnalisysAndActionItems[indexOfRiskAnalisysAndActionItemToAddTo].riskAnalisysAndActionTaskItems.push(newRiskAnalisysAndActionTaskItem);
    
      //Put it back into the state.
      riskAnalisysAndActionYears[indexOfActiveRiskAnalisysAndActionYear].riskAnalisysAndActions[riskAnalisysAndActionTypeId] = riskAnalisysAndActionOfYearAndType;

      return riskAnalisysAndActionYears;
    } else {
      throw new Error("Critical error! No year is active!");
    }
  } else {
    throw new Error("No RiskAnalisysAndActionYears defined!");
  }
}

/**
 * Update RiskAnalisysAndActionItem in RiskAnalisysAndActtion of typeId riskAnalisysAndActionTypeId in active RiskAnalisysAndActionYear.
 * @param {*} riskAnalisysAndActionTypeId 
 * @param {*} riskAnalisysAndActionItemId
 * @param {*} updatedRiskAnalisysAndActionTaskItem
 * @returns 
 */
export async function updateRiskAnalisysAndActionTaskItemInRiskAnalisysAndActionItemInRiskAnalisysAndActtionOfTypeIdInActiveRiskAnalisysAndActionYear( riskAnalisysAndActionTypeId, riskAnalisysAndActionItemId, updatedRiskAnalisysAndActionTaskItem ){
  //Get the current RiskAnalisysAndActionYears from DB.
  const riskAnalisysAndActionYears = await getRiskAnalisysAndActionYearsDirectlyFromDB();

  if( riskAnalisysAndActionYears ){
    //Get the index of the active year.
    const indexOfActiveRiskAnalisysAndActionYear = riskAnalisysAndActionYears.findIndex((riskAnalisysAndActionOfYear) => riskAnalisysAndActionOfYear.active === true);

    if( indexOfActiveRiskAnalisysAndActionYear > -1 ){
      //Get the riskAnalisysAndAction of the specified year and type.
      const riskAnalisysAndActionOfYearAndType = riskAnalisysAndActionYears[indexOfActiveRiskAnalisysAndActionYear].riskAnalisysAndActions[riskAnalisysAndActionTypeId];

      //Get the index of the RiskAnalisysAndActionItem.
      const indexOfRiskAnalisysAndActionItem = riskAnalisysAndActionOfYearAndType.riskAnalisysAndActionItems.findIndex(( riskAnalisysAndActionItem ) => riskAnalisysAndActionItem.id === riskAnalisysAndActionItemId);

      //Get the index of the RiskAnalisysAndActionTaskItem we want to update.
      const indexOfRiskAnalisysAndActionTaskItemToUpdate = riskAnalisysAndActionOfYearAndType.riskAnalisysAndActionItems[indexOfRiskAnalisysAndActionItem].riskAnalisysAndActionTaskItems.findIndex(( riskAnalisysAndActionTaskItem ) => riskAnalisysAndActionTaskItem.id === updatedRiskAnalisysAndActionTaskItem.id);

      //Set the current logged in user id as changedBy and set changedAt to current Date and Time.
      updatedRiskAnalisysAndActionTaskItem.changedBy = await getCurrentLoggedInUserId();
      updatedRiskAnalisysAndActionTaskItem.changedAt = new Date();

      //Update the new item at the index we found.
      riskAnalisysAndActionOfYearAndType.riskAnalisysAndActionItems[indexOfRiskAnalisysAndActionItem].riskAnalisysAndActionTaskItems[indexOfRiskAnalisysAndActionTaskItemToUpdate] = updatedRiskAnalisysAndActionTaskItem;

      //Put it back in the state.
      riskAnalisysAndActionYears[indexOfActiveRiskAnalisysAndActionYear].riskAnalisysAndActions[riskAnalisysAndActionTypeId] = riskAnalisysAndActionOfYearAndType;

      return riskAnalisysAndActionYears;
    } else {
      throw new Error("Critical error! No year is active!");
    }
  } else {
    throw new Error("No RiskAnalisysAndActionYears defined!");
  }
}

/**
 * Delete RiskAnalisysAndActionTaskItem in RiskAnalisysAndActtionItem In RiskAnalisysAndAction of type id riskAnalisysAndActionTypeId in active RiskAnalisysAndActioNYear.
 * @param {*} riskAnalisysAndActionTypeId 
 * @param {*} riskAnalisysAndActionItemId
 * @param {*} riskAnalisysAndActionTaskItemToDeleteId 
 * @returns 
 */
export async function deleteRiskAnalisysAndActionTaskItemInRiskAnalisysAndActionItemRiskAnalisysAndActtionOfTypeIdInActiveRiskAnalisysAndActionYear( riskAnalisysAndActionTypeId, riskAnalisysAndActionItemId, riskAnalisysAndActionTaskItemToDeleteId ){
  //Get the current RiskAnalisysAndActionYears from DB.
  const riskAnalisysAndActionYears = await getRiskAnalisysAndActionYearsDirectlyFromDB();

  if( riskAnalisysAndActionYears ){
  
    //Get the index of the active year.
    const indexOfActiveRiskAnalisysAndActionYear = riskAnalisysAndActionYears.findIndex((riskAnalisysAndActionOfYear) => riskAnalisysAndActionOfYear.active === true);

    if( indexOfActiveRiskAnalisysAndActionYear > -1 ){
      //Get the riskAnalisysAndAction of the specified year and type.
      const riskAnalisysAndActionOfYearAndType = riskAnalisysAndActionYears[indexOfActiveRiskAnalisysAndActionYear].riskAnalisysAndActions[riskAnalisysAndActionTypeId];

      //Get the index of the RiskAnalisysAndActionItem.
      const indexOfRiskAnalisysAndActionItem = riskAnalisysAndActionOfYearAndType.riskAnalisysAndActionItems.findIndex(( riskAnalisysAndActionItem ) => riskAnalisysAndActionItem.id === riskAnalisysAndActionItemId);

      //Filter on the supplied Id so that we get a new array of riskAnalisysAndActionItems that dont have the supplied item.
      riskAnalisysAndActionOfYearAndType.riskAnalisysAndActionItems[indexOfRiskAnalisysAndActionItem].riskAnalisysAndActionTaskItems = riskAnalisysAndActionOfYearAndType.riskAnalisysAndActionItems[indexOfRiskAnalisysAndActionItem].riskAnalisysAndActionTaskItems.filter(( riskAnalisysAndActionTaskItem) => riskAnalisysAndActionTaskItem.id !== riskAnalisysAndActionTaskItemToDeleteId );

      //Put it back in the state.
      riskAnalisysAndActionYears[indexOfActiveRiskAnalisysAndActionYear].riskAnalisysAndActions[riskAnalisysAndActionTypeId] = riskAnalisysAndActionOfYearAndType;

      return riskAnalisysAndActionYears;
    } else {
      throw new Error("Critical error! No year is active!");
    }
  } else {
    throw new Error("No RiskAnalisysAndActionYears defined!");
  }
}