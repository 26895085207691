import { LOCATION_CHANGE } from "redux-first-history";
import { put, takeEvery } from "redux-saga/effects";
import { setAppStateSetting } from "Features/AppState/AppStateSlice";
import { GetChapterSectionTypeFromUrl, GetChapterSectionStepTypeFromUrl } from "Util/Utils";

function* setCorrectTitleAndHighlightedMenuItem(url){
    //Get the currentChapterSectionTypeWithUrl and set to appState.
    const chapterSectionTypeWithUrl = GetChapterSectionTypeFromUrl(url);

    if( chapterSectionTypeWithUrl !== undefined && chapterSectionTypeWithUrl !== null ){
        //Set the chapterSectionType to appState
        yield put( setAppStateSetting( { currentChapterSectionTypeWithUrl: chapterSectionTypeWithUrl } ) );

        //Set the title in the top menu.
        yield put( setAppStateSetting( { titleTextKey: chapterSectionTypeWithUrl.chapterSectionType.HeaderTextKey } ) );

        //Set the chapterInformationTextKey in the top menu.
        yield put( setAppStateSetting( { chapterInformationTextKey: chapterSectionTypeWithUrl.chapterSectionType.ChapterInformationTextKey } ) );

        //Set title to null since we know we have a text key.
        yield put( setAppStateSetting( { title: "" } ) );
    } else {
      //Set the currentChapterSectionTypeWithUrl to null since we are not on chapterSection.
      yield put( setAppStateSetting( { currentChapterSectionTypeWithUrl: null } ) );
      
      //Set title text key to null since we know we are outside user chapters that are translated.
      yield put( setAppStateSetting( { titleTextKey: "" } ) );
    }

    //Get the currentChapterSectionStepTypeWithUrl and set to appState.
    const chapterSectionStepTypeWithUrl = GetChapterSectionStepTypeFromUrl(url);

    if( chapterSectionStepTypeWithUrl !== undefined && chapterSectionStepTypeWithUrl !== null ){
        //Set the chapterSectionType to appState
        yield put( setAppStateSetting( {  currentChapterSectionStepTypeWithUrl: chapterSectionStepTypeWithUrl } ) );
    } else {
      //Set the currentChapterSectionStepTypeWithUrl to null since we are not on chapterSection.
      yield put( setAppStateSetting( { currentChapterSectionStepTypeWithUrl: null } ) );
    }

    return;
  }

function* LocationChange({ payload }) {
    const { location, action } = payload;

    //Set the correct Title and Highlighted menu items.
    yield setCorrectTitleAndHighlightedMenuItem(location.pathname);
}

export default function* RootSaga() {
  yield takeEvery(LOCATION_CHANGE, LocationChange);
}
