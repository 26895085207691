import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setAppStateSetting } from "Features/AppState/AppStateSlice";
import { AuthenticatedUrls } from 'Routes/Urls';
import Loading from "Components/Loading";
import moment from 'moment';
import {
  useGetRiskAnalisysAndActionYearsForLoggedInCustomerQuery,
} from 'API/UserDataStorageAPI';
import { Table, TableBody, TableCell, TableHead, TableRow, FormControl } from '@mui/material';
import { AddButton } from "Components/StandardTextComponentsWithTranslation";


const RiskAnalisysAndActionYear = () => {
  const dispatch = useDispatch();
  
  const navigate = useNavigate();
  const appState = useSelector((state) => state.settings.appState);
  
  const { data: riskAnalisysAndActionYears, error, isLoading: riskAnalisysAndActionYearsIsLoading, refetch } = useGetRiskAnalisysAndActionYearsForLoggedInCustomerQuery(null, { pollingInterval: 3000 } );
  
  const [stateIsLoading, setStateIsLoading] = useState(true);

  useEffect(() => {
    async function asyncUseEffect(){
      dispatch( setAppStateSetting( { title: "Hantera år" } ));

      //Check if we have a customerId.
      if(!appState?.customer){
        //The customer object is empty, do nothinng for now!
        //ToDo: Stop customer object from being loaded when it is empty.
      }else{
        //Scroll to the top of the page.
        window.scrollTo(0, 0);

        setStateIsLoading(false);
      }
    }

    asyncUseEffect();
  }, []);
 
  if ( stateIsLoading || riskAnalisysAndActionYearsIsLoading ) {
    return <Loading />;
  } else {
    return (
      <>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                Från datum
              </TableCell>
              <TableCell>
                Till datum
              </TableCell>
              <TableCell>
                Aktiv
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              riskAnalisysAndActionYears.map((riskAnalisysAndActionYear, idx) => (
                <TableRow key={idx}>
                  <TableCell>
                    { moment( riskAnalisysAndActionYear.fromDate ).format("YYYY-MM-DD") }
                  </TableCell>
                  <TableCell>
                    { moment( riskAnalisysAndActionYear.toDate ).format("YYYY-MM-DD") }
                  </TableCell>
                  <TableCell>
                    { riskAnalisysAndActionYear.active === true ? "Ja" : "Nej" }
                  </TableCell>
                  <TableCell>

                  </TableCell>
                </TableRow>
              ))
            }
            <TableRow>
              <TableCell>
                
              </TableCell>
              <TableCell>
                
              </TableCell>
              <TableCell>
                
              </TableCell>
              <TableCell>
                <FormControl>
                    <AddButton
                      buttonTextKey="10213"
                      toolTipTitleTextKey="10214"
                      onClick={ () => navigate( AuthenticatedUrls.admin.subPages.addRiskAnalisysAndActionYears.path ) }
                    />
                </FormControl>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </> 
    );
  }
}

export default RiskAnalisysAndActionYear;