import React from "react";
import { useLocation } from 'react-router-dom';
import EditView from "Components/EditView";
import { createCustomerSubscription, deleteCustomerSubscription, updateCustomerSubscription } from "graphql/mutations";


const EditCustomerSubscription = () => {
  const location = useLocation();

  return (
    <EditView
      id={ location.state?.id }
      data={ location.state?.data }
      model={ location.state?.model }
      header={ location.state?.id ? "Redigera prenumeration" : "Skapa prenumeration" }
      dbCreate={createCustomerSubscription}
      dbUpdate={updateCustomerSubscription}
      dbDelete={deleteCustomerSubscription}
    />
  );
}

export default EditCustomerSubscription;