import React, { Fragment } from "react";
import i18n from "i18n";
import { useSelector } from 'react-redux';
import { store } from "App/store";
import { useTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';
import { TextField, Button, IconButton } from "@mui/material";
import { InfoOutlined, CheckBoxOutlineBlank, CheckBoxOutlined, ArrowForwardIos, Add, Edit, Save, Cancel, Delete, PushPin, Close } from "@mui/icons-material";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

export const RequiredField = () => {
    const appState = useSelector((state) => state.settings.appState);
    const { t } = useTranslation();

    const textKey = "10000";

    let toolTipTitle = "";

    if ( appState.showTextKeys === true ){
        toolTipTitle += "TextKey: " + textKey + "\n\n";
    }

    toolTipTitle += t(textKey);

    return (
        <Tooltip title={toolTipTitle} placement="right" arrow className="whiteSpacePreLine">
            <span className="requiredFieldComponent" >*</span>
        </Tooltip>
    );
};

export const InfoText = ({ textKey }) => {
    const appState = useSelector((state) => state.settings.appState);
    const { t } = useTranslation();

    let toolTipTitle = "";

    if ( appState.showTextKeys === true ){
        toolTipTitle += "TextKey: " + textKey + "\n\n";

        toolTipTitle += t(textKey);

        return (
            <Tooltip title={toolTipTitle} placement="right" arrow  className="whiteSpacePreLine">
                <InfoOutlined style={{ width: "14pt", height: "14pt", padding: "0"}} />
            </Tooltip>
        );
    } else {
        const text = t(textKey);
        
        if (text && text.trim()) {
            toolTipTitle += t(textKey);

            return (
                <Tooltip title={toolTipTitle} placement="right" arrow  className="whiteSpacePreLine">
                    <InfoOutlined style={{ width: "14pt", height: "14pt", padding: "0"}} />
                </Tooltip>
            );
        } else {
            return <Fragment></Fragment>
        }
    }
};

export const TwKey = ({ textKey }) => {
    const appState = useSelector((state) => state.settings.appState);
    const { t } = useTranslation();

    let toolTipTitle = "";

    if ( appState.showTextKeys === true ){
        toolTipTitle += "TextKey:" + textKey;

        return (
            <Tooltip title={toolTipTitle} placement="right" arrow className="whiteSpacePreLine">
                <span>{t(textKey)}</span>
            </Tooltip>
        )
    } else {
        return (
            <span className="whiteSpacePreLine">{t(textKey)}</span>
        )    
    }
};

export const buttonTypes = {
    AddButton: Symbol("addButton"),
    EditButton: Symbol("editButton"),
    SaveButton: Symbol("saveButton"),
    CancelButton: Symbol("cancelButton"),
    DeleteButton: Symbol("deleteButton"),
    PinButton: Symbol("punButton"),
    UnPinButton: Symbol("unPinButton")
}

export const ActionButton = ({ buttonType, buttonTextKey, toolTipTitleTextKey, onClick, disabled }) => {
    const appState = useSelector((state) => state.settings.appState);
    const { t } = useTranslation();

    let toolTipTitle = "";

    if ( appState.showTextKeys === true ){
        toolTipTitle += "ButtonTextKey:" + buttonTextKey + "\n TooltipTitleTextKey: " + toolTipTitleTextKey + "\n\n";
    }

    toolTipTitle += t(toolTipTitleTextKey);

    let icon = null;

    switch(buttonType){
        case buttonTypes.AddButton:
            icon = <Add className="iconsNormalNew" />
            break;
        case buttonTypes.EditButton:
            icon = <Edit className="iconsNormalNew" />
            break;
        case buttonTypes.SaveButton:
            icon = <Save className="iconsNormalNew" />
            break;
        case buttonTypes.DeleteButton:
            icon = <Delete className="iconsNormalNew" />
            break;
        case buttonTypes.CancelButton:
            icon = <Cancel className="iconsNormalNew" />
            break;
        case buttonTypes.PinButton:
            icon = <PushPin className="iconsNormalNew icon-rotate-45" />
            break;
        case buttonTypes.UnPinButton:
            icon = <Close className="iconsNormalNew" />
            break;
        default:
            icon = <Add className="iconsNormalNew" />
            break;
    }

    var text = t(buttonTextKey);

    return (
        <>
            <Tooltip title={toolTipTitle} placement="right" arrow={true} className="whiteSpacePreLine">
                {
                    disabled ? 
                        <div
                            className="buttonDisabled"
                        >
                            {
                                !text?.trim() ? "" : <span style={{ paddingRight: "10px" }}> {text} </span>
                            }
                            { icon }
                        </div>
                    :
                        <div className="buttonWithIconContainerNew">
                            <Button
                                className="buttonBorderGreenInnerGreen"
                                variant="contained"
                                disabled={ disabled }
                                onClick={onClick}
                                fullWidth={true}
                            >
                                {
                                    !text?.trim() ? "" : <span style={{ paddingRight: "10px" }}> {text} </span>
                                }
                                { icon }
                            </Button>
                        </div>
                }
            </Tooltip>
        </>
    )
};

export const AddButton = ({ buttonTextKey, toolTipTitleTextKey, onClick, disabled }) => {
    return <ActionButton buttonType={ buttonTypes.AddButton } buttonTextKey={ buttonTextKey } toolTipTitleTextKey={ toolTipTitleTextKey } onClick={ onClick } disabled={ disabled } />
};

export const EditButton = ({ buttonTextKey, toolTipTitleTextKey, onClick, disabled }) => {
    return <ActionButton buttonType={ buttonTypes.EditButton } buttonTextKey={ buttonTextKey } toolTipTitleTextKey={ toolTipTitleTextKey } onClick={ onClick } disabled={ disabled } />
};

export const SaveButton = ({ buttonTextKey, toolTipTitleTextKey, onClick, disabled }) => {
    return <ActionButton buttonType={ buttonTypes.SaveButton } buttonTextKey={ buttonTextKey } toolTipTitleTextKey={ toolTipTitleTextKey } onClick={ onClick } disabled={ disabled } />
};

export const CancelButton = ({ buttonTextKey, toolTipTitleTextKey, onClick, disabled }) => {
    return <ActionButton buttonType={ buttonTypes.CancelButton } buttonTextKey={ buttonTextKey } toolTipTitleTextKey={ toolTipTitleTextKey } onClick={ onClick } disabled={ disabled } />
};

export const DeleteButton = ({ buttonTextKey, toolTipTitleTextKey, onClick, disabled }) => {
    return <ActionButton buttonType={ buttonTypes.DeleteButton } buttonTextKey={ buttonTextKey } toolTipTitleTextKey={ toolTipTitleTextKey } onClick={ onClick } disabled={ disabled } />
};



export const SmallIconButton = ({ buttonType, buttonTextKey, toolTipTitleTextKey, onClick, disabled }) => {
    const appState = useSelector((state) => state.settings.appState);
    const { t } = useTranslation();

    let toolTipTitle = "";

    if ( appState.showTextKeys === true ){
        toolTipTitle += "ButtonTextKey:" + buttonTextKey + "\n TooltipTitleTextKey: " + toolTipTitleTextKey + "\n\n";
    }

    toolTipTitle += t(toolTipTitleTextKey);

    let icon = null;

    switch(buttonType){
        case buttonTypes.PinButton:
            icon = <PushPin className="iconsSmallButton icon-rotate-45" />
            break;
        case buttonTypes.UnPinButton:
            icon = <Close className="iconsSmallButton" />
            break;
        default:
            icon = <PushPin className="iconsSmallButton icon-rotate-45" />
            break;
    }

    var text = t(buttonTextKey);

    return (
        <>
            <Tooltip title={toolTipTitle} placement="right" arrow={true} className="whiteSpacePreLine">
                <div className="buttonWithIconContainerNew">
                    <IconButton
                        className="smallIconButton"
                        variant="contained"
                        disabled={ disabled }
                        onClick={onClick}
                        fullWidth={true}
                    >
                        {
                            !text?.trim() ? "" : <span style={{ paddingRight: "10px" }}> {text} </span>
                        }
                        { icon }
                    </IconButton>
                </div>
            </Tooltip>
        </>
    )
};


export const PinButton = ({ buttonTextKey, toolTipTitleTextKey, onClick, disabled }) => {
    return <SmallIconButton buttonType={ buttonTypes.PinButton } buttonTextKey={ buttonTextKey } toolTipTitleTextKey={ toolTipTitleTextKey } onClick={ onClick } disabled={ disabled } />
};

export const UnPinButton = ({ buttonTextKey, toolTipTitleTextKey, onClick, disabled }) => {
    return <SmallIconButton buttonType={ buttonTypes.UnPinButton } buttonTextKey={ buttonTextKey } toolTipTitleTextKey={ toolTipTitleTextKey } onClick={ onClick } disabled={ disabled } />
};




export const LinkButton = ({ linkName, linkTextKey, toolTipTitleTextKey, to, target, onClick }) => {
  const appState = useSelector((state) => state.settings.appState);
  const { t } = useTranslation();

  let toolTipTitle = "";

  if ( appState.showTextKeys === true ){
    if( linkName ){
      toolTipTitle += "TooltipTitleTextKey: " + toolTipTitleTextKey + "\n\n";
    } else {
      toolTipTitle += "ButtonTextKey:" + linkTextKey + "\n TooltipTitleTextKey: " + toolTipTitleTextKey + "\n\n";
    } 
  }

  toolTipTitle += t(toolTipTitleTextKey);

  return (
    onClick ?
      <span onClick={ onClick }>
        {
          linkName != undefined ?
            <span>{ linkName }</span>
          :
            <span>t(linkTextKey)</span>
        }
      </span>
    :
      <Link
          to={to}
          target={target}
      >
        {
          linkName ?
            <Tooltip title={toolTipTitle} placement="right" arrow className="whiteSpacePreLine">
              <span>{ linkName }</span>
            </Tooltip>
          :
            <Tooltip title={toolTipTitle} placement="right" arrow className="whiteSpacePreLine">      
              <span>{t(linkTextKey)}</span>
            </Tooltip>
        }
      </Link>
  )
};

export const ArrowForwardIconLinkButton = ({ buttonTextKey, toolTipTitleTextKey, onClick, disabled, to }) => {
    const appState = useSelector((state) => state.settings.appState);
    const { t } = useTranslation();

    let toolTipTitle = "";

    if ( appState.showTextKeys === true ){
        toolTipTitle += "ButtonTextKey:" + buttonTextKey + "\n TooltipTitleTextKey: " + toolTipTitleTextKey + "\n\n";
    }

    toolTipTitle += t(toolTipTitleTextKey);

    return (
        <Link
            to={to}
        >
            <Tooltip title={toolTipTitle} placement="right" arrow className="whiteSpacePreLine">
                <div className="buttonWithIconContainerNew">
                    <Button
                        className="buttonBorderGreenInnerGreen"
                        variant="contained"
                        disabled={ disabled }
                        onClick={onClick}
                        fullWidth={true}
                    >
                        {t(buttonTextKey)}
                        <ArrowForwardIos
                            className="iconsNormalNew"
                        />
                    </Button>
                </div>
            </Tooltip>
        </Link>
    )
};

export const CheckBoxToggleButton = ({ currentStateIsTrue, buttonTextKeyIfTrue, toolTipTitleTextKeyIfTrue, onClickIfTrue, disabledIfTrue, buttonTextKeyIfFalse, toolTipTitleTextKeyIfFalse, onClickIfFalse, disabledIfFalse }) => {
  const appState = useSelector((state) => state.settings.appState);
  const { t } = useTranslation();

  let toolTipTitleIfTrue = "";

  if ( appState.showTextKeys === true ){
    toolTipTitleIfTrue += "ButtonTextKey:" + buttonTextKeyIfTrue + "\n TooltipTitleTextKey: " + toolTipTitleTextKeyIfTrue + "\n\n";
  }

  toolTipTitleIfTrue += t(toolTipTitleTextKeyIfTrue);

  let toolTipTitleIfFalse = "";

  if ( appState.showTextKeys === true ){
    toolTipTitleIfFalse += "ButtonTextKey:" + buttonTextKeyIfFalse + "\n TooltipTitleTextKey: " + toolTipTitleTextKeyIfFalse + "\n\n";
  }

  toolTipTitleIfFalse += t(toolTipTitleTextKeyIfFalse);

  return (
    <>
      {
        currentStateIsTrue ?
          <Tooltip title={toolTipTitleIfTrue} placement="right" arrow className="whiteSpacePreLine">
            <Button
              fullWidth
              className="checkBoxButtonEnabled"
              variant="contained"
              disabled={ disabledIfTrue }
              onClick={ onClickIfTrue }
            >
              <><p><TwKey textKey={ buttonTextKeyIfTrue } />&nbsp;</p><CheckBoxOutlined /></>
            </Button>
          </Tooltip>
        :
          <Tooltip title={toolTipTitleIfFalse} placement="right" arrow className="whiteSpacePreLine">
            <Button
              fullWidth
              className="checkBoxButtonDisabled"
              variant="contained"
              disabled={ disabledIfFalse }
              onClick={ onClickIfFalse }
            >
              <><p><TwKey textKey={ buttonTextKeyIfFalse } />&nbsp;</p><CheckBoxOutlineBlank /></>
            </Button>
          </Tooltip>
      }
    </>
  )
};

export const TextFieldWithPlaceholder = ({ placeholderTextKey, value, onChange, disabled }) => {
    const appState = useSelector((state) => state.settings.appState);
    const { t } = useTranslation();

    let toolTipTitle = "";

    if ( appState.showTextKeys === true ){
        toolTipTitle += "PlaceholderTextKey:" + placeholderTextKey + "\n\n";
    }

    toolTipTitle += t(placeholderTextKey);

    return (
        <Tooltip title={toolTipTitle} placement="right" arrow className="whiteSpacePreLine">
            <TextField
                style={{margin: "0"}}
                placeholder={t(placeholderTextKey)}
                value={ value }
                onChange={ onChange }
                margin="normal"
                fullWidth
                size="small"
                variant="outlined"
                disabled={ disabled }
            />
        </Tooltip>
    )
};

export const MultiLineTextFieldWithPlaceholder = ({ placeholderTextKey, value, onChange, minRows, maxRows, disabled }) => {
    const appState = useSelector((state) => state.settings.appState);
    const { t } = useTranslation();
    
    let toolTipTitle = "";

    if ( appState.showTextKeys === true ){
        toolTipTitle += "PlaceholderTextKey:" + placeholderTextKey + "\n\n";
    }

    toolTipTitle += t(placeholderTextKey);

    return (
        <Tooltip title={toolTipTitle} placement="right" arrow className="whiteSpacePreLine">
            <TextField
                placeholder={t(placeholderTextKey)}
                value={value}
                onChange={onChange}
                fullWidth
                multiline
                minRows={minRows}
                maxRows={maxRows}
                color="primary"
                variant="outlined"
                disabled={disabled}
            />
        </Tooltip>
    )
};

export function getText( textKey, replaceText = "" ){
    const appState = store.getState().settings.appState;
    
    let text = "";

    if ( appState.showTextKeys === true ){
        text += "TextKey: " + textKey + " - ";
    }
    
    text +=i18n.t(textKey);

    if( replaceText !== ""){
      text = text.replace("<value>", replaceText )
    }

    return text;
}

export function PopupConfirmYesNoWithValue(textKey, value, confirmCallback) {
    const appState = store.getState().settings.appState;

    let message = i18n.t(textKey);
    message = message.replace("<value>", value);

    let text = "";

    if ( appState.showTextKeys === true ){
        text += "TextKey: " + textKey + " - ";
    }
    
    text += message;

    Swal.fire({
        customClass: "popupText",
        title: text,
        confirmButtonText: "Ja",
        confirmButtonClass: "confirmButton",
        showCancelButton: true,
        cancelButtonText: "Nej",
        cancelButtonClass: "cancelButton",
        buttonsStyling: false,
    }).then((result) => {
        if (result.isConfirmed) {
            confirmCallback();
        }
    });
  }

  export async function PopupInputTwoValues(textKey) {
    const appState = store.getState().settings.appState;

    let message = i18n.t(textKey);
    //message = message.replace("<value>", value);

    let text = "";

    if ( appState.showTextKeys === true ){
        text += "TextKey: " + textKey + " - ";
    }
    
    text += message;

    const { value: formValues } = await Swal.fire({
      customClass: "popupText",
      title: text,
      showCancelButton: true,
      cancelButtonText: "Avbryt",
      html:
        'Länktext: <input id="swal-input1" class="swal2-input">' +
        'Länk:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <input id="swal-input2" class="swal2-input">',
      focusConfirm: false,
      buttonsStyling: false,
      preConfirm: () => {
        return [
          document.getElementById('swal-input1').value,
          document.getElementById('swal-input2').value
        ]
      }
    })
    
    if (formValues) {
      return formValues;
    } else {
      return "";
    }
  }