import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { setAppStateSetting } from "Features/AppState/AppStateSlice";
import { API } from "aws-amplify";
import { deleteFile, uploadImage } from "Util/StorageManager";
import { PopupConfirm, PopupError } from "Util/Utils";
import CustomInput from "Components/CustomInput";
import Loading from "Components/Loading";
import Error from "Components/Error";
import Types from "Models/Types";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { SaveOrUpdate } from "Util/Api";
import useForceUpdate from 'use-force-update';

const EditView = ( props ) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const forceUpdate = useForceUpdate();

  let error = false;
  let data = null;
  let editMode = false;

  //Sidan ska redigera befintligt objekt
  if (props?.model && props?.data && props?.id) {
    //We are in edit mode.
    editMode = true;

    //Get the model.
    data = JSON.parse(JSON.stringify(props.model));

    //Fill the model with data from the DB.
    let oldData = props.data;
    
    for (let property of data) {
      for (let oldDataProperty of oldData) {
        if ( property.key === oldDataProperty.key ) {
          property.value = oldDataProperty.value;
        }
      }
    }
  } else if (props?.model) {
    //Sidan ska skapa ett nytt objekt
    //we are NOT in edit mode.
    editMode = false;

    data = JSON.parse(JSON.stringify(props.model));
    for (let property of data) {
      if (property?.defaultValue != null) {
        property.value = property.defaultValue;
      }
    }
  } else {
    //show error and go back.
    error = true;
  }

  const [stateIsLoading, setStateIsLoading] = useState(true);
  const [stateError, setStateError] = useState(error);
  const [stateData, setStateData] = useState(data);
  const [stateEditMode, setStateEditMode] = useState(editMode);
  
  useEffect(() => {
    setStateIsLoading( false );
    dispatch( setAppStateSetting( {  title: props.header } ) );
  }, []);

  async function updateProperty(updatedProperty) {
    let newData = stateData;
    let idx = newData.findIndex((prop) => prop.key === updatedProperty.key);
    newData[idx] = updatedProperty;

    setStateData( newData );

    forceUpdate();
  }

  async function deleteItem() {
    setStateIsLoading( true );

    //Handle custom saving. This is used to save users in cognito
    if (props.onDelete != null) {
      await props.onDelete();
      setStateIsLoading( false );
      return;
    }
    try {
      for (let property of stateData) {
        if (property.type === Types.image) {
          await deleteFile(property.value);
        }
      }
      if (props.preDelete !== undefined) {
        await props.preDelete();
      }
      await API.graphql({ query: props.dbDelete, variables: { input: { id: props.id } } });

      navigate(-1);
    } catch (err) {
      console.error("Deleting " + props.header + ": ", err);
    }
    setStateIsLoading( false );
  }
  
  async function save() {
    setStateIsLoading( true );

    try {
      //Kolla så mandatory properties är satta
      for (let key of Object.keys(props.model)) {
        //Hantera text
        if (
          (stateData[key].type === Types.text ||
            stateData[key].type === Types.multilineText ||
            stateData[key].type === Types.integer ||
            stateData[key].type === Types.date ||
            stateData[key].type === Types.choiceFromTable ||
            stateData[key].type === Types.multichoice) &&
            props.model[key].mandatory &&
            stateData[key].value === props.model[key].value
        ) {
          PopupError(props.model[key].errorMessage);
          setStateIsLoading( false );
          return;
        }
        if (stateData[key].type === Types.image && props.model[key].mandatory && stateData[key].imageFile != null) {
          PopupError(props.model[key].errorMessage);
          setStateIsLoading( false );
          return;
        }
      }
      //Handle custom saving. This is used to save users in cognito
      if (props.onSave != null) {
        await props.onSave(stateData);
        setStateIsLoading( false );
        return;
      }

      //Copy values to a format that can be saved to the database.
      let newData = {};
      for (let property of stateData) {
        //Check that this property should be saved to the database. Ignore if not.
        if (
            //We want to create a new object (editMode = false).
            ( stateEditMode === false && (property.showInCreate === undefined || property.showInCreate === true) )
          ||
            //We want to edit existing object.
            ( stateEditMode === true && (property.showInEdit === undefined || property.showInEdit === true) )
          ){
          //Hantera bilder
          if (property.type === Types.image) {
            let path = "";
            if (property?.removeImage) {
              await deleteFile(property.value);
            }
            //new image
            if (property.imageFile != null) {
              path = await uploadImage(property.imageFile);
              if (path == null) {
                throw new Error("Could not upload image in edit " + props.header + "");
              }
            }
            //No change in path and no remove, use same as before
            if (path === "" && !property.removeImage) {
              path = property.value;
            }
            newData[property.key] = path;
          }
          //Hantera parentId
          else if (property.type === Types.parentId) {
            newData[property.key] = property.value;
          }
          //Handle yearFromAndToDate that is only to be set as the default year.
          else if(property.type === Types.yearFromAndToDate){
            //ToDo: Enable selecting an other year then current year.
            //Create a new RiskAnalisysAndActionYear based on user input.
            //let riskAnalisysAndActionYear = new RiskAnalisysAndActionYear(uuidv4());
            //riskAnalisysAndActionYear.fromDate = GetFirstYear(property.value);
            //riskAnalisysAndActionYear.toDate = GetSecondYear(property.value);
            //riskAnalisysAndActionYear.active = true;

            //await SaveNewItem();
            
            //dispatch(addRiskAnalisysAndActionYear( { riskAnalisysAndActionYear: riskAnalisysAndActionYear } ));
          }
          //Handle common properties that just need to be saved.
          else if (
            property.type === Types.text ||
            property.type === Types.multilineText ||
            property.type === Types.boolean ||
            property.type === Types.date ||
            property.type === Types.choiceFromTable ||
            property.type === Types.multichoice ||
            property.type === Types.color ||
            property.type === Types.order ||
            property.type === Types.integer
          ) {
            newData[property.key] = property.value;
          }
        }
      }

      if ( stateEditMode ) {
        //uppdatera
        newData.id = props.id;
      }

      //Save or update to DB.
      const res = await SaveOrUpdate(newData, props.dbUpdate, props.dbCreate, null);

      if (props.afterSave != null) {
        const id = res.id;
        await props.afterSave(id);
      }

      //Check if an URL was specified that we should go to after save.
      if ( !props.afterSaveUrl || props.afterSaveUrl === "" ) {
        //Go back to the previous page.
        navigate(-1);
      } else {
        //Go to the specified page instead of back to the previous page.
        props.history.push({ pathname: props.afterSaveUrl });
      }
    } catch (err) {
      console.error("Saving " + props.header + ": ", err);
      setStateIsLoading( false );
    }
  }

  if (stateError) {
    return <Error message={"Något gick fel"} />;
  } else if ( stateIsLoading ) {
    return <Loading />;
  } else {
    return (
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Paper elevation={0} style={{ padding: "25px" }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper elevation={0}>
                  {
                    //( property.editOnly !== true || props.id != null) && property.hidden !== true) // hide editonly props if we are not in edit mode.
                    stateData
                      .filter((property) => 
                        //We want to create a new object (editMode = false).
                        ( stateEditMode === false && (property.showInCreate === undefined || property.showInCreate === true) )
                        ||
                        //We want to edit existing object.
                        ( stateEditMode === true && (property.showInEdit === undefined || property.showInEdit === true) )
                      )
                      .map((property) => (
                        <div style={{ paddingBottom: "10px"}}>
                          <CustomInput key={ property.key } property={ property } updateProperty={ async ( data ) => await updateProperty( data ) } />
                        </div>
                      ))
                    }
                </Paper>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <Paper elevation={0}></Paper>
              </Grid>
              <Grid item xs={2}>
                <Paper elevation={0}>
                  <div>
                    <Button variant="contained"
                      fullWidth onClick={ async () => await save() }>
                      Spara
                    </Button>
                  </div>
                </Paper>
              </Grid>
              {props.disableDelete !== true && props.id != null && (
                <Grid item xs={2}>
                  <Paper elevation={0}>
                    <Button
                      variant="contained"
                      color="secondary"
                      fullWidth
                      onClick={() => PopupConfirm("Är du säker på att du vill ta bort?", async () => await deleteItem())}
                    >
                      Ta bort
                    </Button>
                  </Paper>
                </Grid>
              )}
              {!props.hideCancel && (
                <Grid item xs={2}>
                  <Paper elevation={0}>
                    <div>
                      <Button variant="contained" /*color="default"*/ fullWidth onClick={() => navigate(-1)}>
                        Avbryt
                      </Button>
                    </div>
                  </Paper>
                </Grid>
              )}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default EditView;