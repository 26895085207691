import { Auth } from "aws-amplify";
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import Loading from "Components/Loading";
import { UnauthenticatedUrls } from "Routes/Urls";
import { EmailValid, PopupError, PopupWithText } from "Util/Utils";
import { Button, TextField } from "@mui/material";

const Verify = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [stateIsLoading, setStateIsLoading] = useState( false );
  const [stateEmail, setStateEmail] = useState( location.state?.email || "" );
  const [stateCode, setStateCode] = useState( "" );

  useEffect(() => {
    //Scroll to the top of the page.
    window.scrollTo(0, 0);

    setStateIsLoading( false );
  }, []);
  

  async function validateVerify() {
    return stateCode.length === 6 && stateEmail !== "" && EmailValid(stateEmail);
  }

  async function resendConfirmationCode() {
    try {
      await Auth.resendSignUp(stateEmail);
      PopupWithText("Verifieringskod skickad", "Verifiereingskoden kommer till " + stateEmail + " strax");
    } catch (err) {
      if (err.code === "InvalidParameterException") {
        PopupError("Kan inte skicka verifieringskoden, användaren är redan verifierad.");
      }
      console.error("error resending code: ", err);
    }
  }

  async function signup() {
    try {
      setStateIsLoading( true );

      let res = await Auth.confirmSignUp(stateEmail, stateCode);

      if (res != null) {
        navigate({ pathname: UnauthenticatedUrls.landing.subPages.login.path, state: { email: stateEmail } });
      } else {
        setStateIsLoading( false );
      }
    } catch (err) {
      if (err.code === "CodeMismatchException") {
        PopupError("Ej korrekt verifieringskod, prova igen");
      } else if (err.code === "UserNotFoundException") {
        PopupError("Kunde inte hitta användaren, prova igen");
      } else if (err.code === "NotAuthorizedException") {
        PopupError("Användaren är redan verifierad");
      } else {
        console.error(err);
        setStateIsLoading( false );
      }
    }
  }

  if ( stateIsLoading) {
    return <Loading />;
  } else {
    return (
      <>
        <h1 style={{ textAlign: "center"}}>Verifiera konto</h1>
        <div>
          <TextField
            label="E-post"
            placeholder="Ange epostadress"
            value={stateEmail}
            error={!EmailValid(stateEmail)}
            onChange={(event) => setStateEmail( event.target.value )}
            margin="normal"
            fullWidth
            variant="outlined"
            InputLabelProps={{
              shrink: true,
              classes: {
                root: "textFieldLabel",
                focused: "textFieldFocused",
              },
            }}
            InputProps={{ 
              classes: {
                root: "textFieldOutlinedInput",
                focused: "textFieldFocused",
                notchedOutline: "notchedOutline",
              },
            }}
          />
        </div>
        <div>
          <TextField
            label="Verifieringskod"
            type="text"
            placeholder="Ange verifieringskod"
            error={ stateCode.length !== 6 }
            value={ stateCode }
            onChange={(event) => setStateCode( event.target.value )}
            margin="normal"
            fullWidth
            variant="outlined"
            InputLabelProps={{
              shrink: true,
              classes: {
                root: "textFieldLabel",
                focused: "textFieldFocused",
              },
            }}
            InputProps={{ 
              classes: {
                root: "textFieldOutlinedInput",
                focused: "textFieldFocused",
                notchedOutline: "notchedOutline",
              },
            }}
          />
        </div>
        <div style={{ marginTop: "20px" }}>
          <Button
            className="buttonBorderGreenInnerGreen"
            variant="contained"
            disabled={!validateVerify()} onClick={async () => await signup() }
            fullWidth={true}
          >
            Verifiera konto
          </Button>
        </div>
        <div style={{ marginTop: "30px" }}>
          <Button 
            className="buttonBorderGreenInnerLightGreen"
            variant="contained"
            disabled={!EmailValid( stateEmail )} onClick={ async () => await resendConfirmationCode() }
            fullWidth={true}
          >
            Skicka verifieringskod igen?
          </Button>
        </div>
        <hr className="seperatorButtonGrey" />
        <div style={{ marginTop: "20px" }}>
          <Link to={UnauthenticatedUrls.landing.subPages.login.path} style={{ textDecoration: "none" }}>
            <Button
              className="buttonBorderGreenInnerWhite"
              variant="contained"
              fullWidth={true}
            >
              Tillbaka
            </Button>
          </Link>
        </div>
      </>
    );
  }
}

export default Verify;
