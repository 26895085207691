import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AuthenticatedUrls, UnauthenticatedUrls } from "Routes/Urls";
import { selectAppState } from "Features/AppState/AppStateSlice";
import Loading from "Components/Loading";

const ProtectedRoute = ({ element, allowedRoles }) => {
  const navigate = useNavigate();
  const appState = useSelector(selectAppState);

  const [stateIsLoading, setStateIsLoading] = useState(false);

  useEffect(() => {
    //Check if user is authenticated or not.
    if (appState.isAuthenticated) {
      //The user is authenticated.

      //Check if the user has the correct role.
      const foundRole = allowedRoles.find((allowedRole) => allowedRole.value === appState.role);

      if ( foundRole ) {
        //Return the element that the user tried to go to.
        setStateIsLoading( false );
      } else {
        //We are on NOT the allowed list.
        return navigate(AuthenticatedUrls.content.path, { replace: true });
      }
    } else {
      //The user is NOT authenticated.

      //Redirect the user to the landing page.   
      return navigate(UnauthenticatedUrls.landing.path, { replace: true });
    }
  },[]);

  if( stateIsLoading ) {
    <Loading />
  } else {
    return element;
  }
}

export default ProtectedRoute;