import { Auth, API } from "aws-amplify";

async function RunPostQuerry(path, myInit) {
  myInit = {
    ...myInit,
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };
  return await API.post("AdminQueries", path, myInit);
}
async function RunGetQuerry(path, myInit) {
  myInit = {
    ...myInit,
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };
  return await API.get("AdminQueries", path, myInit);
}

export async function setUserPassword(username, password) {
  let myInit = {
    body: {
      username,
      password,
    },
  };
  return await RunPostQuerry("/setUserPassword", myInit);
}

export async function AddUserToGroup(username, groupname) {
  try {
    let myInit = {
      body: {
        username,
        groupname,
      },
    };
    return await RunPostQuerry("/addUserToGroup", myInit);
  } catch (err) {
    console.error(err);
  }
}

export async function GetUser(username) {
  try {
    let myInit = {
      queryStringParameters: {
        username: username,
      },
    };
    return await RunGetQuerry("/getUser", myInit);
  } catch (err) {
    console.error("getUser", err.response.data, err);
  }
}
export async function ListUsers() {
  try {
    let myInit = {};
    return await RunGetQuerry("/listUsers", myInit);
  } catch (err) {
    console.error("listUsers", err.response.data, err);
  }
}
export async function ListUsersInGroup(groupname) {
  try {
    let myInit = {
      queryStringParameters: {
        groupname: groupname,
      },
    };
    return await RunGetQuerry("/listUsersInGroup", myInit);
  } catch (err) {
    console.error("listUsersInGroup", err.response.data, err);
  }
}

export async function DisableUser(username) {
  try {
    let myInit = {
      body: {
        username,
      },
    };
    return await RunPostQuerry("/disableUser", myInit);
  } catch (err) {
    console.error("disableUser", err.response.data, err);
  }
}
export async function AdminCreateUser(user) {
  try {
    let myInit = {
      body: {
        username: user.email,
        customerId: user.customerId,
        email: user.email,
        title: user.title,

        givenName: user.givenName,
        familyName: user.familyName,
      },
    };
    return await RunPostQuerry("/adminCreateUser", myInit);
  } catch (err) {
    console.error("adminCreateUser", err.response.data, err);
  }
}
export async function EnableUser(username) {
  try {
    let myInit = {
      body: {
        username,
      },
    };
    return await RunPostQuerry("/enableUser", myInit);
  } catch (err) {
    console.error("enableUser", err.response.data, err);
  }
}
export async function ConfirmUserSignUp(username) {
  try {
    let myInit = {
      body: {
        username,
      },
    };
    return await RunPostQuerry("/confirmUserSignUp", myInit);
  } catch (err) {
    console.error("confirmUserSignUp", err.response.data, err);
  }
}
export async function AdminDeleteUser(username) {
  try {
    let myInit = {
      body: {
        username,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
    };
    return await RunPostQuerry("/adminDeleteUser", myInit);
  } catch (err) {
    console.error("AdminDeleteUser", err.response.data, err);
  }
}

export async function ListGroupsForUser(username) {
  try {
    let myInit = {
      queryStringParameters: {
        username: username,
      },
    };
    return await RunGetQuerry("/listGroupsForUser", myInit);
  } catch (err) {
    console.error("listGroupsForUser", err.response.data, err);
  }
}

export async function RemoveUserFromGroup(username, groupname) {
  try {
    let myInit = {
      body: {
        username,
        groupname,
      },
    };
    return await RunPostQuerry("/removeUserFromGroup", myInit);
  } catch (err) {
    console.error("removeUserFromGroup", err.response.data, err);
  }
}

export async function adminUpdateUserAttributes(username, email, title, givenName, familyName) {
  try {
    let myInit = {
      body: {
        username,
        email,
        title,
        givenName,
        familyName,
      },
    };
    return await RunPostQuerry("/adminUpdateUserAttributes", myInit);
  } catch (err) {
    console.error("adminUpdateUserAttributes", err.response.data, err);
  }
}
