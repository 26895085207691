const Types = {
  order: "order",
  text: "text",
  label: "label",
  date: "date", //just text. eg 2020-05-06
  color: "color",
  integer: "integer",
  boolean: "boolean",
  choiceFromTable: "choiceFromTable",
  multilineText: "multilineText",
  image: "image",
  linkToChild: "linkToChild",
  link: "link",
  parentId: "parentId",
  multichoice: "multichoice",
  yearFromAndToDate: "yearToAndFromDate"
};
export default Types;
