import { v4 as uuidv4 } from 'uuid';
import { ChapterSectionTypes } from "Util/Const";

export class RiskAnalisysAndActionTaskItemWithCheckBox {
    id;
    checked;
    riskAnalisysAndActionItemId;
    task;
    responsible;
    ongoing;
    followUpDate;
    fixed;
    fixedDate;
    comment;
    createdBy;
    createdAt;
    changedBy;
    changedAt;
    constructor(id) {
        this.id = id;
        this.checked = false;
        this.riskAnalisysAndActionItemId = null;
        this.task = "";
        this.responsible = "";
        this.ongoing = false;
        this.followUpDate = null;
        this.fixed = false;
        this.fixedDate = null;
        this.comment = "";
        this.createdBy = null;
        this.createdAt = new Date();
        this.changedBy = null;
        this.changedAt = new Date();
    }
  }  
  
  export class RiskAnalisysAndActionItemWithCheckBox {
    id;
    checked;
    riskAnalisysAndActionId;
    risk;
    consequence;
    analisys;
    remedy;
    riskAnalisysAndActionTaskItemsWithCheckBox;
    followUpText;
    createdBy;
    createdAt;
    changedBy;
    changedAt;
    constructor(id) {
        this.id = id;
        this.checked = false; 
        this.riskAnalisysAndActionId = null;
        this.risk = "";
        this.consequence = "";
        this.analisys = "";
        this.remedy = true;
        this.riskAnalisysAndActionTaskItemsWithCheckBox = [];
        this.followUpText = "";
        this.createdBy = null;
        this.createdAt = new Date();
        this.changedBy = null;
        this.changedAt = new Date();
    }
  }

  
  
  export class RiskAnalisysAndActionWithCheckBox {
    id;
    checked;
    chapterSectionTypeName;
    titleTextKey;
    informationTextKey;
    chapterDescriptionTextKey;
    tasksChapterInformationTextKey;
    statusChapterInformationTextKey;
    risksDefinedAndAnalyzed;
    riskAnalisysAndActionItemsWithCheckBox;
    createdBy;
    createdAt;
    changedBy;
    changedAt;
    constructor(id) {
        this.id = id;
        this.checked = false;
        this.chapterSectionTypeName = "";
        this.titleTextKey = "";
        this.chapterDescriptionTextKey = "";
        this.risksDefinedAndAnalyzed = false;
        this.riskAnalisysAndActionItemsWithCheckBox = [];
        this.createdBy = null;
        this.createdAt = new Date();
        this.changedBy = null;
        this.changedAt = new Date();
    }
  }

  export class RiskAnalisysAndActionYearWithCheckBox {
    id;
    checked;
    fromDate;
    toDate;
    riskAnalisysAndActionsWithCheckBox;
    active;
    createdBy;
    createdAt;
    changedBy;
    changedAt;
    constructor (id) {
        this.id = id;
        this.checked = false;
        this.fromDate = null;
        this.toDate = null;
        this.active = false;
        this.riskAnalisysAndActionsWithCheckBox = {
            workingConditions: new RiskAnalisysAndActionWithCheckBox(uuidv4(), ChapterSectionTypes.workingConditions.Name, "10150", "10164"),
            wagesAndEmploymentTerms: new RiskAnalisysAndActionWithCheckBox(uuidv4(), ChapterSectionTypes.wagesAndEmploymentTerms.Name, "10151","10200"),
            recruitmentAndPromotion: new RiskAnalisysAndActionWithCheckBox(uuidv4(), ChapterSectionTypes.recruitmentAndPromotion.Name, "10152","10201"),
            educationAndSkillsDevelopment: new RiskAnalisysAndActionWithCheckBox(uuidv4(), ChapterSectionTypes.educationAndSkillsDevelopment.Name, "10153","10202"),
            employmentAndParenthood: new RiskAnalisysAndActionWithCheckBox(uuidv4(), ChapterSectionTypes.employmentAndParenthood.Name, "10154","10203"),
            measuresToPreventHarassment: new RiskAnalisysAndActionWithCheckBox(uuidv4(), ChapterSectionTypes.measuresToPreventHarassment.Name, "10155","10204"),
            equalGenderDistribution: new RiskAnalisysAndActionWithCheckBox(uuidv4(), ChapterSectionTypes.equalGenderDistribution.Name, "10156","10205"),
        };
        this.createdBy = null;
        this.createdAt = new Date();
        this.changedBy = null;
        this.changedAt = new Date();
    }
  }