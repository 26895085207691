import { copyObject } from "Util/Utils";

/**
 * Get ActiveRiskAnalisysAndActionYear from riskAnalisysAndActionYears.
 * @param {*} riskAnalisysAndActionYears
 * @returns 
 */
export function getActiveRiskAnalisysAndActionYear( riskAnalisysAndActionYears ){
  //Get the current RiskAnalisysAndActionYears from DB.
  if( riskAnalisysAndActionYears ){
    //Get the index of the active year.
    const indexOfActiveRiskAnalisysAndActionYear = riskAnalisysAndActionYears.findIndex((riskAnalisysAndActionOfYear) => riskAnalisysAndActionOfYear.active === true);
    
    if( indexOfActiveRiskAnalisysAndActionYear > -1 ){
      //Return a copy of the active riskAnalisysAndActionYear that is not write protected.
      return riskAnalisysAndActionYears[indexOfActiveRiskAnalisysAndActionYear];
    } else {
      throw new Error("Critical error! No year is set as active!");
    }
  } else {
    throw new Error("No RiskAnalisysAndActionYears defined!");
  }
}

/**
 * Get Preparations from currentRiskAnalisysAndActionYear in active RiskAnalisysAndActionYear
 * @param {*} riskAnalisysAndActionYears
 * @returns 
 */
export function getPreparationsFromActiveRiskAnalisysAndActionYear( riskAnalisysAndActionYears ){
  if( riskAnalisysAndActionYears ){
    //Get the index of the active year.
    const indexOfActiveRiskAnalisysAndActionYear = riskAnalisysAndActionYears.findIndex((riskAnalisysAndActionOfYear) => riskAnalisysAndActionOfYear.active === true);
    
    if( indexOfActiveRiskAnalisysAndActionYear > -1 ){
      //Return a copy of the object that is not write protected.
      return copyObject(riskAnalisysAndActionYears[indexOfActiveRiskAnalisysAndActionYear].preparations);
    } else {
      throw new Error("Critical error! No year is set as active!");
    }
  } else {
    throw new Error("No RiskAnalisysAndActionYears defined!");
  }
}

/**
 * Get RiskAnalisysAndAction of TypeId from currentRiskAnalisysAndActionYear in active RiskAnalisysAndActionYear
 * @param {*} riskAnalisysAndActionYears
 * @param {*} riskAnalisysAndActionTypeId 
 * @returns 
 */
export function getRiskAnalisysAndActionOfTypeIdInActiveRiskAnalisysAndActionYear( riskAnalisysAndActionYears, riskAnalisysAndActionTypeId ){ 
  if( riskAnalisysAndActionYears ){
    //Get the index of the active year.
    const activeOfActiveRiskAnalisysAndActionYear = riskAnalisysAndActionYears.findIndex((riskAnalisysAndActionOfYear) => riskAnalisysAndActionOfYear.active === true);
    
    if( activeOfActiveRiskAnalisysAndActionYear > -1 ){
      //Return a copy of the riskAnalisysAndActions of the selected type that is not write protected.
      return copyObject( riskAnalisysAndActionYears[ activeOfActiveRiskAnalisysAndActionYear ].riskAnalisysAndActions[ riskAnalisysAndActionTypeId ] );
    } else {
      throw new Error("Critical error! No year is set as active!");    
    }
  } else {
    throw new Error("No RiskAnalisysAndActionYears defined!");
  }
}