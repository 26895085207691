export const Roles = {
  admin: { value: "Admins", displayValue: "Global administratör" },
  consultantAdmin: { value: "ConsultantAdmins", displayValue: "Konsultadministratör" },
  customerAdmin: { value: "CustomerAdmins", displayValue: "Kundadmininstratör" },
  customer: { value: "Users", displayValue: "Användare" },
};

export const RolesConsultantAndCustomerAdmin = {
  customerAdmin: { value: "CustomerAdmins", displayValue: "Kundadmininstratör" },
  customer: { value: "Users", displayValue: "Användare" },
};

export const UserStates = {
  enabled: { value: "enabled", displayValue: "Aktiv" },
  disabled: { value: "disabled", displayValue: "Ej aktiv" },
};

export const Steps = {
  chapters: {
    kartlaggning: { value: "kartlaggning", displayValue: "1. Förberedelser", dbObjName: "Survey", isRequired: false, hasDbStep: true},
    documentation: { value: "documentation", displayValue: "2. Analys", dbObjName: "Documentation", isRequired: false, hasDbStep: true },
    atgard: { value: "atgard", displayValue: "3. Åtgärder", dbObjName: "MeasuresAndFollowUp", isRequired: false, hasDbStep: true },
  },
  introduktion: {
    welcome: { value: "valkommen", displayValue: "Välkommen", isRequired: true, hasDbStep: false },
    introduktion: { value: "introduktion", displayValue: "Introduktion", isRequired: true, hasDbStep: false },
    material: { value: "material", displayValue: "Stödmaterial", isRequired: true, hasDbStep: true },
    // likabehandlingsplanSettings: { value: "likabehandlingsplanSettings", displayValue: "Inställningar", isRequired: true, hasDbStep: false },
  },
  likabehandlingsplan: {
    status: { value: "status", displayValue: "Status", isRequired: true, hasDbStep: false },
    likabehandlingsplan: { value: "likabehandlingsplan", displayValue: "Likabehandlingsplan", isRequired: true, hasDbStep: false },
  },
};

export const SectionTypes = {
  welcome: "welcome",
  introduction: "introduction",
  workingConditions: "workingconditions",
  wagesAndEmploymentTerms: "wagesandemploymentconditions",
  recruitmentAndPromotion: "recruitmentandpromotion",
  educationAndSkillsDevelopment: "educationandskillsdevelopment",
  employmentAndParenthood: "employmentandparenthood",
  measuresToPreventHarassment: "measuresToPreventHarassment",
  equalGenderDistribution: "equalgenderdistribution",
  equalTreatmentPlan: "equaltreatmentplan",
};


/* -------------- NEW --------------------- */

export const ChapterSectionTypes = {
  welcome: {
    Name: "welcome",
    HeaderTextKey: "10393",
    ChapterInformationTextKey: "",
  },
  introduction: {
    Name: "introduction",
    HeaderTextKey: "10072",
    ChapterInformationTextKey: "",
  },
  materials: {
    Name: "material",
    HeaderTextKey: "10281",
    ChapterInformationTextKey: "",
  },
  dbTest: {
    Name: "dbTest",
    HeaderTextKey: "10294",
    ChapterInformationTextKey: "",
  },
  preparations: {
    Name: "preparations",
    HeaderTextKey: "10294",
    ChapterInformationTextKey: "",
  },
  workingConditions: {
    Name: "workingConditions",
    HeaderTextKey: "10073",
    ChapterInformationTextKey: "10216",
    RisksRiskInfoTextKey: "10012",
    RisksConsequenceInfoTextKey: "10013",
    RiskAnalisysAndActionAnalisysInfoTextKey: "10015",
    RiskAnalisysAndActionTasksInfoTextKey: "10272",
    RiskAnalisysAndActionTaskInfoTextKey: "10016",
  },
  employmentAndParenthood: {
    Name: "employmentAndParenthood",
    HeaderTextKey: "10077",
    ChapterInformationTextKey: "10220",
    RisksRiskInfoTextKey: "10049",
    RisksConsequenceInfoTextKey: "10050",
    RiskAnalisysAndActionAnalisysInfoTextKey: "10052",
    RiskAnalisysAndActionTasksInfoTextKey: "10276",
    RiskAnalisysAndActionTaskInfoTextKey: "10053"
  },
  wagesAndEmploymentTerms: {
    Name: "wagesAndEmploymentTerms",
    HeaderTextKey: "10074",
    ChapterInformationTextKey: "10217",
    RisksRiskInfoTextKey: "10034",
    RisksConsequenceInfoTextKey: "10035",
    RiskAnalisysAndActionAnalisysInfoTextKey: "10037",
    RiskAnalisysAndActionTasksInfoTextKey: "10273",
    RiskAnalisysAndActionTaskInfoTextKey: "10038"
  },
  recruitmentAndPromotion: {
    Name: "recruitmentAndPromotion",
    HeaderTextKey: "10075",
    ChapterInformationTextKey: "10218",
    RisksRiskInfoTextKey: "10039",
    RisksConsequenceInfoTextKey: "10040",
    RiskAnalisysAndActionAnalisysInfoTextKey: "10042",
    RiskAnalisysAndActionTasksInfoTextKey: "10274",
    RiskAnalisysAndActionTaskInfoTextKey: "10043"
  },
  educationAndSkillsDevelopment: {
    Name: "educationAndSkillsDevelopment",
    HeaderTextKey: "10076",
    ChapterInformationTextKey: "10219",
    RisksRiskInfoTextKey: "10044",
    RisksConsequenceInfoTextKey: "10045",
    RiskAnalisysAndActionAnalisysInfoTextKey: "10047",
    RiskAnalisysAndActionTasksInfoTextKey: "10275",
    RiskAnalisysAndActionTaskInfoTextKey: "10048"
  },  
  measuresToPreventHarassment: {
    Name: "measuresToPreventHarassment",
    HeaderTextKey: "10078",
    ChapterInformationTextKey: "10221",
    RisksRiskInfoTextKey: "10054",
    RisksConsequenceInfoTextKey: "10055",
    RiskAnalisysAndActionAnalisysInfoTextKey: "10057",
    RiskAnalisysAndActionTasksInfoTextKey: "10277",
    RiskAnalisysAndActionTaskInfoTextKey: "10058"
  },
  equalGenderDistribution: {
    Name: "equalGenderDistribution",
    HeaderTextKey: "10079",
    ChapterInformationTextKey: "10222",
    RisksRiskInfoTextKey: "10059",
    RisksConsequenceInfoTextKey: "10060",
    RiskAnalisysAndActionAnalisysInfoTextKey: "10062",
    RiskAnalisysAndActionTasksInfoTextKey: "10278",
    RiskAnalisysAndActionTaskInfoTextKey: "10063"
  },
  equalTreatmentPlanStatus: {
    Name: "equalTreatmentPlanStatus",
    HeaderTextKey: "10260",
    ChapterInformationTextKey: "10261",
  },
  riskActionsAndFollowUp: {
    Name: "riskActionsAndFollowUp",
    HeaderTextKey: "10258",
    ChapterInformationTextKey: "10259",
  },
  equalTreatmentPlanReport: {
    Name: "equalTreatmentPlan",
    HeaderTextKey: "10262",
    ChapterInformationTextKey: "10263",
  },
};

export const ChapterSectionStepTypes = {
  introduction: {
    welcome: {
      value: "welcome",
      displayValue: "welcome",
      isRequired: true,
      hasDbStep: false
    },
    introduction: {
      value: "introduction",
      displayValue: "introduction",
      isRequired: true,
      hasDbStep: false
    },
    material: {
      value: "material",
      displayValue: "Stödmaterial",
      isRequired: true,
      hasDbStep: true
    },
    settings: {
      value: "settings",
      displayValue: "Inställningar",
      isRequired: true,
      hasDbStep: false
    },
  },
  userChangableChapters: {
    risks: {
      value: "risks",
      displayValue: "1. Risker",
      dbObjName: "Risks",
      isRequired: false,
      hasDbStep: true
    },
    riskAnalysisAndAction: {
      value: "analisysAndAction",
      displayValue: "2. Analys och åtgärd",
      isRequired: false,
      dbObjName: "AnalisysAndAction",
      hasDbStep: true
    },
    summery: {
      value: "summery",
      displayValue: "3. Åtgärder",
      isRequired: false,
      hasDbStep: false
    },
  },
  equalTreatmentPlan: {
    riskActionsAndFollowUp: {
      value: "riskActionsAndFollowUp",
      displayValue: "Åtgärder och uppföljning",
      isRequired: true,
      hasDbStep: false
    },
    equalTreatmentPlanStatus: {
      value: "status",
      displayValue: "Status",
      isRequired: true,
      hasDbStep: false
    },
    equalTreatmentPlanReport: {
      value: "equalTreatmentPlanReport",
      displayValue: "Likabehandlingsplan",
      isRequired: true,
      hasDbStep: false
    },
  },
};