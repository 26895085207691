/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer(
    $input: CreateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    createCustomer(input: $input, condition: $condition) {
      id
      name
      description
      image
      responsible
      consultatManagerId
      invoiceAdress1
      invoiceAdress2
      invoicePostalCode
      invoiceCity
      CustomerSubscriptions {
        items {
          id
          customerId
          startDate
          endDate
          serviceId
          Service {
            id
            serviceType
            createdById
            changedById
            createdAt
            updatedAt
          }
          createdById
          changedById
          createdAt
          updatedAt
        }
        nextToken
      }
      Sections {
        items {
          id
          customerId
          sectionType
          Steps {
            items {
              id
              sectionId
              done
              stepType
              Files {
                items {
                  id
                  stepId
                  fileName
                  path
                  order
                  createdById
                  changedById
                  createdAt
                  updatedAt
                }
                nextToken
              }
              Documentation {
                items {
                  id
                  stepId
                  conclusion
                  createdById
                  changedById
                  createdAt
                  updatedAt
                }
                nextToken
              }
              MeasuresAndFollowUp {
                items {
                  id
                  stepId
                  measure
                  preformedBy
                  lastFollowUpDate
                  followUpDate
                  ongoing
                  createdById
                  changedById
                  createdAt
                  updatedAt
                }
                nextToken
              }
              Survey {
                items {
                  id
                  stepId
                  name
                  checked
                  order
                  createdById
                  changedById
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdById
              changedById
              createdAt
              updatedAt
            }
            nextToken
          }
          active
          createdById
          changedById
          createdAt
          updatedAt
        }
        nextToken
      }
      createdById
      changedById
      createdAt
      updatedAt
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer(
    $input: UpdateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    updateCustomer(input: $input, condition: $condition) {
      id
      name
      description
      image
      responsible
      consultatManagerId
      invoiceAdress1
      invoiceAdress2
      invoicePostalCode
      invoiceCity
      CustomerSubscriptions {
        items {
          id
          customerId
          startDate
          endDate
          serviceId
          Service {
            id
            serviceType
            createdById
            changedById
            createdAt
            updatedAt
          }
          createdById
          changedById
          createdAt
          updatedAt
        }
        nextToken
      }
      Sections {
        items {
          id
          customerId
          sectionType
          Steps {
            items {
              id
              sectionId
              done
              stepType
              Files {
                items {
                  id
                  stepId
                  fileName
                  path
                  order
                  createdById
                  changedById
                  createdAt
                  updatedAt
                }
                nextToken
              }
              Documentation {
                items {
                  id
                  stepId
                  conclusion
                  createdById
                  changedById
                  createdAt
                  updatedAt
                }
                nextToken
              }
              MeasuresAndFollowUp {
                items {
                  id
                  stepId
                  measure
                  preformedBy
                  lastFollowUpDate
                  followUpDate
                  ongoing
                  createdById
                  changedById
                  createdAt
                  updatedAt
                }
                nextToken
              }
              Survey {
                items {
                  id
                  stepId
                  name
                  checked
                  order
                  createdById
                  changedById
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdById
              changedById
              createdAt
              updatedAt
            }
            nextToken
          }
          active
          createdById
          changedById
          createdAt
          updatedAt
        }
        nextToken
      }
      createdById
      changedById
      createdAt
      updatedAt
    }
  }
`;
export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer(
    $input: DeleteCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    deleteCustomer(input: $input, condition: $condition) {
      id
      name
      description
      image
      responsible
      consultatManagerId
      invoiceAdress1
      invoiceAdress2
      invoicePostalCode
      invoiceCity
      CustomerSubscriptions {
        items {
          id
          customerId
          startDate
          endDate
          serviceId
          Service {
            id
            serviceType
            createdById
            changedById
            createdAt
            updatedAt
          }
          createdById
          changedById
          createdAt
          updatedAt
        }
        nextToken
      }
      Sections {
        items {
          id
          customerId
          sectionType
          Steps {
            items {
              id
              sectionId
              done
              stepType
              Files {
                items {
                  id
                  stepId
                  fileName
                  path
                  order
                  createdById
                  changedById
                  createdAt
                  updatedAt
                }
                nextToken
              }
              Documentation {
                items {
                  id
                  stepId
                  conclusion
                  createdById
                  changedById
                  createdAt
                  updatedAt
                }
                nextToken
              }
              MeasuresAndFollowUp {
                items {
                  id
                  stepId
                  measure
                  preformedBy
                  lastFollowUpDate
                  followUpDate
                  ongoing
                  createdById
                  changedById
                  createdAt
                  updatedAt
                }
                nextToken
              }
              Survey {
                items {
                  id
                  stepId
                  name
                  checked
                  order
                  createdById
                  changedById
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdById
              changedById
              createdAt
              updatedAt
            }
            nextToken
          }
          active
          createdById
          changedById
          createdAt
          updatedAt
        }
        nextToken
      }
      createdById
      changedById
      createdAt
      updatedAt
    }
  }
`;
export const createCustomerSubscription = /* GraphQL */ `
  mutation CreateCustomerSubscription(
    $input: CreateCustomerSubscriptionInput!
    $condition: ModelCustomerSubscriptionConditionInput
  ) {
    createCustomerSubscription(input: $input, condition: $condition) {
      id
      customerId
      startDate
      endDate
      serviceId
      Service {
        id
        serviceType
        createdById
        changedById
        createdAt
        updatedAt
      }
      createdById
      changedById
      createdAt
      updatedAt
    }
  }
`;
export const updateCustomerSubscription = /* GraphQL */ `
  mutation UpdateCustomerSubscription(
    $input: UpdateCustomerSubscriptionInput!
    $condition: ModelCustomerSubscriptionConditionInput
  ) {
    updateCustomerSubscription(input: $input, condition: $condition) {
      id
      customerId
      startDate
      endDate
      serviceId
      Service {
        id
        serviceType
        createdById
        changedById
        createdAt
        updatedAt
      }
      createdById
      changedById
      createdAt
      updatedAt
    }
  }
`;
export const deleteCustomerSubscription = /* GraphQL */ `
  mutation DeleteCustomerSubscription(
    $input: DeleteCustomerSubscriptionInput!
    $condition: ModelCustomerSubscriptionConditionInput
  ) {
    deleteCustomerSubscription(input: $input, condition: $condition) {
      id
      customerId
      startDate
      endDate
      serviceId
      Service {
        id
        serviceType
        createdById
        changedById
        createdAt
        updatedAt
      }
      createdById
      changedById
      createdAt
      updatedAt
    }
  }
`;
export const createService = /* GraphQL */ `
  mutation CreateService(
    $input: CreateServiceInput!
    $condition: ModelServiceConditionInput
  ) {
    createService(input: $input, condition: $condition) {
      id
      serviceType
      createdById
      changedById
      createdAt
      updatedAt
    }
  }
`;
export const updateService = /* GraphQL */ `
  mutation UpdateService(
    $input: UpdateServiceInput!
    $condition: ModelServiceConditionInput
  ) {
    updateService(input: $input, condition: $condition) {
      id
      serviceType
      createdById
      changedById
      createdAt
      updatedAt
    }
  }
`;
export const deleteService = /* GraphQL */ `
  mutation DeleteService(
    $input: DeleteServiceInput!
    $condition: ModelServiceConditionInput
  ) {
    deleteService(input: $input, condition: $condition) {
      id
      serviceType
      createdById
      changedById
      createdAt
      updatedAt
    }
  }
`;
export const createSection = /* GraphQL */ `
  mutation CreateSection(
    $input: CreateSectionInput!
    $condition: ModelSectionConditionInput
  ) {
    createSection(input: $input, condition: $condition) {
      id
      customerId
      sectionType
      Steps {
        items {
          id
          sectionId
          done
          stepType
          Files {
            items {
              id
              stepId
              fileName
              path
              order
              createdById
              changedById
              createdAt
              updatedAt
            }
            nextToken
          }
          Documentation {
            items {
              id
              stepId
              conclusion
              createdById
              changedById
              createdAt
              updatedAt
            }
            nextToken
          }
          MeasuresAndFollowUp {
            items {
              id
              stepId
              measure
              preformedBy
              lastFollowUpDate
              followUpDate
              ongoing
              createdById
              changedById
              createdAt
              updatedAt
            }
            nextToken
          }
          Survey {
            items {
              id
              stepId
              name
              checked
              order
              createdById
              changedById
              createdAt
              updatedAt
            }
            nextToken
          }
          createdById
          changedById
          createdAt
          updatedAt
        }
        nextToken
      }
      active
      createdById
      changedById
      createdAt
      updatedAt
    }
  }
`;
export const updateSection = /* GraphQL */ `
  mutation UpdateSection(
    $input: UpdateSectionInput!
    $condition: ModelSectionConditionInput
  ) {
    updateSection(input: $input, condition: $condition) {
      id
      customerId
      sectionType
      Steps {
        items {
          id
          sectionId
          done
          stepType
          Files {
            items {
              id
              stepId
              fileName
              path
              order
              createdById
              changedById
              createdAt
              updatedAt
            }
            nextToken
          }
          Documentation {
            items {
              id
              stepId
              conclusion
              createdById
              changedById
              createdAt
              updatedAt
            }
            nextToken
          }
          MeasuresAndFollowUp {
            items {
              id
              stepId
              measure
              preformedBy
              lastFollowUpDate
              followUpDate
              ongoing
              createdById
              changedById
              createdAt
              updatedAt
            }
            nextToken
          }
          Survey {
            items {
              id
              stepId
              name
              checked
              order
              createdById
              changedById
              createdAt
              updatedAt
            }
            nextToken
          }
          createdById
          changedById
          createdAt
          updatedAt
        }
        nextToken
      }
      active
      createdById
      changedById
      createdAt
      updatedAt
    }
  }
`;
export const deleteSection = /* GraphQL */ `
  mutation DeleteSection(
    $input: DeleteSectionInput!
    $condition: ModelSectionConditionInput
  ) {
    deleteSection(input: $input, condition: $condition) {
      id
      customerId
      sectionType
      Steps {
        items {
          id
          sectionId
          done
          stepType
          Files {
            items {
              id
              stepId
              fileName
              path
              order
              createdById
              changedById
              createdAt
              updatedAt
            }
            nextToken
          }
          Documentation {
            items {
              id
              stepId
              conclusion
              createdById
              changedById
              createdAt
              updatedAt
            }
            nextToken
          }
          MeasuresAndFollowUp {
            items {
              id
              stepId
              measure
              preformedBy
              lastFollowUpDate
              followUpDate
              ongoing
              createdById
              changedById
              createdAt
              updatedAt
            }
            nextToken
          }
          Survey {
            items {
              id
              stepId
              name
              checked
              order
              createdById
              changedById
              createdAt
              updatedAt
            }
            nextToken
          }
          createdById
          changedById
          createdAt
          updatedAt
        }
        nextToken
      }
      active
      createdById
      changedById
      createdAt
      updatedAt
    }
  }
`;
export const createStep = /* GraphQL */ `
  mutation CreateStep(
    $input: CreateStepInput!
    $condition: ModelStepConditionInput
  ) {
    createStep(input: $input, condition: $condition) {
      id
      sectionId
      done
      stepType
      Files {
        items {
          id
          stepId
          fileName
          path
          order
          createdById
          changedById
          createdAt
          updatedAt
        }
        nextToken
      }
      Documentation {
        items {
          id
          stepId
          conclusion
          createdById
          changedById
          createdAt
          updatedAt
        }
        nextToken
      }
      MeasuresAndFollowUp {
        items {
          id
          stepId
          measure
          preformedBy
          lastFollowUpDate
          followUpDate
          ongoing
          createdById
          changedById
          createdAt
          updatedAt
        }
        nextToken
      }
      Survey {
        items {
          id
          stepId
          name
          checked
          order
          createdById
          changedById
          createdAt
          updatedAt
        }
        nextToken
      }
      createdById
      changedById
      createdAt
      updatedAt
    }
  }
`;
export const updateStep = /* GraphQL */ `
  mutation UpdateStep(
    $input: UpdateStepInput!
    $condition: ModelStepConditionInput
  ) {
    updateStep(input: $input, condition: $condition) {
      id
      sectionId
      done
      stepType
      Files {
        items {
          id
          stepId
          fileName
          path
          order
          createdById
          changedById
          createdAt
          updatedAt
        }
        nextToken
      }
      Documentation {
        items {
          id
          stepId
          conclusion
          createdById
          changedById
          createdAt
          updatedAt
        }
        nextToken
      }
      MeasuresAndFollowUp {
        items {
          id
          stepId
          measure
          preformedBy
          lastFollowUpDate
          followUpDate
          ongoing
          createdById
          changedById
          createdAt
          updatedAt
        }
        nextToken
      }
      Survey {
        items {
          id
          stepId
          name
          checked
          order
          createdById
          changedById
          createdAt
          updatedAt
        }
        nextToken
      }
      createdById
      changedById
      createdAt
      updatedAt
    }
  }
`;
export const deleteStep = /* GraphQL */ `
  mutation DeleteStep(
    $input: DeleteStepInput!
    $condition: ModelStepConditionInput
  ) {
    deleteStep(input: $input, condition: $condition) {
      id
      sectionId
      done
      stepType
      Files {
        items {
          id
          stepId
          fileName
          path
          order
          createdById
          changedById
          createdAt
          updatedAt
        }
        nextToken
      }
      Documentation {
        items {
          id
          stepId
          conclusion
          createdById
          changedById
          createdAt
          updatedAt
        }
        nextToken
      }
      MeasuresAndFollowUp {
        items {
          id
          stepId
          measure
          preformedBy
          lastFollowUpDate
          followUpDate
          ongoing
          createdById
          changedById
          createdAt
          updatedAt
        }
        nextToken
      }
      Survey {
        items {
          id
          stepId
          name
          checked
          order
          createdById
          changedById
          createdAt
          updatedAt
        }
        nextToken
      }
      createdById
      changedById
      createdAt
      updatedAt
    }
  }
`;
export const createDocumentation = /* GraphQL */ `
  mutation CreateDocumentation(
    $input: CreateDocumentationInput!
    $condition: ModelDocumentationConditionInput
  ) {
    createDocumentation(input: $input, condition: $condition) {
      id
      stepId
      conclusion
      createdById
      changedById
      createdAt
      updatedAt
    }
  }
`;
export const updateDocumentation = /* GraphQL */ `
  mutation UpdateDocumentation(
    $input: UpdateDocumentationInput!
    $condition: ModelDocumentationConditionInput
  ) {
    updateDocumentation(input: $input, condition: $condition) {
      id
      stepId
      conclusion
      createdById
      changedById
      createdAt
      updatedAt
    }
  }
`;
export const deleteDocumentation = /* GraphQL */ `
  mutation DeleteDocumentation(
    $input: DeleteDocumentationInput!
    $condition: ModelDocumentationConditionInput
  ) {
    deleteDocumentation(input: $input, condition: $condition) {
      id
      stepId
      conclusion
      createdById
      changedById
      createdAt
      updatedAt
    }
  }
`;
export const createMeasuresAndFollowUp = /* GraphQL */ `
  mutation CreateMeasuresAndFollowUp(
    $input: CreateMeasuresAndFollowUpInput!
    $condition: ModelMeasuresAndFollowUpConditionInput
  ) {
    createMeasuresAndFollowUp(input: $input, condition: $condition) {
      id
      stepId
      measure
      preformedBy
      lastFollowUpDate
      followUpDate
      ongoing
      createdById
      changedById
      createdAt
      updatedAt
    }
  }
`;
export const updateMeasuresAndFollowUp = /* GraphQL */ `
  mutation UpdateMeasuresAndFollowUp(
    $input: UpdateMeasuresAndFollowUpInput!
    $condition: ModelMeasuresAndFollowUpConditionInput
  ) {
    updateMeasuresAndFollowUp(input: $input, condition: $condition) {
      id
      stepId
      measure
      preformedBy
      lastFollowUpDate
      followUpDate
      ongoing
      createdById
      changedById
      createdAt
      updatedAt
    }
  }
`;
export const deleteMeasuresAndFollowUp = /* GraphQL */ `
  mutation DeleteMeasuresAndFollowUp(
    $input: DeleteMeasuresAndFollowUpInput!
    $condition: ModelMeasuresAndFollowUpConditionInput
  ) {
    deleteMeasuresAndFollowUp(input: $input, condition: $condition) {
      id
      stepId
      measure
      preformedBy
      lastFollowUpDate
      followUpDate
      ongoing
      createdById
      changedById
      createdAt
      updatedAt
    }
  }
`;
export const createSurvey = /* GraphQL */ `
  mutation CreateSurvey(
    $input: CreateSurveyInput!
    $condition: ModelSurveyConditionInput
  ) {
    createSurvey(input: $input, condition: $condition) {
      id
      stepId
      name
      checked
      order
      createdById
      changedById
      createdAt
      updatedAt
    }
  }
`;
export const updateSurvey = /* GraphQL */ `
  mutation UpdateSurvey(
    $input: UpdateSurveyInput!
    $condition: ModelSurveyConditionInput
  ) {
    updateSurvey(input: $input, condition: $condition) {
      id
      stepId
      name
      checked
      order
      createdById
      changedById
      createdAt
      updatedAt
    }
  }
`;
export const deleteSurvey = /* GraphQL */ `
  mutation DeleteSurvey(
    $input: DeleteSurveyInput!
    $condition: ModelSurveyConditionInput
  ) {
    deleteSurvey(input: $input, condition: $condition) {
      id
      stepId
      name
      checked
      order
      createdById
      changedById
      createdAt
      updatedAt
    }
  }
`;
export const createFile = /* GraphQL */ `
  mutation CreateFile(
    $input: CreateFileInput!
    $condition: ModelFileConditionInput
  ) {
    createFile(input: $input, condition: $condition) {
      id
      stepId
      fileName
      path
      order
      createdById
      changedById
      createdAt
      updatedAt
    }
  }
`;
export const updateFile = /* GraphQL */ `
  mutation UpdateFile(
    $input: UpdateFileInput!
    $condition: ModelFileConditionInput
  ) {
    updateFile(input: $input, condition: $condition) {
      id
      stepId
      fileName
      path
      order
      createdById
      changedById
      createdAt
      updatedAt
    }
  }
`;
export const deleteFile = /* GraphQL */ `
  mutation DeleteFile(
    $input: DeleteFileInput!
    $condition: ModelFileConditionInput
  ) {
    deleteFile(input: $input, condition: $condition) {
      id
      stepId
      fileName
      path
      order
      createdById
      changedById
      createdAt
      updatedAt
    }
  }
`;
export const createSurveyTemplate = /* GraphQL */ `
  mutation CreateSurveyTemplate(
    $input: CreateSurveyTemplateInput!
    $condition: ModelSurveyTemplateConditionInput
  ) {
    createSurveyTemplate(input: $input, condition: $condition) {
      id
      name
      order
      sectionType
      createdById
      changedById
      createdAt
      updatedAt
    }
  }
`;
export const updateSurveyTemplate = /* GraphQL */ `
  mutation UpdateSurveyTemplate(
    $input: UpdateSurveyTemplateInput!
    $condition: ModelSurveyTemplateConditionInput
  ) {
    updateSurveyTemplate(input: $input, condition: $condition) {
      id
      name
      order
      sectionType
      createdById
      changedById
      createdAt
      updatedAt
    }
  }
`;
export const deleteSurveyTemplate = /* GraphQL */ `
  mutation DeleteSurveyTemplate(
    $input: DeleteSurveyTemplateInput!
    $condition: ModelSurveyTemplateConditionInput
  ) {
    deleteSurveyTemplate(input: $input, condition: $condition) {
      id
      name
      order
      sectionType
      createdById
      changedById
      createdAt
      updatedAt
    }
  }
`;
export const createMaterials = /* GraphQL */ `
  mutation CreateMaterials(
    $input: CreateMaterialsInput!
    $condition: ModelMaterialsConditionInput
  ) {
    createMaterials(input: $input, condition: $condition) {
      id
      name
      path
      type
      order
      createdById
      changedById
      createdAt
      updatedAt
    }
  }
`;
export const updateMaterials = /* GraphQL */ `
  mutation UpdateMaterials(
    $input: UpdateMaterialsInput!
    $condition: ModelMaterialsConditionInput
  ) {
    updateMaterials(input: $input, condition: $condition) {
      id
      name
      path
      type
      order
      createdById
      changedById
      createdAt
      updatedAt
    }
  }
`;
export const deleteMaterials = /* GraphQL */ `
  mutation DeleteMaterials(
    $input: DeleteMaterialsInput!
    $condition: ModelMaterialsConditionInput
  ) {
    deleteMaterials(input: $input, condition: $condition) {
      id
      name
      path
      type
      order
      createdById
      changedById
      createdAt
      updatedAt
    }
  }
`;
export const createUserDataStorage = /* GraphQL */ `
  mutation CreateUserDataStorage(
    $input: CreateUserDataStorageInput!
    $condition: ModelUserDataStorageConditionInput
  ) {
    createUserDataStorage(input: $input, condition: $condition) {
      id
      data
      div
      createdById
      changedById
      createdAt
      updatedAt
    }
  }
`;
export const updateUserDataStorage = /* GraphQL */ `
  mutation UpdateUserDataStorage(
    $input: UpdateUserDataStorageInput!
    $condition: ModelUserDataStorageConditionInput
  ) {
    updateUserDataStorage(input: $input, condition: $condition) {
      id
      data
      div
      createdById
      changedById
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserDataStorage = /* GraphQL */ `
  mutation DeleteUserDataStorage(
    $input: DeleteUserDataStorageInput!
    $condition: ModelUserDataStorageConditionInput
  ) {
    deleteUserDataStorage(input: $input, condition: $condition) {
      id
      data
      div
      createdById
      changedById
      createdAt
      updatedAt
    }
  }
`;
