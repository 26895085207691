import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setAppStateSetting } from "Features/AppState/AppStateSlice";
import Loading from "Components/Loading";
import { GetDateOnly, GetDateString } from "Util/Utils";

const NoSubscription = () => {
  const appState = useSelector((state) => state.settings.appState);
  const dispatch = useDispatch();

  const [stateIsLoading, setStateIsLoading] = useState(true);

  dispatch( setAppStateSetting( {  title: "" } ));
  
  useEffect(() => {
    setStateIsLoading( false );
  }, []);

  const today = GetDateString(GetDateOnly());
  const newCustomer = appState.customer?.CustomerSubscriptions?.items?.length === 0 || false;

  //Allow any subscription with the correct date.
  let expired = false;
  let futureSubscription = null;
  let notStarted = false;

  let todayAsDate = new Date(today);
  let todayAsTime = todayAsDate.getTime();

  if( appState.customer !== null){
    for ( let customerSubscriptionItem of Object.values( appState.customer?.CustomerSubscriptions.items )) {
      const startDate = new Date(customerSubscriptionItem.startDate)
      const startDateAsTime = new Date(customerSubscriptionItem.startDate).getTime();
      const endDateAsTime = new Date(customerSubscriptionItem.endDate).getTime();
      
      if( todayAsTime >= startDateAsTime && todayAsTime <= endDateAsTime ) {
        break;
      } else if( todayAsTime >= startDateAsTime && todayAsTime > endDateAsTime ) {
        expired = true;
      } else if( todayAsTime < startDateAsTime && todayAsTime < endDateAsTime ) {
        futureSubscription = startDate;
        notStarted = true;
      }
    }
  }

  if (stateIsLoading) {
    return <Loading />;
  } else {
    return (
      <div className="outerContainerForInnerContent">
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%", justifyContent: "center", flex: "1", height: "100%" }}>
          {newCustomer && (
            <>
              <h3>Prenumeration ej tecknad</h3>
              <div style={{ width: "100%", textAlign: "center" }}><p>Kontakta din administratör eller info@likabehandlingsplan.se för att aktivera en ny eller förlänga en prenumeration.</p></div>
            </>
          )}
          {expired && (
            <>
              <h3>Prenumeration har gått ut</h3>
              <div style={{ width: "100%", textAlign: "center" }}><p>Kontakta din administratör eller info@likabehandlingsplan.se för att aktivera en ny eller förlänga en prenumeration.</p></div>
            </>
          )}
          {notStarted && (
            <>
              <h3>Prenumeration har inte börjat än</h3>
              <div style={{ width: "100%", textAlign: "center" }}><p>Din prenumeration börjar {GetDateString(futureSubscription)}.</p></div>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default NoSubscription;