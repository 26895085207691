//items är en array av databasresultat
//ex: [Customer1,Customer2...]
//returnerar en array av Models med datat från items.
export function FillModel(Model, items) {
  let output = [];
  //Översätt datat från DB att passa i modellen.
  for (let item of items) {
    let model = JSON.parse(JSON.stringify(Model));
    for (let property of Model) {
      if (property.key in item) {
        let prop = model.find((prop) => prop.key === property.key);
        prop.value = item[property.key];
      }
    }
    output.push({ id: item.id, model });
  }
  return output;
}
