import { AuthenticatedUrls } from "Routes/Urls";
import { Roles, RolesConsultantAndCustomerAdmin, SectionTypes, UserStates } from "Util/Const";
import { SectionModels } from "Models/SectionModels";
import Types from "Models/Types";

export const CustomerModel = [
  { key: "name", value: "", label: "Namn", type: Types.text, mandatory: true, errorMessage: "Namn saknas", showInOverview: true },
  { key: "createdAt", value: "", label: "Skapad", type: Types.date, mandatory: false, errorMessage: "", showInOverview: true, showInCreate: false, showInEdit: false },
  { key: "description", value: "", label: "Beskrivning", type: Types.multilineText, mandatory: false, errorMessage: "", showInOverview: false },
  { key: "image", value: "", imageFile: null, removeImage: false, label: "Bild", type: Types.image, mandatory: false, showInOverview: true },
  {
    key: "responsible",
    value: "",
    label: "Ytterst ansvarig",
    type: Types.text,
    mandatory: true,
    errorMessage: "Ytterst ansvarig saknas",
    showInOverview: false,
  },
  { key: "labelYearFromAndToDate", label: "Ange startår för Likabehandlingsplan", type: Types.label, mandatory: true, errorMessage: "Startår för Likabehandlingsplan saknas", showInOverview: false, showInCreate: true, showInEdit: false  },
  { key: "yearFromAndToDate", value: "", label: "Från och till datum", type: Types.yearFromAndToDate, mandatory: true, errorMessage: "Från och till datum för startår för Likabehandlingsplan saknas", showInOverview: false, showInCreate: true, showInEdit: false },
  { key: "labelInvoice", label: "Fakturaadress", type: Types.label, mandatory: true, errorMessage: "Fakturaadress saknas", showInOverview: false },
  { key: "invoiceAdress1", value: "", label: "Adress rad 1", type: Types.text, mandatory: true, errorMessage: "Fakturaadress saknas", showInOverview: false },
  { key: "invoiceAdress2", value: "", label: "Adress rad 2 (valfri)", type: Types.text, mandatory: false, errorMessage: "", showInOverview: false },
  { key: "invoicePostalCode", value: "", label: "Postnummer", type: Types.text, mandatory: true, errorMessage: "Postnummer saknas", showInOverview: false },
  { key: "invoiceCity", value: "", label: "Ort", type: Types.text, mandatory: true, errorMessage: "Stad saknas", showInOverview: false },

  {
    key: "CustomerSubscriptionsLink",
    value: AuthenticatedUrls.admin.subPages.customerSubscription,
    type: Types.linkToChild,
    showInOverview: true,
  },
  {
    key: "UsersLink",
    value: AuthenticatedUrls.admin.subPages.users,
    type: Types.linkToChild,
    showInOverview: true,
  },
];

//Has no dynamoDb table
//Cognito representation
export const UserModel = [
  { key: "customerId", value: "", label: "", type: Types.parentId, mandatory: true, errorMessage: "customerId saknas", showInOverview: false },
  { key: "email", value: "", label: "Email", type: Types.text, mandatory: true, errorMessage: "Email saknas", showInOverview: true },
  { key: "givenName", value: "", label: "Förnamn", type: Types.text, mandatory: true, errorMessage: "Förnamn saknas", showInOverview: false },
  { key: "familyName", value: "", label: "Efternamn", type: Types.text, mandatory: true, errorMessage: "Efternamn saknas", showInOverview: false },
  { key: "title", value: "", label: "Titel", type: Types.text, mandatory: false, errorMessage: "Titel saknas", showInOverview: true },
  {
    key: "role",
    value: "",
    label: "Roll",
    type: Types.multichoice,

    defaultValue: Roles.customer.value,
    choices: Object.values(Roles),
    choiceKey: "value",
    choiceLabel: "displayValue",
    mandatory: true,
    errorMessage: "Roll saknas",
    showInOverview: true,
  },
  {
    key: "enabled",
    value: "",
    label: "Aktiverad",
    type: Types.multichoice,

    defaultValue: UserStates.enabled.value,
    choices: Object.values(UserStates),
    choiceKey: "value",
    choiceLabel: "displayValue",
    mandatory: true,
    errorMessage: "Användarstatus saknas",
    showInOverview: true,
    showInCreate: false,
    showInEdit: true,
  },
];

//Has no dynamoDb table
//Cognito representation
export const UserModelConsultantAndCustomerAdmin = [
  { key: "customerId", value: "", label: "", type: Types.parentId, mandatory: true, errorMessage: "customerId saknas", showInOverview: false },
  { key: "email", value: "", label: "Email", type: Types.text, mandatory: true, errorMessage: "Email saknas", showInOverview: true },
  { key: "givenName", value: "", label: "Förnamn", type: Types.text, mandatory: true, errorMessage: "Förnamn saknas", showInOverview: false },
  { key: "familyName", value: "", label: "Efternamn", type: Types.text, mandatory: true, errorMessage: "Efternamn saknas", showInOverview: false },
  { key: "title", value: "", label: "Titel", type: Types.text, mandatory: false, errorMessage: "Titel saknas", showInOverview: true },
  {
    key: "role",
    value: "",
    label: "Roll",
    type: Types.multichoice,

    defaultValue: RolesConsultantAndCustomerAdmin.customer.value,
    choices: Object.values(RolesConsultantAndCustomerAdmin),
    choiceKey: "value",
    choiceLabel: "displayValue",
    mandatory: true,
    errorMessage: "Roll saknas",
    showInOverview: true,
  },
  {
    key: "enabled",
    value: "",
    label: "Aktiverad",
    type: Types.multichoice,

    defaultValue: UserStates.enabled.value,
    choices: Object.values(UserStates),
    choiceKey: "value",
    choiceLabel: "displayValue",
    mandatory: true,
    errorMessage: "Användarstatus saknas",
    showInOverview: true,
    editOnly: true,
  },
];

//Same as above but for profile editing.
export const UserModelProfile = [
  { key: "email", value: "", label: "Email", type: Types.text, mandatory: true, errorMessage: "Email saknas", showInOverview: true },
  { key: "givenName", value: "", label: "Förnamn", type: Types.text, mandatory: true, errorMessage: "Förnamn saknas", showInOverview: false },
  { key: "familyName", value: "", label: "Efternamn", type: Types.text, mandatory: true, errorMessage: "Efternamn saknas", showInOverview: false },
  { key: "title", value: "", label: "Titel", type: Types.text, mandatory: false, errorMessage: "Titel saknas", showInOverview: true },
];

export const CustomerSubscriptionModel = [
  { key: "customerId", value: "", label: "", type: Types.parentId, mandatory: true, errorMessage: "KundId saknas", showInOverview: false },
  { key: "startDate", value: "", label: "Startdatum", type: Types.date, mandatory: true, errorMessage: "Startdatum saknas", showInOverview: true },
  { key: "endDate", value: "", label: "Slutdatum", type: Types.date, mandatory: true, errorMessage: "Slutdatum saknas", showInOverview: true },
  //Note: These are commented now since they will be implemented in a future version.

  // {
  //   key: "serviceId",
  //   value: "",
  //   label: "Tjänst",
  //   type: Types.choiceFromTable,
  //   choiceKey: "serviceType",
  //   choicesListQuerry: listServices,
  //   choicesGetQuerry: getService,
  //   mandatory: true,
  //   errorMessage: "Tjänst saknas",
  //   showInOverview: true,
  // },
];

export const ServiceModel = [
  {
    key: "serviceType",
    value: "",
    defaultValue: "Alla tjänster",
    label: "Tjänsttyp",
    type: Types.text,
    mandatory: true,
    errorMessage: "Typ saknas",
    showInOverview: true,
  },
];

export const SurveyTemplateModel = [
  { key: "name", value: "", label: "Namn", type: Types.text, mandatory: true, errorMessage: "Namn saknas", showInOverview: true },
  { key: "order", value: "0", label: "Ordning", type: Types.order, mandatory: false, errorMessage: "", showInOverview: false, showInEdit: false },
  {
    key: "sectionType",
    value: "",
    label: "Sektion",
    type: Types.multichoice,

    defaultValue: SectionTypes.arbetsforhallanden,
    choices: Object.values(SectionModels)
      .filter((x) => 
        (x.value !== SectionTypes.introduktion
          && x.value !== SectionTypes.likabehandlingsplan))
      .map((x) => ({ value: x.sectionType, displayValue: x.name })),
    choiceKey: "value",
    choiceLabel: "displayValue",
    mandatory: true,
    errorMessage: "Sektion saknas",
    showInOverview: false,
  },
];