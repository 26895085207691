import React, { useState, useEffect, memo } from 'react';
import { useSelector } from 'react-redux';
import { PDFViewer } from '@react-pdf/renderer';
import {
  useLazyGetRiskAnalisysAndActionYearsForLoggedInCustomerQuery,
} from 'API/UserDataStorageAPI';
import {
  getActiveRiskAnalisysAndActionYear
} from 'API/SelectFromUserDataStorageItems';
import Loading from "Components/Loading";
import EqualTreatmentPlanPdf from "Views/Chapters/Sections/EqualTreatmentPlan/EqualTreatmentPlanPdf";

const EqualTreatmentPlanPreview = () => {
  const appState = useSelector((state) => state.settings.appState);

  const [ lazyGetRiskAnalisysAndActionYearsForLoggedInCustomer ] = useLazyGetRiskAnalisysAndActionYearsForLoggedInCustomerQuery();

  const [ statePageContent, setStatePageContent ]  = useState(null); 

  useEffect(() => {
    async function asyncUseEffect(){
      const riskAnalisysAndActionYearsLazy = await lazyGetRiskAnalisysAndActionYearsForLoggedInCustomer().unwrap();

      const pageContent = 
        <>
          <div className="sectionTopPadding"></div>
          <div className="outerContainerForAccordion">
            <div className="chapterStep">
              {/* <div className="chapterStepTitle">
                <h2>Likabehandlingsplan förhandsgranskning</h2>
              </div> */}
              <div className="chapterStepContent">
                <div className="previewPage">
                  <div>
                    <PDFViewer style={{width: "100%", height: "800px"}}>
                      <EqualTreatmentPlanPdf riskAnalisysAndActionYear={ getActiveRiskAnalisysAndActionYear(riskAnalisysAndActionYearsLazy) } responsible={ appState.customer.responsible } />
                    </PDFViewer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>;

      setStatePageContent( pageContent );
    }

    //Scroll to the top of the page.
    window.scrollTo(0, 0);

    asyncUseEffect();
    //setStateIsLoading( false );
  }, []);

  if ( statePageContent === null ) {
    return <Loading />;
  } else {
    return (
      statePageContent
    );
  }
}

export default EqualTreatmentPlanPreview;