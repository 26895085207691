import { createTheme, adaptV4Theme } from '@mui/material/styles';

const theme = createTheme(adaptV4Theme({
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 6,
        fontFamily: "Manrope",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "24px",
      },
      contained: {
        backgroundColor: "var(--colorButtonBG) !important",
        fontFamily: "Manrope",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "24px",
        color: "#FFFFFF",
        height: "48px",  
        '&:hover': {
          backgroundColor: "var(--colorButtonHoverBG) !important",
          color: "var(--colorButtonForgroundHover) !important",
        },
      }
    },
    MuiAccordionSummary: {
      root: {
        display: "flex",
        padding: "0px 16px",
        transition: "min-height 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        borderBottomColor: "#E1E1E1 !important",
        borderBottomStyle: "solid !important",
        borderBottomWidth: "1px !important",
        borderRadius: "0 !important",
        height: "64px !important",
        minHeight: "64px !important",
      },
      expandIcon: {
        backgroundColor: "var(--colorButtonBG) !important",
        color: "var(--colorButtonForground) !important",
        '&:hover': {
          backgroundColor: "var(--colorButtonHoverBG) !important",
          color: "var(--colorButtonForgroundHover) !important",
        },
      },
    },
    MuiTableRow: {
      root: {
        '&:nth-of-type(even)': {
          backgroundColor: "#FFFFFF",
          border: "none",
        },
        '&:nth-of-type(odd)': {
          backgroundColor: "#F4F6F9",
          border: "none",
        },
      },
    },
    MuiTableCell: {
      root: {
        border: "none !important",
        fontFamily: "Manrope",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "24px",
      },
    },
    MuiSvgIcon:{
      root: {
        fontSize: "1.5rem",
      }
    },
  },
}));

export default theme