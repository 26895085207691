import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setAppStateSetting } from 'Features/AppState/AppStateSlice';
import contactImage from "Assets/Images/contactImage.jpg";
import Loading from "Components/Loading";

const Contact = () => {
  const dispatch = useDispatch();

  const [stateIsLoading, setStateIsLoading] = useState(true);

  dispatch ( setAppStateSetting( {  title: "Kontakt" } ) );
  
  useEffect(() => {
    //Scroll to the top of the page.
    window.scrollTo(0, 0);

    setStateIsLoading( false );
  }, []);

  if ( stateIsLoading ) {
    return <Loading />;
  } else {
    return (
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%", justifyContent: "center", flex: "1", height: "100%" }}>
        <img style={{ width: "300px", height: "auto", borderRadius: "300px" }} src={contactImage} alt="Bild saknas" />
        <h3>VD</h3>
        <h1>Elin Bjuhr</h1>
        <h2>Din HR-partner AB</h2>

        <p>
          <a href="mailto:elin@dinhrpartner.se">elin@dinhrpartner.se</a>
        </p>
        <div style={{ width: "100%", textAlign: "center" }}>
          Din HR-partner AB är ett Umeåbaserat konsultbolag. Företaget har funnits sedan 2011 och tillhandahåller tjänster inom HR-området. Vi fungerar som
          extern personalavdelning till ett 100-tal företag och har även utvecklat webbtjänsten Policyfabriken.se
        </div>
      </div>
    );
  }
}

export default Contact;
