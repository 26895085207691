import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setAppStateSetting } from "Features/AppState/AppStateSlice";
import { Delete, Warning } from "@mui/icons-material";
import Loading from "Components/Loading";
import { AdminDeleteUser } from "Util/AdminQuerries";
import { getDisabledUsers } from "Util/DisabledUsers";
import { PopupConfirm, PopupError } from "Util/Utils";
import { Table, TableHead, TableBody, TableRow, TableCell } from "@mui/material";

const DisabledUsersOverview = () => {
  const dispatch = useDispatch();

  const [stateIsLoading, setStateIsLoading] = useState(true);
  const [stateDisabledUsers, setStateDisabledUsers] = useState( [] );

  useEffect(() => {
    async function asyncUseEffect(){
      const users = await getDisabledUsers();

      dispatch( setAppStateSetting( {  title: "Översikt avaktiverade användare" } ));

      setStateDisabledUsers( users );

      //Scroll to the top of the page.
      window.scrollTo(0, 0);

      setStateIsLoading( false );
    }

    asyncUseEffect();
  }, []);

  async function deleteUser(user) {
    try {
      setStateIsLoading( true );

      await AdminDeleteUser(user.id);
      //todo: remove userid from all that the user has changed/created?
      const users = await getDisabledUsers();
      setStateIsLoading( false )
      setStateDisabledUsers( users );

    } catch (err) {
      console.error(err);
      PopupError("Det gick inte att ta bort användaren");
      setStateIsLoading( false );
    }
  }

  if ( stateIsLoading ) {
    return <Loading />;
  } else {
    return (
      <div className="adminInnerContainer">
        { stateDisabledUsers.length > 0 && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>E-post</TableCell>
                <TableCell>Antal dagar sedan användarens togs bort</TableCell>
                <TableCell></TableCell>
                <TableCell>Ta bort</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { stateDisabledUsers
                .sort((a, b) => b.days - a.days)
                .map((user, idx) => (
                  <TableRow key={idx}>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.days}</TableCell>
                    <TableCell>{user.warning ? <Warning /> : null}</TableCell>
                    <TableCell>
                      <Delete className="iconsDelete" onClick={() => PopupConfirm("Vill du ta bort användaren?", async () => await deleteUser(user))} />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        )}
        { stateDisabledUsers.length === 0 && <p>Det finns inga avaktiverade användare.</p> }
      </div>
    );
  }
}

export default DisabledUsersOverview;