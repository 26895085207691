import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setAppStateSetting } from "Features/AppState/AppStateSlice";
import { Link } from "react-router-dom";
import Types from "Models/Types";
import { AdminMenuUrls } from "Routes/Urls";
import CustomOutput from "Components/CustomOutput";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const PropertyTable = ( props ) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch( setAppStateSetting( {  title: props.header } ));
  }, []);

  let model = props.model;
  //Add the parent ID if it exists.
  let parentIdProperty = model.find((m) => m.type === Types.parentId);
  if (props?.parentId != null && parentIdProperty != null) {
    parentIdProperty.value = props.parentId;
  } else if (props?.parentId == null && parentIdProperty != null) {
    console.error("Invalid parent!");
  }

  //Cross update properties for all enteties
  let data = props.data;
  for (let entity of data) {
    entity = entity.model;
  }
  //If there is an order property, sort data based on that order.
  if (Object.values(model).find((m) => m.type === Types.order) != null) {
    data = data.sort((a, b) =>
      a.model.find((p) => p.type === Types.order).value > b.model.find((p) => p.type === Types.order).value
        ? 1
        : a.model.find((p) => p.type === Types.order).value < b.model.find((p) => p.type === Types.order).value
        ? -1
        : 0
    );
  }

  return (
    <div>
      {/* Bakåtknapp för de länkar som ej är i menyn/toppen */}
      {Object.values(AdminMenuUrls).find((url) => url.path === location.pathname) == null && (
        <div>
          <Button variant="contained" onClick={() => navigate(-1)} style={{ marginRight: "20px" }}>
            Tillbaka
          </Button>
        </div>
      )}
      <div>
        <Button
          variant="contained"
          onClick={ () => navigate(props.editUrl, { state: { model: props.model } }) }
        >
          Lägg till
        </Button>
      </div>
      <Table aria-label="simple table">
        <TableHead></TableHead>
        <TableBody>
          {data.map((entity) => (
            <TableRow key={entity.id}>
              {props.model
                .filter((x) => x.showInOverview === true)
                .map((property) => property.key)
                .map((key) => (
                  <TableCell style={{ padding: "5px !important" }} key={entity.id + "-" + key} >
                    <CustomOutput property={entity.model.find((property) => property.key === key)} id={entity.id} />
                  </TableCell>
                ))}
              <TableCell style={{ padding: "5px !important" }}>
                <Button
                  variant="contained"
                  // color="default"
                  fullWidth
                  onClick={ () => navigate(props.editUrl, { state: { model: props.model, data: entity.model, id: entity.id } }) }
                >
                  Redigera
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

export default PropertyTable;
