import { AuthenticatedUrls } from "Routes/Urls";
import { ChapterSectionTypes, Steps } from "Util/Const";

export const SectionModels = {
  introduction: {
    name: "Introduktion",
    chapterSectionType:ChapterSectionTypes.introduction,
    path: AuthenticatedUrls.content.subPages.introduction.path,
  },
  workingConditions: {
    name: "Arbetsförhållanden",
    chapterSectionType:ChapterSectionTypes.workingConditions,
    //path: AuthenticatedUrls.content.subPages.workingConditions.path,
    step: {
      [Steps.chapters.kartlaggning.value]: {
        defaultOptions: [
          {
            name: "Diskussion kring den organisatoriska miljön sett ur ett likabehandlingsperspektiv",
            checked: false,
            order: 1,
          },
          {
            name: "Granskning och analys av rutiner kring arbetsmiljöarbetet",
            checked: false,
            order: 2,
          },
          {
            name: "Föregående års jämställdhetsplan",
            checked: false,
            order: 3,
          },
          {
            name: "Granskning och diskussion kring ohälsotal",
            checked: false,
            order: 4,
          },
          {
            name: "Diskussion kring jargongen på arbetsplatsen och förekomst av sexistiska, diskriminerande eller på annat sätt exkluderande/nedsättande skämt",
            checked: false,
            order: 5,
          },
          {
            name: "Diskussion kring kunskapsnivåer för de som arbetar med eller på annat sätt ansvarar för arbetsmiljöfrågor",
            checked: false,
            order: 6,
          },
          {
            name: "Granskning av möjlighet att påverka arbetssituation, arbetstider och arbetstakt",
            checked: false,
            order: 7,
          },
          {
            name: "Diskussion kring den fysiska/praktiska miljön sett ur ett likabehandlingsperspektiv",
            checked: false,
            order: 8,
          },
          {
            name: "Diskussion kring den psykosociala miljön sett ur ett likabehandlingsperspektiv",
            checked: false,
            order: 9,
          },
          {
            name: "Granskning och analys av genomförd medarbetarenkät",
            checked: false,
            order: 10,
          },
          {
            name: "Granskning och diskussion kring arbetsskador och tillbud",
            checked: false,
            order: 11,
          },
          {
            name: "Föregående års likabehandlingsplan",
            checked: false,
            order: 12,
          },
        ],
      },
      [Steps.chapters.documentation.value]: {
        text: [
          "I detta moment diskuterar och analyserar ni de underlag ni bestämt att fokusera på från förberedelserna.",
          "<ol>" +
            "<li>Sett ur ett likabehandlingsperspektiv, hur kan arbetsmiljön förbättras?</li>" +
            "<li>Hittar ni några utvecklingsområden?</li>" +
            "<li>Vilka bakomliggande orsaker kan finnas till eventuella problemområden?</li>" +
            "<li>Dokumentera analysen i rutan nedan.</li>" +
            "</ol>",
        ],
        html: `
              <p>Diskutera och analysera ert underlag med stöd av följande frågeställningar:</p>
              <ol>
                <li>Sett ur ett likabehandlingsperspektiv, vilka riskområden i arbetsmiljön finns?</li>
                <li>Hittar ni några utvecklingsområden?</li>
                <li>Vilka bakomliggande orsaker kan finnas till eventuella problemområden?</li>             
              </ol>
              <p>Sammanfatta sedan er analys kring arbetsmiljö i rutan nedan.</p>
              `,
        exampleText: `
          <p>Exempel på analys:</p>
          <ul>
            <li>Vår skyddskommitté är väldigt homogen till sin sammansättning. Vi tror att det vore bra med en större mångfald i gruppen för att bättre kunna bevaka likabehandlingsfrågor i arbetsmiljöarbetet.</li>
            <li>Det förekommer en jargong på arbetsplatsen som vissa upplever som skämtsam och rolig men andra finner stötande.</li>
          </ul>
          `,
      },
      [Steps.chapters.atgard.value]: {
        description: "",
        text:[
          "I detta moment formulerar ni eventuella åtgärder som diskuterats fram från kartläggning och analys. ",
          "Exempel på åtgärder: Workshop kring attityder på arbetsplatsen eller dörröppnare till entré och toaletter. ",
        ],
        html: `
          <p>I detta moment formulerar ni eventuella åtgärder som diskuterats fram från kartläggning och analys.</p>
          <p>Exempel på åtgärder:</p>
          <ul>
            <li>Workshop kring attityder på arbetsplatsen</li>
            <li>Dörröppnare till entré och toaletter</li>
          </ul>
        `,
      },
    },
  },
  wagesAndEmploymentTerms: {
    name: "Löner och Anställningsvillkor",
    chapterSectionType:ChapterSectionTypes.wagesAndEmploymentTerms,
    //path: AuthenticatedUrls.content.subPages.wagesAndEmploymentTerms.path,
    step: {
      [Steps.chapters.kartlaggning.value]: {
        defaultOptions: [
          {
            name: "Granskning och analys av rutiner vid lönesättning och hur dessa efterlevs",
            checked: false,
            order: 1,
          },
          {
            name: "Genomlysning av anställningsförmåner och hur de regleras",
            checked: false,
            order: 2,
          },
          {
            name: "Genomlysning av lönesättande chefers kunskap för att kunna säkerställa objektiv och saklig lönesättning som är fri från diskriminering",
            checked: false,
            order: 3,
          },
          {
            name: "Granskning och analys av gällande lönepolicy och hur den efterlevs",
            checked: false,
            order: 4,
          },
          {
            name: "Diskussion kring hur vi säkerställer en objektiv och saklig lönesättning",
            checked: false,
            order: 5,
          },
          {
            name: "Granskning av lönekriterier och tillämpning av dessa",
            checked: false,
            order: 6,
          },
        ],
      },
      [Steps.chapters.documentation.value]: {
        text: [
          "I detta moment diskuterar och analyserar ni de underlag ni bestämt att fokusera på från förberedelserna.",
          "<ol>" +
            "<li>Sett ur ett likabehandlingsperspektiv, är lönepolitiken rättvis och sker på lika grunder?</li>" +
            "<li>Hittar ni några utvecklingsområden?</li>" +
            "<li>Vilka bakomliggande orsaker kan finnas till eventuella problemområden?</li>" +
            "<li>Dokumentera analysen i rutan nedan.</li>" +
            "</ol>",
        ],
        html: `
        <p>Diskutera och analysera ert underlag med stöd av följande frågeställningar:</p>
              <ol>
                <li>Sett ur ett likabehandlingsperspektiv, är lönepolitiken rättvis och sker på lika grunder?</li>
                <li>Hur sker fördelning av personalförmåner?</li>
                <li>Vilka bakomliggande orsaker kan finnas till eventuella problemområden?</li>             
              </ol>
              <p>Sammanfatta sedan er analys kring arbetsmiljö i rutan nedan.</p>
              <br>
              `,
        exampleText: `
          <p>Exempel på analys:</p>
          <ul>
            <li>Arbetsgruppen konstaterar att företaget saknar en tydlig lönepolicy samt kriterier för lönesättningen.</li>
            <li>Fördelningen av andra personalförmåner sker med visst godtycke och styrs idag av respektive chef. Vi behöver ta ett samlat grepp och skapa bättre kontroll över detta.</li>
          </ul>
          `,
      },
      [Steps.chapters.atgard.value]: {
        description: "",
        text:[
        "I detta moment formulerar ni eventuella åtgärder som diskuterats fram från kartläggning och analys. ",
        "Exempel på åtgärder: Arbeta fram en lönepolicy med kriterier för lönesättning eller utbilda chefer i objektiv lönesättning ",
      ],
      html: `
          <p>I detta moment formulerar ni eventuella åtgärder som diskuterats fram från kartläggning och analys.</p>
          <p>Exempel på åtgärder:</p>
          <ul>
            <li>Arbeta fram en lönepolicy med kriterier för lönesättning</li>
            <li>Utbilda chefer i objektiv lönesättning</li>
          </ul>
        `,
      },
    },
  },
  recruitmentAndPromotion: {
    name: "Rekrytering & Befordran",
    chapterSectionType:ChapterSectionTypes.recruitmentAndPromotion,
    //path: AuthenticatedUrls.content.subPages.recruitmentAndPromotion.path,
    step: {
      [Steps.chapters.kartlaggning.value]: {
        defaultOptions: [
          {
            name: "Granskning och diskussion kring befordran",
            checked: false,
            order: 1,
          },
          {
            name: "Granskning och diskussion kring rekryteringsgruppens sammansättning och kunskapsnivå",
            checked: false,
            order: 2,
          },
          {
            name: "Granskning och diskussion av rekryteringsprocessen",
            checked: false,
            order: 3,
          },
          {
            name: "Genomgång av rutin för att rekrytera medarbetare",
            checked: false,
            order: 4,
          },
          {
            name: "Granskning och diskussion kring platsannonser",
            checked: false,
            order: 5,
          },
        ],
      },
      [Steps.chapters.documentation.value]: {
        text: [
          "I detta moment diskuterar och analyserar ni de underlag ni bestämt att fokusera på från förberedelserna.",
          "<ol>" +
            "<li>Sett ur ett likabehandlingsperspektiv, är rekryteringsprocessen bra?</li>" +
            "<li>Hittar ni några utvecklingsområden?</li>" +
            "<li>Vilka bakomliggande orsaker kan finnas till eventuella problemområden?</li>" +
            "<li>Dokumentera analysen i rutan nedan.</li>" +
            "</ol>",
        ],
        html: `<p>Diskutera och analysera ert underlag med stöd av följande frågeställningar:</p>
        <ol> 
        <li>Sett ur ett likabehandlingsperspektiv, är rekryteringsprocessen bra?</li> 
        <li>Hittar ni några utvecklingsområden?</li> 
        <li>Vilka bakomliggande orsaker kan finnas till eventuella problemområden?</li> 
        </ol> 
        <p>Sammanfatta sedan er analys kring arbetsmiljö i rutan nedan.</p>
        <br>
        `,
        exampleText: `
          <p>Exempel på analys:</p>
          <ul>
            <li>Varje chef sköter sin egen rekrytering och vi saknar en tydlig rutinbeskrivning av rekryteringsprocessen.</li>
            <li>Vid en granskning av våra platsannonser och vår hemsida konstaterar vi att det företrädesvis är män som syns på bild, vilket är särskilt olyckligt då vi önskar rekrytera fler kvinnor till vår verksamhet.</li>
          </ul>
          `,
      },
      [Steps.chapters.atgard.value]: {
        description: "",
        text: [
        "I detta moment formulerar ni eventuella åtgärder som diskuterats fram från kartläggning och analys. ",
        "Exempel på åtgärder: Skapa en tydlig process för rekrytering eller korrigera bilder på hemsida och platsannonser"
      ],
      html: `
      <p>I detta moment formulerar ni eventuella åtgärder som diskuterats fram från kartläggning och analys.</p>
      <p>Exempel på åtgärder:</p>
      <ul>
        <li>Skapa en tydlig process för rekrytering</li>
        <li>Korrigera bilder på hemsida och platsannonser</li>
      </ul>
    `,
        exampleText: ["Ta fram en rutinbeskrivning för vår rekryteringsprocess.", "Omarbeta hemsida och platsannonser med avseende på val av bilder."],
      },
    },
  },
  educationAndSkillsDevelopment: {
    name: "Utbildning & kompetensutveckling",
    chapterSectionType:ChapterSectionTypes.educationAndSkillsDevelopment,
    //path: AuthenticatedUrls.content.subPages.educationAndSkillsDevelopment.path,
    step: {
      [Steps.chapters.kartlaggning.value]: {
        defaultOptions: [
          {
            name: "Diskussion kring hur utbildning och kompetensutveckling planeras och beslutas",
            checked: false,
            order: 1,
          },
          {
            name: "Genomgång av mall för medarbetarsamtal",
            checked: false,
            order: 2,
          },
          {
            name: "Genomgång av interna rutiner kring kompetensutveckling",
            checked: false,
            order: 3,
          },
          {
            name: "Diskussion om beslutsfattarens kunskap om diskriminering och likabehandling",
            checked: false,
            order: 4,
          },
          {
            name: "Diskussion kring hur arbetsplatsen kan säkerställa en process för kompetensutveckling som är saklig och fri från diskriminering",
            checked: false,
            order: 5,
          },
        ],
      },
      [Steps.chapters.documentation.value]: {
        text: [
          "I detta moment diskuterar och analyserar ni de underlag ni bestämt att fokusera på från förberedelserna.",
          "<ol>" +
            "<li>Gå igenom de dokument och arbetsrutiner ni har på området såsom mall för utvecklingssamtal och kartläggning av utbildningsinsatser.</li>" +
            "<li>Hur fungerar era interna rutiner avseende utbildning och kompetensutveckling?</li>" +
            "<li>Kommer kompetensutveckling alla till del?</li>" +
            "<li>Dokumentera analysen i rutan nedan.</li>" +
            "</ol>"
          ,
        ],
        html: ` <p>Diskutera och analysera ert underlag med stöd av följande frågeställningar:</p>
        <ol>
        <li>Sett ur ett likabehandlingsperspektiv, hur jobbar ni med utbildning och kompetensutveckling?
        </li>
        <li>Finns interna rutiner avseende utbildning och kompetensutveckling?</li>
        <li>Kommer kompetensutveckling alla till del?</li>
        </ol>
        <p>Sammanfatta sedan er analys kring arbetsmiljö i rutan nedan.</p>
        <br>
        `,
        exampleText: `
          <p>Exempel på analys:</p>
          <ul>
            <li>Under utvecklingssamtalet diskuteras kompetensutveckling med varje medarbetare. Vi skulle dock behöva ett mer strukturerat sätt att följa upp hur utbildning fördelas</li>
            <li>Det är svårt att upptäcka diskriminering, särskilt när en själv tillhör en normgrupp. Vi behöver säkerställa att alla chefer har tillräcklig kunskap om likabehandling och diskriminering.</li>
          </ul>
          `,
      },
      [Steps.chapters.atgard.value]: {
        description: "",
        text: [
        "I detta moment formulerar ni eventuella åtgärder som diskuterats fram från kartläggning och analys. ",
        "Exempel på åtgärder: Skapa rutin för årlig uppföljning av utbildningsinsatser eller utbilda chefer och ledare i normer och diskriminering ",
      ],
      html: `
      <p>I detta moment formulerar ni eventuella åtgärder som diskuterats fram från kartläggning och analys.</p>
      <p>Exempel på åtgärder:</p>
      <ul>
        <li>Skapa rutin för årlig uppföljning av utbildningsinsatser </li>
        <li>Utbilda chefer och ledare i normer och diskriminering</li>
      </ul>
    `,
      },
    },
  },
  employmentAndParenthood: {
    name: "Förvärvsarbete & föräldraskap",
    chapterSectionType:ChapterSectionTypes.employmentAndParenthood,
    //path: AuthenticatedUrls.content.subPages.employmentAndParenthood.path,
    step: {
      [Steps.chapters.kartlaggning.value]: {
        defaultOptions: [
          {
            name: "Diskussion om vilken påverkan föräldraskap har på möjligheter och villkor i arbetet",
            checked: false,
            order: 1,
          },
          {
            name: "Diskussion om möjligheter att förena förvärvsarbete och föräldraskap och ev. påverkan på villkor och möjligheter på arbetsplatsen",
            checked: false,
            order: 2,
          },
          {
            name: "Granskning av övertidsuttag och diskussion kring synen på övertidsarbete på arbetsplatsen",
            checked: false,
            order: 3,
          },
          {
            name: "Kartläggning om i vilken utsträckning föräldralediga får ta del av informationsutskick och bjuds in till att delta vid personalaktiviteter",
            checked: false,
            order: 4,
          },
          {
            name: "Diskussion om föräldraskap är ett hinder för chefskap eller i utövandet av chefskapet",
            checked: false,
            order: 5,
          },
          {
            name: "Översyn och granskning av om det finns rutiner och praxis som på något vis begränsar möjligheter för föräldrar",
            checked: false,
            order: 6,
          },
          {
            name: "Översyn av arbetstider och förläggning av möten",
            checked: false,
            order: 7,
          },
          {
            name: "Granskning och analys av genomförd medarbetarenkät",
            checked: false,
            order: 8,
          },
        ],
      },
      [Steps.chapters.documentation.value]: {
        text: [
          "I detta moment diskuterar och analyserar ni de underlag ni bestämt att fokusera på från förberedelserna.",
          "<ol>" +
            "<li>Hur fungerar möjligheten att förena arbete med föräldraskap på er arbetsplats?</li>" +
            "<li>Hittar ni några utvecklingsområden?</li>" +
            "<li>Vilka bakomliggande orsaker kan finnas till eventuella problemområden?</li>" +
            "<li>Dokumentera analysen i rutan nedan.</li>" +
            "</ol>",
        ],
        html: `<p>Diskutera och analysera ert underlag med stöd av följande frågeställningar:</p>
        <ol>
        <li>Hur fungerar det att förena arbete med föräldraskap på er arbetsplats?</li>
        <li>Hittar ni några utvecklingsområden?</li>
        <li>Vilka bakomliggande orsaker kan finnas till eventuella problemområden?</li>
        </ol>
        <p>Sammanfatta sedan er analys kring arbetsmiljö i rutan nedan.</p>
        <br>
        `,
        exampleText: `
          <p>Exempel på analys:</p>
          <ul>
            <li>Uppfattningen i arbetsgruppen är att företaget har en öppen och positiv syn på föräldraledighet. Vi skulle vilja försäkra oss om att medarbetare som är föräldrar upplever det på samma sätt.</li>
            <li>Möten förläggs ofta tidigt på morgonen och sent på eftermiddagen vilket kan försvåra för den som ska hämta eller lämna barn på förskola/skola.</li>
          </ul>
          `,
      },
      [Steps.chapters.atgard.value]: {
        description: "",
        text: [
          "I detta moment formulerar ni eventuella åtgärder som diskuterats fram från kartläggning och analys. ",
          "Exempel på åtgärder: Lägg in fråga i medarbetarundersökning kring upplevelsen av möjligheten av att förena arbete med föräldraskap eller åtgärd att förhindra trakasserier ",
        ],
        html: `
      <p>I detta moment formulerar ni eventuella åtgärder som diskuterats fram från kartläggning och analys.</p>
      <p>Exempel på åtgärder:</p>
      <ul>
        <li>Lägg in fråga i medarbetarundersökning kring upplevelsen av möjligheten av att förena arbete med föräldraskap</li>
        <li>Åtgärd att förändra möteskultur</li>
      </ul>
    `,
        exampleText: [
          "Lägga till en fråga i mallen för utvecklingssamtal om hur det fungerar att förena föräldraskap med ett arbete hos oss.",
          "Förändra vår möteskultur och i möjligaste mån förlägga obligatoriska möten tidigast klockan 8.30 samt senast kl. 15.00.",
        ],
      },
    },
  },
  measuresToPreventHarassment: {
    name: "Åtgärder för att förhindra trakasserier",
    chapterSectionType:ChapterSectionTypes.measuresToPreventHarassment,
    //path: AuthenticatedUrls.content.subPages.measuresToPreventHarassment.path,
    step: {
      [Steps.chapters.kartlaggning.value]: {
        defaultOptions: [
          {
            name: "Genomgång av policy mot trakasserier, sexuella trakasserier och repressalier",
            checked: false,
            order: 1,
          },
          {
            name: "Granskning och analys av genomförd medarbetarenkät",
            checked: false,
            order: 2,
          },
          {
            name: "Diskussion kring chefernas kunskapsnivåer för att kunna förebygga och hantera trakasserier, sexuella trakasserier och repressalier",
            checked: false,
            order: 3,
          },
          {
            name: "Diskussion kring rutinernas praktiska hantering",
            checked: false,
            order: 4,
          },
          {
            name: "Diskussion kring hur upplevelsen av trakasserier och repressalier följs upp",
            checked: false,
            order: 5,
          },
          {
            name: "Genomgång av beredskapsplan för att hantera trakasserier, sexuella trakasserier och repressalier",
            checked: false,
            order: 6,
          },
        ],
      },
      [Steps.chapters.documentation.value]: {
        text: [
          "I detta moment diskuterar och analyserar ni de underlag ni bestämt att fokusera på från förberedelserna.",
          "<ol>" +
            "<li>Hur är era interna rutiner för att förhindra, utreda och följa upp trakasserier?</li>" +
            "<li>Hittar ni några utvecklingsområden?</li>" +
            "<li>Vilka bakomliggande orsaker kan finnas till eventuella problemområden?</li>" +
            "<li>Dokumentera analysen i rutan nedan.</li>" +
            "</ol>",
        ],
        html: `<p>Diskutera och analysera ert underlag med stöd av följande frågeställningar:</p>
        <ol>
        <li>Hur är era interna rutiner för att förhindra, utreda och följa upp trakasserier?</li>
        <li>Hittar ni några utvecklingsområden?</li>
        <li>Vilka bakomliggande orsaker kan finnas till eventuella problemområden?</li>
        </ol>
        <p>Sammanfatta sedan er analys kring arbetsmiljö i rutan nedan.</p>
        <br>
        `,
        exampleText: `
          <p>Exempel på analys:</p>
          <ul>
            <li>Vi har en tydlig policy mot kränkande särbehandling men den är inte tillräckligt känd i verksamheten.</li>
            <li>De psykosociala förhållandena följs årligen upp i medarbetarsamtal samt i en anonym medarbetarenkät. I enkäten saknas frågor kring trakasserier och kränkande särbehandling.</li>
          </ul>
          `,
      },
      [Steps.chapters.atgard.value]: {
        description: "",
        text: [
          "I detta moment formulerar ni eventuella åtgärder som diskuterats fram från kartläggning och analys. ",
          "Exempel på åtgärder: Kommunicera policyn på personalmöten eller Komplettera enkät med frågor om trakasserier ",
        ],
        html: `
      <p>I detta moment formulerar ni eventuella åtgärder som diskuterats fram från kartläggning och analys.</p>
      <p>Exempel på åtgärder:</p>
      <ul>
        <li>Kommunicera policyn på personalmöten</li>
        <li>Komplettera enkät med frågor om trakasserier</li>
      </ul>
    `,
        exampleText: [
          "Kommunicera policyn mot kränkande särbehandling vid nästa personalmöte samt minst en gång per år hädanefter.",
          "Lägga till en fråga i medarbetarenkäten som följer upp förekomst av trakasserier/kränkande särbehandling på arbetsplatsen.",
        ],
      },
    },
  },
  equalGenderDistribution: {
    name: "Åtgärder för jämn könsfördelning",
    chapterSectionType:ChapterSectionTypes.equalGenderDistribution,
    //path: AuthenticatedUrls.content.subPages.equalGenderDistribution.path,
    step: {
      [Steps.chapters.kartlaggning.value]: {
        defaultOptions: [
          {
            name: "Diskussion om hur vi skulle kunna uppnå en jämnare könsfördelning – på lång och på kort sikt",
            checked: false,
            order: 1,
          },
          {
            name: "Diskussion om huruvida det finns det ”typiska” arbeten för kvinnor eller män",
            checked: false,
            order: 2,
          },
          {
            name: "Granskning av statistik som visar på könsfördelningen i olika roller och på olika positioner i företaget",
            checked: false,
            order: 3,
          },
        ],
      },
      [Steps.chapters.documentation.value]: {
        text: ["-", "-"],
        html: ` <p>Diskutera och analysera ert underlag med stöd av följande frågeställningar:</p>
        <ol>
        <li>Hur ser könsfördelningen ut inom företaget i stort?</li>
        <li>Hur ser könsfördelningen ut på ledande positioner och inom olika yrkeskategorier?</li>
        <li>Vilka bakomliggande orsaker som kan finnas till eventuella problemområden?</li>
        </ol>
        <p>Sammanfatta sedan er analys kring arbetsmiljö i rutan nedan.</p>
        <br>
        `,
        exampleText: `
        <p>Exempel på analys:</p>
        <ul>
          <li>Få sökanden av det underrepresenterade könet.</li>
          <li>Få kvinnor i ledande position.</li>
        </ul>
        `,
      },
      [Steps.chapters.atgard.value]: {
        description: "",
        text: [
          "I detta moment formulerar ni eventuella åtgärder som diskuterats fram från kartläggning och analys. ",
          "Exempel på åtgärder: Kalla alltid sökande från underrepresenterade grupper till intervju eller fördjupad kartläggning kring hur vi tillsätter chefsposter. ",
        ],
        html: `
      <p>I detta moment formulerar ni eventuella åtgärder som diskuterats fram från kartläggning och analys.</p>
      <p>Exempel på åtgärder:</p>
      <ul>
        <li>Kalla alltid sökande från underrepresenterade grupper till intervju</li>
        <li>Fördjupad kartläggning kring hur vi tillsätter chefsposter.</li>
      </ul>
    `,
        exampleText: [
          "Vid rekrytering är ett mål att hitta minst två kandidater av underrepresenterat kön som uppfyller tillräckliga kvalifikationer och kalla dessa på intervju  ",
          "Som arbetsgivare ska man främja en jämn könsfördelning i skilda typer av arbeten, inom olika kategorier av arbetstagare och på ledande positioner. Det kan du göra genom att exempelvis utbilda eller kompetensutveckla anställda.",
        ],
      },
    },
  },
  payrollMapping: {
    name: "Lönekartläggning",
    chapterSectionType:ChapterSectionTypes.payrollMapping,
    //path: AuthenticatedUrls.content.subPages.payrollMapping.path,
    step: {
      [Steps.chapters.kartlaggning.value]: {
        defaultOptions: [
          {
            name: "Underlag: Arbetsvärdering och Lönekartläggning",
            checked: false,
            order: 1,
          },
        ],
      },
      [Steps.chapters.documentation.value]: {
        text: ["-", "-"],
        html: ` <p>Diskutera och analysera ert underlag med stöd av följande frågeställningar:</p>
        <ol> 
        <li>Finns det löneskillnader mellan män och kvinnor som är sysselsatta inom lika och likvärdiga arbeten?</li> 
        <li>Hur förklaras sådana skillnader? Finns könsneutrala förklaringar?</li> 
        <li>Vilka bakomliggande orsaker som kan finnas till eventuella problemområden?</li>
        </ol> 
        <p>Sammanfatta sedan er analys kring arbetsmiljö i rutan nedan.</p>
        <br>
        `,
        exampleText: `
        <p>Exempel på analys:</p>
        <ul>
          <li>Utifrån lönekartläggningen har vi gjort en analys av löneläget inom lika och likvärdiga befattningar utifrån ett genusperspektiv. Efter genomförd analys bedömer vi att befintliga lönenivåer är rimliga sett till objektiva krav på respektive befattning. Det finns anledning att särskilt kommentera lönenivåer i grupp 4.</li>
          <li>I grupp 4 återfinns kundtjänstmedarbetare, varav hälften är män och hälften är kvinnor. I lönekartläggningen syns att kvinnorna i genomsnitt har högre löner än männen. Detta kan förklaras genom att kvinnorna i genomsnitt har längre erfarenhet medan männen har kortare sammanlagd erfarenhet av yrket. Företaget bedömer att lönebilden visar på en rimlig lönespridning inom befattningen som kan förklaras med könsneutrala argument.</li>
          <li>Samverkan i arbetet har uppfyllts genom att en medarbetarrepresentant har varit delaktig i såväl arbetsvärdering som löneanalys.</li>
        </ul>
        `,
      },
      [Steps.chapters.atgard.value]: {
        description: "",
        text: [
          "I detta moment formulerar ni eventuella åtgärder som diskuterats fram från kartläggning och analys. ",
          "Exempel på åtgärder: Utför en årlig lönekartlägging eller utför lönejusteringarna",
        ],
        html: `
      <p>I detta moment formulerar ni eventuella åtgärder som diskuterats fram från kartläggning och analys.</p>
      <p>Exempel på åtgärder:</p>
      <ul>
        <li>Utför en årlig lönekartlägging</li>
        <li>Utför lönejusteringarna så snart som möjligt</li>
      </ul>
    `,
        exampleText: [
          "(Ska innehålla eventuella lönejusteringar för att rätta till osakliga löneskillnader samt en kostnadsberäkning och tidplan för dessa.  Lönejusteringarna ska genomföras så snart som möjligt och senast inom tre år) ",
        ],
      },
    },
  },
};