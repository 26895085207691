import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import Auth from "@aws-amplify/auth";
import { Button } from "@mui/material";
import Loading from "Components/Loading";
import { CustomerModel } from "Models/Models";
import { AuthenticatedUrls } from "Routes/Urls";

const Onboarding = () => {
  const navigate = useNavigate();

  const [stateIsLoading, setStateIsLoading] = useState(true);

  useEffect(() => {
    async function asyncUseEffect() {
      try {
        const user = await Auth.currentAuthenticatedUser();
        //if we have succeded and there is a customerID associated with current user we can go right ahead and go to and use the application.
        if (user.attributes?.["custom:customerId"] != null) {
          navigate(AuthenticatedUrls.admin.path);
        }
      } catch (err) {
        console.error(err);
      }

      //Scroll to the top of the page.
      window.scrollTo(0, 0);

      setStateIsLoading( false );
    }

    asyncUseEffect();
  }, []);

  if ( stateIsLoading ) {
      return <Loading />;
  } else {
    return (
      <div className="adminContainer">
        <div className="adminInnerContainer">
          <div style={{ flex: 1 }}>
            <h1>Välkommen till likabehandlingsplanen!</h1>
            <p>
              För att använda tjänsten behöver du registrera ditt företag. När företaget är registrerat kan du betala för tjänsten och därefter börja använda den.{" "}
            </p>

            <Button
              variant="contained"
              component={Link}
              onClick={ () => navigate( AuthenticatedUrls.admin.subPages.editCustomers.path, { state: { model: CustomerModel, addCustomerToCurrentUser: true } })}
            >
              Registrera företag
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default Onboarding;
