import React, { useState, useEffect } from 'react';
import Auth from "@aws-amplify/auth";
import EditView from "Components/EditView";
import Loading from "Components/Loading";
import { FillModel } from "Models/FillModel";
import { UserModelProfile } from "Models/Models";
import { UserStates } from "Util/Const";
import { Switches } from "Util/Switches";
import { PopupConfirm, PopupWithText } from "Util/Utils";

const Profile = () => {
  const [stateIsLoading, setStateIsLoading] = useState(true);
  const [stateUser, setStateUser] = useState( [] );
 
  useEffect(() => {
    async function asyncUseEffect(){
      const userData = await Auth.currentUserInfo();

      const user = {
        id: userData.username,
        email: userData.attributes["email"],
        familyName: userData.attributes["family_name"],
        givenName: userData.attributes["given_name"],
        customerId: userData.attributes["custom:customerId"],
        title: userData.attributes["custom:title"],
        enabled: UserStates.enabled.value,
      };

      //Scroll to the top of the page.
      window.scrollTo(0, 0);

      setStateIsLoading( false );
      setStateUser( FillModel(UserModelProfile, [user]) );
    }
    
    asyncUseEffect();
  }, []);

  async function asyncDisableUser() {
    //Todo: disable user in some way.
  }

  async function editUser(data) {
    try {
      //Deal with change of title and email.
      const title = data.find((d) => d.key === "title").value;
      const email = data.find((d) => d.key === "email").value;
      const givenName = data.find((d) => d.key === "givenName").value;
      const familyName = data.find((d) => d.key === "familyName").value;

      const user = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(user, {
        email: email,
        family_name: familyName,
        given_name: givenName,
        "custom:title": title,
      });
      PopupWithText("Sparad!", "Dina ändringar har sparats!");
    } catch (err) {
      console.error(err);
    }
  }

  if ( stateIsLoading ) {
    return <Loading />;
  } else {
    return (
      <div className="adminContainer">
        <div className="adminInnerContainer">
          <div className="contentWrapper">
            <div>
              <EditView
                id={ stateUser[0].id }
                data={ stateUser[0].model }
                model={ UserModelProfile }
                header="Redigera användare"
                onSave={ async (x) => await editUser(x) }
                onDelete={() =>
                  PopupConfirm("Vill du avaktivera din användare? För att aktivera användaren på nytt kontakta din administratör.", async () => await asyncDisableUser() )
                }
                disableDelete={ !Switches.userCandisableItself }
                hideCancel={ true }
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Profile;
