import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, Outlet} from 'react-router-dom';
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import Loading from "Components/Loading";
import { AuthenticatedUrls, ChapterSectionTypeUrls } from "Routes/Urls";
import { GetDateOnly, GetDateString } from "Util/Utils";
import { TwKey } from "Components/StandardTextComponentsWithTranslation";
import Navbar from "Components/Navbar";
import SideMenu from "Components/SideMenu";
import { UrlsAreEqual } from "Util/Utils";

const Chapters = () => {
  const [stateIsLoading, setStateIsLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const appState = useSelector((state) => state.settings.appState);

  useEffect(() => {
    if( UrlsAreEqual( location.pathname, AuthenticatedUrls.content.path ) ){
      navigate( AuthenticatedUrls.content.subPages.welcome.path, { replace: true } );
    }

    //Scroll to the top of the page.
    window.scrollTo(0, 0);

    setStateIsLoading(false);
  }, []);

  //Allow any subscription with the correct date.
  const today = GetDateString(GetDateOnly());
  let todayAsDate = new Date(today);
  let todayAsTime = todayAsDate.getTime();

  if ( appState.customer !== null ){
    for ( let customerSubscriptionItem of Object.values(appState.customer?.CustomerSubscriptions.items)) {
      const startDateAsTime = new Date(customerSubscriptionItem.startDate).getTime();
      const endDateAsTime = new Date(customerSubscriptionItem.endDate).getTime();

      if( todayAsTime >= startDateAsTime && todayAsTime <= endDateAsTime ) {
        break;
      }
    }
  }

  if (stateIsLoading) {
    return <Loading />;
  } else {
    // Get menuitems with done.
    let menuItems = [];
        
    //Get what to put in the menu.
    for ( let currentChapterSection of Object.values(ChapterSectionTypeUrls) ) {  
      let pagePath = null;

      //Check if we are on Introduction or Likabehandlingsplan.
      if( currentChapterSection.chapterSectionType === ChapterSectionTypeUrls.welcome.chapterSectionType ){
        pagePath = AuthenticatedUrls.content.subPages.welcome.path;
      } else if( currentChapterSection.chapterSectionType === ChapterSectionTypeUrls.introduction.chapterSectionType ){
        pagePath = AuthenticatedUrls.content.subPages.introduction.path;
      } else if( currentChapterSection.chapterSectionType === ChapterSectionTypeUrls.materials.chapterSectionType ){
        pagePath = AuthenticatedUrls.content.subPages.materials.path;
      } else if( currentChapterSection.chapterSectionType === ChapterSectionTypeUrls.preparations.chapterSectionType ){
        pagePath = AuthenticatedUrls.content.subPages.preparations.path;
      } else if ( currentChapterSection.chapterSectionType === ChapterSectionTypeUrls.equalTreatmentPlanStatus.chapterSectionType ) {
        pagePath = AuthenticatedUrls.content.subPages.equalTreatmentPlanStatus.path;
      } else if ( currentChapterSection.chapterSectionType === ChapterSectionTypeUrls.riskActionsAndFollowUp.chapterSectionType ) {
        pagePath = AuthenticatedUrls.content.subPages.riskActionsAndFollowUp.path;
      } else if ( currentChapterSection.chapterSectionType === ChapterSectionTypeUrls.equalTreatmentPlanReport.chapterSectionType ) {
        pagePath = AuthenticatedUrls.content.subPages.equalTreatmentPlanReport.path;
      } else {
        pagePath = AuthenticatedUrls.content.subPages.chapterSection.subPages.risks.path.replace(":chapterSection", currentChapterSection.subPath);
      }

      //Get path to risker...
      let menuItemRow = {
        path: pagePath, 
        headerTextKey: currentChapterSection.chapterSectionType.HeaderTextKey,
      }

      menuItems.push(menuItemRow);
    }

    const currentIdx = menuItems.findIndex((x) => x.path === location.pathname);

    const menuLen = menuItems.length;

    return (
      <>
        <div className="pageContainer">
          <div className="wrapperOuter">
            <SideMenu />
            <div className="wrapperInner">
              <Navbar />
              <div className="contentWrapper">
                <div className='contentWrapperMid'>
                  <Outlet />
                  {/* {currentIdx !== -1 && (
                    <div style={{ width: "100%" }}>
                      <div className="bottomNavbar">
                        <div>
                          {currentIdx > 0 && (
                            <Link to={menuItems[currentIdx - 1].path} className="flexRowAlignedCenter" style={{ textDecoration: "none", color: "black" }}>
                              <Button
                                variant="contained"
                                className="buttonBorderGreenInnerGreen"
                                fullWidth={true}
                              >
                                <ArrowBack style={{ marginRight: "30px" }} />
                                <p><TwKey textKey={ menuItems[currentIdx - 1].headerTextKey } /></p>
                              </Button>
                            </Link>
                          )}
                        </div>
                        <div>
                        </div>
                        <div>
                          {currentIdx < menuLen - 1 && (
                            <Link to={menuItems[currentIdx + 1].path} className="flexRowAlignedCenter" style={{ textDecoration: "none", color: "black" }}>
                              <Button
                                variant="contained"
                                className="buttonBorderGreenInnerGreen"
                                fullWidth={true}
                              >
                                <p style={{ marginRight: "30px" }}><TwKey textKey={ menuItems[currentIdx + 1].headerTextKey } /></p>
                                <ArrowForward />
                              </Button>
                            </Link>
                          )}
                        </div>
                      </div>
                    </div> 
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </div>    
      </>
    );
  }
}

export default Chapters;