import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setAppStateSetting } from "Features/AppState/AppStateSlice";
import { AuthenticatedUrls } from 'Routes/Urls';
import Loading from "Components/Loading";
import moment from 'moment';
import { Table, TableBody, TableCell, TableHead, TableRow, FormControl, Card, Grid, Item, Paper, CardContent, TextField } from '@mui/material';
import { TextFieldWithPlaceholder, RequiredField, InfoText, TwKey, ArrowForwardIconLinkButton, AddButton, EditButton, SaveButton, CancelButton } from "Components/StandardTextComponentsWithTranslation";
import { CheckBoxOutlineBlank, CheckBoxOutlined } from "@mui/icons-material";
import { getAllRiskAnalisysAndActionsForRiskAnalisysAndActionYear } from "Util/Utils";
import { toRiskAnalisysAndActionYearWithCheckBox } from "Util/toRiskAnalisysAndActionYearWithCheckBox";
import { copyRiskAnalisysAndActionYearWithCheckBoxElseReturnNew } from "Util/copyRiskAnalisysAndActionYearWithCheckBoxElseReturnNew";
import { v4 as uuidv4 } from 'uuid';
import { ChapterSectionTypes } from "Util/Const";
import { RiskAnalisysAndActionYear, RiskAnalisysAndAction } from "Classes/UserData";
import { RiskAnalisysAndActionYearWithCheckBox } from 'Models/UserDataWithCheckBoxes';
import { GetFirstDayOfYear, GetLastDayOfYear, AddDays } from "Util/Utils";
import {
  useGetRiskAnalisysAndActionYearsForLoggedInCustomerQuery,
  useLazyGetRiskAnalisysAndActionYearsForLoggedInCustomerQuery,
  useUpdateRiskAnalisysAndActionYearsForLoggedInCustomerMutation
} from 'API/UserDataStorageAPI';
import {
  getActiveRiskAnalisysAndActionYear,
} from 'API/SelectFromUserDataStorageItems';
import {
  addRiskAnalisysAndActionYear,
  updateActiveRiskAnalisysAndActionYear
} from 'API/ModifyUserDataStorageItems';
import useForceUpdate from 'use-force-update';

const AddRiskAnalisysAndActionYear = () => {
  const dispatch = useDispatch();
  
  const navigate = useNavigate();
  const appState = useSelector((state) => state.settings.appState);

  const forceUpdate = useForceUpdate();

  const [ lazyGetRiskAnalisysAndActionYearsForLoggedInCustomer ] = useLazyGetRiskAnalisysAndActionYearsForLoggedInCustomerQuery();

  const [
    updateRiskAnalisysAndActionYearsForLoggedInCustomer, // This is the mutation trigger
    { isLoading: isUpdatingUserDataStorageForLoggedInCustomer }, // This is the destructured mutation result
  ] = useUpdateRiskAnalisysAndActionYearsForLoggedInCustomerMutation();
  
  const [stateIsLoading, setStateIsLoading] = useState(true);

  const [stateRiskAnalisysAndActionYearWithCheckBox, setStateRiskAnalisysAndActionYearWithCheckBox] = useState([]);

  useEffect(() => {
    async function asyncUseEffect() {
      dispatch( setAppStateSetting( { title: "Lägg till nytt år" } ));

      const riskAnalisysAndActionYearsLazy = await lazyGetRiskAnalisysAndActionYearsForLoggedInCustomer().unwrap();

      const activeRiskAnalisysAndActionYear = getActiveRiskAnalisysAndActionYear( riskAnalisysAndActionYearsLazy );

      //Get the current end date from the current active year as date.
      const oldToDate = new Date(activeRiskAnalisysAndActionYear.toDate);

      //Add one day to the date.
      const newFromDate = AddDays(oldToDate, 1);

      //Get the end of the year
      const newToDate = GetLastDayOfYear( newFromDate.getFullYear() );

      let riskAnalisysAndActionYearWithCheckBox = await toRiskAnalisysAndActionYearWithCheckBox( activeRiskAnalisysAndActionYear, newFromDate, newToDate );
      
      setStateRiskAnalisysAndActionYearWithCheckBox( riskAnalisysAndActionYearWithCheckBox ); 

      //Scroll to the top of the page.
      window.scrollTo(0, 0);

      setStateIsLoading( false );
    }

    asyncUseEffect();
  }, []);

  async function setRiskAnalisysAndActionItemWithCheckBoxToTrue( riskAnalisysAndActionWithCheckBoxTypeName, riskAnalisysAndActionItemWithCheckBoxId ){
    var riskAnalisysAndActionYearWithCheckBox = stateRiskAnalisysAndActionYearWithCheckBox;

    //Find the index of riskAnalisysAndActionItemWithCheckBox with the id riskAnalisysAndActionItemWithCheckBoxId.
    var indexOfRiskAnalisysAndActionItemWithCheckBox = riskAnalisysAndActionYearWithCheckBox.riskAnalisysAndActionsWithCheckBox[riskAnalisysAndActionWithCheckBoxTypeName].riskAnalisysAndActionItemsWithCheckBox.findIndex(( riskAnalisysAndActionItemWithCheckBox ) => riskAnalisysAndActionItemWithCheckBox.id === riskAnalisysAndActionItemWithCheckBoxId);

    //Set the correct item to true.
    riskAnalisysAndActionYearWithCheckBox.riskAnalisysAndActionsWithCheckBox[riskAnalisysAndActionWithCheckBoxTypeName].riskAnalisysAndActionItemsWithCheckBox[indexOfRiskAnalisysAndActionItemWithCheckBox].checked = true;

    //Update the state.
    setStateRiskAnalisysAndActionYearWithCheckBox(riskAnalisysAndActionYearWithCheckBox);

    //Force the component to rerender.
    forceUpdate();
  }

  async function setRiskAnalisysAndActionItemWithCheckBoxToFalse( riskAnalisysAndActionWithCheckBoxTypeName, riskAnalisysAndActionItemWithCheckBoxId ){
    var riskAnalisysAndActionYearWithCheckBox = stateRiskAnalisysAndActionYearWithCheckBox;

    //Find the index of riskAnalisysAndActionItemWithCheckBox with the id riskAnalisysAndActionItemWithCheckBoxId.
    var indexOfRiskAnalisysAndActionItemWithCheckBox = riskAnalisysAndActionYearWithCheckBox.riskAnalisysAndActionsWithCheckBox[riskAnalisysAndActionWithCheckBoxTypeName].riskAnalisysAndActionItemsWithCheckBox.findIndex(( riskAnalisysAndActionItemWithCheckBox ) => riskAnalisysAndActionItemWithCheckBox.id === riskAnalisysAndActionItemWithCheckBoxId);

    //Set the correct item to true.
    riskAnalisysAndActionYearWithCheckBox.riskAnalisysAndActionsWithCheckBox[riskAnalisysAndActionWithCheckBoxTypeName].riskAnalisysAndActionItemsWithCheckBox[indexOfRiskAnalisysAndActionItemWithCheckBox].checked = false;

    //Update the state.
    setStateRiskAnalisysAndActionYearWithCheckBox(riskAnalisysAndActionYearWithCheckBox);

    //Force the component to rerender.
    forceUpdate();
  }

  async function setRiskAnalisysAndActionTaskItemWithCheckBoxToTrue( riskAnalisysAndActionWithCheckBoxTypeName, riskAnalisysAndActionItemWithCheckBoxId, riskAnalisysAndActionTaskItemWithCheckBoxId ){
    var riskAnalisysAndActionYearWithCheckBox = stateRiskAnalisysAndActionYearWithCheckBox;

    //Find the index of riskAnalisysAndActionItemWithCheckBox with the id riskAnalisysAndActionItemWithCheckBoxId.
    var indexOfRiskAnalisysAndActionItemWithCheckBox = riskAnalisysAndActionYearWithCheckBox.riskAnalisysAndActionsWithCheckBox[riskAnalisysAndActionWithCheckBoxTypeName].riskAnalisysAndActionItemsWithCheckBox.findIndex(( riskAnalisysAndActionItemWithCheckBox ) => riskAnalisysAndActionItemWithCheckBox.id === riskAnalisysAndActionItemWithCheckBoxId);

    //Find the index of riskAnalisysAndActionTaskItemWithCheckBox with the id riskAnalisysAndActionTaskItemWithCheckBoxId.
    var indexOfRiskAnalisysAndActionTaskItemWithCheckBox = riskAnalisysAndActionYearWithCheckBox.riskAnalisysAndActionsWithCheckBox[riskAnalisysAndActionWithCheckBoxTypeName].riskAnalisysAndActionItemsWithCheckBox[indexOfRiskAnalisysAndActionItemWithCheckBox].riskAnalisysAndActionTaskItemsWithCheckBox.findIndex(( riskAnalisysAndActionTaskItemWithCheckBox ) => riskAnalisysAndActionTaskItemWithCheckBox.id === riskAnalisysAndActionTaskItemWithCheckBoxId);

    //Set the correct item to true.
    riskAnalisysAndActionYearWithCheckBox.riskAnalisysAndActionsWithCheckBox[riskAnalisysAndActionWithCheckBoxTypeName].riskAnalisysAndActionItemsWithCheckBox[indexOfRiskAnalisysAndActionItemWithCheckBox].riskAnalisysAndActionTaskItemsWithCheckBox[indexOfRiskAnalisysAndActionTaskItemWithCheckBox].checked = true;

    //Update the state.
    setStateRiskAnalisysAndActionYearWithCheckBox(riskAnalisysAndActionYearWithCheckBox);

    //Force the component to rerender.
    forceUpdate();
  }

  async function setRiskAnalisysAndActionTaskItemWithCheckBoxToFalse( riskAnalisysAndActionWithCheckBoxTypeName, riskAnalisysAndActionItemWithCheckBoxId, riskAnalisysAndActionTaskItemWithCheckBoxId ){
    var riskAnalisysAndActionYearWithCheckBox = stateRiskAnalisysAndActionYearWithCheckBox;

    //Find the index of riskAnalisysAndActionItemWithCheckBox with the id riskAnalisysAndActionItemWithCheckBoxId.
    var indexOfRiskAnalisysAndActionItemWithCheckBox = riskAnalisysAndActionYearWithCheckBox.riskAnalisysAndActionsWithCheckBox[riskAnalisysAndActionWithCheckBoxTypeName].riskAnalisysAndActionItemsWithCheckBox.findIndex(( riskAnalisysAndActionItemWithCheckBox ) => riskAnalisysAndActionItemWithCheckBox.id === riskAnalisysAndActionItemWithCheckBoxId);

    //Find the index of riskAnalisysAndActionTaskItemWithCheckBox with the id riskAnalisysAndActionTaskItemWithCheckBoxId.
    var indexOfRiskAnalisysAndActionTaskItemWithCheckBox = riskAnalisysAndActionYearWithCheckBox.riskAnalisysAndActionsWithCheckBox[riskAnalisysAndActionWithCheckBoxTypeName].riskAnalisysAndActionItemsWithCheckBox[indexOfRiskAnalisysAndActionItemWithCheckBox].riskAnalisysAndActionTaskItemsWithCheckBox.findIndex(( riskAnalisysAndActionTaskItemWithCheckBox ) => riskAnalisysAndActionTaskItemWithCheckBox.id === riskAnalisysAndActionTaskItemWithCheckBoxId);

    //Set the correct item to true.
    riskAnalisysAndActionYearWithCheckBox.riskAnalisysAndActionsWithCheckBox[riskAnalisysAndActionWithCheckBoxTypeName].riskAnalisysAndActionItemsWithCheckBox[indexOfRiskAnalisysAndActionItemWithCheckBox].riskAnalisysAndActionTaskItemsWithCheckBox[indexOfRiskAnalisysAndActionTaskItemWithCheckBox].checked = false;

    //Update the state.
    setStateRiskAnalisysAndActionYearWithCheckBox(riskAnalisysAndActionYearWithCheckBox);

    //Force the component to rerender.
    forceUpdate();
  }

  async function copyToNewRiskAnalisysAndActionYear(){
    //Get all current riskAnalisysAndActionYears.
    const riskAnalisysAndActionYearsLazy = await lazyGetRiskAnalisysAndActionYearsForLoggedInCustomer().unwrap();
    
    //Get what the user has checked.
    var newRiskAnalisysAndActionYear = await copyRiskAnalisysAndActionYearWithCheckBoxElseReturnNew(
      stateRiskAnalisysAndActionYearWithCheckBox,
      stateRiskAnalisysAndActionYearWithCheckBox.fromDate,
      stateRiskAnalisysAndActionYearWithCheckBox.toDate );

    //Get the old RiskAnalisysAndActionYear.
    var oldRiskAnalisysAndActionYear = getActiveRiskAnalisysAndActionYear( riskAnalisysAndActionYearsLazy );

    var riskAnalisysAndActionYearsToUpdate = JSON.parse( JSON.stringify( riskAnalisysAndActionYearsLazy ) );

    //Set the old year so it will be inactive.
    riskAnalisysAndActionYearsToUpdate = await updateActiveRiskAnalisysAndActionYear( oldRiskAnalisysAndActionYear.id, false );

    //Add a new year.
    riskAnalisysAndActionYearsToUpdate = await addRiskAnalisysAndActionYear( newRiskAnalisysAndActionYear );

    //Update database.
    var result = await updateRiskAnalisysAndActionYearsForLoggedInCustomer( riskAnalisysAndActionYearsToUpdate );

    navigate(AuthenticatedUrls.admin.subPages.riskAnalisysAndActionYears.path);

    //Force the component to rerender.
    forceUpdate();
  }

  
  if ( stateIsLoading ) {
    return <Loading />;
  } else {
    return (
      <>
        <h2>Skapa nytt år</h2>
        <h3>Tidsintervall för nytt år.</h3>
        <p>Ange tidsintervall för nytt år (start och slut)</p>
        <Grid container spacing={3} xs={6}>
          <Grid item xs={4}>
            <Paper elevation={0}>
              <TextField
                variant="outlined"
                color="primary"
                type="date"
                size="small"
                fullWidth
                value={ stateRiskAnalisysAndActionYearWithCheckBox.fromDate }
                onChange={(event) => setStateRiskAnalisysAndActionYearWithCheckBox( { ...stateRiskAnalisysAndActionYearWithCheckBox, fromDate: event.target.value })}
              />
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper elevation={0}>
              <TextField
                variant="outlined"
                color="primary"
                type="date"
                size="small"
                fullWidth
                value={ stateRiskAnalisysAndActionYearWithCheckBox.toDate }
                onChange={(event) => setStateRiskAnalisysAndActionYearWithCheckBox( { ...stateRiskAnalisysAndActionYearWithCheckBox, toDate: event.target.value })}
              />
            </Paper>
          </Grid>
        </Grid>
        <h3>Välj Risker att kopiera</h3>
        <p>Välj de Risker och åtgärder i varje kategori som du vill kopiera från det nuvarande året till det nya året. Tryck sedan på "Skapa".</p>
        {
          Object.values(stateRiskAnalisysAndActionYearWithCheckBox.riskAnalisysAndActionsWithCheckBox).map(( riskAnalisysAndActionWithCheckBox, riskAnalisysAndActionWithCheckBoxIdx ) => (
            <>
              <h4 key={riskAnalisysAndActionWithCheckBoxIdx}><TwKey textKey={riskAnalisysAndActionWithCheckBox.titleTextKey}/></h4>
              {
                Object.values(riskAnalisysAndActionWithCheckBox.riskAnalisysAndActionItemsWithCheckBox).length > 0 ?
                  <Grid container spacing={1}>
                    {
                      Object.values(riskAnalisysAndActionWithCheckBox.riskAnalisysAndActionItemsWithCheckBox).map( ( riskAnalisysAndActionItemWithCheckBox, riskAnalisysAndActionItemWithCheckBoxIdx ) => (
                        <Grid item xs={12}>
                          <Paper variant='outlined' style={{paddingLeft: "16px", paddingRight: "32px"}}>
                              <Fragment key={riskAnalisysAndActionItemWithCheckBoxIdx}>
                                <Table>
                                  <TableHead style={{ backgroundColor: "#FFFFFF" }}>
                                    <TableRow style={{ backgroundColor: "#FFFFFF" }}>
                                      <TableCell style={{ width: "20px", verticalAlign: "Top", backgroundColor: "#FFFFFF" }}>
                                        <b>Kopiera</b>
                                      </TableCell>
                                      <TableCell>
                                        <b>Risk</b>
                                      </TableCell>
                                      <TableCell colSpan={2}>
                                        <b>Konsekvens</b>
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    <TableRow>
                                      <TableCell style={{ width: "20px", verticalAlign: "Top", backgroundColor: "#FFFFFF" }}>
                                        {
                                          riskAnalisysAndActionItemWithCheckBox.checked ?
                                          <CheckBoxOutlined 
                                            onClick={ async (event) => await setRiskAnalisysAndActionItemWithCheckBoxToFalse( riskAnalisysAndActionWithCheckBox.chapterSectionTypeName, riskAnalisysAndActionItemWithCheckBox.id ) }
                                          />
                                          :
                                          <CheckBoxOutlineBlank
                                            onClick={ async (event) => await setRiskAnalisysAndActionItemWithCheckBoxToTrue( riskAnalisysAndActionWithCheckBox.chapterSectionTypeName, riskAnalisysAndActionItemWithCheckBox.id ) }
                                          />
                                        }
                                      </TableCell>
                                      <TableCell>
                                        {riskAnalisysAndActionItemWithCheckBox.risk}
                                      </TableCell>
                                      <TableCell colSpan={2}>
                                        {riskAnalisysAndActionItemWithCheckBox.consequence}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell style={{ width: "20px", verticalAlign: "Top", backgroundColor: "#FFFFFF" }}>
                                      </TableCell>
                                      <TableCell colSpan={2}>
                                        <b>Analys</b>
                                      </TableCell>
                                      <TableCell>
                                        <b>Åtgärda</b>
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell style={{ width: "20px", verticalAlign: "Top", backgroundColor: "#FFFFFF" }}>
                                      </TableCell>
                                      <TableCell colSpan={2}>
                                        {riskAnalisysAndActionItemWithCheckBox.analisys}
                                      </TableCell>
                                      <TableCell>
                                        { riskAnalisysAndActionItemWithCheckBox.remedy ? "Ja" : "Nej" }
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell style={{ width: "20px", verticalAlign: "Top", backgroundColor: "#FFFFFF" }}>
                                      </TableCell>
                                      <TableCell colSpan={3}>
                                        <b>Åtgärder</b><br/>
                                        {
                                          Object.values(riskAnalisysAndActionItemWithCheckBox.riskAnalisysAndActionTaskItemsWithCheckBox).length > 0 ?
                                            <>
                                              <p>Bocka i de åtgärder på denna risk som du vill kopiera.</p>
                                              <Table>
                                                <TableHead style={{ backgroundColor: "#FFFFFF" }}>
                                                  <TableRow  style={{ backgroundColor: "#FFFFFF" }}>
                                                    <TableCell style={{ width: "20px" }}>
                                                      <b>Kopiera</b>
                                                    </TableCell>
                                                    <TableCell  style={{ backgroundColor: "#FFFFFF" }}>
                                                      <b><TwKey textKey="10131" /></b>
                                                    </TableCell>
                                                    <TableCell>
                                                      <b><TwKey textKey="10132" /></b>
                                                    </TableCell>
                                                    <TableCell>
                                                      <b><TwKey textKey="10133" /></b>
                                                    </TableCell>
                                                    <TableCell>
                                                      <b><TwKey textKey="10134" /></b>
                                                    </TableCell>
                                                    <TableCell>
                                                      <b><TwKey textKey="10157" /></b>
                                                    </TableCell>
                                                    <TableCell>
                                                      <b><TwKey textKey="10135" /></b>
                                                    </TableCell>
                                                    <TableCell>
                                                      <b><TwKey textKey="10136" /></b>
                                                    </TableCell>
                                                  </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                  {
                                                    Object.values(riskAnalisysAndActionItemWithCheckBox.riskAnalisysAndActionTaskItemsWithCheckBox).map( ( riskAnalisysAndActionTaskItemWithCheckBox, riskAnalisysAndActionTaskItemWithCheckBoxIdx ) => (
                                                      <TableRow key={riskAnalisysAndActionTaskItemWithCheckBoxIdx} style={{verticalAlign: "Middle"}}>
                                                        <TableCell style={{ width: "20px" }}>
                                                        {
                                                          riskAnalisysAndActionTaskItemWithCheckBox.checked ?
                                                          <CheckBoxOutlined 
                                                            onClick={ async (event) => await setRiskAnalisysAndActionTaskItemWithCheckBoxToFalse( riskAnalisysAndActionWithCheckBox.chapterSectionTypeName, riskAnalisysAndActionItemWithCheckBox.id, riskAnalisysAndActionTaskItemWithCheckBox.id ) }
                                                          />
                                                          :
                                                          <CheckBoxOutlineBlank
                                                            onClick={ async (event) => await setRiskAnalisysAndActionTaskItemWithCheckBoxToTrue( riskAnalisysAndActionWithCheckBox.chapterSectionTypeName, riskAnalisysAndActionItemWithCheckBox.id, riskAnalisysAndActionTaskItemWithCheckBox.id ) }
                                                          />
                                                        }
                                                        </TableCell>
                                                        <TableCell>
                                                          {
                                                            riskAnalisysAndActionTaskItemWithCheckBox.task
                                                          }
                                                        </TableCell>
                                                        <TableCell>
                                                          {
                                                            riskAnalisysAndActionTaskItemWithCheckBox.responsible
                                                          }
                                                        </TableCell>
                                                        <TableCell>
                                                          {
                                                          riskAnalisysAndActionTaskItemWithCheckBox.ongoing ?
                                                            <CheckBoxOutlined className="checkBoxColorDisabled" />
                                                          :
                                                            <CheckBoxOutlineBlank className="checkBoxColorDisabled" />
                                                        }
                                                        </TableCell>
                                                        <TableCell>
                                                          {
                                                            riskAnalisysAndActionTaskItemWithCheckBox.ongoing ?
                                                              ""
                                                            :
                                                              riskAnalisysAndActionTaskItemWithCheckBox.followUpDate
                                                          }
                                                        </TableCell>
                                                        <TableCell>
                                                          {
                                                            riskAnalisysAndActionTaskItemWithCheckBox.fixed ?
                                                              <CheckBoxOutlined className="checkBoxColorDisabled" />
                                                            :
                                                              <CheckBoxOutlineBlank className="checkBoxColorDisabled" />
                                                          }
                                                        </TableCell>
                                                        <TableCell>
                                                          {
                                                            riskAnalisysAndActionTaskItemWithCheckBox.fixedDate
                                                          }
                                                        </TableCell>
                                                        <TableCell>
                                                          {
                                                            riskAnalisysAndActionTaskItemWithCheckBox.comment
                                                          }
                                                        </TableCell>
                                                      </TableRow>
                                                    ))
                                                  }
                                                </TableBody>
                                              </Table>
                                            </>
                                          :
                                            <TwKey textKey="10159"/>
                                        }
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </Fragment>
                          </Paper>
                        </Grid>
                      ))
                    }
                  </Grid>
                :
                  <TwKey textKey="10158"/>
              }
            </>
          ))
        }
        <br/><br/>
        <FormControl>
          <SaveButton
            buttonTextKey="10109"
            toolTipTitleTextKey="10110"
            onClick={ async () => await copyToNewRiskAnalisysAndActionYear() }
          />
        </FormControl>
        &nbsp;
        <FormControl>
          <CancelButton
            buttonTextKey="10111"
            toolTipTitleTextKey="10112"
            // onClick={ async () => await cancelEditRiskAnalisysAndActionTaskItemButtonOnClick() }
          />
        </FormControl>
      </>
    );
  }
}

export default AddRiskAnalisysAndActionYear;