import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setAppStateSetting } from "Features/AppState/AppStateSlice";
import { Accordion, AccordionDetails, AccordionSummary, Button, Table, TableHead, TableBody, TableRow, TableCell } from "@mui/material";
import { CloudUpload, CloudDownload, Delete, DragIndicator, ExpandMore } from "@mui/icons-material";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Loading from "Components/Loading";
import Updating from "Components/Updating";
import { createMaterials, deleteMaterials, updateMaterials } from "graphql/mutations";
import { listMaterialss } from "graphql/queries";
import { DeleteItemById, GetAllItems, SaveOrUpdate } from "Util/Api";
import { NewDownloadFunction, deleteFile, uploadFileWithOriginalFileName } from "Util/StorageManager";
import { PopupConfirm, reorder } from "Util/Utils";
import { AddButton, PopupInputTwoValues } from 'Components/StandardTextComponentsWithTranslation';
import { AuthenticatedUrls } from 'Routes/Urls';

const HandleMaterials = () => {
  const appState = useSelector((state) => state.settings.appState);
  const dispatch = useDispatch();

  const [stateIsLoading, setStateIsLoading] = useState( true );
  const [stateMaterials, setStateMaterials] = useState( [] );
  const [stateIsUpdating, setStateIsUpdating] = useState( false );
  
  useEffect(() => {
    async function asyncUseEffect(){
      await getData();
      
      dispatch ( setAppStateSetting( {  title: "Hantera material" } ) );

      //Scroll to the top of the page.
      window.scrollTo(0, 0);
    }

    asyncUseEffect();
  }, []);

  async function getData() {
    const materials = await GetAllItems(listMaterialss);
    setStateIsLoading( false );
    setStateMaterials( materials );
  }

  async function uploadFileToStorageAndAddToDb(event) {
    setStateIsUpdating( true );

    var order = 0;

    if( stateMaterials.length > 0 ){
      order =
        Math.max.apply(
          Math,
          stateMaterials.map(function (o) {
            return o.order;
          })
        ) + 1;
    }

    //Set the type to a file that is stored in the DB.
    const type = 0;

    try {
      const [fileName, path] = await uploadFileWithOriginalFileName("Materials", event.target.files[0]);
      const material = {
        name: fileName,
        path,
        type,
        order
      };
      await SaveOrUpdate(material, null, createMaterials, null);
      await getData();
    } catch (err) {
      console.error(err);
    }

    setStateIsUpdating( false );
  }

  async function addMaterialLinkToDb(){
    const userInput = await PopupInputTwoValues(10285);

    var order = 0;

    if( stateMaterials.length > 0 ){
      order =
        Math.max.apply(
          Math,
          stateMaterials.map(function (o) {
            return o.order;
          })
        ) + 1;
    }

    //Set the type to link.
    const type = 1;
    try {
      const material = {
        name: userInput[0],
        path: userInput[1],
        type,
        order
      };
      await SaveOrUpdate(material, null, createMaterials, null);
      await getData();
    } catch (err) {
      console.error(err);
    }
  } 

  async function deleteMaterialFromDbAndOrStorage(material) {
    if( material !== undefined ){
      try {
        //Check if this is a file and if so, delete it from the S3 storage.
        if(material.type == 0){
          await deleteFile(material.path);
        }

        await DeleteItemById(material.id, deleteMaterials, null);
        setStateMaterials( [...stateMaterials.filter((f) => f.id !== material.id)] );
      } catch (err) {
        console.error(err);
      }
    }
  }

  async function onDragEnd(result) {
    // dropped outside the list
    try {
      if (!result.destination) {
        return;
      }
      const oldMaterials = [...stateMaterials];
      let materialsToUpdate = [];
      const materials = reorder(stateMaterials, result.source.index, result.destination.index);
      materials.forEach((st, idx) => {
        if (st.id !== oldMaterials[idx].id) {
          materialsToUpdate.push({ id: st.id, order: idx });
        }
      });
      // setStateIsLoading( true );
      setStateMaterials( materials );
      let promises = [];
      for (let materialToUpdate of materialsToUpdate) {
        promises.push(SaveOrUpdate(materialToUpdate, updateMaterials, null, null));
      }
      await Promise.all(promises);
    } catch (err) {
      console.error(err);
    }
  }

  if (stateIsLoading) {
    return <Loading />;
  } else {
    return (
      <>
        { stateIsUpdating && <Updating /> }
        <div className="chapterStepContent">
          <div className="accordionSummary">
            <h2>Material</h2>
            <div style={{ display: "flex" }}>
                <AddButton style={{ float: "right" }} buttonTextKey="10283" toolTipTitleTextKey="10284" onClick={ async () => await addMaterialLinkToDb() } />
                <input id={"choose-file-"} type="file" style={{ display: "none" }} onChange={uploadFileToStorageAndAddToDb} />
                <label htmlFor={"choose-file-"}>
                  <div>
                    <Button
                      style={{ marginLeft: "20px", marginRight: "10px", float: "left" }} 
                      variant="contained"
                      component="span"
                    >
                      <p style={{ marginRight: "20 px"}}>Ladda upp&nbsp;&nbsp;</p>
                      <CloudUpload />
                    </Button>
                  </div>
                </label>
            </div>
          </div>
          <div>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <Table aria-label="simple table" {...provided.droppableProps} ref={provided.innerRef}>
                    <TableHead>
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell>Namn och länk</TableCell>
                          <TableCell>Skapad</TableCell>
                          <TableCell>Ta bort</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                      {stateMaterials
                        .sort((a, b) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 0))
                        .map((material, index) => (
                          <Draggable key={material.id} draggableId={material.id} index={index}>
                            {(provided, snapshot) => (
                              <TableRow
                                key={material.id}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={provided.draggableProps.style}
                              >
                                <TableCell>
                                  <DragIndicator />
                                </TableCell>
                                <TableCell>
                                  {
                                    //Check if file or link:
                                    material.type == 0 ?
                                      <a href={ AuthenticatedUrls.admin.subPages.handleMaterials.path + "/" + material.name } onClick={ async () => await NewDownloadFunction( material.path, material.name ) } target="_blank" rel="noreferrer">{ material.name }</a>
                                    :
                                      <a href={ material.path } target="_blank" rel="noreferrer">{ material.name }</a>
                                  }
                                </TableCell>
                                <TableCell>{material.createdAt.split("T")[0]}</TableCell>
                                {!snapshot.isDragging && (
                                  <>
                                    <TableCell style={{ cursor: "pointer", textAlign: "right" }}>
                                      <Delete className="iconsDelete"
                                        onClick={() => PopupConfirm("Är du säker på att du vill ta bort " + material.name + "?", () => deleteMaterialFromDbAndOrStorage(material))}
                                      />
                                    </TableCell>
                                  </>
                                )}
                              </TableRow>
                            )}
                          </Draggable>
                        ))}
                    </TableBody>
                  </Table>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </div>
      </>
    );
  }
}

export default HandleMaterials;
