import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { push } from "redux-first-history";
import { userSignIn } from "Features/AppState/AppStateSlice";
import { ThemeProvider, StyledEngineProvider } from "@mui/material";
import Loading from "Components/Loading";
import Router from "Routes/Router";
import { AuthenticatedUrls } from "Routes/Urls";
import "style.css";
import { Roles } from "Util/Const";
import { getDisabledUsers } from "Util/DisabledUsers";
import { PopupGeneric } from "Util/Utils";
import ScrollToHashElement from "Components/ScrollToHashElement";
import theme from 'Theme'

const App = () => {
  const [stateIsLoading, setStateIsLoading] = useState(true);
  const appState = useSelector((state) => state.settings.appState);
  const dispatch = useDispatch();

  useEffect(() => {
    async function asyncUseEffect() {
      await dispatch(userSignIn("/kapitel/arbetsforhallanden/risker"));
 
      if( appState.isAuthenticated === true ){
        if ( appState.role != null && appState.role === Roles.admin.value) {
          let disabledUsers = await getDisabledUsers(); 
    
          if (disabledUsers.find((x) => x.warning === true)) {
            PopupGeneric(
              "Varning",
              `Det finns ${disabledUsers.filter((x) => x.warning === true).length} avaktiverade användare som enligt GDPR rekomenderas att de tas bort`,
              () => push({ pathname: AuthenticatedUrls.admin.subPages.disabledUsersOverview.path }),
              "Gå till överikt",
              true
            );
          }
        }
      }

      setStateIsLoading(false);
    }

    asyncUseEffect();
  }, []);

  if (stateIsLoading) {
    return <Loading />;
  } else {
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <ScrollToHashElement />
          <Router />
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }
}

export default App;