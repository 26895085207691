import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setAppStateSetting } from "Features/AppState/AppStateSlice";
import { Grid, Paper, Accordion, AccordionDetails, AccordionSummary, Button, TextField, Table, TableHead, TableBody, TableRow, TableCell, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import { InfoOutlined, ExpandMore } from "@mui/icons-material";
import Loading from "Components/Loading";
import { PopupWithTextOK } from "Util/Utils";
import { GetLocaleFileAsJson, SaveLocaleFileAsJsonTextFileToS3 } from "Util/LocaleHandling";
import ShowTextKeyExample01 from "Assets/Images/ShowTextKeyExample01.png";
import ShowTextKeyExample02 from "Assets/Images/ShowTextKeyExample02.png";
import ShowTextKeyExample03 from "Assets/Images/ShowTextKeyExample03.png";

const EditLocales = () => {
  const dispatch = useDispatch();

  const appState = useSelector((state) => state.settings.appState);

  const [stateIsLoading, setStateIsLoading] = useState(true);
  const [stateTextFile, setStateTextFile] = useState(null);
  const [stateOpenAccordions, setStateOpenAccordions] = useState( {first: true} );

  useEffect(() => {
    async function asyncUseEffect(){
      dispatch( setAppStateSetting( { title: "Redigera texter" } ));

      const textFile = await GetLocaleFileAsJson();

      setStateTextFile( textFile );

      //Scroll to the top of the page.
      window.scrollTo(0, 0);

      setStateIsLoading( false );
    }
    
    asyncUseEffect();
  }, []);

  async function handleChange( event, currentKey ) {
    setStateTextFile( { ...stateTextFile, [currentKey]: event.target.value });
  }

  async function saveLocaleFile(){
    const json = JSON.stringify( stateTextFile );

    await SaveLocaleFileAsJsonTextFileToS3(json);
    
    PopupWithTextOK(
      "Redigera texter",
      "Språkfilen är nu uppdaterad! OBS! Kom ihåg att du måste ladda om sidan i den flik eller fönster där du tar reda på TextKeys och granskar resultat av textändringar för att ändringarna ska visas i den fliken eller fönstret!",
    );
  }

  async function cancelChanges(){
    const textFile = await GetLocaleFileAsJson();

    setStateTextFile( textFile );
  }

  async function setShowTextKeys( event ){
    if( event.target.value === "true" ){
      dispatch( setAppStateSetting( { showTextKeys: true } ));
    } else {
      dispatch( setAppStateSetting( { showTextKeys: false } ));
    }
  }

  if ( stateIsLoading ) {
    return <Loading />;
  } else {
    var fieldsArray = [];

    for (const [index, currentKey] of Object.keys(stateTextFile).entries()){
      fieldsArray.push(
        <TableRow key={currentKey}>
          <TableCell>
            { currentKey }
          </TableCell>
          <TableCell>
            <TextField
              fullWidth
              multiline
              minRows={2}
              maxRows={5}
              color="primary"
              variant="outlined"
              value={ stateTextFile[currentKey] }
              onChange={ async ( event ) => await handleChange(event, currentKey) }
            />
          </TableCell>
        </TableRow>
      );
    }

    return (
      <>
        <div className="chapterStepContent">
          <div className="accordionSummary">
            <h2>Visa TextKeys</h2>
          </div>
          <div>
            <p>Använd denna inställning för att visa eller dölja TextKeys.</p>
            <p><b>OBS! Denna inställning gäller endast i detta fönster och i denna flik!</b></p>
            <p>Du kan alltså inte öppna ett nytt fönster eller en ny flik för att visa TextKeys! Observera också att inställningen nollställs om du laddar om sidan!</p>
            <FormControl>
              <FormLabel id="showTextKeys-radio-buttons-group-label">Visa TextKeys</FormLabel>
              <RadioGroup
                row={true}
                aria-labelledby="showTextKeys-radio-buttons-group-label"
                name="radio-buttons-group"
                value={appState.showTextKeys}
                onChange={async ( event ) => await setShowTextKeys( event ) }
              >
                <FormControlLabel value={true} control={<Radio />} label="Ja" />
                <FormControlLabel value={false} control={<Radio />} label="Nej (standard)" />
              </RadioGroup>
            </FormControl>
          </div>
          <Accordion
            elevation={0}
            className="backGroundColor5"
            expanded={ stateOpenAccordions.first }
            onChange={ async () => await setStateOpenAccordions( { ...stateOpenAccordions, first: !stateOpenAccordions.first } )}
          >
            <AccordionSummary expandIcon={<ExpandMore />} style={{ padding: "0px" }}>
              <div className="accordionSummary">
                  <h2>Instruktion</h2>
              </div>
            </AccordionSummary>
            <AccordionDetails style={{padding: "0" }}>
              <div style={{width: "100%" }}>
                Följ nedan instruktion för att redigera texter.
                <ol>
                  <li>Öppna upp dev.likabehandlingsplan.se i en ny flik eller fönster (det blir enklare att ha två flikar eller fönster och växla mellan dessa). En där du tar reda på TextKeys och granskar textändringar och en där du gör textändringar).</li>
                  <li>
                      Hovra med muspekaren över en text eller tooltip (informationstext som visas om man hovrar över t.ex. en { <InfoOutlined style={{ width: "14pt", height: "14pt", padding: "0"}} />  } ikon) som du vill ändra. Se nedan exempel:<br/>
                      <img src={ShowTextKeyExample01} alt="TextKey shown on mouse hover" style={{ width: "200px"}} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <img src={ShowTextKeyExample02} alt="TextKey shown on mouse hover" style={{ width: "200px"}} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <img src={ShowTextKeyExample03} alt="TextKey shown on mouse hover" style={{ width: "200px"}} />
                      <br/>
                      OBS! Om det inte visas någon TextKey när du hovrar över en text så betyder det att denna text för närvarande inte kan redigeras. Detta gäller t.ex. Admin gränssnittet. Kontakta utvecklaren ifall fler TextKeys behöver läggas till!
                  </li>
                  <li>Anteckna den "TextKey" som är för texten.</li>
                  <li>Leta upp rätt "TextKey" i nedan tabell och skriv in en ny text.</li>
                  <li>Upprepa steg 2-4 ifall du vill ändra flera texter.</li>
                  <li>Tryck på knappen "Spara" längst ner på denna sida när du är klar.</li>
                  <li>Ladda om sidan i den flik eller fönster där du tar reda på TextKeys och granskar resultatet för att läsa in de nya texterna. Detta görs inte automatiskt utan då måste göra det själv!</li>
                </ol>
                <p>
                  OBS! Endast i undantagsfall återanvänds samma TextKey eftersom man kan vilja använda olika texter för samma funktion om funktionen finns på olika sidor. Därav måste man ändra på värdet för alla TextKeys som ska innehålla samma text. <br/>
                  <br/>
                  Exempel:
                  <ul>
                    <li>"Risker" ska bytas ut till "Risk" på både sidan "/kapitel/arbetsfothallanden/risker" och "/kapitel/arbetsforhallanden/analysochatgarder". Därav måste man ändra texten "Risker" för de TextKeys som används för detta ord på båda dessa sidor. Eftersom övriga sidor har en annan TextKey för ordet "Risker" kommer de att ha kvar samma text.</li>
                  </ul>
                  <br/>
                  Ett sådant undantag är symbolen * som används för att visa att ett fält är obligatoriskt och som har en informationstext som standard heter "Oblikatorisk".
                </p>
                </div>
            </AccordionDetails>
          </Accordion>
          <div className="accordionSummary">
            <h2>Tabell med TextKey och redigerbara texter</h2>
          </div>
          <p>OBS! Läs instruktion ovan innan du börjar redigera texter!</p>
          <p>&nbsp;</p>
          <Table>
            <TableHead>
              <TableRow style={{ backgroundColor: "#ffffff"}}>
                <TableCell>
                  <b>TextKey</b>
                </TableCell>
                <TableCell>
                  <b>Text</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fieldsArray}
            </TableBody>
          </Table>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Paper elevation={0}>
                <Button variant="contained" className="buttonBorderGreenInnerGreen" fullWidth onClick={ async () => await saveLocaleFile() }>
                  Spara
                </Button>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper elevation={0}>
                <Button variant="contained" color="secondary" fullWidth onClick={ async () => await cancelChanges() }>
                    Avbryt
                </Button>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </>
    );
  }
}

export default EditLocales