import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PopupErrorCallback } from "Util/Utils";

const Error = () => {
  const navigate = useNavigate();

  PopupErrorCallback("Fel, gå tillbaka.", () => navigate(-1));
  return (
    <div>
      <h1>Error</h1>
    </div>
  );
}

export default Error;